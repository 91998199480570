import { Card, Col, Row } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"

const Cargando = (props) => {

    return <div>
        <Card className="mb-3 p-4">
        <Row>
            <Col xs={3}><Skeleton height={50} /></Col>
            <Col xs={3}><Skeleton height={50} /></Col>
            <Col xs={3}><Skeleton height={50} /></Col>
            <Col xs={3}><Skeleton height={50} /></Col>
        </Row>
        </Card>
    </div>
}

export default Cargando