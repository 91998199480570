import React, { useEffect, useRef, useState} from 'react'
import { mapbox_token, urlapi } from '../../lib/backend/data';
import socket from '../../lib/websockets';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { cerrarSesion } from '../../redux/actions/session';
import { fechaATexto } from '../../lib/helpers/helpers';
import { obtenerCentroMapaPorPais } from '../../lib/helpers/data/internationa';
import { Map, Marker } from 'react-map-gl';
import * as turf from '@turf/turf';
import { Alert, Button, Card, Col, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { BsCarFrontFill } from 'react-icons/bs'
import { RxOpenInNewWindow } from 'react-icons/rx'
import { rutas } from '../../lib/routes/routes';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from 'mapbox-gl';
import Skeleton from 'react-loading-skeleton';

mapboxgl.accessToken = mapbox_token

const MapaMonitoreoFlota = () => {
    const pais = useSelector(state => state.pais)
    const [marcadores, setMarcadores] = useState([])
    const [ loadingMaster, setLoadingMaster ] = useState(true)
    const [loadingRefrescar, setLoadingRefrescar] = useState(false)
    const [filtroBusqueda, setFiltroBusqueda] = useState("")
    const [intervalo, setIntervalo] = useState(false)
    const sesion = useSelector(state => state.miusuario)
    const idioma = useSelector(state => state.idioma)
    const [coordenadasRecibidas, setCoordenadasRecibidas] = useState(0)
    const [ condicionBusqueda, setCondicionBusqueda ] = useState({})
    const dispatch = useDispatch()
    const coordRef = useRef();
    const defaultViewState = obtenerCentroMapaPorPais(pais)
    const [viewState, setViewState] = useState(defaultViewState);
    const [ zoomInit, setZoomInit ] = useState(false) 
    const mapRef = useRef()
    coordRef.current = coordenadasRecibidas
    const controls = []

    const socketFunctions = () => {
      socket.on(`coordenadas_mapa`, data => {
        toast.success("coordenadas recibidas")
        /* ESTE ES EL PAYLOAD DE DATA
        {
            lat: latitude,
            lng: longitude,
            date: formatDateHoy(new Date()),
            precisiongps:"",
            idusuario: driverauthorization,
            identificador_conductor
        }
        */
        const nuevo = coordRef.current + 1
        setCoordenadasRecibidas(nuevo)
        let nuevos = marcadores
        const i = nuevos.findIndex(m => m.idusuario === data.idusuario)
        if(i > -1){
          nuevos[i] = data
        } else {
          nuevos.unshift(data)
        }
        return setMarcadores(prev => [...[], ...nuevos])
      })
      socket.on('conexion_conductor', datos => {
        refrescarListado()
      })
    }

    const ajustarCentro = (markers) => {
        return false
    if(markers.length < 1) return false
    const points = markers.map(marker => turf.point([marker.lng, marker.lat]));
    const collection = turf.featureCollection(points);
    const bounds = turf.bbox(collection);

    const newViewport = {
      ...viewState,
      latitude: (bounds[1] + bounds[3]) / 2,
      longitude: (bounds[0] + bounds[2]) / 2
    };

    const options = {
      padding: 50 // Ajusta el valor de padding según tus necesidades
    };

    console.log(newViewport)
    setViewState(newViewport);
    mapRef.current.fitBounds(bounds, options);
  }

  const handleGeocoderResult = (event) => {
    if (event.result && event.result.geometry && event.result.geometry.coordinates) {
      const [lng, lat] = event.result.geometry.coordinates;
      setViewState({ ...viewState, longitude: lng, latitude: lat, zoom: 12 });
    }
  };

  const geocodificador = () => {
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      marker: false, // Desactivar el marcador de ubicación seleccionada
      placeholder: "Busca una dirección",
      countries: pais
    });
    geocoder.on('result', handleGeocoderResult);
    setTimeout(() => {
      if(controls.length < 1) mapRef.current?.addControl(geocoder, "top-right")
      controls.push('geocoder')
    }, 200);
  }

  const handleLoadMap = () => {
    geocodificador()
  }

    useEffect(() => {
      refrescarListado(null, true)
      const id_intervalo = setInterval(() => {
        refrescarListado()
      }, 4000);
      setIntervalo(id_intervalo)
      const identificador_1 = 'coordenadas_mapa'
      const identificador_2 = 'conexion_conductor'
      socket.on(identificador_1, data => {
        const nuevo = coordRef.current + 1
        setCoordenadasRecibidas(nuevo)
        let nuevos = marcadores
        const i = nuevos.findIndex(m => m.idusuario === data.idusuario)
        if(i > -1){
          nuevos[i] = data
        } else {
          nuevos.unshift(data)
        }
        return setMarcadores(prev => [...[], ...nuevos])
      })
        socket.on(identificador_2, datos => {
            refrescarListado()
        })
      return () => {
        clearInterval(id_intervalo)
        socket?.off(identificador_1)
        socket?.off(identificador_2)
      }
  },[ ])

  const refrescarListado = async (cond, centrar) => {
    
    const query = cond ? cond : condicionBusqueda
    return fetch(`${urlapi}/conductores/vehicles-actives`,{
        method:'POST',
        body: JSON.stringify({
          condicion: query
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${sesion.tokenSession}`,
            'country': pais,
            'lang': idioma
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
        if(!res){
            return false
        } else if(res.errorMessage){
            return false
        } else if(Array.isArray(res) !== false){
          let ids_usuarios = []
          for( const marker of marcadores ){
            const { idusuario } = marker
            if(!idusuario) continue
            const i = res.findIndex(m => m._id === marker.idusuario)
            if(i < 0){
              ids_usuarios.push(marker.idusuario)
            }
          }
          // console.log({ids_usuarios})
          for( const id of ids_usuarios ){
            const pos = marcadores.findIndex(m => m.idusuario === id)
            if(pos > -1) marcadores.splice(pos,1)
          }
          setMarcadores(prev => [...[], ...res])
          if(centrar === true){
            setTimeout(() => {
              ajustarCentro(res)
            }, 300);
          }
        }
        setLoadingMaster(false)
        return setLoadingRefrescar(false)
    })
    .catch(error => {
        return setLoadingRefrescar(false)
    })
}

const handleChangeFiltroProveedor = (e) => {
    const { value } = e.target
    return setFiltroBusqueda(value)
}

const centrarMapa = (centro, max_zoom) => {
  const nuevo_zoom = defaultViewState.zoom + (max_zoom ? max_zoom : 3)
  console.log({centro, max_zoom})
  mapRef.current?.flyTo({center: [centro.longitude, centro.latitude], zoom: nuevo_zoom, duration: 2000});
}

  const visualizarListado = () => {

    if(loadingMaster) return <div>
        <Skeleton className='mb-3' height={20} />
        <Skeleton className='mb-3' height={30} />
        <Skeleton className='mb-3' height={20} />
        <Skeleton className='mb-3' height={30} />
        <Skeleton className='mb-3' height={20} />
        <Skeleton className='mb-3' height={30} />
        <Skeleton className='mb-3' height={20} />
    </div>

    if(marcadores.length < 1) return <div >
      <h5>No hay conductores</h5>
    </div>


        const filtrar = filtroBusqueda.toLowerCase();
        let datos_filtrados = marcadores.filter(item => {
            return Object.keys(item).some(key => {
            if(!item[key]) return false
            if(key === 'orden'){
                if(!item[key].billing) return false
                return Object.keys(item[key].billing).some(llave => {
                    if(!item[key].billing[llave]) return false
                    return item[key].billing[llave].toString().toLowerCase().includes(filtrar)
                })
            }
            return item[key].toString().toLowerCase().includes(filtrar)
            })
        })
        
    return <div className='p-3'>
      { loadingRefrescar ? <Spinner /> : false }
      <h5>{marcadores.length} Conductores</h5>
      <p>Se muestran conductores de tu flota con su última ubicación conocida</p>
      <input className='form-control mb-2' placeholder='BUSCAR VEHÍCULO' onChange={handleChangeFiltroProveedor} />
      <div style={{ maxHeight: "50vh", overflowY: "scroll" }}>
      {
        datos_filtrados.map((mark,i) => {
          return <div key={`driver-${mark.idusuario}`} style={{ paddingTop: 13 }}>
            <h5 className='mb-0'>{mark.identificador_conductor} <a target='_blank' href={`/${rutas.vehiculos.slug}/edit/${mark._id}`}><RxOpenInNewWindow /></a> </h5>
            <h6 className='mb-0' style={{ textTransform: "uppercase",  }} >PATENTE: <b>{mark.patente}</b></h6>
            <p className='mb-0'><Moment locale="ES" fromNow>{mark.fecha}</Moment></p>
            <Button size="sm" variant='outline-dark' style={{ fontSize: 12 }} onClick={() => centrarMapa({ latitude: mark.lat, longitude: mark.lng })}>MOSTRAR</Button>
          </div>
        })
      }
      </div>
    </div>
  }

  const onFilter = (cond) => {
    setLoadingRefrescar(true)
    setCondicionBusqueda(cond)
    refrescarListado(cond, true)
    clearInterval(intervalo)
    const id_intervalo = setInterval(() => {
      refrescarListado(cond)
    }, 4000);
    setIntervalo(id_intervalo)
  }

  return <Row >
  <Col item md={3} xs={12} >
    <div style={{ padding: 20 }}>
    <h3>Mi flota</h3>
    {visualizarListado()}
    </div>
  </Col>
  <Col item md={9} xs={12}>
        <Map
    ref={mapRef}
    {...viewState}
    onMove={evt => setViewState(evt.viewState)}
    style={{width: "100%", height: "100vh"}}
    mapStyle="mapbox://styles/mapbox/streets-v9"
    mapboxAccessToken={mapbox_token}
    onLoad={handleLoadMap}
  >
    {
        marcadores.map(marker => {
            const content = (
                <div>
                    <h4>{marker.fecha}</h4>
                </div>
            )
            return <Marker
                    key={marker._id}
                    longitude={marker.lng}
                    latitude={marker.lat}
                >
                    <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >{marker.identificador_conductor}<br/>
                              <Moment locale="ES" fromNow>{marker.fecha}</Moment></Tooltip>
                            }
                            >
                    <div>
                        <BsCarFrontFill  size={40} />
                    
                  </div>
            </OverlayTrigger>
            </Marker>
        })
    }
  </Map>
  </Col>
  </Row>
}

export default MapaMonitoreoFlota