const stringByStatusLote = (val) => {
    switch (val) {
        case "pendiente-aprobacion-cliente":
            return "Pendiente de aprobación del cliente"
        case "aprobado-cliente":
            return "Aprobado por cliente"
        case "rechazado-cliente":
            return "Rechazado por cliente"
        case "trash":
            return "Inactivo"
        case "caducada":
            return "Vencido"
        default:
            return val
    }
}

module.exports = {
    stringByStatusLote
}