import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button, Table, Tabs, Tab, Accordion } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import data, { key_local_storage_user } from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import Moment from 'react-moment';
import 'moment/locale/es';
import PantallaCargando from '../../subComponents/general/pantalla_cargando'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Select from 'react-select';
import FlujosEstadosCarga from '../../subComponents/estados_carga/flujos'
import { showByTipoServicio } from '../../lib/helpers/pedidos/pedidos'

class EstadosCarga extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem(key_local_storage_user)),
            loadingEstados: true,
            loadingMore: false,
            total: 0,
            usuarios: [],
            parametro_busqueda: '',
            loadingBusqueda: false,
            filtro_rol: '',
            loadingSubestados: true,
            rutcliente: '',
            loadingUpload: false,
            clienteconsultar: { value: this.props.idconsulta, label: this.props.razonsocial },
            tipovisualizacion: 'estados',
            estados: [], 
            subestados: [],
            nuevo_estado: {},
            nuevo_subestado:{},
            id_estado_crear_subestado: false,
            eliminando: false,
            loadingAcciones: true,
            acciones: [],
            acciones_subestado_actual: [],
            eliminandoAcciones: false,
            tipos_servicio: [],
            filtro_tipo_servicio: '',
            configuracion: {},
            loadingConfiguracionNotificaciones: true,
            loadingConfiguracion: true,
            plantillas_ws:[],
            loadingPlantillasWhatsapp: true
        }

        this.handleChange = this.handleChange.bind(this)
        this.buscarUsuario = this.buscarUsuario.bind(this)
        this.handlechangeStatus = this.handlechangeStatus.bind(this)
        this.handleChangeSelectTipoServicio = this.handleChangeSelectTipoServicio.bind(this)
        this.handleChangeSelectTipoServicioFiltro = this.handleChangeSelectTipoServicioFiltro.bind(this)
        this.handleChangeAccion = this.handleChangeAccion.bind(this)
        this.handleChangeAccionArray = this.handleChangeAccionArray.bind(this)
        this.handlechangeSubestado = this.handlechangeSubestado.bind(this)
        this.handleChangeConfig = this.handleChangeConfig.bind(this)

    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeAccion(e){
        const { name, value, id } = e.target
        const { acciones_subestado_actual } = this.state
        const i = acciones_subestado_actual.findIndex(acc => acc._id === id)
        if(i < 0) return false
        acciones_subestado_actual[i][name] = value
        console.log(acciones_subestado_actual)
        return this.setState({ acciones_subestado_actual })
    }

    handleChangeAccionArray(e){
        const { value, id } = e.target
        const { acciones_subestado_actual } = this.state
        const i = acciones_subestado_actual.findIndex(acc => acc._id === id)
        if(i < 0) return false
        acciones_subestado_actual[i].valor = value
        this.setState({ acciones_subestado_actual })
        const tienecoma = value.indexOf(',')
        if( tienecoma < 0) return false
        const dividir = value.split(',')
        if(!acciones_subestado_actual[i].opciones) acciones_subestado_actual[i].opciones = []
        acciones_subestado_actual[i].opciones.push(dividir[0])
        acciones_subestado_actual[i].valor = ''
        return this.setState({ acciones_subestado_actual })
    }

    componentDidMount(){
        this.getEstados()
        this.getConfiguracion('logistica','tipos-servicio','tipos_servicio')
        this.getAcciones()
        this.getPlantillasWhatsapp()
    }

    getPlantillasWhatsapp(){
        const { user } = this.state
        const loading = 'loadingPlantillasWhatsapp'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/whatsapp/plantillas`,{
            method: "GET",
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(pros => pros.json())
        .then(data => { 
            if(!data){
                toast.warn('No hay configuración de notificaciones creada para este subestado', this.state.toaststyle)
            } else if(data.errorMessage){
                toast.error(data.errorMessage)
                return this.setState({ [loading]: false })
            } else if(Array.isArray(data) !== false){
                this.setState({ plantillas_ws: data })
            }
            return this.setState({ [loading]: false  })
        })
        .catch(error => {
            toast.error('No pudimos cargar las plantillas de whatsapp', this.state.toaststyle)
            this.setState({ [loading]: false })
        })
    }

    async getConfiguracion(tipo,subtipo,estado){
        const { user } = this.state
        const loading = 'loadingConfiguracion'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/configuracion/tipo?tipo=${tipo}&subtipo=${subtipo}`,{
            method: "GET",
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(pros => pros.json())
        .then(data => { 
            console.log(data)
            if(data.errorMessage){
                toast.error(data.errorMessage)
                return this.setState({ loadingFormulario: false })
            } else if(Array.isArray(data) !== false){
                if(data.length > 0){
                    const datos = data.map(e => ({ value: e._id, label: e.valor }))
                    this.setState({ [estado]: datos })
                }
            }
            return this.setState({ [loading]: false  })
        })
        .catch(error => {
            toast.error('No pudimos cargar la información', this.state.toaststyle)
           this.setState({ [loading]: false })
        })
    }

    getEstados(propietario){
        const { user } = this.state
        const loading = 'loadingEstados'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/estadoscarga/details`,{
            method: "GET",
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            },
        })
        .then(pros => pros.json())
        .then(data => { 
            if(data.errorMessage){
                toast.error(data.errorMessage)
                return this.setState({ loadingFormulario: false })
              }
            if(data.estados.length < 1) toast.warn('No hay registros', this.state.toaststyle)
            this.setState({ [loading]: false, subestados: data.subestados, estados: data.estados  })
        })
        .catch(error => {
            toast.error('No pudimos cargar la información', this.state.toaststyle)
           this.setState({ [loading]: false })
        })
    }

    buscar(){
        const { parametro_busqueda, user } = this.state
        if(!parametro_busqueda) return toast.error('El valor de búsqueda es requerido')
        if(parametro_busqueda.length < 3) return toast.error('Ingresa un valor de búsqueda válido')
        let loading = 'loadingBusqueda'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/usuarios/search`,{
            method:'POST',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            },
            body: JSON.stringify({ parametro_busqueda })
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            if(res.length < 1){
                toast.warn('No se encontraron resultados')
                return this.setState({ [loading]: false })
            }
            return this.setState({ usuarios: res, filtro_rol:'', [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    async getUsuarios(cargarmas){
        const { user, usuarios } = this.state
        let loading = 'loadingEstados'
        if(cargarmas===true) loading = 'loadingMore'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/usuarios/list?skip=${usuarios.length}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            if(cargarmas===true){
                if(Array.isArray(res.datos) !== false){
                    const final_array = usuarios.concat(res.datos)
                    return this.setState({ usuarios: final_array, total: res.total, [loading]: false })
                } else {
                    return this.setState({ [loading]: false })
                }
            } else {
                this.setState({ usuarios: res.datos, total: res.total, [loading]: false })
            }
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    buscarUsuario(e){
        e.preventDefault()
        return this.buscar()
    }
    
    sinRegistros(){
        return <div className='p-5'>
            <Row className="justify-content-md-center">
                <Col md={6} className="text-center">
                <h4>Aún no tienes usuarios</h4>
                <p>Puedes crear diversos usuarios y asignar permisos a cada uno de ellos</p>
                </Col>
            </Row>
        </div>
    }

    crearEstado(){
        const { nuevo_estado, clienteconsultar, user, estados } = this.state
        if(!nuevo_estado.titulo) return toast.error('Título es requerido', this.state.toaststyle)
        if(!nuevo_estado.color) return toast.error('color es requerido', this.state.toaststyle)
        if(!nuevo_estado.codigo_estado) return toast.error('Código es requerido', this.state.toaststyle)
        if(!nuevo_estado.tipos_servicio) return toast.error('tipo servicio es requerido', this.state.toaststyle)
        if(nuevo_estado.tipos_servicio.length < 1) return toast.error('tipo servicio es requerido', this.state.toaststyle)
        if(nuevo_estado.tipo.length < 1) return toast.error('tipo es requerido', this.state.toaststyle)
        nuevo_estado.propietario = clienteconsultar.value
        this.setState({ loadingFormulario: true })
        return fetch(`${data.urlapi}/estadoscarga/create-or-edit-estado`, {
            method: 'POST',
            body: JSON.stringify(nuevo_estado),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                toast.error('Hubo un error de servidor', this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                toast.error(pros.errorMessage, this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              }
              toast.success(`Realizado exitosamente`, this.state.toaststyle)
              if(!nuevo_estado._id){
                if(pros._id) estados.push(pros)
            }
              return this.setState({ loadingFormulario: false, estados, tipovisualizacion: 'estados' })
          })
          .catch(async error => {
              console.log(error)
            this.setState({ loadingFormulario: false })
            return toast.error("No pudimos consultar la información, intente de nuevo recargando la página", this.state.toaststyle)
          })
    }

    handlechangeStatus(e){
        const { name, value } = e.target
        const { nuevo_estado } = this.state
        nuevo_estado[name] = value
        return this.setState({ nuevo_estado })
    }

    handleChangeSelectTipoServicioFiltro(e){
        return this.setState({ filtro_tipo_servicio: e.value })
    }

    handleChangeSelectTipoServicio(e){
        const { nuevo_estado } = this.state
        let opciones = []
        if(e){
            e.map(valor => {
                return opciones.push(valor.value)
            })
        }
        nuevo_estado.tipos_servicio = opciones
        return this.setState({ nuevo_estado })
    }

    formularioCreacion(){
        const { loadingFormulario, loadingConfiguracion, nuevo_estado, tipos_servicio } = this.state
    
        if(!nuevo_estado) return false
        if(loadingFormulario) return <PantallaCargando />
    
        let opciones_tipo_servicio_elegidas = []
    
            if(nuevo_estado.tipos_servicio){
                if(Array.isArray(nuevo_estado.tipos_servicio) !== false){
                    if(nuevo_estado.tipos_servicio.length > 0){
                        opciones_tipo_servicio_elegidas = tipos_servicio.filter(op => {
                            return nuevo_estado.tipos_servicio.includes(op.value)
                        })
                    }
                }
        }
    
        return <div >
        
        <h4>{nuevo_estado._id ? `Editar ${nuevo_estado.titulo}` : `Crear nuevo estado`}</h4>
        <button className="btn btn-sm btn-outline-danger mb-3" onClick={() => this.setState({ tipovisualizacion: 'estados' })} >CANCELAR</button>
        <Row>
            <Col md={5} className="mb-3">
                <label className='form-control-label'>Título</label>
                <input className='form-control' name="titulo" defaultValue={nuevo_estado.titulo} onChange={this.handlechangeStatus} />
            </Col>
    
            <Col md={2} className="mb-3">
                <label className='form-control-label'>Código</label>
                <input className='form-control' name="codigo_estado" defaultValue={nuevo_estado.codigo_estado} onChange={this.handlechangeStatus} />
            </Col>
    
            <Col md={2} className="mb-3">
                <label className='form-control-label'>Color</label>
                <input className='form-control' type="color" name="color" defaultValue={nuevo_estado.color} onChange={this.handlechangeStatus} />
            </Col>
    
            <Col md={2} className="mb-3">
                        <label className='form-control-label'>Tipo</label>
                        <select className='form-control' name="tipo" defaultValue={nuevo_estado.tipo} onChange={this.handlechangeStatus}>
                            <option value="">Selecciona</option>
                            <option value="web">Web</option>
                            <option value="movil">Móvil</option>
                        </select>
            </Col>

            <Col md={6} className="mb-3">
            <h6 className='hover' onClick={()=>this.setBoleanPropertyEstado('mostrar_linea_tiempo')}>{ nuevo_estado.mostrar_linea_tiempo === true ? 
            <i className="fas text-success fa-check-square"></i> :
            <i className="far fa-square"></i> } Mostrar en la línea de tiempo de tracking</h6>
            </Col>
            <Col md={6} className="mb-3">
            <h6 className='hover' onClick={()=>this.setBoleanPropertyEstado('opcional_linea_tiempo')}>{ nuevo_estado.opcional_linea_tiempo === true ? 
            <i className="fas text-success fa-check-square"></i> :
            <i className="far fa-square"></i> } Opcional en la linea de tiempo</h6>
            </Col>
    
            {
                nuevo_estado.mostrar_linea_tiempo === true ? <Col md={3} className="mb-3">
                        <label className='form-control-label'>Posición en la línea de tiempo</label>
                        <input className='form-control' name="posicion_linea_tiempo" defaultValue={nuevo_estado.posicion_linea_tiempo}  onChange={this.handlechangeStatus} />
                </Col> : false
            }
            <Col md={6} className="mb-3">
                    <label className="form-control-label d-block">Filtrar por tipo servicio</label>
                    <Select 
                        style={{ marginBottom: 10 }}
                        onChange={this.handleChangeSelectTipoServicio}
                        defaultValue={opciones_tipo_servicio_elegidas}
                        options={tipos_servicio}
                        isMulti={true}
                        isLoading={loadingConfiguracion}
                        noOptionsMessage={()=>'Sin opciones'}
                        placeholder="Tipo de servicio..."
                    />
            </Col>
    
            <Col md={3} className="mb-3">
                <label className='form-control-label d-block'>Click para </label>
                <button className='btn btn-success' onClick={()=>this.crearEstado()}>{ nuevo_estado._id ? 'GUARDAR CAMBIOS' : 'CREAR' }</button>
            </Col>
        </Row>
        </div>
    }

    mostrarUsuarios(){
        const { usuarios, loadingEstados, loadingMore, total, filtro_rol, loadingBusqueda } = this.state
        if(loadingEstados) return <PantallaCargando />
        if(usuarios.length < 1) return this.sinRegistros()

        const filtrados_rol = filtro_rol ? usuarios.filter(user => user.role === filtro_rol) : usuarios
        return <div>

            <form onSubmit={this.buscarUsuario}>
            <Row className='mb-3'>
                <Col md={2} className='mb-3'>
                    <label className='form-control-label d-block'>Buscar</label>
                    <input className='form-control' name="parametro_busqueda" onChange={this.handleChange} />
                </Col>
                <Col md={2} className='mb-3'>
                    <label className='form-control-label d-block'>Click para buscar</label>
                    {
                        loadingBusqueda === true ? <Spinner animation='border' /> : <Button size="sm" variant="outline-primary" onClick={()=>this.buscar()}>BUSCAR</Button>
                    }
                </Col>
                <Col md={2}>
                    <label className='form-control-label d-block'>Filtrar por rol</label>
                    <select className='form-control' name="filtro_rol" value={filtro_rol} onChange={this.handleChange} >
                        <option value="" >Seleccione</option>
                        <option value="user" >Usuario</option>
                        <option value="admin" >Administrador</option>
                    </select>
                </Col>
            </Row>
            </form>
            
            <Tabs defaultActiveKey="activos" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="activos" title="Activas">
                <h5 className='mb-3'>Se muestran {filtrados_rol.length} de {total} registros</h5>
                <div className='table-responsive'>
                <Table bordered className='table'>
                    <thead>
                        <tr>
                            <th>Nombres</th>
                            <th>Apellidos</th>
                            <th>Email</th>
                            <th>Teléfono</th>
                            <th>Estado</th>
                            <th>Creación</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filtrados_rol.map((usuario,i) => {
                                return <tr key={`user-${usuario._id}`}>
                                        <th><Link to={`/usuarios/edit-${usuario._id}`}>{usuario.nombres}</Link> </th>
                                        <th>{usuario.apellidos}</th>
                                        <th>{usuario.email}</th>
                                        <th>{usuario.phone}</th>
                                        <th>{usuario.activo === true ? 'Activo' : 'Inactivo'}</th>
                                        <th><Moment locale="ES" fromNow>{usuario.createdAt}</Moment></th>
                                    </tr>
                            })
                        }
                    </tbody>

                </Table>
                {
                    usuarios.length < total ? <div>
                        {
                            loadingMore === true ? <Spinner animation='border' /> : <Button size="sm" variant="outline-primary" onClick={()=>this.getUsuarios(true)}>CARGAR MÁS</Button>
                        }
                    </div> : false
                    
                }
                
                </div>
            </Tab>
            <Tab eventKey="archivados" title="Archivados">

            </Tab>
            </Tabs>
            
            
        </div>
    }

    editarEstado(nuevo_estado){
        return this.setState({ tipovisualizacion: 'nuevoestado', nuevo_estado })
    }

    eliminarRegistro(id){
        const { estados, user } = this.state
        const estado_loading = 'eliminando'
        this.setState({ [estado_loading]: true })
        return fetch(`${data.urlapi}/estadoscarga/eliminar-estado?id=${id}`,{
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            },
        })
        .then(res => res.json())
        .then(res => {
            if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [estado_loading]: false })
            }
            toast.success('Eliminado exitosamente', this.state.toaststyle)
            const i = estados.findIndex(a => a._id === id)
            if(i > -1) estados.splice(i,1)
            return this.setState({ [estado_loading]: false, estados })
        })
        .catch(error => {
            toast.error("Ocurrió un error de servidor", this.state.toaststyle)
            return this.setState({ [estado_loading]: false })
        })
    }

    solicitarEliminarAccion(id){
        return confirmAlert({
            title: '¿Estás seguro?',
            message: `¿Deseas eliminar este item? Esta opción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => this.eliminarAccion(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              },
            ],
          })
    }

    solicitarEliminar(id){
        return confirmAlert({
            title: '¿Estás seguro?',
            message: `¿Deseas eliminar este item? Esta opción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => this.eliminarRegistro(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              },
            ],
          })
    }

    eliminarAccion(id){
        const { acciones_subestado_actual } = this.state
        const estado_loading = 'eliminandoAcciones'
        this.setState({ [estado_loading]: true })
        return fetch(`${data.urlapi}/estadoscarga/eliminar-accion?id=${id}`)
        .then(res => res.json())
        .then(res => {
            if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [estado_loading]: false })
            }
            toast.success('Eliminado exitosamente', this.state.toaststyle)
            const i = acciones_subestado_actual.findIndex(a => a._id === id)
            if(i > -1) acciones_subestado_actual.splice(i,1)
            return this.setState({ [estado_loading]: false, acciones_subestado_actual })
        })
        .catch(error => {
            toast.error("Ocurrió un error de servidor", this.state.toaststyle)
            return this.setState({ [estado_loading]: false })
        })
    }

    mostrarTiposServicioConcatenados(tipos){
        const { tipos_servicio } = this.state
        if(!tipos) return false
        if(Array.isArray(tipos) !== true) return false
        if(tipos.length < 1) return false
        return tipos.map(tipo => <span className='spanguia mr-2' style={{ fontSize: 11 }}>{showByTipoServicio(tipo, tipos_servicio)}</span>)
    }

    nuevoSubEstado(id_estado_crear_subestado){
        this.getConfiguracionNotificaciones(false)
        return this.setState({ tipovisualizacion: 'nuevosubestado', id_estado_crear_subestado, nuevo_subestado: {} })
  }

  async getConfiguracionNotificaciones(idsubestado){
      const { user } = this.state
      const loading = 'loadingConfiguracionNotificaciones'
      if(!idsubestado) return this.setState({ [loading]: false })
    this.setState({ [loading]: true })
    return fetch(`${data.urlapi}/estadoscarga/notificacion-por-subestado?idsubestado=${idsubestado}`,{
        method: "GET",
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${user.tokenSession}`
        },
    })
    .then(pros => pros.json())
    .then(data => { 
        if(!data){
            toast.warn('No hay configuración de notificaciones creada para este subestado', this.state.toaststyle)
        } else if(data.errorMessage){
            toast.error(data.errorMessage)
        }
        return this.setState({ [loading]: false, configuracion: data ? data : {}  })
    })
    .catch(error => {
        toast.error('No pudimos cargar la información', this.state.toaststyle)
        this.setState({ [loading]: false })
    })
}

  editarSubEstado(nuevo_subestado){
    this.setState({ 
        tipovisualizacion: 'nuevosubestado', 
        nuevo_subestado, 
        id_estado_crear_subestado: nuevo_subestado.idparent,
        acciones_subestado_actual: []
    })
    this.getConfiguracionNotificaciones(nuevo_subestado._id)
    return this.getAccionesPorSubEstado(nuevo_subestado._id)
}

getAccionesPorSubEstado(idsubestado){
    const { user } = this.state
    const loading = 'loadingAcciones'
    this.setState({ [loading]: true })
    return fetch(`${data.urlapi}/estadoscarga/acciones-por-subestado?idsubestado=${idsubestado}`,{
        method: "GET",
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${user.tokenSession}`
        },
    })
    .then(pros => pros.json())
    .then(data => { 
        console.log(data)
        if(data.errorMessage){
            toast.error(data.errorMessage)
            return this.setState({ [loading]: false })
        }
        this.setState({ [loading]: false, acciones_subestado_actual: data  })
    })
    .catch(error => {
        toast.error('No pudimos cargar la información', this.state.toaststyle)
        this.setState({ [loading]: false })
    })
}

getAcciones(){
    const { user } = this.state
    const loading = 'loadingAcciones'
    this.setState({ [loading]: true })
    return fetch(`${data.urlapi}/estadoscarga/acciones`,{
        method: "GET",
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${user.tokenSession}`
        },
    })
    .then(pros => pros.json())
    .then(data => { 
        if(data.errorMessage){
            toast.error(data.errorMessage)
            return this.setState({ [loading]: false })
        }
        if(data.length < 1) toast.warn('No hay acciones disponibles para crear formularios', this.state.toaststyle)
        this.setState({ [loading]: false, acciones: data  })
    })
    .catch(error => {
        toast.error('No pudimos cargar la información', this.state.toaststyle)
       this.setState({ [loading]: false })
    })
}

guardarCambiosAccion(id){
    const { acciones_subestado_actual, user } = this.state
    const i = acciones_subestado_actual.findIndex(acc => acc._id === id)
    if(i < 0) return false
    const loading = 'loadingAcciones'
    acciones_subestado_actual[i].valor = ''
    this.setState({ [loading]: true })
    return fetch(`${data.urlapi}/estadoscarga/actualizar-accion`, {
        method: 'POST',
        body: JSON.stringify(acciones_subestado_actual[i]),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${user.tokenSession}`
        }
      })
      .then(pros => pros.json())
      .then(async pros => {
          if(!pros){
            toast.error('Hubo un error de servidor', this.state.toaststyle)
            return this.setState({ [loading]: false })
          } else if(pros.errorMessage){
            toast.error(pros.errorMessage, this.state.toaststyle)
            return this.setState({ [loading]: false })
          }
          toast.success(`Operación realizada exitosamente`, this.state.toaststyle)
          return this.setState({ [loading]: false })
      })
      .catch(async error => {
          console.log(error)
        this.setState({ [loading]: false })
        return toast.error("No pudimos consultar la información, intente de nuevo recargando la página", this.state.toaststyle)
      })
}

obligatorio(id){
    const { acciones_subestado_actual } = this.state
    const i = acciones_subestado_actual.findIndex(acc => acc._id === id)
    if(i < 0) return false
    acciones_subestado_actual[i].required = acciones_subestado_actual[i].required === true ? false : true
    return this.setState({ acciones_subestado_actual })
}

removerOpcionAccion(_id, i){
    const { acciones_subestado_actual } = this.state
    const pos = acciones_subestado_actual.findIndex(acc => acc._id === _id)
    if(pos < 0) return false
    acciones_subestado_actual[pos].opciones.splice(i,1)
    return this.setState({ acciones_subestado_actual })
}

mostrarAccionesDisponibles(nuevo_subestado){
    if(!nuevo_subestado._id) return false
    const { acciones, loadingAcciones } = this.state
    if(loadingAcciones) return <Spinner animation='border' />
    if(acciones.length < 1) return <h4>No hay acciones disponibles para agregar al formulario</h4>

    return <div>
        <p className='mb-2'>Toca la opción para agregar a este subestado</p>
        {
            acciones.map((accion,i) => {
                return <span key={`accion-${i}`} onClick={() => this.crearAccion(accion.tipo_accion, nuevo_subestado._id)} className='p-2 hover' style={{ backgroundColor: 'white', border: '1px solid #767676', marginRight: 10 }} >{accion.titulo}</span>
            })
        }
    </div>
}

eliminarSubEstado(id){
    const { subestados } = this.state
    const estado_loading = 'eliminando'
    this.setState({ [estado_loading]: true })
    return fetch(`${data.urlapi}/estadoscarga/eliminar-subestado?id=${id}`)
    .then(res => res.json())
    .then(res => {
        if(res.errorMessage){
            toast.error(res.errorMessage)
            return this.setState({ [estado_loading]: false })
        }
        toast.success('Eliminado exitosamente', this.state.toaststyle)
        const i = subestados.findIndex(a => a._id === id)
        if(i > -1) subestados.splice(i,1)
        return this.setState({ [estado_loading]: false, subestados })
    })
    .catch(error => {
        toast.error("Ocurrió un error de servidor", this.state.toaststyle)
        return this.setState({ [estado_loading]: false })
    })
}

solicitarEliminarSubEstado(id){
    return confirmAlert({
        title: '¿Estás seguro?',
        message: `¿Deseas eliminar este item? Esta opción no se puede deshacer`,
        buttons: [
          {
            label: 'CONFIRMAR',
            onClick: () => this.eliminarSubEstado(id)
          },
          {
            label: 'CANCELAR',
            onClick: () => false
          },
        ],
      })
}

crearSubEstado(){
    const { nuevo_subestado, subestados, user, clienteconsultar, id_estado_crear_subestado  } = this.state
    if(!nuevo_subestado.titulo) return toast.error('Título es requerido', this.state.toaststyle)
    if(!nuevo_subestado.codigo_estado) return toast.error('Código es requerido', this.state.toaststyle)
    if(!nuevo_subestado.tipo) return toast.error('tipo es requerido', this.state.toaststyle)
    nuevo_subestado.propietario = clienteconsultar.value
    nuevo_subestado.idparent = id_estado_crear_subestado 
    this.setState({ loadingFormulario: true })
    return fetch(`${data.urlapi}/estadoscarga/create-or-edit-subestado`, {
        method: 'POST',
        body: JSON.stringify(nuevo_subestado),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${user.tokenSession}`
        }
    })
    .then(pros => pros.json())
    .then(async pros => {
        console.log(pros)
        if(!pros){
            toast.error('Hubo un error de servidor', this.state.toaststyle)
            return this.setState({ loadingFormulario: false })
        } else if(pros.errorMessage){
            toast.error(pros.errorMessage, this.state.toaststyle)
            return this.setState({ loadingFormulario: false })
        }
        toast.success(`Operación realizada exitosamente`, this.state.toaststyle)
        if(!nuevo_subestado._id){
            if(pros._id) subestados.push(pros)
        }
        return this.setState({ loadingFormulario: false, subestados, tipovisualizacion: 'estados' })
    })
    .catch(async error => {
        this.setState({ loadingFormulario: false })
        return toast.error("No pudimos consultar la información, intente de nuevo recargando la página", this.state.toaststyle)
    })
}

setBoleanPropertyEstado(name){
    const { nuevo_estado } = this.state
    nuevo_estado[name] = nuevo_estado[name] === true ? false : true
    return this.setState({ nuevo_estado })
}

setBoleanProperty(name){
    const { nuevo_subestado } = this.state
    nuevo_subestado[name] = nuevo_subestado[name] === true ? false : true
    return this.setState({ nuevo_subestado })
}

handlechangeSubestado(e){
    const { name, value } = e.target
    const { nuevo_subestado } = this.state
    nuevo_subestado[name] = value
    console.log(nuevo_subestado)
    return this.setState({ nuevo_subestado })
}

formularioCreacionSubestado(){
    const { loadingFormulario, eliminandoAcciones, acciones_subestado_actual, estados, nuevo_subestado, id_estado_crear_subestado } = this.state

    let titulo_estado = ''
    const iestado = estados.findIndex(es => es._id === id_estado_crear_subestado)
    if(iestado > -1) titulo_estado = estados[iestado].titulo
    

    if(loadingFormulario) return <Spinner animation='border' />

    return <div >
    <h6> {nuevo_subestado._id ? `Editar subestado ${nuevo_subestado.titulo} · ${titulo_estado}` : `Crear nuevo subestado dentro de ${titulo_estado}`} </h6>
    <button className="btn btn-sm btn-outline-danger mb-3" onClick={() => this.setState({ tipovisualizacion: 'estados', nuevo_subestado: {}, acciones_subestado_actual: [] })} >CANCELAR</button>
        <div className="row">
            <div className="col-md-5 mb-3">
                <label className='form-control-label'>Título</label>
                <input className='form-control' name="titulo" defaultValue={nuevo_subestado.titulo}  onChange={this.handlechangeSubestado} />
            </div>

            <div className="col-md-2 mb-3">
                <label className='form-control-label'>Código</label>
                <input className='form-control' name="codigo_estado" defaultValue={nuevo_subestado.codigo_estado}  onChange={this.handlechangeSubestado} />
            </div>
            

            <div className="col-md-2 mb-3">
                <label className='form-control-label'>Tipo</label>
                <select className='form-control' name="tipo" defaultValue={nuevo_subestado.tipo} onChange={this.handlechangeSubestado}>
                    <option value="">Selecciona</option>
                    <option value="web">Web</option>
                    <option value="movil">Móvil</option>
                </select>
            </div>

            <div className="col-md-2 mb-3">
                <label className='form-control-label'>Color</label>
                <input className='form-control' type="color" name="color" defaultValue={nuevo_subestado.color} onChange={this.handlechangeSubestado} />
            </div>

            <div className="col-md-12 mb-2">
                
            <h6 className='hover' onClick={()=>this.setBoleanProperty('publico')}>{ nuevo_subestado.publico === true ? 
            <i className="fas text-success fa-check-square"></i> :
            <i className="far fa-square"></i> } Público (Clientes ven este estado)</h6>

            <h6 className='hover' onClick={()=>this.setBoleanProperty('email')}>{ nuevo_subestado.email === true ? 
            <i className="fas text-success fa-check-square"></i> :
            <i className="far fa-square"></i> } Envía correo electrónico</h6>
            
            <h6 className='hover' onClick={()=>this.setBoleanProperty('whatsapp')}>{ nuevo_subestado.whatsapp === true ? 
            <i className="fas text-success fa-check-square"></i> :
            <i className="far fa-square"></i> } Envía whatsapp</h6>

            <h6 className='hover' onClick={()=>this.setBoleanProperty('notificar_planel_flota')}>{ nuevo_subestado.notificar_planel_flota === true ? 
            <i className="fas text-success fa-check-square"></i> :
            <i className="far fa-square"></i> } Requiere auditarse por Flota</h6>

            <h6 className='hover' onClick={()=>this.setBoleanProperty('finalizar')}>{ nuevo_subestado.finalizar === true ? 
            <i className="fas text-success fa-check-square"></i> :
            <i className="far fa-square"></i> } Estado final (Considerado como finalizado el servicio)</h6>
            
            </div>
            <div className="col-md-12 mb-3">
                {
                    loadingFormulario === true ? <Spinner animation="border" /> : <button className='btn btn-success' onClick={()=>this.crearSubEstado()}>{ nuevo_subestado._id ? 'GUARDAR CAMBIOS' : 'CREAR' }</button>
                }
                
            </div>

            <div className="col-md-12 mb-3">
            <h5><i className="fas fa-file-alt"></i> Compromiso</h5>
            <p>Puedes modificar automáticamente las fechas de compromiso de una orden al recibir este estado</p>

            <label className='form-control-label'>Agregar días a la fecha de compromiso</label>
            <input className='form-control' type="number" name="sumar_dias" defaultValue={nuevo_subestado.sumar_dias} onChange={this.handlechangeSubestado} />
            <hr className='hr' />

            </div>
            
            <div className="col-md-12 mb-3">
            <h5><i className="fas fa-file-alt"></i> Formulario App Móvil</h5>
            <hr className='hr' />
            {this.mostrarAccionesDisponibles(nuevo_subestado)}
            {acciones_subestado_actual.length > 0 ? <div>
            <hr className='hr' />
                {
                    acciones_subestado_actual.map((accion,i) => {
                        return <div className='card mb-3'>
                        <div className='p-3'>
                            <i className="fas fa-keyboard m-0 d-block" style={{ fontSize: 30 }}></i>
                            <span>{accion.tipo_accion}</span>
                            <h2 className='form-control-label d-block'>Título</h2>
                            <input className='form-control mb-2' name="titulo" defaultValue={accion.titulo} id={accion._id} onChange={this.handleChangeAccion} />
                            { accion.tipo_accion === 'selector' ? <div>
                                <h2 className='form-control-label d-block'>Opciones (Separa cada valor con coma)</h2>
                                <input className='form-control mb-2' name="valor" placeholder='Escribe tu opción y a continuación escribe ,' value={accion.valor} id={accion._id} onChange={this.handleChangeAccionArray} />
                                {
                                    accion.opciones.map((opcion,i) => {
                                        return <div key={`opcion-${i}`} className="p-2 mr-2" style={{ display:'inline-block', borderRadius: 6, backgroundColor: '#535353' }}>
                                            <h4 className='text-light m-0'>{opcion} <button className='btn btn-sm btn-danger ml-3' onClick={()=>this.removerOpcionAccion(accion._id, i)}>REMOVER</button></h4>
                                        </div>
                                    })
                                }
                                        <div className='mb-3'></div>
                            </div> : false }

                               <h6 className='hover' onClick={()=>this.obligatorio(accion._id)}>{ accion.required === true ? 
                               <i className="fas text-success fa-check-square"></i> :
                               <i className="far fa-square"></i> } Obligatorio</h6>

                                <button className='btn btn-sm btn-success mr-3' onClick={()=>this.guardarCambiosAccion(accion._id)}>GUARDAR</button>
                            { 
                                eliminandoAcciones ? 
                                <Spinner animation="border" /> : 
                                <button className='btn btn-sm btn-outline-danger' onClick={()=>this.solicitarEliminarAccion(accion._id)}>ELIMINAR</button>
                            }
                        </div>
                    </div>
                    })
                }
            </div> : <p className='mt-3'>No hay acciones para este sub estado</p>}
            </div>
            {this.mostrarNotificacionesConfig()}

        </div>
    </div>
}

setVar(status, texto){
    const { configuracion } = this.state
    const valora_actual = configuracion[status] ? `${configuracion[status]} ` : ''
    configuracion[status] = `${valora_actual}${texto}`
    this.setState({configuracion})
}

opciones_plantillas(status){
    return <div>
        <p>Click para agregar una etiqueta de contenido dinámico</p>
        <Button size="sm" variant="outline-light" className='mr-3' onClick={()=>this.setVar(status, '{{nombre_cliente}}')} >{'nombre_cliente'}</Button>
        <Button size="sm" variant="outline-light" className='mr-3' onClick={()=>this.setVar(status, '{{nombre_direccion}}')} >{'nombre_direccion'}</Button>
        <Button size="sm" variant="outline-light" className='mr-3' onClick={()=>this.setVar(status, '{{referencia}}')} >{'referencia'}</Button>
        <Button size="sm" variant="outline-light" className='mr-3' onClick={()=>this.setVar(status, '{{ot}}')} >{'ot'}</Button>
        <Button size="sm" variant="outline-light" className='mr-3' onClick={()=>this.setVar(status, '{{notas}}')} >{'notas'}</Button>
    </div>
}

handleChangeConfig(e){
    const { configuracion } = this.state
    const { name, value } = e.target
    configuracion[name] = value    
    return this.setState({ configuracion })
}

async guardarCambiosConfiguracion(){
    const { configuracion, nuevo_subestado } = this.state
    const loading = 'loadingConfiguracionNotificaciones'
    configuracion.idsubestado = nuevo_subestado._id
    this.setState({ [loading]: true })
    return fetch(`${data.urlapi}/estadoscarga/notificacion-subestado`, {
        method: 'PUT',
        body: JSON.stringify(configuracion),
        headers: {
            'Content-Type': 'application/json'
        }
      })
      .then(pros => pros.json())
      .then(async pros => {
          if(!pros){
            toast.error('Hubo un error de servidor', this.state.toaststyle)
            return this.setState({ [loading]: false })
          } else if(pros.errorMessage){
            toast.error(pros.errorMessage, this.state.toaststyle)
            return this.setState({ [loading]: false })
          }
          toast.success(`Operación realizada exitosamente`, this.state.toaststyle)
          return this.setState({ [loading]: false })
      })
      .catch(async error => {
          console.log(error)
        this.setState({ [loading]: false })
        return toast.error("No pudimos consultar la información, intente de nuevo recargando la página", this.state.toaststyle)
      })
}

mostrarNotificacionesConfig(){
    const { loadingConfiguracionNotificaciones, loadingPlantillasWhatsapp, configuracion, plantillas_ws  } = this.state
    if(loadingConfiguracionNotificaciones===true) return <Spinner animation="border" />

    return <div className='col-md-12'>

    <Card className='p-3 mb-3 shadow'>
    <h5><i className="fab fa-whatsapp"></i> Whatsapp</h5>
    <hr className='hr' />
    <p>Selecciona uno de los siguientes mensajes aprobados por whatsapp</p>
        <label className='form-control-label d-block'>Mensaje</label>
        <select className='form-control mb-3' name="whatsapp_message" value={configuracion.whatsapp_message} onChange={this.handleChangeConfig}>
            <option value="">{ loadingPlantillasWhatsapp ? 'Cargando' : 'Selecciona' }</option>
            {
                plantillas_ws.map(ws => {
                    return <option value={ws._id}>{ws.mensaje}</option>
                })
            }
        </select>
    <button className='btn btn-success' onClick={()=> this.guardarCambiosConfiguracion()}>GUARDAR CAMBIOS</button>
    </Card>

    <Card className='p-3 text-white mb-3' bg="dark">
    <h5><i className="far fa-envelope"></i> Configuración de notificaciones</h5>
    <hr className='hr' />

    <h6>Asunto</h6>
    {this.opciones_plantillas('subject')}
    <input placeholder='Escribe el asunto de tu email de notificación' className='form-control mb-3 mt-4' name="subject" value={configuracion.subject} onChange={this.handleChangeConfig} />

    <h6>Mensaje</h6>
    {this.opciones_plantillas('message')}
    <input placeholder='Escribe el contenido del mensaje principal' className='form-control mb-3 mt-4' name="message" value={configuracion.message} onChange={this.handleChangeConfig} />

    <button className='btn btn-success' onClick={()=> this.guardarCambiosConfiguracion()}>GUARDAR CAMBIOS</button>
    </Card>
    
    
    </div>
}

crearAccion(tipo_accion, idsubestado){
    const { acciones_subestado_actual } = this.state
    const loading = 'loadingAcciones'
    this.setState({ [loading]: true })
    return fetch(`${data.urlapi}/estadoscarga/crear-accion`, {
        method: 'POST',
        body: JSON.stringify({tipo_accion, idsubestado}),
        headers: {
            'Content-Type': 'application/json'
        }
      })
      .then(pros => pros.json())
      .then(async pros => {
          console.log(pros)
          if(!pros){
            toast.error('Hubo un error de servidor', this.state.toaststyle)
            return this.setState({ [loading]: false })
          } else if(pros.errorMessage){
            toast.error(pros.errorMessage, this.state.toaststyle)
            return this.setState({ [loading]: false })
          }
          toast.success(`Operación realizada exitosamente`, this.state.toaststyle)
          if(pros._id) acciones_subestado_actual.push(pros)
          return this.setState({ [loading]: false, acciones_subestado_actual })
      })
      .catch(async error => {
          console.log(error)
        this.setState({ [loading]: false })
        return toast.error("No pudimos consultar la información, intente de nuevo recargando la página", this.state.toaststyle)
      })
}

    tablaEstados(estados, subestados){
        const { eliminando } = this.state
        if(estados.length < 1) return <div>
            <h2>No hay estados de carga asociados</h2>
        </div>

      return <div>
          <h4 className='mb-3'>{estados.length} Estados de carga encontrados </h4>
          <Accordion >
          {estados.map((estado,iestado) => {
              
              const subest = subestados.filter(sub => sub.idparent === estado._id)
              
              return <Card className="p-0" >
                            <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor:'white' }} eventKey={estado._id}>
                            <div className="pl-3 pr-3 pb-0">
                            <h6 className='mb-0'>{estado.titulo} · <b>{estado.codigo_estado}</b> 
                                <i style={{ color: estado.color }} className="fas fa-circle ml-3"></i> 
                                <Button size="sm" className='mb-2 ml-3 mr-3' style={{ fontSize: 10 }} onClick={()=>this.editarEstado(estado)}>EDITAR</Button> 
                                { eliminando === true ? <Spinner animation='border' /> :  
                                <Button variant="outline-danger" size="sm" className=' mb-2' style={{  fontSize: 10, float:'right', clear: 'both' }} onClick={()=>this.solicitarEliminar(estado._id)}>ELIMINAR</Button>}
                                {this.mostrarTiposServicioConcatenados(estado.tipos_servicio)}
                            </h6>
                            </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={estado._id}>
                            <Card.Body style={{ backgroundColor:'#f1f1f1' }}>
                            <Button size="sm" className='mb-2' style={{ fontSize:11 }} onClick={()=>this.nuevoSubEstado(estado._id)}>CREAR SUBESTADO</Button>
                            <hr className='hr' />
                            {
                                subest.length > 0 ? subest.map((subestado,isub) => {
                                    return <div key={`subestado-${subestado._id}`}>
                                        <h6 className='mb-0' >{subestado.titulo} · <b>{subestado.codigo_estado}</b> <i style={{ color: subestado.color }} className="fas fa-circle"></i></h6>
                                        <Button size="sm" variant="link" className='p-0 mr-3 mb-2' style={{ fontSize: 10 }} onClick={()=>this.editarSubEstado(subestado)}>EDITAR</Button>
                                        <Button size="sm" variant="link" className='p-0 text-danger mb-2' style={{ fontSize: 10 }} onClick={()=>this.solicitarEliminarSubEstado(subestado._id)}>ELIMINAR</Button>
                                        { (isub+1) === subest.length ? false : <hr className='hr' /> }
                                    </div>
                                }) : <h6>No hay sub estados</h6>
                            }
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
          })}
          </Accordion>
      </div>

    }

    mostrarEstados(){

        const { estados, subestados, filtro_tipo_servicio, tipos_servicio, loadingEstados } = this.state

        if(loadingEstados === true) return <PantallaCargando />
    
        const estados_mostrar = filtro_tipo_servicio ? estados.filter(e => {
            if(e.tipos_servicio){
                if(Array.isArray(e.tipos_servicio) !== false){
                    if(e.tipos_servicio.length > 0){
                        return e.tipos_servicio.includes(filtro_tipo_servicio)
                    }
                }
            }
            return false
        }) : estados
    
        return <Row>
            <Col md={12}>
            <Button size="sm" className='mb-3' onClick={() => this.setState({ tipovisualizacion: 'nuevoestado', nuevo_estado:{} })}>CREAR NUEVO</Button>
            </Col>
        <Col md={12}>
                <Select 
                        style={{ marginBottom: 10 }}
                        onChange={this.handleChangeSelectTipoServicioFiltro}
                        defaultValue={filtro_tipo_servicio}
                        options={tipos_servicio}
                        noOptionsMessage={()=>'Sin opciones'}
                        placeholder="Filtra por tipo de servicio..."
                    />
        <hr />
        {this.tablaEstados(estados_mostrar, subestados)}
        </Col>
    
        </Row>
    }

    mostrarVista(){
        const { tipovisualizacion, owner } = this.state
        if(tipovisualizacion==='estados') return this.mostrarEstados()
        if(tipovisualizacion==='nuevoestado') return this.formularioCreacion()
        if(tipovisualizacion==='nuevosubestado') return this.formularioCreacionSubestado()
        if(tipovisualizacion==='flujos') return <FlujosEstadosCarga owner={owner} />
  }
    
    render(){
        return this.mostrarVista()
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(EstadosCarga);