import Map, { Layer, Marker, Source } from 'react-map-gl';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import bbox from '@turf/bbox';
import { mapbox_token, urlapi } from '../../lib/backend/data';
import SelectorZonas from '../geodata/selector_zonas';
import { toast } from 'react-toastify';
import { obtenerCentroMapaPorPais } from '../../lib/helpers/data/internationa';
import { Button, Col, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { cerrarSesion } from '../../redux/actions/session';
import { GrClose } from 'react-icons/gr'
import { StringMes, formatDateAno } from '../../lib/helpers/dates';
import { formatMes } from '../../lib/helpers/opcionesfecha';
import { procesarDatosGraficosEstadisticaConductorLastMile, procesarOTIFParaGraficos } from '../../lib/helpers/conductores';
import GraficoOtiff from './grafico_otif';
import { heatmapLayer } from './style/heat-map-style';
import { recuperarCoordenadas } from '../../lib/helpers/geo';

const EstadisticaGeografica = (props) => {
    const {
        height,
        redzone,
        tipo_operacion_default,
        hideControls
    } = props
    const [ zonasBD, setZonasBD ] = useState([])
    const [ geocercaSeleccionada, setGeocercaSeleccionada ] = useState(false)
    const [ loadingMaster, setLoadingMaster ] = useState(false)
    const [ loadingGeocercaDetalle, setLoadingGeocercaDetalle ] = useState(false)
    const [ geocercas, setGeoCercas ] = useState([])
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [ otif, setOtif ] = useState(false)
    const [ coleccion, setColeccion ] = useState({
      type: 'FeatureCollection',
      features: []
    })
    const [ coleccion_ordenes, setColeccionOrdenes ] = useState({
      type: 'FeatureCollection',
      features: []
    })
    const sesion = useSelector(state => state.miusuario)
    const [viewState, setViewState] = useState(obtenerCentroMapaPorPais(pais));
    const [ zoomInit, setZoomInit ] = useState(false) 
    const dispatch = useDispatch()
    const mapRef = useRef()
    const [ mes, setMes] = useState(formatMes(new Date()))
    const [ ano, setAno] = useState(formatDateAno(new Date()))
    const tipo_operacion = tipo_operacion_default ? tipo_operacion_default : "operacion"

    const deteccionRecursiva = (data) => {
      
    }

    useEffect(() => {
      
        if (coleccion.features.length > 0) {

          const instancia_geocercas = JSON.parse( JSON.stringify( coleccion.features ))
          const coordinates = instancia_geocercas.reduce((acc, geofence) => {
            const geofenceCoords = recuperarCoordenadas(geofence.geometry.coordinates)
            return [...acc, ...geofenceCoords];
          }, []);

          console.log({coordinates})
    
          const combinedFeatures = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'Polygon',
                  coordinates: [coordinates],
                },
              },
            ],
          };
  
            const bounds = bbox(combinedFeatures);
            const newViewport = {
              ...viewState,
              latitude: (bounds[1] + bounds[3]) / 2,
              longitude: (bounds[0] + bounds[2]) / 2
            };
      
            const options = {
              padding: 30 // Ajusta el valor de padding según tus necesidades
            };
      
            setViewState(newViewport);
            mapRef.current.fitBounds(bounds, options);

          }
          
    }, [ coleccion ])

    const remover = (i) => {
      const instancia_geos = JSON.parse( JSON.stringify(geocercas))
      instancia_geos.splice(i,1)
      setGeoCercas(instancia_geos)
      setColeccion((prevCollection) => ({
              ...prevCollection,
              features: instancia_geos,
      }))
      consultarDatos(instancia_geos)
    }

    const mostrarGeocercas = () => {
      
      if(geocercas.length < 1 ) return <div className='mb-3' style={{ padding: "15px 15px 0px 15px" }} ><h4 className='mb-0 mt-0' level={4}>Sin zonas cargadas</h4></div>

      return <div className='mt-2'>
          {
            geocercas.map((geo,pos) => {
              return <p key={geo._id} style={{ fontSize: 11, background: "#d0d0d0", padding: "2px 8px", borderRadius: 5, fontWeight: "bold", display: "inline-block", fontWeight: "normal", textTransform: "uppercase" }} className='mb-2 mt-0 mr-3'> {geo.titulo} <GrClose onClick={() => remover(pos)} /> </p>
            })
          }
      </div>
    }

    const consultarDatos = async (geos, month)=>{
      if(geos.length < 1) return false
      const mes_actual = month ? month : mes
      setLoadingMaster(true)
      return fetch(`${urlapi}/estadisticas/ordenes/geocercas`,{
          method:'POST',
          body: JSON.stringify({
              geos: geos.map(g => g.id_geocerca),
              mes: mes_actual,
              ano
          }),
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${sesion.tokenSession}`
          }
      })
      .then(res => {
          if(res.status === 401) return window.location = '/login'
          return res.json()
      })
      .then(res => {
          if(!res){
              toast.error('Sin datos')
          } else if(res.errorMessage){
              toast.error(res.errorMessage)
          } else if(Array.isArray(res) !== false){
              const graphs_v2 = procesarDatosGraficosEstadisticaConductorLastMile(res)
              const otif_mes = procesarOTIFParaGraficos(graphs_v2)
              setOtif(otif_mes)

              const puntos = res.map(ord => {
                if(!ord.location) return false
                if(typeof ord.location !== "object") return false
                if(!ord.location.type) return false
                if(!ord.location.coordinates) return false
                if(Array.isArray(ord.location.coordinates) !== true) return false
                if(ord.location.coordinates.length < 2) return false
                if(ord.location.coordinates.filter(c => !c).length > 0) return false
                return {
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: ord.location.type,
                    coordinates: ord.location.coordinates
                  }
                }
              }).filter(p => p)
              
              const newFeatures = {
                type: 'FeatureCollection',
                features: puntos
              }
              setColeccionOrdenes({...{}, ...newFeatures})
          }
          return setLoadingMaster(false)
      })
      .catch(error => {
          toast.error("Error al consultar la información, intente nuevamente")
          return setLoadingMaster(false)
      })
  }

  const consultarDetallesGeocerca = async (id)=>{
    return fetch(`${urlapi}/geodata/zonas/geocerca?id=${id}`,{
        method:'GET',
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${sesion.tokenSession}`
        }
    })
    .then(res => {
        if(res.status === 401) return window.location = '/login'
        return res.json()
    })
    .then(res => {
        if(!res){
            toast.error('Sin datos')
        } else if(res.errorMessage){
            toast.error(res.errorMessage)
        } else if(res._id){
          return res
        }
        return false
    })
    .catch(error => {
        toast.error("Error al consultar la información, intente nuevamente")
        return false
    })
  }
  

    const agregarZona = async () => {
        if(!geocercaSeleccionada) return false
        if(!geocercaSeleccionada.region){
          setLoadingGeocercaDetalle(true)
          const detalles_geo = await consultarDetallesGeocerca(geocercaSeleccionada._id)
          console.log({detalles_geo})
          if(detalles_geo){
            onAgregarZona(detalles_geo)
          }
          return setLoadingGeocercaDetalle(false)
        }
        return onAgregarZona(geocercaSeleccionada)
    }

    const onAgregarZona = (data) => {
        const buscar = geocercas.findIndex(geo => geo.id_geocerca === data._id)
        if(buscar > -1) return toast.error("Esta geocerca ya fue agregada")
          const nueva = {
              id_geocerca: data._id,
              titulo: data.titulo,
              type: "Feature",
              properties: data.properties,
              geometry: {
                type: data.region.type,
                coordinates: data.region.coordinates
              }
            }
            console.log({nueva})
            const nuevo_arreglo = [...geocercas, ...[nueva]]
            setGeoCercas(nuevo_arreglo)
            coleccion.features = nuevo_arreglo
            setColeccion((prevCollection) => ({
              ...prevCollection,
              features: [...prevCollection.features, nueva],
            }))
            setGeocercaSeleccionada(false)
            consultarDatos(nuevo_arreglo)
        }

    const mostrarAcciones = () => {
      if(hideControls === true) return false
      return <div >
      <Row>
        <Col md={12}>
        <OverlayTrigger
                              placement="auto"
                              overlay={
                                <Tooltip >Selecciona la geo zona y luego agrégala al filtro</Tooltip>
                              }
                              >
          <SelectorZonas title="Zonas del país" condicion={{ }} full onChangeValue={data => setGeocercaSeleccionada(data)} />
                              </OverlayTrigger>
                { loadingGeocercaDetalle ? <Spinner animation='border' /> : <Button style={{ fontSize: 11 }} className='w-100' disabled={geocercaSeleccionada ? false : true} onClick={() => agregarZona()} >AGREGAR {geocercaSeleccionada ? geocercaSeleccionada.titulo ? geocercaSeleccionada.titulo.toUpperCase()  : "SIN INFORMACIÓN" : ""} AL FILTRO</Button> }
                {mostrarGeocercas()}
        </Col>
      </Row>
    </div>
    }

    const handleChangeMes = (e) => {
      const { value } = e.target
      setMes(value)
      consultarDatos(geocercas, value)
  }

  const handleChangeAno = (e) => {
    const { value } = e.target
    return setAno(value)
}

    const mostrarInformacion = () => {
      return <div>
        <Row className='mb-2'>
        <Col className='mb-2'>
                    <label className='form-control-label d-block'>Año</label>
                    <input className='form-control' type="number" value={ano} name="ano"  onChange={handleChangeAno} />
          </Col>
        
          <Col className='mb-2'>
          <label className='form-control-label d-block'>Mes</label>
                    <select className='form-control' value={mes} onChange={handleChangeMes} >
                    <option value="01">Enero</option>
                    <option value="02">Febrero</option>
                    <option value="03">Marzo</option>
                    <option value="04">Abril</option>
                    <option value="05">Mayo</option>
                    <option value="06">Junio</option>
                    <option value="07">Julio</option>
                    <option value="08">Agosto</option>
                    <option value="09">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                    </select>
          </Col>
          <Col className='mb-2' xs={12}>
                    <label className='form-control-label d-block'>Click para refrescar registros</label>
                    <Button disabled={loadingMaster} style={{ fontSize: 11 }} className='w-100' onClick={() => {
                      consultarDatos(geocercas)
                    }}>{ loadingMaster ? "CONSULTANDO INFORMACIÓN DE ÓRDENES..." : "ACTUALIZAR REGISTROS" } </Button>
          </Col>
        </Row>
        <GraficoOtiff typeView="simple" data={otif} />
      </div>
    }

    const [viewport, setViewport] = useState({
      width: '100%',
      height: '100%',
      latitude: -33.4489,
      longitude: -70.6693,
      zoom: 10,
    });
  
    const data = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [-70.6483, -33.4569], // Santiago coordinates
          },
        },
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [-70.6231, -33.4691], // Santiago coordinates
          },
        },
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [-70.6488, -33.4418], // Santiago coordinates
          },
        },
        // Add more points as needed
      ],
    };
  
  

    return <div>
        <Row>
            <Col md={4}>
            {mostrarAcciones()}
            {mostrarInformacion()}
            </Col>
          <Col md={8}>
          <Map
                ref={mapRef}
                mapboxAccessToken={mapbox_token}
                {...viewState}
                onMove={evt => setViewState(evt.viewState)}
                style={{width: "100%", height: height ? height : 500}}
                pitch={30}
                dragPan={false}
                mapStyle="mapbox://styles/mapbox/dark-v9"
            >
            <Source
                key={"ajsfkn2"}
                id={"geocerca._id2"}
                type="geojson"
                data={coleccion_ordenes}
                >
                    <Layer
          id="heatmap-layer"
          type="heatmap"
          paint={{
            'heatmap-weight': 1,
            'heatmap-intensity': 0.5,
            'heatmap-radius': 20,
            'heatmap-color': [
              'interpolate',
              ['linear'],
              ['heatmap-density'],
              0,
              'rgba(0, 0, 255, 0)',
              0.2,
              'royalblue',
              0.4,
              'cyan',
              0.6,
              'lime',
              0.8,
              'yellow',
              1,
              'red',
            ],
            'heatmap-opacity': 0.8,
          }}
        />
        
                </Source>

            <Source
                key={"ajsfkn"}
                id={"geocerca._id"}
                type="geojson"
                data={coleccion}
                >
                    
                    <Layer
                      id="geocercas-layer"
                      type="line"
                      paint={{
                          'line-width': 4,
                          'line-color': '#0080ef'
                        }}                      
                      />
                      </Source>
            </Map>
          </Col>
        </Row>
  </div>
}

export default EstadisticaGeografica