import { useEffect, useState } from "react"
import { calcularCentroYZoom, centerMapCoordinates } from "../../lib/helpers/maps"

const MapaEstados = (props) => {
    const {
        estados,
        heightMap
    } = props
    const [ centro_mapa, setCentroMapa ] = useState({ lat: -33.458829, lng: -70.654644 })
    const [ zoom, setZoom ] = useState(14)
    const [ seleccionado, setSeleccionado ] = useState(false)

    useEffect(() => {
      if(estados.length > 0){
        const coordenadas = estados.map(e => {
          if(!e.location) return false
          if(typeof e.location !== "object") return false
          if(!e.location.coordinates) return false
          if(Array.isArray(e.location.coordinates) !== true) return false
          if(e.location.coordinates.length < 2) return false
          const lat = e.location.coordinates[0]
          const lng = e.location.coordinates[1]
          return {
            lat,
            lng
          }
        }).filter(e => e)
        console.log(coordenadas)
        if(coordenadas.length > 0){
          const centro = calcularCentroYZoom(coordenadas)
          
          if(centro) setCentroMapa(centro.centro)
          setZoom(centro.zoom / 0.6)
        }
      }
    },[])

    return <div>

    </div>
/*
    return <LoadScript
    googleMapsApiKey={maps_key}
    >
      <GoogleMap
      mapContainerStyle={{
        width: '100%',
        height: heightMap ? heightMap :"100%",
        minHeight: heightMap ? heightMap : 500,
        borderRadius: 10
      }}
      defaultZoom={8}
      zoom={zoom}
      center={centro_mapa}
      options={{
        styles: MapStyleDefault,
        zoomControl: false,
        drawingControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,

      }}
      >
  {
    estados.length > 0 ? estados.map((marker,imarker) => {
        const icon_marker = markerSmallPoint(marker.estado_color)
        return <Marker
        key={`marcador${imarker}`}
        onMouseOver={() => setSeleccionado(marker._id)}
        icon={icon_marker}
        position={{ lat: parseFloat(marker.location.coordinates[0]), lng: parseFloat(marker.location.coordinates[1]) }}
        // labelAnchor={new window.google.maps.Point(0, 0)}
        // labelStyle={{backgroundColor: "white", borderRadius: 10, fontSize: "12px", padding: "10px", boxShadow: '10 10 10 black' }}
      />
    }) : false
  }
  
  {
    estados.length > 0 ? estados.map((marker,imarker) => {
        if(seleccionado !== marker._id) return false
        const fecha = new Date(marker.fecha)
        return <InfoWindow
        key={`mark-${imarker}`}
        position={{ lat: parseFloat(marker.location.coordinates[0]), lng: parseFloat(marker.location.coordinates[1]) }}
        >
            <div>
            <p style={{ fontSize:9 }} className='mb-0'>{fecha.toLocaleDateString('es-ES', configuracion_fechas )} · <b>{fecha.toLocaleTimeString('en-US', configuracion_horas)}</b></p>
            <h6 style={{ fontSize: 10 }} className="mb-0">{marker.estado} · {marker.subestado}</h6>
            </div>
        </InfoWindow>
    }) : false
  }
  
  </GoogleMap>
    </LoadScript>
      */

}

export default MapaEstados