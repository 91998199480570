import { Accordion, Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import { fechaATexto } from "../../../lib/helpers/helpers"
import { eventoToString } from "../../../lib/helpers/integraciones"

const BoxList = (props) => {
    const registro = props.registro ? props.registro : false

    if(!registro) return false
    let estado = 'desconocido'
    if(registro.respuesta){
        if(typeof registro.respuesta === 'object'){
            if(registro.respuesta.status){
                estado = registro.respuesta.status
            }
        }
    }
    const color = estado !== 200 ? '#a60000' : 'green'
    const border = estado !== 200 ? 'danger' : 'success'

    return <Accordion>
    <Card className="p-2" border={border}>
    <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >Toca para ver detalles</Tooltip>
                            }
                            >
                        <Accordion.Toggle as={Card.Header} style={{ padding: '8px 8px', border:'none', fontSize: 12, backgroundColor:'white' }} eventKey={registro._id}>
                        <Row>
                            <Col md={3} xs={12} className="mb-1"><h5 className="mb-0" style={{ backgroundColor: color, color:'white', textAlign:'center', fontSize: 14, padding:'3px 5px', borderRadius: 5}}>{estado}</h5></Col>
                            <Col md={4} xs={12} className="mb-1"><p className="mb-0" style={{ fontSize: 11 }} >{eventoToString(registro.nombre)}</p></Col>
                            <Col md={4} xs={12} className="mb-1"><p className="mb-0" style={{ fontSize: 11 }} >{fechaATexto(registro.createdAt)}</p></Col>
                        </Row>
                        </Accordion.Toggle>
                        </OverlayTrigger>
                        <Accordion.Collapse eventKey={registro._id}>
                        <Card.Body>
                            <h5>Detalles de la respuesta</h5>
                            <p><b>POST</b> {registro.url}</p>
                            <p>Respuesta:</p>
                        { /* <ReactJson 
                            name={false}
                            indentWidth="4" 
                            src={registro.respuesta}
                        /> */}
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
    </Accordion>
}

export default BoxList