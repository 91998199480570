import Moment from 'react-moment'
import 'moment/locale/es';
import { useEffect, useState } from "react";
import Header from '../../../components/Header';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import PantallaCargando from '../../general/pantalla_cargando';
import SinDatos from '../../general/sin_registros';
import { urlapi } from '../../../lib/backend/data';
import { Col, Row } from 'react-bootstrap';


const DetallesApiless = (props) => {
    const [ hook , setHook ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(true)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const { id } = useParams()

    useEffect(() => {
        obtenerWebhooks()
    }, [])

    const obtenerWebhooks = () => {
        if(!id) return setLoadingDetalle(false)
        return fetch(`${urlapi}/integraciones/conexiones?id=${id}`,{
            method: "GET",
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            },
        })
        .then(pros => pros.json())
        .then(res => { 
            if(!res){
                toast.error("Sin datos del servidor")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if( res._id ){
                setHook(res)
            }
            return setLoadingDetalle(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información")
            return setLoadingDetalle(false)
        })
    }

    const handleChangeOrigen = () => {
        return <div>
            
        </div>
    }

    const formularioMantenedor = () => {
        return <div>
            <Row>
                <Col>
                    <textarea className='form-control' onChange={handleChangeOrigen} />
                </Col>
                <Col></Col>
            </Row>
        </div>
    }


    const mostrarInfo = () => {
        if(loadingDetalle) return <PantallaCargando />
        if(!hook) return <SinDatos />

        return <div>
                {formularioMantenedor()}
        </div>
    }

    return <div>
        <div className='p-3'>
        {mostrarInfo()}
        </div>
    </div>

}

export default DetallesApiless