import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button, Breadcrumb } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import 'moment/locale/es';
import data, { key_local_storage_user } from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import 'moment/locale/es';
import Select from 'react-select'
import { host } from '../../lib/global/data'
import GeocercaDrawer from '../../subComponents/geocercas/drawer'
import withRouter from '../RoutesApp/wrapped_component'
import { confirmAlert } from 'react-confirm-alert'
import { rutas } from '../../lib/routes/routes'

class VehiculosEditar extends Component {
    constructor(props){
        super(props)
        this.state = {
            id: this.props.params.id,
            user: JSON.parse(localStorage.getItem(key_local_storage_user)),
            nuevoconductor: {
                activo: true
            },
            creandoVehiculo: true,
            loadingProveedores: true,
            proveedores: [],
            geocercas: [],
        }
        this.handleChangeVehiculo = this.handleChangeVehiculo.bind(this)
        this.handleChangeChecked = this.handleChangeChecked.bind(this)
        this.handleChangeProveedor = this.handleChangeProveedor.bind(this)
    }

    componentDidMount(){
        const { id } = this.state
        if(!id) return this.setState({ loadingUsuario: true })
        this.getVehiculo(id)
        this.obtenerProveedores({})
    }

    handleChangeProveedor(e){
        const { nuevoconductor } = this.state 
        nuevoconductor.id_proveedor = e.value
        return this.setState(nuevoconductor)
    }

    async obtenerProveedores(condicion){
        const { user } = this.state
        const loading = 'loadingProveedores'
        return fetch(`${data.urlapi}/proveedores/list-full`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            } else if(Array.isArray(res) !== false){
                let proveedores = res.map(p => ({ value: p._id, label: p.titulo }))
                proveedores.unshift({ value:'', label: "Ninguno" })
                this.setState({ proveedores })
            }
            return this.setState({ [loading]: false })
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return this.setState({ [loading]: false })
        })
    }

    async getVehiculo(id){
        const { user } = this.state
        let loading = 'creandoVehiculo'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/conductores?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            return this.setState({ nuevoconductor: res, [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    handleChangeVehiculo(e){
        const { name, value } = e.target
        const { nuevoconductor } = this.state
        if(name === 'rut'){
            nuevoconductor[name] = value.replace(/\D/g, "")
        } else if(name === 'frecuencytracking'){
            nuevoconductor[name] = value.replace(/\D/g, "")
        } else {
            nuevoconductor[name] = value
        }
        return this.setState({ nuevoconductor })
    }

    handleChangeChecked(checked){
        return this.setState({ show_password: checked })
    }

    editarVehiculo(){
        const { nuevoconductor, user } = this.state
        const requeridos = [
            { value:'nombres', label: 'Nombres' },
            { value:'apellidos', label: 'Apellidos' },
            { value:'patente', label: 'Patente' },
            { value:'phone', label: 'Teléfono' },
            { value:'password', label: 'Contraseña' }
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!nuevoconductor[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)

        const loading = 'creandoVehiculo'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/conductores`,{
            method:'PUT',
            body: JSON.stringify( nuevoconductor ),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){ 
                toast.success('Guardado exitosamente')
                setTimeout(() => {
                    return window.location = '/vehiculos'
                }, 500);
            }
            this.setState({ [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }

    sinResultados() {
        return <Row className="justify-content-md-center">
        <Col md={6} className="text-center">
          <img src={`${host}/images/pedidos.png`} style={{ width: "50%" }} />
        <h4>No hay datos suficientes para visualizar esta sección</h4>
        </Col>
        </Row>
    }

    accionesGeocerca(){
        const { geocercas } = this.state
        if(geocercas.length < 1) return false

        return <div>
            <Button variant="success" size="sm" className='mb-3'>GUARDAR CAMBIOS</Button>
        </div>
    }

    mostrarGeocercas(p){
        const { geocercas } = this.state
        
        return <div>
            <h6>{geocercas.length} Geocercas</h6>
            {this.accionesGeocerca()}
            {
                geocercas.map((geo,igeo) => {
                    return <div key={`geo-${igeo}`}>
                        <Row>
                        <Col md={6} className='mb-3'>
                            <label className='form-control-label'>Título</label>
                            <input name="titulo" className='form-control'/>
                        </Col >
                        <Col md={6} className='mb-3'>
                            <label className='form-control-label'>Tipo</label>
                            <select className="form-control" name="status" >
                                <option value=''>Seleccione</option>
                                <option value='disponible'>Zona disponibilidad</option>
                                <option value='restringido'>Zona restringida</option>
                            </select>
                        </Col >
                        </Row>
                        </div>
                })
            }
        </div>
    }

    onPolygonComplete(p){
        const { geocercas } = this.state
        geocercas.unshift(p)
        return this.setState({geocercas})
    }
    formularioVehiculo(){
        const { nuevoconductor, creandoVehiculo, proveedores, loadingProveedores } = this.state
        if(creandoVehiculo === true) return <Spinner animation='border' />
        if(Object.keys(nuevoconductor).length < 2) return this.sinResultados()


        let default_proveedor = false
        const i = proveedores.findIndex(p => p.value === nuevoconductor.id_proveedor)
        if(i > -1) default_proveedor = proveedores[i]
        return <div>
            <Row>

            <Col md={2} className='mb-3'>
                <label className='form-control-label'>Conductor Nombres</label>
                <input name="nombres" className='form-control' value={nuevoconductor.nombres}  onChange={this.handleChangeVehiculo}/>
            </Col >
            <Col md={2} className='mb-3'>
                <label className='form-control-label'>Conductor Apelidos</label>
                <input name="apellidos" className='form-control' value={nuevoconductor.apellidos} onChange={this.handleChangeVehiculo} />
            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label'>Conductor Rut</label>
                <input name="rut" className='form-control' value={nuevoconductor.rut} onChange={this.handleChangeVehiculo} />
            </Col >
            <Col md={2} className='mb-3'>
                <label className='form-control-label'>Modelo Vehículo</label>
                <input name="modelo" className='form-control' value={nuevoconductor.modelo} onChange={this.handleChangeVehiculo} />
            </Col >
            <Col md={2} className='mb-3'>
                <label className='form-control-label'>Año Vehículo</label>
                <input name="ano" type="number" className='form-control' value={nuevoconductor.ano} onChange={this.handleChangeVehiculo} />
            </Col >
            <Col md={2} className='mb-3'>
                <label className='form-control-label'>Patente Vehículo</label>
                <input name="patente" className='form-control' value={nuevoconductor.patente} onChange={this.handleChangeVehiculo} />
            </Col >
            <Col md={2} className='mbd-3'>
                <label className='form-control-label'>Contraseña APP</label>
                <input name="password" className='form-control' value={nuevoconductor.password} onChange={this.handleChangeVehiculo} />
            </Col >
            <Col md={2} className='mb-3'>
                <label className='form-control-label'>Móvil Whatsapp</label>
                <input name="phone" className='form-control' value={nuevoconductor.phone} placeholder='+5612341234' onChange={this.handleChangeVehiculo} />
            </Col>
            <Col md={4} className='mb-3'>
                <label className='form-control-label d-block'>Categoría</label>
                <Select
                                    isLoading={loadingProveedores} 
                                    loadingMessage={()=>'Cargando información'}
                                    placeholder="Selecciona una opción"
                                    noOptionsMessage={()=>'Sin opciones'}
                                    onChange={this.handleChangeProveedor}
                                    defaultValue={default_proveedor}
                                    options={proveedores} 
                                />
            </Col>
            
            <Col md={2} className='mb-3'>
                <label className='form-control-label d-block'>Estado</label>
                <select className="form-control" name="status" value={nuevoconductor.status} onChange={this.handleChangeVehiculo}>
                    <option value='active'>Activo</option>
                    <option value='inactive'>Inactivo</option>
                </select>
            </Col>

            <Col md={12} className='mb-3'>
                <h3>Rendimiento</h3>
            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label d-block'>Combustible</label>
                <select className="form-control" name="status" value={nuevoconductor.status} onChange={this.handleChangeVehiculo}>
                    <option value=''>Seleccione</option>
                    <option value='gasolina'>Gasolina</option>
                    <option value='diesel'>Diesel</option>
                    <option value='electrico'>Eléctrico</option>
                    <option value='gas'>Gas</option>
                </select>
            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label d-block'>Unidad de medida combustible</label>
                <select className="form-control" name="unidad_combustible" value={nuevoconductor.unidad_combustible} onChange={this.handleChangeVehiculo}>
                    <option value=''>Seleccione</option>
                    <option value='litro'>Litro</option>
                    <option value='porcentaje'>Porcentaje {"(Bateria)"}</option>
                    <option value='metros_cubicos'>Metros cúbicos</option>
                    <option value='gas'>Gas</option>
                </select>
            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label d-block'>Múltiplo por u.m. combustible</label>
                <input name="multiplo_um_combustible" className='form-control' type="number" value={nuevoconductor.multiplo_um_combustible} onChange={this.handleChangeVehiculo} />
            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label d-block'>Kilómetros por u.m. combustible</label>
                <input name="peso" className='form-control' type="number" value={nuevoconductor.peso} onChange={this.handleChangeVehiculo} />
            </Col>
            
            <Col md={12} className='mb-3'>
                <h3>Capacidad de carga / traslado por ruta</h3>
                <p>Esta capacidad está determinada para el modelo de planes delimitados por capacidad de carga, en vez de por ejemplo: por tiempo en ruta.</p>
            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label d-block'>Peso en Kg.</label>
                <input name="peso" className='form-control' type="number" value={nuevoconductor.peso} onChange={this.handleChangeVehiculo} />
            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label d-block'>Cantidad de puntos.</label>
                <input name="cap_puntos" className='form-control' type="number" value={nuevoconductor.cap_puntos} onChange={this.handleChangeVehiculo} />
            </Col>

            <Col md={12} className='mb-3'>
                <h3>Horario de trabajo</h3>
                <p>Este horario está determinado para que al crear planes, si seleccionas la opción de respetar horarios individuales de trabajo, en caso de tener; se limina la ruta.</p>
            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label d-block'>Desde.</label>
                <input name="horario_desde" className='form-control' type="time" value={nuevoconductor.horario_desde} onChange={this.handleChangeVehiculo} />
            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label d-block'>Hasta.</label>
                <input name="horario_hasta" className='form-control' type="time" value={nuevoconductor.horario_hasta} onChange={this.handleChangeVehiculo} />
            </Col>
                <Col md={12}>
                    <Button size="sm" variant='success' onClick={()=>this.editarVehiculo()}>GUARDAR CAMBIOS</Button>
                </Col>
            </Row>
            
        </div>
    }

    botonSeleccionar(id){
        const { planes_activos } = this.state
        const i = planes_activos.findIndex(plan => plan._id === id)
        if(i > -1) return <Button size="sm" variant="outline-secondary" >PLAN ACTIVO</Button>

        return <Button size="sm" variant="outline-primary" ><Link to={`pay-plan-${id}`} >SELECCIONAR</Link></Button>
    }

    async confirmarEliminado(){
        const { user } = this.state
        const loading = 'creandoVehiculo'
        this.setState({ [loading]: false })
        return fetch(`${data.urlapi}/conductores?id=${this.state.id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success('Eliminada exitosamente')
                return setTimeout(() => {
                    window.location = `/${rutas.vehiculos.slug}`
                }, 1000);
            }
            return this.setState({ [loading]: false })
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return this.setState({ [loading]: false })
        })
    }

    solicitarEliminar() {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Estás a punto de eliminar este recurso, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => this.confirmarEliminado()
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }
    
    render(){
        return(
            <div className="fluid">
                <Breadcrumb>
        <Breadcrumb.Item href="#"><Link to="/vehiculos">Vehículos</Link> </Breadcrumb.Item>
        <Breadcrumb.Item active>Editar</Breadcrumb.Item>
    </Breadcrumb>
    <Row>
        <Col md={6}><h4>Editar vehículo </h4></Col>
        <Col md={6} className='text-right'><Button variant='ghost' className='text-danger' size='sm' onClick={() => this.solicitarEliminar()}>ELIMINAR</Button> </Col>
    </Row>
      <Card >
          <Card.Body>
          {this.formularioVehiculo()}
          </Card.Body>
      </Card>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(withRouter(VehiculosEditar));