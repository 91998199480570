import { useEffect, useState } from "react"
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap"
import { toast } from "react-toastify"
import data from '../../../lib/backend/data'
import { eventos, eventoToString } from "../../../lib/helpers/integraciones"
import BoxWebhook from "./box-webhook"

const Webhooks = (props) => {
    const payload_default = {
        format: 'json',
        version: '2022-09'
    }
    const [ openModal, setOpenModal ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const [ loadingForm, setLoadingForm ] = useState(false)
    const [ webhooks, setWebhooks ] = useState([])
    const [ newWebhook, setNewWebhook ] = useState(payload_default)
    const token = props.token ? props.token : false

    useEffect(() => {
        obtenerWebhooks()
    }, [])

    const obtenerWebhooks = () => {
        return fetch(`${data.urlapi}/integraciones/webhook/list`,{
            method: "GET",
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            },
        })
        .then(pros => pros.json())
        .then(data => { 
            if(!data){
                return setLoading(false)
            } else if(data.errorMessage){
                toast.error(data.errorMessage)
                return this.setState({ loadingFormulario: false })
            } else if( Array.isArray(data) !== false){
                setWebhooks(data)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información")
            return setLoading(false)
        })
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        newWebhook[name] = value
        return setNewWebhook(newWebhook)
    }

    const crearWebHook = async () => {
        const requeridos = [
            { value:"evento", label: "Evento" },
            { value:"format", label: "Formato" },
            { value:"url", label: "Url" },
            { value:"version", label: "Versión de API" }
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!newWebhook[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)
        setLoadingForm(true)
        return fetch(`${data.urlapi}/integraciones/webhook`,{
            method:'POST',
            body: JSON.stringify(newWebhook),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingForm(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingForm(false)
            } else if(res._id){
                toast.success('Creada exitosamente')
                webhooks.unshift(res)
                setWebhooks(prev => [...[], ...webhooks])
                setNewWebhook(payload_default)
                setOpenModal(false)
            }
            return setLoadingForm(false)
        })
        .catch(error => {
            toast.error(error.message)
            return setLoadingForm(false)
        })
    }

    const crearWebhook = () => {
        
        return <Modal show={openModal} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Crear nuevo webhook</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p></p>
            <Row>
                <Col xs={6} className="mb-3">
                    <label className='d-block form-control-label'>Evento</label>
                    <select className="form-control" name="evento" onChange={handleChange}>
                        <option value="" >Seleccione</option>
                        {
                            eventos.map((e,i) => {
                                return <option key={`op-${i}`} value={e.value} >{e.label}</option>
                            })
                        }
                    </select>
                </Col>
                <Col xs={6} className="mb-3">
                    <label className='d-block form-control-label' name="format" onChange={handleChange} value={newWebhook.format}>Formato</label>
                    <select className="form-control" >
                        <option value="json" >JSON</option>
                    </select>
                </Col>
                <Col xs={12} className="mb-3">
                    <label className='d-block form-control-label'>Url</label>
                    <input className='form-control' name="url" onChange={handleChange} />
                </Col>
                <Col xs={12} className="mb-3">
                    <label className='d-block form-control-label'>Versión de la API de webhooks</label>
                    <select className="form-control" name="version" value={newWebhook.version} onChange={handleChange}>
                        <option value="2022-09" >2022-09</option>
                    </select>
                </Col>
                <Col md={12}>
                    {
                        loadingForm ? <Spinner animation="border" /> : <Button size="sm" className="mr-3" variant="success" onClick={() => crearWebHook()} >CREAR</Button>
                    }
                </Col>
            </Row>
        </Modal.Body>
        
      </Modal>
    }

    const onDeleteItem = (id) => {
        const i = webhooks.findIndex(w => w._id === id)
        webhooks.splice(i,1)
        return setWebhooks(prev => [...[], ...webhooks])
    }

    const mostrarWebhooks = () => {
        if(webhooks.length < 1) return  <div>
            <h5>Nada por aquí</h5>
            <p>No hay registros en esta sección</p>
        </div>

        return <div>
            <h5>{webhooks.length} Registros creado</h5>
            {
                webhooks.map(w => {
                    return <Card key={w._id} className="p-3 mb-3">
                        <BoxWebhook token={token} webhook={w} onDeleteItem={(id) => onDeleteItem(id)} />
                    </Card>
                })
            }
        </div>
    }


    return <div>
        <h4>Webhooks <i className="fa-solid fa-circle-plus text-primary hover" onClick={() => setOpenModal(true)}></i></h4>
        {crearWebhook()}
        {mostrarWebhooks()}
    </div>
}

export default Webhooks