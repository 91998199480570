const getValueFromNestedObject = (obj, path, esquema, iterable) => {
    //console.log({path})
    const parts = path.replace('*','').split('.');
    let value = obj;

    let i = -1
    for (const part of parts) {
        i++
        parts.splice(i, 1)
        if (Array.isArray(value)) {
            
            const index = parseInt(part);
            // console.log('es array', value, part)
            if (!isNaN(index) && value[index] !== undefined) {
                value = value[index];
            } else {
                
                const nuevo_arreglo = value.map(o => {
                    const nuevo_objeto = {}
                    Object.keys(esquema).map(field => {
                        let valorr = esquema[field]
                        let ruta = part.replace('*','')
                        const encontrar = getValueFromNestedObject(o, parts.join('.'), esquema, true)
                        // console.log({encontrar, o, ruta, valorr})
                        // if(o[part]) o[part] = 1
                        nuevo_objeto[field] = valorr
                    })
                    return nuevo_objeto
                })
                
            // console.log(`este busco ${part}`, parts, nuevo_arreglo)
          value = nuevo_arreglo;
        }
      } else if (value && value.hasOwnProperty(part)) {
        value = value[part];
      } else {
        value = undefined;
        break;
      }
    }
  
    return value;
};


const testJsonText = (string) => {
    try {
        const formateado = JSON.parse(string)
        return formateado
    } catch (error) {
        return false
    }
}

const procesarCampo = (campo) => {
    if(Array.isArray(campo) !== false) return "array"
    const tipo_de_campo = typeof campo
    return tipo_de_campo
}

const procesarEsquema = (campo, esquemaOrigen, iterable, esquema ) => {
    //console.log({ campo })
    const tipo_campo = procesarCampo(campo)

    if(campo === null) return null 


    if( tipo_campo === "array" ){
        console.log({ campote: campo })
        const nuevoArray = procesarEsquema(campo[0], esquemaOrigen, true, campo[0] )
        console.log({ nuevoArray })
        return nuevoArray
    } else if( tipo_campo === "object"){

                let es_iterable = iterable === true ? true : false
                let completo = {}

                let llaves_dinamicas = Object.keys(campo).filter(e => campo[e].includes('*'))

                console.log({llaves_dinamicas, esquema})
                Object.keys(campo).map((llave,pos) => {
                    console.log({ campo, llave, valor: campo[llave] })
                    const valor = procesarEsquema(campo[llave], esquemaOrigen, es_iterable, esquema)
                    completo = valor
                    //console.log('por field', valor)
                    campo[llave] = valor
                })
                console.log('es objeto', campo, es_iterable )
                return es_iterable === true ? completo : campo
    } else {

        const verificar_json = testJsonText(campo)
        if(verificar_json){
            return verificar_json
        }

        if(tipo_campo === "string"){
            const primer_caracter = campo.toString().substring(0,1)
            const ultimo_caracter = campo.toString().substring(campo.length - 1)
            if(primer_caracter === '*' &&  ultimo_caracter === '*'){
                // ESTA ENLAZADO
                const buscar_campo = campo.replace('*','').replace('*',"")
                const obtenerValor = getValueFromNestedObject(esquemaOrigen, buscar_campo, esquema, iterable)
                // console.log({ obtenerValor, campo: buscar_campo, iterable, esquema })
                return obtenerValor
            }
        }

        return campo
    }
}

export const homologarDatosEsquema = (esquema, esquemaDestino) => {

    let origen = JSON.parse( JSON.stringify( esquema ) )
    let destino = JSON.parse( JSON.stringify( esquemaDestino ) )
    console.log({ destino })
    if(typeof destino === "object"){
        Object.keys(destino).map(key => {
            const valor = procesarEsquema(destino[key], origen, false, destino)
            destino[key] = valor
        })
    }
    return destino
}
