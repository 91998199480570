const stringByNameModule = (val) => {
    switch (val) {
        case "facturacion_esperada":
            return 'Facturación esperada'
        case "facturacion_proforma":
            return 'Facturación proforma'
        case "facturacion_real":
            return 'Facturación real'
        case "total_pendiente_proforma":
            return 'Sin gestión'
        case "total_costos":
            return 'Costos'
        case "roi":
            return 'ROI Esperado'
        case "roi_real":
            return 'ROI facturado'
        default:
            return val
    }
}

const unitMeasureByModule = (val) => {
    switch (val) {
        case "facturacion_esperada":
            return ''
        case "facturacion_proforma":
            return ''
        case "facturacion_real":
            return ''
        case "total_pendiente_proforma":
            return ''
        case "total_costos":
            return ''
        case "roi":
            return '%'
        case "roi_real":
            return '%'
        default:
            return val
    }
}

const stringByTitleCost = (val) => {
    switch (val) {
        case "logistica":
            return 'Logística'
        case "flota":
            return 'Flota'
        case "courrier":
            return 'Pago de courrier'
        default:
            return val
    }
}

module.exports = {
    stringByNameModule,
    unitMeasureByModule,
    stringByTitleCost
}