import { useState } from "react"
import { Button, ButtonGroup, Dropdown, DropdownButton, Modal, Spinner } from "react-bootstrap"
import { confirmAlert } from "react-confirm-alert"
import { toast } from "react-toastify"
import data from '../../lib/backend/data'
import BuscadorRutas from "../rutas/buscador"

const AccionesModal = (props) => {
    const [ openModal, setOpenModal ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ loadingBloqueo, setLoadingBloqueo ] = useState(false)
    const [ loadingMostrarApp, setLoadingMostrarApp ] = useState(false)
    const [ numeroRuta, setNumeroRuta ] = useState('')
    const [ tipoVisualizacion, setTipoVisualizacion ] = useState('')
    const token = props.token ? props.token : false
    const [ pedido, setPedido ] = useState(props.pedido ? props.pedido : false)

    const openModalEstado = (tipo) => {
        setOpenModal(true)
        setTipoVisualizacion(tipo)
    }


    const handleChangeRuta = (e) => {
        return setNumeroRuta(e.value) 
    }

    const showByTipoVisualizacion = () => {
        switch (tipoVisualizacion) {
            case 'reasignar':
                return <div>
                    <h3>Reasignar ruta</h3>
                    <label className="form-control-label d-block">Número de ruta</label>
                    <BuscadorRutas token={token} onChangeValue={(e) => handleChangeRuta(e)} />
                    { loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>reasignarRuta()} >CONFIRMAR</Button> }
                </div>
            default:
                break;
        }
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const reasignarRuta = () => {
        if(!numeroRuta) return toast.error('Número de ruta es requerido')
        setLoading(true)
        return fetch(`${data.urlapi}/ordenes/ruta`,{
            method:'PUT',
            body: JSON.stringify({
                id_pedido: pedido._id,
                ruta: numeroRuta
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                toast.success('Operación realizada exitosamente')
                setOpenModal(false)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoading(false)
        })
    }


    const confirmarEliminado = () => {
        setLoading(true)
        return fetch(`${data.urlapi}/ordenes?id=${pedido._id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                toast.success('Eliminada exitosamente')
                return setTimeout(() => {
                    window.location = '/ordenes'
                }, 1000);
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoading(false)
        })
    }
    
    const bloquearEntrega = (i) => {
        setLoadingBloqueo(true)
        return fetch(`${data.urlapi}/ordenes/bloquear-entrega?id=${pedido._id}&bloquear=${i}`,{
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingBloqueo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingBloqueo(false)
            } else if(res._id){
                toast.success('Operación exitosa')
                pedido.entrega_bloqueada = i === 'yes' ? true : false
                setPedido(pedido)
            }
            return setLoadingBloqueo(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingBloqueo(false)
        })
    }
    
    const mostrarEnApp = (i) => {
        setLoadingMostrarApp(true)
        return fetch(`${data.urlapi}/ordenes/ocultar-entrega?id=${pedido._id}&mostrar=${i}`,{
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingMostrarApp(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingMostrarApp(false)
            } else if(res._id){
                toast.success('Operación exitosa')
                pedido.show_on_app = i === 'yes' ? true : false
                setPedido(pedido)
            }
            return setLoadingMostrarApp(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingMostrarApp(false)
        })
    }

    const solicitarEliminar = () => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Estás a punto de eliminar esta orden, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado()
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    return <div>
        {
            loading || loadingBloqueo || loadingMostrarApp ? <Spinner animation="border" /> : <ButtonGroup>
            <Button style={{ fontSize: 12 }} variant="outline-dark" size="sm" onClick={() => openModalEstado('reasignar')}>REASIGNAR RUTA</Button>
            <Button style={{ fontSize: 12 }} variant="outline-dark" size="sm" onClick={() => bloquearEntrega(pedido.entrega_bloqueada === true ? 'no' : 'yes')}>{ pedido.entrega_bloqueada === true ? 'DESBLOQUEAR PEDIDO' : 'BLOQUEAR PEDIDO' }</Button>
            <Button style={{ fontSize: 12 }} variant="outline-dark" size="sm" onClick={() => mostrarEnApp(pedido.show_on_app === true ? 'no' : 'yes')}>{ pedido.show_on_app === true ? 'OCULTAR PEDIDO DEL APP' : 'MOSTRAR PEDIDO EN APP' }</Button>
            <Button style={{ fontSize: 12 }} variant="danger" size="sm" onClick={() => solicitarEliminar()}>ELIMINAR ORDEN</Button>
        </ButtonGroup>
        }

        <Modal show={openModal} size="lg" onHide={()=>handleClose()} centered >
            <Modal.Body>
            {showByTipoVisualizacion()}
            </Modal.Body>
        </Modal>
    </div>
}

export default AccionesModal