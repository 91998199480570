import React, { useEffect, useRef } from 'react';
import { Card, Col, ProgressBar, Row } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { calcularPorcentaje } from '../../lib/helpers/helpers';

const GraficoOtiff = (props) => {
  const {
     data,
     typeView
  } = props
  if(!data) return false

  const {
    otif,
    total_asignadas,
    total_completadas,
    total_rechazadas
  } = data

  const porcentaje_retrasadas = calcularPorcentaje(total_asignadas, total_rechazadas)
  const porcentaje_completadas = calcularPorcentaje(total_asignadas, total_completadas)
  const porcentaje_pendientes = calcularPorcentaje(total_asignadas, (total_asignadas-total_completadas))
  const tipo_vista = typeView ? typeView : ''

  const validateNumber = (number) => {
    if(isNaN(parseInt(number))) return 0
    return Math.round(number)
  }

  const vistaBarras = () => {

    const porcentaje = "100%"
    const fontSize = 12
    return <div>
    <Row>
      <Col className='mb-1'>
        <h6 className="" style={{ fontSize }}>OTIF · {total_asignadas} Órdenes {otif}%</h6>
        <div style={{ width: porcentaje, display: "block"}}>
          <ProgressBar variant='success' now={otif}  />
        </div>
      </Col>
      <Col className='mb-1'>
        <h6 className="" style={{ fontSize }}>Retrasadas · {total_rechazadas} Órdenes {validateNumber(porcentaje_retrasadas)}%</h6>
        <div style={{ width: porcentaje, display: "block"}}>
        <ProgressBar variant='danger' now={validateNumber(porcentaje_retrasadas)}  />
        </div>
      </Col>
      <Col className='mb-1'>
        <h6 className="" style={{ fontSize }}>Completada · {total_completadas} Órdenes {validateNumber(porcentaje_completadas)}%</h6>
        <div style={{ width: porcentaje, display: "block"}}>
        <ProgressBar now={validateNumber(porcentaje_completadas)}  />
        </div>
      </Col>
      <Col className='mb-1'>
        <h6 className="" style={{ fontSize }}>En curso · {total_asignadas-total_completadas} Órdenes {validateNumber(porcentaje_pendientes)}</h6>
        <div style={{ width: porcentaje,display: "block"}}>
        <ProgressBar now={validateNumber(porcentaje_pendientes)} />
        </div>
      </Col>
      <Col className='mb-1'>
        <h6 className="" style={{ fontSize }}>Anuladas · {0} Órdenes 0%</h6>
        <div style={{ width: porcentaje,display: "block"}}>
        <ProgressBar variant='info' now={validateNumber(0)} />
        </div>
      </Col>
    </Row>
  </div>
  }

  const vistaSmall = () => {

    const porcentaje = "30%"
    const fontSize = 12
    return <div>
    <Card className='p-3'>
    <Row>
      <Col xs={4} className='mb-3'>
        <h6 className="" style={{ fontSize }}>OTIF · {total_asignadas} Órdenes</h6>
        <div style={{ width: porcentaje, display: "block"}}>
        <CircularProgressbar value={validateNumber(otif)} text={`${validateNumber(otif)}%`} />
        </div>
      </Col>
      <Col xs={4} className='mb-3'>
        <h6 className="" style={{ fontSize }}>Retrasadas · {total_rechazadas} Órdenes</h6>
        <div style={{ width: porcentaje, display: "block"}}>
        <CircularProgressbar styles={buildStyles({
          pathColor: 'red',
          textColor: 'red'
        })} value={validateNumber(porcentaje_retrasadas)} text={`${validateNumber(porcentaje_retrasadas).toFixed(0)}%`} />
        </div>
      </Col>
      <Col xs={4} className='mb-3'>
        <h6 className="" style={{ fontSize }}>Completada · {total_completadas} Órdenes {}</h6>
        <div style={{ width: porcentaje, display: "block"}}>
        <CircularProgressbar styles={buildStyles({
          pathColor: '#1cb03a',
          textColor: '#1cb03a'
        })} value={validateNumber(porcentaje_completadas)} text={`${validateNumber(porcentaje_completadas).toFixed(0)}%`} />
        </div>
      </Col>
      <Col xs={4} className='mb-3'>
        <h6 className="" style={{ fontSize }}>En curso · {total_asignadas-total_completadas} Órdenes</h6>
        <div style={{ width: porcentaje,display: "block"}}>
        <CircularProgressbar styles={buildStyles({
        })} value={validateNumber(porcentaje_pendientes)} text={`${validateNumber(porcentaje_pendientes).toFixed(0)}%`} />
        </div>
      </Col>
      <Col xs={4} className='mb-3'>
        <h6 className="" style={{ fontSize }}>Anuladas · {0} Órdenes</h6>
        <div style={{ width: porcentaje,display: "block"}}>
        <CircularProgressbar styles={buildStyles({
        })} value={validateNumber(0)} text={`${validateNumber(0).toFixed(0)}%`} />
        </div>
      </Col>
    </Row>
    </Card>
  </div>
  }

  const vistaSimple = () => {

    const porcentaje = "30%"
    const fontSize = 12
    return <div>
    <Card className='p-3'>
    <Row>
      <Col xs={6} className='mb-3'>
        <h6 className="" style={{ fontSize }}>OTIF · {total_asignadas} Órdenes</h6>
        <div style={{ width: porcentaje, display: "block"}}>
        <CircularProgressbar value={validateNumber(otif)} text={`${validateNumber(otif)}%`} />
        </div>
      </Col>
      <Col xs={6} className='mb-3'>
        <h6 className="" style={{ fontSize }}>Retrasadas · {total_rechazadas} Órdenes</h6>
        <div style={{ width: porcentaje, display: "block"}}>
        <CircularProgressbar styles={buildStyles({
          pathColor: 'red',
          textColor: 'red'
        })} value={validateNumber(porcentaje_retrasadas)} text={`${validateNumber(porcentaje_retrasadas).toFixed(0)}%`} />
        </div>
      </Col>
      <Col xs={6} className='mb-3'>
        <h6 className="" style={{ fontSize }}>Completada · {total_completadas} Órdenes {}</h6>
        <div style={{ width: porcentaje, display: "block"}}>
        <CircularProgressbar styles={buildStyles({
          pathColor: '#1cb03a',
          textColor: '#1cb03a'
        })} value={validateNumber(porcentaje_completadas)} text={`${validateNumber(porcentaje_completadas).toFixed(0)}%`} />
        </div>
      </Col>
      <Col xs={6} className='mb-3'>
        <h6 className="" style={{ fontSize }}>En curso · {total_asignadas-total_completadas} Órdenes</h6>
        <div style={{ width: porcentaje,display: "block"}}>
        <CircularProgressbar styles={buildStyles({
        })} value={validateNumber(porcentaje_pendientes)} text={`${validateNumber(porcentaje_pendientes).toFixed(0)}%`} />
        </div>
      </Col>
      <Col xs={6} className='mb-3'>
        <h6 className="" style={{ fontSize }}>Anuladas · {0} Órdenes</h6>
        <div style={{ width: porcentaje,display: "block"}}>
        <CircularProgressbar styles={buildStyles({
        })} value={validateNumber(0)} text={`${validateNumber(0).toFixed(0)}%`} />
        </div>
      </Col>
    </Row>
    </Card>
  </div>
  }
  const vistaNormal = () => {
    return <div>
    <Card className='p-3 text-white' bg='dark'>
    <Row>
      <Col xs={2} className='mb-3'>
        <h6 className="text-center">OTIF<br/>{total_asignadas} Órdenes</h6>
        <div style={{ width:"20%", textAlign: "center", margin: "0 auto", display: "block"}}>
        <CircularProgressbar value={validateNumber(otif)} text={`${validateNumber(otif)}%`} />
        </div>
      </Col>
      <Col xs={2} className='mb-3'>
        <h6 className=" text-center">Retrasadas<br/>{total_rechazadas} Órdenes</h6>
        <div style={{ width:"20%", textAlign: "center", margin: "0 auto", display: "block"}}>
        <CircularProgressbar styles={buildStyles({
          pathColor: 'red',
          textColor: 'red'
        })} value={validateNumber(porcentaje_retrasadas)} text={`${validateNumber(porcentaje_retrasadas).toFixed(0)}%`} />
        </div>
      </Col>
      <Col xs={2} className='mb-3'>
        <h6 className=" text-center">Completada<br/>{total_completadas} Órdenes</h6>
        <div style={{ width:"20%", textAlign: "center", margin: "0 auto", display: "block"}}>
        <CircularProgressbar styles={buildStyles({
          pathColor: '#1cb03a',
          textColor: '#1cb03a'
        })} value={validateNumber(porcentaje_completadas)} text={`${validateNumber(porcentaje_completadas).toFixed(0)}%`} />
        </div>
      </Col>
      <Col xs={2} className='mb-3'>
        <h6 className=" text-center">En curso<br/>{total_asignadas-total_completadas} Órdenes</h6>
        <div style={{ width:"20%", textAlign: "center", margin: "0 auto", display: "block"}}>
        <CircularProgressbar styles={buildStyles({
        })} value={validateNumber(porcentaje_pendientes)} text={`${validateNumber(porcentaje_pendientes).toFixed(0)}%`} />
        </div>
      </Col>
      <Col xs={2} className='mb-3'>
        <h6 className=" text-center">Anulados<br/>{0} Órdenes</h6>
        <div style={{ width:"20%", textAlign: "center", margin: "0 auto", display: "block"}}>
        <CircularProgressbar styles={buildStyles({
        })} value={validateNumber(porcentaje_pendientes)} text={`${validateNumber(porcentaje_pendientes).toFixed(0)}%`} />
        </div>
      </Col>
    </Row>
    </Card>
  </div>
  }

  switch (tipo_vista) {
    case "barras":
      return vistaBarras()
    case "small":
      return vistaSmall()
    case "simple":
      return vistaSimple()
    default:
        return vistaNormal()
  }


  
}

export default GraficoOtiff