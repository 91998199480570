export const obtenerZonasDePedidos = (ordenes) => {
    if(!ordenes) return []
    if(Array.isArray(ordenes) !== true) return []
    if(ordenes.length < 1) return []
    let zonas = []
    for( const pedido of ordenes ){

        if(!pedido.orden) {
            continue
        }
        if(typeof pedido.orden !== "object") {
            continue
        }
        if(!pedido.orden.geo_datos) {
            continue
        }
        if(typeof pedido.orden.geo_datos !== "object") {
            continue
        }
        if(!pedido.orden.geo_datos.lat) {
            continue
        }
        if(!pedido.orden.geo_datos.level3) {
            continue
        }
        if(!zonas.includes(pedido.orden.geo_datos.level3)) zonas.push(pedido.orden.geo_datos.level3)
    }
    return zonas
}

export const detectarPedidosSinGeoDatos = (pedidos) => {
    const default_data = {
        sinlatlong: 0,
        invalidos: []
    }
    if(!pedidos) return default_data
    if(Array.isArray(pedidos) !== true) return default_data

    let sinlatlong = 0;
    let invalidos = []

    for( const pedido of pedidos ){
        if(!pedido.orden) {
            invalidos.push(pedido)
            sinlatlong++
            continue
        }
        if(typeof pedido.orden !== "object") {
            invalidos.push(pedido)
            sinlatlong++
            continue
        }
        if(!pedido.orden.geo_datos) {
            invalidos.push(pedido)
            sinlatlong++
            continue
        }
        if(typeof pedido.orden.geo_datos !== "object") {
            invalidos.push(pedido)
            sinlatlong++
            continue
        }
        if(!pedido.orden.geo_datos.lat) {
            invalidos.push(pedido)
            sinlatlong++
            continue
        }
        if(!pedido.orden.geo_datos.level3) {
            invalidos.push(pedido)
            sinlatlong++
            continue
        }
    }
    return {
        sinlatlong,
        invalidos
    }
}

export const stringByStatusAsignacionRuta = (val) => {
    switch (val) {
        case "pendiente-cliente":
            return "Espera por propietario"
        case "rechazada-cliente":
            return "Rechazada por propietario"
        case "pendiente-proveedor":
            return "Espera por proveedor"
        case "rechazada":
            return "Rechazada"
        case "aceptada-cliente":
            return "Aceptada por propietario"
        case "aceptada-proveedor":
            return "Aceptada por proveedor"
        default:
            break;
    }
}

export const colorByStatusAsignacionRuta = (val) => {
    switch (val) {
        case "pendiente-cliente":
            return ""
        case "pendiente-proveedor":
            return ""
        case "rechazada":
            return "red"
        case "aceptada-cliente":
            return "#8aca2b"
        case "aceptada-proveedor":
            return "#8aca2b"
        default:
            break;
    }
}