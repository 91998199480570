export const rutas = {
    excel_formats: {
        slug: 'excel_formats'
    },
    custom_fields: {
        slug: 'custom-fields'
    },
    geocercas: {
        slug: 'geofencing'
    },
    integraciones: {
        slug: 'integraciones'
    },
    proveedores:{
        slug: 'proveedores'
    },
    estadisticas:{
        slug: 'estadisticas'
    },
    vehiculos:{
        slug: 'vehiculos'
    },
    actividad:{
        slug: 'actividad'
    },
    rendimiento_proveedores:{
        slug: 'rendimiento-flota-externa'
    },
    operacion_en_curso:{
        slug: 'operacion-en-curso'
    },
    actividad_flota:{
        slug: 'actividad-flota'
    },
    actividad_estados:{
        slug: 'actividad-estados'
    },
    actividad_auditoria:{
        slug: 'actividad-auditoria'
    },
    usuarios:{
        slug: 'usuarios'
    },
    ordenes:{
        slug: 'ordenes'
    },
    ordenes_crear:{
        slug: 'ordenes/nuevo'
    },
    rutas:{
        slug: 'rutas'
    },
    rutas_importar_excel:{
        slug: 'rutas/importar'
    },
    rutas_importar_avanzado:{
        slug: 'rutas/importar-avanzado'
    },
    planificador:{
        slug: 'planificador'
    },
    rutas_asignaciones:{
        slug: 'rutas-asignaciones'
    },
    estados_carga:{
        slug: 'estados-carga'
    },
    flujos_carga:{
        slug: 'flujos-carga'
    },
    avanzada:{
        slug: 'configuracion'
    },
    etiquetas:{
        slug: 'etiquetas'
    },
    bodega_usuarios:{
        slug: 'bodega-usuarios'
    },
    bodega_bodegas:{
        slug: 'bodegas'
    },
    bodega_expediciones:{
        slug: 'bodega-expediciones'
    },
    canales:{
        slug: 'canales'
    },
    mercadolibre_config:{
        slug: 'integraciones/mercadolibre/init'
    },
    apiless_detail:{
        slug: 'integraciones/apiless/details'
    },
    logistic_providers:{
        slug: 'proveedores/transporte'
    },
    logistic_providers_link:{
        slug: 'proveedores/enlace'
    },
    integraciones_funciones_crear:{
        slug: 'integraciones/funciones/new'
    },
    clientes:{
        slug: 'clientes'
    },
    pricing:{
        slug: 'pricing'
    },
    pricing_lote_nuevo:{
        slug: 'pricing/lote/nuevo'
    },
    pricing_lotes:{
        slug: 'pricing/lote'
    },
    facturacion: {
        slug: 'billing'
    },
    landing: {
        actividad: {
            slug: 'product'
        }
    }
}