import { Card } from "react-bootstrap"
import { fechaATexto } from "../../lib/helpers/helpers"

const BoxList = (props) => {
    const proveedor = props.proveedor ? props.proveedor : false
    const seleccionado = props.seleccionado ? props.seleccionado : false

    if(!proveedor) return false

    const onClickProveedor = (id) => {
        if(props.onClickProveedor) return props.onClickProveedor(id)
        return false
    }

    return <Card bg={ seleccionado === true ? 'primary' : 'white' } className={`hover p-3 ${seleccionado === true ? 'text-white' : 'text-dark'}`} onClick={() => onClickProveedor(proveedor._id)}>
    <h6 style={{ fontSize: 13 }} className="mb-1">{proveedor.titulo}</h6>
    <h6 style={{ fontSize:13, fontWeight:'lighter' }} className="mb-0">{ fechaATexto(proveedor.createdAt)}</h6>
</Card>
}

export default BoxList