import Map, { Marker } from 'react-map-gl';
import { mapbox_token, urlapi } from '../../lib/backend/data';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as turf from '@turf/turf';
import Pin from '../../subComponents/mapas/pin';
import { obtenerCentroMapaPorPais } from '../../lib/helpers/data/internationa';

const MapaDetallePedido = (props) => {
    const {
        height,
        markers
    } = props
    const [ marcadores, setMarcadores ] = useState(markers)
    const sesion = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const defaultViewState = obtenerCentroMapaPorPais(pais)
    const [viewState, setViewState] = useState(defaultViewState);
    const [ zoomInit, setZoomInit ] = useState(false) 
    const mapRef = useRef()

    const ajustarCentro = (puntos) => {
        if(zoomInit) return false
        if(puntos.length < 1) return false
        setZoomInit(true)
        const points = puntos.map(marker => turf.point([marker.longitude, marker.latitude]));
      const collection = turf.featureCollection(points);
      const bounds = turf.bbox(collection);

      const newViewport = {
        ...viewState,
        latitude: (bounds[1] + bounds[3]) / 2,
        longitude: (bounds[0] + bounds[2]) / 2,
        maxZoom: 12
      };

      const options = {
        padding: 100 // Ajusta el valor de padding según tus necesidades
      };

      setViewState(newViewport);
      mapRef.current?.fitBounds(bounds, options);
    }

      useEffect(() => {
        setTimeout(() => {
            ajustarCentro(marcadores.map(marker => ({ latitude: marker.lat, longitude: marker.lng })))
        }, 500);
      }, [])

      const refrescarListado = async ()=>{
        console.log("se dispara")
        return fetch(`${urlapi}/conductores/vehicles-actives`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return res.json() //dispatch(cerrarSesio())
            return res.json()
        })
        .then(res => {
            if(!res){
                return false
            } else if(res.errorMessage){
                return false
            } else if(Array.isArray(res) !== false){
                ajustarCentro(res.map(marker => ({ latitude: marker.lat, longitude: marker.lng })))
                setMarcadores(prev => [...[], ...res])
            }
            return false
        })
        .catch(error => {
            return false
        })
    }

    return <Map
    ref={mapRef}
    {...viewState}
    scrollZoom={false}
    dragPan={false}
    onMove={evt => setViewState(evt.viewState)}
    style={{width: "100%", height: height ? height : 350}}
    pitch={25}
    mapStyle="mapbox://styles/mapbox/streets-v9"
    mapboxAccessToken={mapbox_token}
  >
    {
        marcadores.map(marker => {
            const content = (
                <div>
                    <p>{marker.fecha_}</p>
                </div>
            )
            return <Marker
                    key={marker._id}
                    longitude={marker.lng}
                    latitude={marker.lat}
                >
                <div>
                    <Pin />
                </div>
            </Marker>
        })
    }
  </Map>
}

export default MapaDetallePedido