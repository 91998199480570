import { reiniciarEsquema } from "./esquemaTablas"

const { Navigate } = require("react-router-dom")
const { default: guardarUsuario } = require("./guardarUsuario")

export const cerrarSesion = () => {
    return async dispatch => {
            dispatch(reiniciarEsquema())
            dispatch(guardarUsuario(false))
            return <Navigate to="/login" />
    }
}
