import React, { Component, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { userService } from '../../services/user.service'
import { Row, Col, Container, Form, Button, InputGroup, FormControl } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import { validateEmail } from '../../lib/helpers/helpers'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import guardarUsuario from '../../redux/actions/guardarUsuario'
import { inicializarEsquema, reiniciarEsquema } from '../../redux/actions/esquemaTablas'
import { cambiarPais } from '../../redux/actions/pais'
import "../Login/login.css"
import { url_images } from '../../lib/global/data'



const Login = (props) => {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errorLogin, setErrorLogin] = useState('')
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(guardarUsuario(false))
        dispatch(reiniciarEsquema())
    }, [])

    const handleSubmit = (e) => {
        if (e) e.preventDefault()
        if (!username) return toast.error("Escribe tu email")
        if (!password) return toast.error("Escribe tu contraseña")
        if (validateEmail(username) !== true) return toast.error('Email inválido')

        setLoading(true)
        userService.login(username, password)
            .then(
                user => {
                    if (!user) {
                        setErrorLogin("Email o Contraseña erroneas")
                        setLoading(false)
                    } else {
                        if (user.configuracion_interfaz) {
                            if (typeof user.configuracion_interfaz === "object") {
                                if (user.configuracion_interfaz.detalles) {
                                    if (typeof user.configuracion_interfaz.detalles === "object") {
                                        dispatch(inicializarEsquema(user.configuracion_interfaz.detalles))
                                    }
                                }
                            }
                        }
                        if (user.data.empresa) if (typeof user.data.empresa === "object") if (user.data.empresa.pais_code) dispatch(cambiarPais(user.data.empresa.pais_code))
                        dispatch(guardarUsuario(user))
                        return window.location = "/"
                    }
                },
                error => setLoading(false)
            );
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === "username") {
            return setUsername(value)
        } else if (name === "password") {
            return setPassword(value)
        }
    }

    return <div className='login-form'>
        <Row className="justify-content-md-center">
            <Col md={4} className="centerv row justify-content-center border px-5 py-4 shadow">
                <Row className='row justify-content-center'>
                    <img alt="logotipo" src={`${url_images}/logoColor.svg`} style={{ width: 200 }} />
                </Row>

                <Container className="text-center">
                    <p className='my-3' style={{ fontWeight: 700, fontSize: 15 }}>Transformamos la experiencia en software</p>
                    <p className="m-0" style={{ fontWeight: 900, fontSize: 32 }}>¡BIENVENIDO!</p>
                    <p style={{ fontWeight: 900, fontSize: 18 }}>Iniciar Sesión</p>
                </Container>

                <form className="container" onSubmit={handleSubmit}>
                    <InputGroup className="input mb-4" style={{ fontWeight: 400, fontSize: 18 }}>
                        <InputGroup.Prepend>
                            <InputGroup.Text className="iconBck">
                                <img alt="emailIcon" src={`${url_images}/icono_email.png`} style={{ width: 24 }} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            id="emailFormInput"
                            type="email"
                            name="username"
                            placeholder="Ingresar Email"
                            onChange={handleChange} />
                    </InputGroup>

                    <InputGroup className="input mb-4" style={{ fontWeight: 400, fontSize: 18 }}>
                        <InputGroup.Prepend>
                            <InputGroup.Text className="iconBck">
                                <img alt="passwordIcon" src={`${url_images}/icono_Candado.png`} style={{ width: 24 }} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            id="FormInpasswordput"
                            type="password"
                            name="password"
                            placeholder="Ingresar Contraseña"
                            onChange={handleChange} />
                    </InputGroup>

                    {loading ? <Spinner animation="border" /> :
                        <Button
                            className="btn shadow"
                            type="submit"
                            block
                            style={{ fontWeight: 700, fontSize: 18 }}>
                            Entrar
                        </Button>}
                    {errorLogin ? <p className="text-danger">{errorLogin}</p> : false}
                </form>
                <Container className="text-right">
                    <p className='mb-5 mt-3' style={{ fontWeight: 400, fontSize: 15 }}>
                        <Link to={`/olvide-password`}>¿Olvidaste tu contraseña?</Link>
                    </p>
                </Container>
                <div className="d-flex align-items-center px-4">
                    {/* <Form.Check className="p-0" id="checkbox" type="checkbox" /> */}
                    <p className="m-0 px-0" style={{ fontWeight: 700, fontSize: 12 }}>
                        <Link to={'/terminos-condiciones'}>Términos y Condiciones</Link> | <Link to={'/politicas-privacidad'}>Política de privacidad</Link>
                    </p>
                </div>
            </Col>
        </Row>
    </div>
}

export default Login
/*
class Login extends Component {
    constructor(props){
        super(props)
        props.guardarUsuario(false)
        userService.logout();
        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            errorLogin: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleSubmit(e){}
    
    render(){
        const { loading, errorLogin } = this.state
        return(
            <Container style={{ backgroundColor:'black' }}>
                
        <Row className="justify-content-md-center">
            <Col md={4} className="centerv">
            <img alt="logotipo" src="images/logo.png" style={{ width: 200 }} />
            <h5 className='mb-5' style={{ fontWeight:'lighter' }}>Transformamos la experiencia en software</h5>

                <h4>Bienvenido de vuelta, Inicia sesión</h4>

            <form onSubmit={this.handleSubmit}>
            <Form.Control className='mb-3' type="email" name="username" placeholder="Email" onChange={this.handleChange} />
            <Form.Control className='mb-3' type="password" name="password" placeholder="Contraseña" onChange={this.handleChange} />
            { loading ? <Spinner animation="border" /> : <Button type="submit"  block variant="primary">ENTRAR</Button> }  
            { errorLogin ? <p className="text-danger">{errorLogin}</p> : false }
            </form>
            <h6 className='mb-5 mt-3'>¿Olvidaste tu contraseña? <Link to={`/olvide-password`}>Recuperar</Link> </h6>

    <Row>
        <Col md={6}><Link to={'/terminos-condiciones'}>Términos y condiciones</Link> </Col>
        <Col md={6}><Link to={'/politicas-privacidad'}>Política de privacidad</Link> </Col>
    </Row>
    <p style={{ fontSize:12 }}>Al ingresar aceptas los Términos y Condiciones y la Política de Privacidad</p>
            </Col>
        </Row>

        
        
    </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        guardarUsuario: (data) => dispatch(guardarUsuario(data))
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Login);
  */