import { Card, Col, Row } from "react-bootstrap"
import { fechaATexto } from "../../../lib/helpers/helpers"
import { colorByStatusAsignacionRuta, stringByStatusAsignacionRuta } from "../../../lib/helpers/rutas"

const BoxList = (props) => {
    const {
        asignacion
    } = props
    const ruta = props.ruta ? props.ruta : false
    const seleccionado = props.seleccionado ? props.seleccionado : false

    if(!ruta) return false

    const clickRuta = (id) => {
        if(props.onClickRuta) return props.onClickRuta(id)
        return false
    }

    const mostrarVehiculo = (driver) => {
        if(!driver) return false
        return <div>
            <hr className="hr" />
            <h6 className="mb-0" style={{ fontWeight: 'lighter' }}><b>{driver.modelo}</b> {driver.ano}</h6>
            <h6 className="mb-0" style={{ fontWeight: 'lighter' }}>PATENTE: <b>{driver.patente.toUpperCase()}</b></h6>
        </div>
    }
    
    const mostrarEmpresa = () => {
        if(!asignacion.empresa) return false
        if(typeof asignacion.empresa !== "object") return false
        return <Card className="p-2 mt-2 text-dark">
            <span style={{ fontSize: 9}}>EMPRESA</span>
            <h6 className="mb-0">{asignacion.empresa.razon_social}</h6>
            </Card>
    }

    return <Card bg={ seleccionado === true ? 'primary' : 'white' } className={`hover p-3 ${seleccionado === true ? 'text-white' : 'text-dark'}`} onClick={() => clickRuta(ruta._id)}>
        <p style={{ fontSize: 11 }} className="mb-0"><b>Creado</b> {fechaATexto(ruta.createdAt)}</p>
        <h6 className="mb-0">RUTA {ruta.id} · <b style={{ fontWeight:'lighter' }}>{ruta.pedidos} ÓRDENES</b></h6>
        <h6 className="mb-0" style={{ color: colorByStatusAsignacionRuta(asignacion.status) }} >{stringByStatusAsignacionRuta(asignacion.status)}</h6>
        {mostrarEmpresa()}
</Card>
}

export default BoxList