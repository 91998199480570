import React, { Component} from 'react'
import { connect } from 'react-redux'
import { 
    Row, 
    Col, 
    Card, 
    Button, 
    Form } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import 'moment/locale/es';
import data, { key_local_storage_user } from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import 'moment/locale/es';
import Switch from "react-switch";
import { validateEmail } from '../../lib/helpers/helpers'
import SinDatos from '../../subComponents/general/sin_registros'
import { acciones} from '../../lib/global/data'

class MiCuenta extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem(key_local_storage_user)),
            usuario: false,
            loadingUsuario: false,
            comunas_descartar_creacion: [],
            show_password: false,
            guardandoCambios: false,
            desbloquear_permisos: false,
            cambiandoPassword: false,
            password: '',
            password_confirm: '',
            loadingPermisos: true,
            permisos: [],
            permisos_otorgados: []
        }
        this.handleChangeUsuario = this.handleChangeUsuario.bind(this)
        this.handleChangeChecked = this.handleChangeChecked.bind(this)
        this.handleChangePassword = this.handleChangePassword.bind(this)
        this.handleCheck = this.handleCheck.bind(this)
    }

    handleChangePassword(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleCheck(e){
        const { permisos_otorgados } = this.state
        const { name } = e.target
        const permiso = e.target.getAttribute('permiso')
        // check if permissions are granted
        const i = permisos_otorgados.findIndex(per => per.slug === permiso)
        if(i > -1){

            if(e.target.checked === true){
                if(!permisos_otorgados[i].actions.includes(name)) permisos_otorgados[i].actions.push(name)
            } else {
                const pos = permisos_otorgados[i].actions.findIndex(action => action === name)
                if(pos > -1) permisos_otorgados[i].actions.splice(pos,1)
            }

        } else {
            permisos_otorgados.push({
                slug: permiso,
                actions: [ name ]
            })
        }
        console.log(permisos_otorgados)
        return this.setState({ permisos_otorgados })
    }

    handleChangeUsuario(e){
        const { usuario } = this.state
        const { name, value } = e.target
        usuario[name] = value
        return this.setState({ usuario })
    }

    handleChangeChecked(checked){
        const { usuario } = this.state
        usuario.activo = checked
        return this.setState({ usuario })
    }

    componentDidMount(){
        this.getUsuario()
        this.getEsquemaPermisos()
    }

    async getEsquemaPermisos(){
        const { user } = this.state
        let loading = 'loadingPermisos'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/permisos/modelo`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            return this.setState({ permisos: res, [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }
    async getUsuario(){
        const { user } = this.state
        let loading = 'loadingUsuario'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/usuarios/cuenta`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            if(res.role === "admin") this.setState({ desbloquear_permisos: true })
            if(res.permisos){
                if(Array.isArray(res.permisos) !== false){
                    this.setState({ permisos_otorgados: res.permisos.map(p => ({ slug: p.module, actions: p.actions })) })
                }
            }
            return this.setState({ usuario: res, [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    cambiarPassword(){
        const { usuario, user, password, password_confirm } = this.state
        if(!password_confirm || !password) return toast.error('Contraseña es requerida')
        if(password.length < 5) return toast.error('La contraseña debe tener al menos 5 carácteres')
        if(password !== password_confirm) return toast.error('Las contraseñas no coinciden')
        const loading = 'cambiandoPassword'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/usuarios/reset-password`,{
            method:'PUT',
            body: JSON.stringify( {
                id: usuario._id,
                password, 
                password_confirm
            } ),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(res._id){ 
                toast.success('Guardado exitosamente')
            } else {
                toast.error('Ocurrió un error inesperado')
            }
            this.setState({ [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }

    guardarCambios(){
        const { usuario, user, permisos_otorgados, permisos } = this.state
        const requeridos = [
            { value:'nombres', label: 'Nombres' },
            { value:'apellidos', label: 'Apellidos' },
            { value:'email', label: 'Email' },
            { value:'role', label: 'Rol de usuario' },
            { value:'status', label: 'Estado' },
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!usuario[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)
        if(validateEmail(usuario.email) !== true) return toast.error('Email inválido')
        const loading = 'guardandoCambios'
        if(usuario.role === "admin"){
            // ACTIVAMOS TODOS LOS PERMISOS
            const todos = permisos.map(p => {
                const i = permisos_otorgados.findIndex(pe => pe.module === p.slug )
                if(i > -1){
                    permisos_otorgados[i].actions = acciones.map(a => a.slug)
                    return permisos_otorgados[i]
                } else {
                    p.actions = acciones.map(a => a.slug)
                    return p
                }
            })
            console.log({todos})
            usuario.permisos = todos
        } else {
            usuario.permisos = permisos_otorgados
        }
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/usuarios`,{
            method:'PUT',
            body: JSON.stringify( usuario ),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(res._id){ 
                toast.success('Guardado exitosamente')
                setTimeout(() => {
                    window.location = `/account`
                }, 500);
            } else {
                toast.error('Ocurrió un error inesperado')
            }
            this.setState({ [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }

    permisos(){
        const { loadingPermisos, permisos_otorgados, permisos, desbloquear_permisos } = this.state
        if(!desbloquear_permisos) return false
        if(loadingPermisos===true) return <Col xs={12} className="mt-3">
           <Spinner animation='border' />
           <h4>Cargando permisos</h4>
        </Col>
        return <Col xs={12}>
        <hr />
        <i className="fa-solid fa-sliders"></i>
        <h3>Permisos</h3>
        <Row>
        {
            permisos.map(permiso => {
                return <Col key={permiso._id} md={3} className="mb-4">
                    <h5>{permiso.titulo}</h5>
                    {
                        acciones.map((accion,i) => {
                            let checkeado = false
                            const pos = permisos_otorgados.findIndex(per => per.slug === permiso.slug)
                            if(pos > -1){
                                if(permisos_otorgados[pos].actions.includes(accion.slug)) checkeado = true
                            }
                            return <Form.Group key={accion.slug} className="mb-3" controlId={`${permiso._id}-${accion.slug}`}>
                                    <Form.Check type="switch" defaultChecked={checkeado} permiso={permiso.slug} name={accion.slug} label={accion.label} onChange={this.handleCheck} />
                                </Form.Group>
                        })
                    }
                    </Col>
            })
        }
        </Row>
        </Col>
    }

    formularioUsuario(){
        const { loadingUsuario, show_password, cambiandoPassword, guardandoCambios, usuario } = this.state
        if(loadingUsuario) return <Spinner animation='border' />
        if(!usuario) return <SinDatos />
        return <div>
            <Row>
                <Col md={9}>
                <i className="icono fa-solid fa-gear"></i>
                <h3>Datos generales</h3>
                    <Row>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label'>Nombres</label>
                        <input name="nombres" defaultValue={usuario.nombres} className='form-control' onChange={this.handleChangeUsuario} />
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label'>Apellidos</label>
                        <input name="apellidos" defaultValue={usuario.apellidos} className='form-control' onChange={this.handleChangeUsuario} />
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label'>Email</label>
                        <input name="email" defaultValue={usuario.email} className='form-control' readOnly />
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label'>ID Fiscal</label>
                        <input name="rut" defaultValue={usuario.rut} className='form-control' onChange={this.handleChangeUsuario} />
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label'>Fecha nacimiento</label>
                        <input name="fecha_nacimiento" defaultValue={usuario.fecha_nacimiento} type="date" className='form-control' onChange={this.handleChangeUsuario} />
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label'>Teléfono</label>
                        <input name="phone" defaultValue={usuario.phone} className='form-control' onChange={this.handleChangeUsuario} />
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label'>Sexo</label>
                        <select name="sexo" defaultValue={usuario.sexo} className='form-control'  readOnly>
                            <option value="">Selecciona</option>
                            <option value="H">Hombre</option>
                            <option value="M">Mujer</option>
                        </select>
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label'>Rol de usuario</label>
                        <select name="role" defaultValue={usuario.role} className='form-control' readOnly>
                            <option value="">Selecciona</option>
                            <option value="user">Usuario</option>
                            <option value="admin">Administrador</option>
                        </select>
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label'>Estado</label>
                        <select name="status" defaultValue={usuario.status} className='form-control' readOnly>
                            <option value="">Selecciona</option>
                            <option value="active">Activo</option>
                            <option value="trash">Desactivado</option>
                        </select>
                    </Col>
                    </Row>
                </Col>
                <Col md={3}>
                    <i className="icono fa-solid fa-lock"></i>
                    <h3> Seguridad</h3>

                    <label className='form-control-label d-block'>Contraseña</label>
                    <input className='form-control mb-4' type={ show_password === true ? 'text' : 'password' } name="password" onChange={this.handleChangePassword} />

                    <label className='form-control-label d-block'>Repite Contraseña</label>
                    <input className='form-control mb-4' type={ show_password === true ? 'text' : 'password' } name="password_confirm" onChange={this.handleChangePassword} />

                    { cambiandoPassword === true ? <Spinner animation='border' /> : <Button variant="outline-primary" size="sm" onClick={()=>this.cambiarPassword()}>CAMBIAR CONTRASEÑA</Button>}
                </Col>

                {this.permisos()}
            </Row>
            <hr />
                        {
                            guardandoCambios === true ? <Spinner animation='border' /> : <Button size="sm" variant='success' onClick={()=>this.guardarCambios()}>GUARDAR CAMBIOS</Button>
                        }
        </div>
    }

    botonSeleccionar(id){
        const { planes_activos } = this.state
        const i = planes_activos.findIndex(plan => plan._id === id)
        if(i > -1) return <Button size="sm" variant="outline-secondary" >PLAN ACTIVO</Button>

        return <Button size="sm" variant="outline-primary" ><Link to={`pay-plan-${id}`} >SELECCIONAR</Link></Button>
    }
    
    render(){
        return(<div>
            <h4>Mi cuenta </h4>
                <Card >
                    <Card.Body>
                    {this.formularioUsuario()}
                    </Card.Body>
                </Card>
        </div>)
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(MiCuenta);