import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button, Breadcrumb, InputGroup, FormControl } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import 'moment/locale/es';
import data, { key_local_storage_user } from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import 'moment/locale/es';
import { rutas } from '../../lib/routes/routes'
import { obtenerNumeroCelularPorPais } from '../../lib/helpers/data/internationa'

class VehiculosCrear extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem(key_local_storage_user)),
            nuevoconductor: {
                activo: true
            },
            creandoVehiculo: false,
            codigo_movil: obtenerNumeroCelularPorPais(this.props.pais)
        }
        this.handleChangeVehiculo = this.handleChangeVehiculo.bind(this)
        this.handleChangeChecked = this.handleChangeChecked.bind(this)
    }

    handleChangeVehiculo(e){
        const { name, value } = e.target
        const { nuevoconductor } = this.state
        if(name === 'rut'){
            nuevoconductor[name] = value.replace(/\D/g, "")
        } else if(name === 'frecuencytracking'){
            nuevoconductor[name] = value.replace(/\D/g, "")
        } else {
            nuevoconductor[name] = value
        }
        return this.setState({ nuevoconductor })
    }

    handleChangeChecked(checked){
        return this.setState({ show_password: checked })
    }

    componentDidMount(){
        const { user } = this.state
    }

    crearVehiculo(){
        const { nuevoconductor, user, codigo_movil } = this.state
        const requeridos = [
            { value:'nombres', label: 'Nombres' },
            { value:'apellidos', label: 'Apellidos' },
            { value:'patente', label: 'Patente' },
            { value:'phone', label: 'Teléfono' },
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!nuevoconductor[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)

        const instancia = JSON.parse( JSON.stringify(nuevoconductor) )
        instancia.phone = `+${codigo_movil}${nuevoconductor.phone}`

        const loading = 'creandoVehiculo'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/conductores`,{
            method:'POST',
            body: JSON.stringify( instancia ),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){ 
                toast.success('Guardado exitosamente')
                setTimeout(() => {
                    return window.location = `/${rutas.vehiculos.slug}/edit/${res._id}`
                }, 500);
            }
            this.setState({ [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }

    formularioVehiculo(){
        const { nuevoconductor, creandoVehiculo } = this.state
        if(creandoVehiculo === true) return <Spinner animation='border' />

        return <div>
            <Row>

            <Col md={2} className='mb-3'>
                <label className='form-control-label'>Conductor Nombres</label>
                <input name="nombres" className='form-control' value={nuevoconductor.nombres}  onChange={this.handleChangeVehiculo}/>
            </Col >
            <Col md={2} className='mb-3'>
                <label className='form-control-label'>Conductor Apelidos</label>
                <input name="apellidos" className='form-control' value={nuevoconductor.apellidos} onChange={this.handleChangeVehiculo} />
            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label'>Conductor Rut</label>
                <input name="rut" className='form-control' value={nuevoconductor.rut} onChange={this.handleChangeVehiculo} />
            </Col >
            <Col md={2} className='mb-3'>
                <label className='form-control-label'>Modelo Vehículo</label>
                <input name="modelo" className='form-control' value={nuevoconductor.modelo} onChange={this.handleChangeVehiculo} />
            </Col >
            <Col md={2} className='mb-3'>
                <label className='form-control-label'>Año Vehículo</label>
                <input name="ano" type="number" className='form-control' value={nuevoconductor.ano} onChange={this.handleChangeVehiculo} />
            </Col >
            <Col md={2} className='mb-3'>
                <label className='form-control-label'>Patente Vehículo</label>
                <input name="patente" className='form-control' value={nuevoconductor.patente} onChange={this.handleChangeVehiculo} />
            </Col >
            <Col md={2} className='mb-3'>
                <label className='form-control-label'>Móvil Whatsapp</label>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">+{this.state.codigo_movil}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                    onChange={this.handleChangeVehiculo} 
                    name="phone"
                    value={nuevoconductor.phone}
                    />
                </InputGroup>
            </Col>
                <Col md={12}>
                    <Button size="sm" variant='success' onClick={()=>this.crearVehiculo()}>CREAR </Button>
                </Col>
            </Row>
            
        </div>
    }

    botonSeleccionar(id){
        const { planes_activos } = this.state
        const i = planes_activos.findIndex(plan => plan._id === id)
        if(i > -1) return <Button size="sm" variant="outline-secondary" >PLAN ACTIVO</Button>

        return <Button size="sm" variant="outline-primary" ><Link to={`pay-plan-${id}`} >SELECCIONAR</Link></Button>
    }
    
    render(){
        return(
            <div>
            <Breadcrumb>
                <Breadcrumb.Item href="#"><Link to="/vehiculos">Vehículos</Link> </Breadcrumb.Item>
                <Breadcrumb.Item active>Crear</Breadcrumb.Item>
            </Breadcrumb>
            <h4>Crear nuevo vehículo </h4>
            <Card >
                <Card.Body>
                {this.formularioVehiculo()}
                </Card.Body>
            </Card>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario,
        pais: state.pais
    }
}

export default connect(mapStateToProps)(VehiculosCrear);