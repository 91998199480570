import React, { Component} from 'react'
import { Row, Col } from 'react-bootstrap'
import Header from '../Header'
import 'moment/locale/es';
import ExcelFormatosListado from '../../subComponents/excel-formats/listado'

const ExcelFormats = (props) => {
  
  return <ExcelFormatosListado condicion_default={{}} />
}
export default ExcelFormats