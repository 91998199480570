import { Col, Dropdown, Row } from "react-bootstrap"
import { url_images } from "../../lib/global/data"
import { Link } from "react-router-dom"
import { cerrarSesion } from "../../redux/actions/session"
import { version_app } from "../../lib/backend/data"
import { rutas } from "../../lib/routes/routes"

const { useDispatch, useSelector } = require("react-redux")

const TopBar = () => {
    const session = useSelector(state => state.miusuario)
    const user = session
    const dispatch = useDispatch()
    let logotipo_empresa = ''

    if(session){
      if(session.data){
        if(session.data.empresa){
          if(session.data.empresa.logotipo) logotipo_empresa = session.data.empresa.logotipo
        }
      }
    }


    return <div className='p-3 shadow header bg-white'>
    <Row>
        <Col md={2} xs={3} ><img style={{ width: "100%", maxWidth: 150 }} src={`${url_images}/logoColor.svg`} /><p className="mb-0" style={{ fontSize: 12, textAlign: "center", marginTop: -5 }}><b>VERSIÓN {version_app}</b></p> </Col>
        
        <Col md={10} xs={9} className="text-right d-flex align-items-center justify-content-end">
        {logotipo_empresa ? <img style={{ width: "100%", maxWidth: 130, marginRight: 20 }} src={logotipo_empresa} /> : false }
        <Dropdown>
            <Dropdown.Toggle
                className='dropdown-header'
                variant="white"
                style={{ padding:8 }}>
                <div className=''>
                <img src={`${url_images}/sidebar/tuerca.svg`} />
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ fontSize: 14, fontWeight: 400, color: '#1B3665' }}>
                <Dropdown.Item eventKey="1"><Link to={`/${rutas.avanzada.slug}`}>General</Link></Dropdown.Item>
                <Dropdown.Item eventKey="2"><Link to={`/${rutas.usuarios.slug}`}>Usuarios</Link></Dropdown.Item>
                <Dropdown.Item eventKey="3"><Link to={`/${rutas.excel_formats.slug}`}>Formatos excel</Link></Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
        <Dropdown>
            <Dropdown.Toggle
                className='dropdown-header'
                variant="white"
                style={{ background: 'none', padding: 0, display: "inline-block" }}>
                <div className='user-box d-inline-flex align-items-center'>
                <img className='icon' alt="inicioIcon" src={`${url_images}/sidebar/iconoperfil.svg`} style={{ height: 40, marginRight: 16 }} />
                <div className='usuario d-flex flex-column align-items-center' style={{ background: 'none', color: '#1B3665' }}>
                    <div style={{ fontSize: 16, fontWeight: 700, whiteSpace: 'nowrap' }}>{user.data.nombres}</div>
                    <div style={{ fontSize: 12, fontWeight: 400, whiteSpace: 'nowrap' }}>{user.data.email}</div>
                </div>
                <img className='icon' alt="inicioIcon" src={`${url_images}/sidebar/flecha.svg`} style={{ height: 21, marginLeft: 16 }} />
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ fontSize: 14, fontWeight: 400, color: '#1B3665' }}>
                <Dropdown.Item eventKey="1"><Link to="/account">Mi cuenta</Link></Dropdown.Item>
                <Dropdown.Item eventKey="2" onClick={() => dispatch(cerrarSesion())}>Cerrar Sesión</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
        </Col>
    </Row>
    </div>
}

export default TopBar