import { Card, Col, Row } from "react-bootstrap"
import { fechaATexto } from "../../lib/helpers/helpers"

const BoxList = (props) => {
    const plan = props.ruta ? props.ruta : false
    const seleccionado = props.seleccionado ? props.seleccionado : false

    if(!plan) return false

    const clickplan = (id) => {
        if(props.onClickRuta) return props.onClickRuta(id)
        return false
    }

    return <Card bg={ seleccionado === true ? 'primary' : 'white' } className={`hover p-3 ${seleccionado === true ? 'text-white' : 'text-dark'}`} onClick={() => clickplan(plan._id)}>
        <p style={{ fontSize: 11 }} className="mb-0"><b>Creado</b> {fechaATexto(plan.createdAt)}</p>
        <h6 className="mb-0">LOTE {plan.id}</h6>
        <h6 className="mb-0"></h6>
</Card>
}

export default BoxList