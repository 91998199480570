const esquema_ordenes = [
        'referencia',
        'vehiculo',
        'item',
        'cantidad',
        'codigo_item',
        'nombre_contacto',
        'telefono',
        'email_contacto',
        'direccion',
]

const esquema_ordenes_requeridas = [
        'referencia',
        'vehiculo',
        'item',
        'cantidad',
        'codigo_item',
        'identificador_contacto',
        'nombre_contacto',
        'telefono',
        'email_contacto',
        'direccion',
        'alto',
        'ancho',
        'largo',
        'peso',
        'fecha_min_entrega',
        'fecha_max_entrega'
]

const esquema_formato_base = [
        "labelnumber"
]

module.exports = {
    esquema_ordenes,
    esquema_formato_base,
    esquema_ordenes_requeridas
}