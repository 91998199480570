import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import data, { urlapi } from '../../lib/backend/data';
import { Col, Modal, Pagination, Row, Spinner } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import BoxList from './box-list';
import CargandoDetalleFull from './cargando_detalle_full';
import DetallesCanal from './detalles';
import { Link } from 'react-router-dom';
import CrearCanal from './crear';

const ListadoCanales = (props) => {
    const [ conductores, setConductores ] = useState([])
    const [ filtro, setFiltro ] = useState('')
    const [ loadingConductores, setLoadingConductores ] = useState(true)
    const [ idSeleccionado, setIdSeleccionado ] = useState(false)
    const [ pedidoSeleccionado, setPedidoSeleccionado ] = useState(false)
    const [ pedidoBusqueda, setPedidoBusqueda ] = useState('')
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const [ total, setTotal ] = useState(0)
    const [ pagina, setPagina ] = useState(1)
    const [ openModal, setOpenModal ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(false)
    const upWhenClick = typeof props.upWhenClick !== "undefined" ? props.upWhenClick : true
    const showAddButton = typeof props.showAddButton !== "undefined" ? props.showAddButton : true
    const session = useSelector(state => state.miusuario)
    const titulo = typeof props.titulo !== "undefined" ? props.titulo : "Canales"

    const getConductores = () => {
        const url = `${urlapi}/bodega/canal/list`;
        return fetch(url, {
            method: "POST",
            body: JSON.stringify({
                condicion: {}
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${session.tokenSession}`
            }
        })
        .then(pros => pros.json())
        .then(res => {
            if(!res){
                toast.error("Sin datos")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
            }
            setLoadingConductores(false)
        })
        .catch(error => {
            toast.error('Ocurrió un error inesperado')
            setLoadingConductores(false)
        })
    }

    const obtenerPedido = async (id) => {
        setLoadingDetalle(true)
        if(upWhenClick === true) window.scrollTo({top: 0, behavior: 'smooth'})
        setIdSeleccionado(id)
        return fetch(`${urlapi}/bodega/canal?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingDetalle(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingDetalle(false)
            } else if(res._id){
                setPedidoSeleccionado(res)
            }
            return setLoadingDetalle(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingDetalle(false)
        })
    }

    const onSelected = (id) => {
        if(props.onSelected) return props.onSelected(id)
    }


    useEffect(() => {
        getConductores()
    }, [])

    const cargandoScreen = () => {
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerDatos(page)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        return <div>
            <Pagination size='sm'>
                {
                    Array.from(Array(cantidad).keys()).map(number => {
                        const active = pagina === (number+1) ? true : false
                        return <Pagination.Item key={number+1} active={active} onClick={()=>paginar(number+1,active)} >{number+1}</Pagination.Item>
                    })
                }
            </Pagination>
        </div>
    }

    const tabla_registros = () => {
        if(loadingConductores) return cargandoScreen()
        return <div>
            <h5>{(conductores.length * pagina) - conductores.length + 1} - {conductores.length * pagina} <b style={{ fontWeight:'lighter' }}>de {total}</b></h5>
            {paginacion(data.pagina, total)}        
            {
                conductores.map((conductor, i) => {
                        let seleccionado = false
                        if(idSeleccionado === conductor._id) seleccionado = true
                        return <div key={conductor._id} className='mb-3'>
                            <BoxList registro={conductor} seleccionado={seleccionado} onClick={(id) => setIdSeleccionado(id)} />
                        </div>
                    })
            }
            </div>
    }

    const obtenerDatos = async (page, query)=>{
        setLoadingConductores(true)
        const condicion = query ? query : condicion_busqueda
        return fetch(`${urlapi}/bodega/canal/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    const handleSubmitReferencia = (e) => {
        e.preventDefault()
        if(!pedidoBusqueda){
            const condicion = {  }
            setCondicionBusqueda(condicion)
            setPagina(1)
            return obtenerDatos(1, condicion)
        }
        const condicion = { $text: { $search: pedidoBusqueda } }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerDatos(1, condicion)
    }

    const handleChangePedido = (e) => {
        const { value } = e.target
        return setPedidoBusqueda(value)
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const modalFiltro = () => {
        return <Modal show={openModal} size="lg" onHide={()=>handleClose()} centered >
        <Modal.Header closeButton>
          <Modal.Title>CREAR CANAL</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <CrearCanal />
        </Modal.Body>
        
      </Modal>
    }

    const nuevoRegistro = () => {
        return setOpenModal(true)
    }

    return <div className='mt-3 mb-3'>
        <h4>{titulo} { showAddButton === true ? <i className="fa-solid text-primary fa-circle-plus" onClick={() => nuevoRegistro()} ></i> : false }</h4>
        <Row>
            <Col md={3}>
            <Row>
                    <Col md={12}>
                        <form onSubmit={handleSubmitReferencia}>
                        <input className='mb-3 form-control delpa-buscador' placeholder='BUSCAR' onChange={handleChangePedido} />
                        </form>
                    </Col>
            </Row>
            {tabla_registros()}
            </Col>
            <Col md={9}>
                    {
                        loadingDetalle === true ? <CargandoDetalleFull /> : <DetallesCanal idcanal={idSeleccionado} />
                    }
            </Col>
        </Row>
        {modalFiltro()}
    </div>
}

export default ListadoCanales