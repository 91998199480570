import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { urlapi } from "../../../lib/backend/data"
import { toast } from "react-toastify"
import Cargando from "./cargando"
import ConteoEstadosCliente from "./conteo_estados"
import { Card, Col, Modal, Row } from "react-bootstrap"
import ConteoEstadosClienteEstaticos from "./conteo_estados_as_props"
import OrdenesListado from "../../ordenes/listado"

const OperacionEnCurso = (props) => {
    const {
        condicion_default
    } = props
    const [ condicionBuquedaOrdenes, setCondicionBusquedaOrdenes ] = useState(false)
    const [ showModal, setShowModal ] = useState(false)
    const [ empresas, setEmpresas ] = useState([])
    const [ conductores, setConductores ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const [ condicion, setCondicion ] = useState(condicion_default ? condicion_default : {})
    const session = useSelector(state => state.miusuario)

    const handleClose = () => {
        setShowModal(false)
    }

    const obtenerDatos = async () => {
        setLoading(true)
        return fetch(`${urlapi}/estadisticas/proveedores/operacion`,{
            method:'POST',
            body: JSON.stringify({
                condicion
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            }
            if(Array.isArray(res.empresas) !== false){
                setEmpresas(res.empresas)
            }
            if(Array.isArray(res.conductores) !== false){
                setConductores(res.conductores)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoading(false)
        })
    }

    useEffect(() => {
        obtenerDatos()
    }, [])

    const filtrar = (ids_query) => {
        console.log({ ids_query })
        setCondicionBusquedaOrdenes({ _id: { $in: ids_query } })
        setShowModal(true)
    }

    const mostrarInformacion = () => {
        if(loading) return <Cargando />
        return <div>
            <h4>Operación en curso</h4>
            {
                empresas.map(empresa => {

                    let drivers = []

                    for( const orden of empresa.ordenes ){
                        if(!orden.idconductor) continue
                        if(!drivers.includes(orden.idconductor)) drivers.push(orden.idconductor)
                    }

                    const vehiculos = conductores.filter(ve => drivers.includes(ve._id))

                    return <div key={empresa._id}>
                        <Card className="p-3">
                        <h4 className="mb-0">{empresa.razon_social}</h4>
                        { empresa.logotipo ? <img alt={empresa.razon_social} style={{ width: 140 }} src={empresa.logotipo} /> : false }
                        <div className="mb-3"></div>
                        <ConteoEstadosCliente datos={empresa.ordenes} propietario={empresa.rut} onFilter={(ids) => filtrar(ids)} />
                        <h5>{vehiculos.length} Conductores involucrados en esta logística</h5>
                        <Row>
                        {
                            vehiculos.map(conductor => {
                                return <Col md={6} key={`${empresa._id}-${conductor._id}`}>
                                    <Card className="p-3">
                                    <h4 className="mb-0">{conductor.nombres} {conductor.apellidos} {conductor.modelo ? conductor.modelo.toUpperCase() : '' } {conductor.ano}</h4>
                                    <hr className="mb-3" />
                                    <ConteoEstadosClienteEstaticos datos={empresa.ordenes} propietario={empresa.rut} onFilter={(ids) => filtrar(ids)} />
                                    </Card>
                                </Col>
                            })
                        }
                        </Row>
                        </Card>
                        </div>
                })
            }
        </div>
    }

    const modalPedidos = () => {
        return <div>
            <Modal show={showModal} size="xl" onHide={()=>handleClose()} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Órdenes relacionadas</Modal.Title>
                </Modal.Header>
                                                <Modal.Body>
                                                    <OrdenesListado force={true} upWhenClick={false} showSearch={false} showAddButton={false} showFilter={false} showExporter={false} condicion_default={condicionBuquedaOrdenes} />
                                                </Modal.Body>
                                            </Modal>
        </div>
    }
    return <div>
        {modalPedidos()}
        {mostrarInformacion()}
    </div>
}

export default OperacionEnCurso