import { Card, Spinner } from 'react-bootstrap'
import { Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Header from '../../../../../components/Header'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '../../../../../lib/helpers/router'
import { toast } from 'react-toastify'
import { mercadolibre_config, urlapi } from '../../../../../lib/backend/data'

const MercadoLibreConexion = (props) => {
  const session = useSelector(state => state.miusuario)
  const [ loading, setLoading ] = useState(true)
  const [ mensajeSuccess, setMensajeSuccess ] = useState("")
  const [ mensajeError, setMensajeError ] = useState("")
  const query = useQuery()
  const code = query.get('code')

  useEffect(() => {
        configurarML()
    }, [])

    const configurarML = () => {
        return fetch(`${urlapi}/integraciones/plugin/mercadolibre/conectar`,{
          method:'POST',
          body: JSON.stringify({
            code,
            client_id: mercadolibre_config.client_id,
            redirect_uri: mercadolibre_config.redirect_uri
          }),
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${session.tokenSession}`
          }
      })
      .then(res => {
          if(res.status === 401) return window.location = '/login'
          return res.json()
      })
      .then(res => {
        console.log(res)
        if(!res){
          setMensajeError("Sin respuesta")
        } else if(res.errorMessage){
          setMensajeError(res.errorMessage)
        } else if(res._id){
          setMensajeSuccess("Conectado exitosamente")
          setTimeout(() => {
            return window.location = "/"
          }, 1500);
        }
        return setLoading(false)
      })
      .catch(error => {
        setMensajeError(error.message)
        return setLoading(false)
      })
      }

  const progreso = () => {
    if(loading) return <div>
        <Spinner animation='border' />
        <h4 className='mb-0'>Configurando</h4>
        <p className='mb-0'>No cierres la página por favor</p>
        </div>

  }
  
        return <div className="fluid">
            <div className='text-center' style={{ maxWidth: 500, margin: "0 auto"}}>
                    <img style={{ maxHeight: 120, margin: "0 auto", display: "block" }} src={'/images/mercadolibre-logo.png'} />
                    {progreso()}
                    {mensajeSuccess ? <h6 className='text-success'>{mensajeSuccess}</h6> : false }
                    {mensajeError ? <h6 className='text-danger'>{mensajeError}</h6> : false }
                    </div>
            </div>
}

export default MercadoLibreConexion