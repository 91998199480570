import { useEffect, useState } from 'react'
import { Row, Col, Button, Table, Card, Spinner, Form, Modal, ButtonGroup } from 'react-bootstrap'
import data, { urlapi } from '../../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import * as XLSX from 'xlsx'
import { corregirTelefono, sliceIntoChunks, validateEmail } from '../../../lib/helpers/helpers';
import { DateRange } from 'react-date-range';
import { es } from 'date-fns/locale'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addMoreDays, formatDateHoy } from '../../../lib/helpers/dates';
import CargandoFlotante from '../../general/cargando_flotante'
import FiltroFechas from '../../general/filtro_fechas';
import { esquema_ordenes } from '../../../lib/esquemas';
import ImportadorIntegraciones from './importador_integraciones';
import BuscadorVehiculos from '../../conductores/buscador';
import SelectorTipoAsignacion from '../../general/selector_tipo_asignacion';
import ProveedoresListadoProveedores from '../../proveedores_transporte/enlace/listado_proveedores';
import { useSelector } from 'react-redux';
import SelectorBodega from '../../bodega/bodegas/selector_bodega';
import { obtenerGeoDatosGoogle } from '../../../lib/helpers/pedidos/pedidos';
import { BiSolidChevronRight } from 'react-icons/bi';
import { FaMoneyBillAlt } from 'react-icons/fa'
import { formatoMoneda } from '../../../lib/helpers/main';
import SelectorServicios from '../../servicios/selector';

const RutasImportadorAvanzado = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: addMoreDays(new Date(),1),
        key: 'selection',
    }
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ loadingCamposPersonalizados, setLoadingCamposPersonalizados ] = useState(true)
    const [ camposPersonalizados, setCamposPersonalizados ] = useState([])
    const [ errores, setErrores ] = useState([])
    const [ registros, setRegistros ] = useState([])
    const [ columnasrequeridas, setColumnasRequeridas ] = useState(esquema_ordenes)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(false)
    const [ bodegaOrigen, seleccionarBodegaOrigen ] = useState(false)
    const [ loadingOrdenes, setLoadingOrdenes ] = useState(false)
    const [ loadingCarga, setLoadingCarga ] = useState(false)
    const [ showModalErrores, setShowModalErrores ] = useState(false)
    const [ showModalTarifas, setShowModalTarifas ] = useState(false)
    const [ procesados, setProcesados ] = useState(0)
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ loadingMaster, setLoadingMaster] = useState(false)
    const [ servicio, setServicio] = useState(false)
    const [ codigoProveedor, setCodigoProveedor ] = useState("")
    const [ loadingOperaciones, setLoadingOperaciones] = useState(0)
    const [ duplicados, setDuplicados] = useState([])
    const [ mensajeLoading, setMensajeLoading ] = useState('')
    const [ operacionesListas, setOperacionesListas] = useState(0)
    const [ tipoAsignacion, setTipoAsignacion ] = useState('vehiculo')
    const [ idRutaImportar, setIdRutaImportar ] = useState(false)
    const [ omitirEstados, setOmitirEstados ] = useState(false)
    const [ formatoSeleccionado, setFormatoSeleccionado ] = useState(false)
    const [ conductorSeleccionado, setConductorSeleccinoado ] = useState(false)
    const [ selectionRangeBusqueda, setSelectionRangeBusqueda ] = useState(initialDate)
    const [ filtroEstado, setFiltroEstado ] = useState([])
    const session = useSelector(state => state.miusuario)
    const [ ordenes, setOrdenes ] = useState([])
    const [ tipoFechaBusqueda, setTipoFechaBusqueda ] = useState('todos')
    const token = session.tokenSession
    
    useEffect(() => {
        obtenerCamposPersonalizados()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const cargandoScreen = () => {
        return <Row>
            <Col md={4}><Skeleton count={3} /></Col>
                <Col md={4}><Skeleton count={3} /></Col>
                <Col md={4}><Skeleton count={3} /></Col>
            </Row>
    }

    const obtenerCamposPersonalizados = () => {
        return fetch(`${data.urlapi}/ordenes/campos-personalizados`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setCamposPersonalizados(res)
            }
            return setLoadingCamposPersonalizados(false)
        })
        .catch(error => {
            setLoadingCamposPersonalizados(false)
            return toast.error(error.message)
        })
    }

    const iterarTarifasOrden = (orders) => {
        const iterar = orders.map(o => {
            if(typeof o.tarifa === "object"){
                if(!isNaN(o.tarifa.tarifa_cotizacion)) o.tarifa_cotizacion = {
                    _id: o.tarifa._id,
                    tarifa: o.tarifa.tarifa_cotizacion,
                    propietario: o.tarifa.propietario
                }
            }
            delete o.tarifa
            return o
        })
        return iterar
    }

    const subirDatos = async (idruta, datos) => {
        const progreso = procesados + datos.length

        const agregar_bodega = bodegaOrigen ? datos.map(o => {

            const geo_informacion = obtenerGeoDatosGoogle(bodegaOrigen.geodata)

            o.origen = {
                address_1: bodegaOrigen.direccion,
                first_name: bodegaOrigen.titulo,
                ...geo_informacion
            }
            return o
        }) : datos

        const iterarTarifas = iterarTarifasOrden(agregar_bodega)

        return fetch(`${data.urlapi}/ordenes/batch`,{
            method:'POST',
            body: JSON.stringify({
                idruta,
                create: iterarTarifas
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                return toast.error('Sin datos')
            } else if(res.errorMessage){
                return toast.error(res.errorMessage)
            }
            if(Array.isArray(res.invalidos) !== false){
                for( const error of res.invalidos ){
                        errores.push(error)
                }
                setErrores(prev => [...[], ...errores])
            }
            return setProcesados(progreso)
        })
        .catch(error => {
            toast.error(error.message)
            return setProcesados(progreso)
        })
    }

    const validarPatentes = async (patentes) => {
        const data_default = patentes.map(pa => ({ patente: pa, valido: false }))
        return fetch(`${data.urlapi}/conductores/validar/patentes`,{
            method:'POST',
            body: JSON.stringify({
                patentes
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return false
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return false
            } else if(Array.isArray(res) !== false){
                return res
            }
            return data_default
        })
        .catch(error => {
            toast.error(error.message)
            return data_default
        })
    }
    
    const end_point_validar_referencia = async (referencias) => {
        const data_default = referencias.map(pa => ({ referencia: pa, valido: false }))
        return fetch(`${data.urlapi}/ordenes/validar/referencia`,{
            method:'POST',
            body: JSON.stringify({
                referencias
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return false
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return false
            } else if(Array.isArray(res) !== false){
                return res
            }
            return data_default
        })
        .catch(error => {
            toast.error(error.message)
            return data_default
        })
    }

    const obtenerUrlCreacionBasadoEnTipoAsignacion = () => {
        if( tipoAsignacion === "vehiculo") return `${data.urlapi}/rutas/por-patente`
        if( tipoAsignacion === "proveedor") return `${data.urlapi}/rutas`
        return ''
    }

    const crearRuta = async (id_conductor) => {
        if(idRutaImportar) return idRutaImportar
        let nuevo = {
            id_conductor,
            tipo_asignacion: tipoAsignacion,
            fecha_despacho: selectionRange.startDate,
            fecha_asignacion: new Date(),
            entrega_desde: selectionRange.startDate,
            codigo_proveedor: codigoProveedor,
            entrega_hasta: selectionRange.endDate
        }
        const url_creacion = obtenerUrlCreacionBasadoEnTipoAsignacion()
        if(!url_creacion) return false
        return fetch(url_creacion, {
            method:'POST',
            body: JSON.stringify(nuevo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return false
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return false
            } else if(res._id){
                return res._id
            }
            return false
        })
        .catch(error => {
            toast.error(error.message)
            return false
        })
    }

    const obtenerRegistrosParaCreacion = (datos, patente_vehiculo) => {
        if(tipoAsignacion === "vehiculo"){
            const filtrados = datos.filter(reg => reg.vehiculo === patente_vehiculo)
            return filtrados
        } else if( tipoAsignacion === "proveedor"){
            return datos
        }

    }

    const importacionExitosa = () => {
        if(props.onDatosImportados) return props.onDatosImportados()
        return true
    }

    const subirRegistros = async () => {
        if(registros.length < 1) return toast.error("No hay registros para importar")
        setErrores([])
        let vehiculos = []
        let sin_vehiculos = 0
        registros.map(re => {
            if(!re.vehiculo){
                sin_vehiculos++
                return false
            }
            const i = vehiculos.filter(v => v === re.vehiculo)
            if(i.length > 0) return 
            vehiculos.push(re.vehiculo)
            return
        })
        setLoadingCarga(true)

        // VALIDAR 

        if( tipoAsignacion === "vehiculo"){

            if(registros.length === sin_vehiculos){
                // NINGUNO CON VEHÍCULOS, DEBE HABER RUTA SELECCIONADA
                if(!idRutaImportar){
                    setLoadingCarga(false)
                    return toast.error("No hay vehículos en la importación, en cuyo caso debe haber al menos una ruta seleccionada.")
                }
            } else if(sin_vehiculos > 0){
                // ALGUNOS SIN VEHÍCULOS
                    setLoadingCarga(false)
                    return toast.error(`Fueron encontrados ${sin_vehiculos} registros sin patente de vehículo asignado, no es posible cargar`)
            }
            const validacion = await validarPatentes(vehiculos)
            const invalidos = validacion.filter(pa => pa.valido === false).map(pa => pa.patente)
            if(invalidos.length > 0){
                setLoadingCarga(false)
                return toast.error(`Los siguientes vehículos no existen, ${invalidos.join(', ')}`)
            }

        } else if( tipoAsignacion === "proveedor" ){
            if(!codigoProveedor) return toast.error(`Selecciona un transporte primero`)
            vehiculos.push(codigoProveedor)
        }


        setMensajeLoading('Validando referencias')
        const validar_referencias = await validarReferencias(registros)

        if(Array.isArray(validar_referencias) !== true ){
            setLoadingCarga(false)
            return
        }
        
        if(validar_referencias.length > 0){
            if(omitirEstados === true){
                for( const orden of validar_referencias ){
                    const { referencia } = orden
                    const i = registros.findIndex(r => r.referencia === referencia)
                    if( i > -1) registros.splice(i,1)
                }
                setRegistros(prev => [...[], ...registros])
            } else {
                setLoadingCarga(false)
                return
            }
        } 

        setMensajeLoading('Creando órdenes')
        setLoadingMaster(true)
        setLoadingOperaciones(registros.length)
        setOperacionesListas(0)
        let total_procesadas = 0

        for( const vehiculo of vehiculos ){
            const ruta = await crearRuta(vehiculo)
            if(!ruta){
                continue
            }

            const filtrados = obtenerRegistrosParaCreacion(registros, vehiculo)
            const dividir = sliceIntoChunks(filtrados, 50)
            for( const array of dividir ){
                await subirDatos(ruta, array)
                total_procesadas = total_procesadas + array.length
                setOperacionesListas(total_procesadas)
            }
        }
        setLoadingMaster(false)
        setLoadingCarga(false)
        toast.success("Progreso finalizado")
        importacionExitosa()
        setTimeout(() => {
            window.location = '/rutas'
        }, 200);
        return setRegistros([])
    }

    const handleCloseErrores = () => {
        return setShowModalErrores(false)
    }
    const handleCloseTarifas = () => {
        return setShowModalTarifas(false)
    }

    const mostrarTarifas = () => {
        const filter = registros.filter(o => o.tarifa)
        if(filter.length < 1) return false


        const total_cotizacion = registros.reduce((prev,next) => {
            let sumar = 0
            let tarifa = false
            if(next.tarifa) if(typeof next.tarifa === "object") if(!isNaN(next.tarifa.tarifa_cotizacion)) tarifa = next.tarifa.tarifa_cotizacion
            if(tarifa !== false) sumar = tarifa
            return prev + sumar
        }, 0)

        return <div className="mt-3">
            <Card bg="info" className="p-2 text-white">
            <h6 className="mb-0">¿Deseas ver las tarifas cotizadas? <Button size="sm" variant="link text-white" onClick={() => setShowModalTarifas(true)}><BiSolidChevronRight size={20} /> <b>VER TARIFAS</b></Button></h6>
            </Card>
            <Modal show={showModalTarifas} size="lg" onHide={handleCloseTarifas} centered >
                <Modal.Header closeButton>
                <Modal.Title>Tarifas cotizadas </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                
                <h4 className='mb-3'>Total cotización: <b className='text-success'>{formatoMoneda(total_cotizacion)}</b></h4>
                <Table>
                <thead>
                    <tr>
                        <th>REFERENCIA</th>
                        <th>DIRECCION</th>
                        <th>TARIFA</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        registros.map((reg,ire) => {
                            let tarifa = false
                            if(reg.tarifa) if(typeof reg.tarifa === "object") if(!isNaN(reg.tarifa.tarifa_cotizacion)) tarifa = reg.tarifa.tarifa_cotizacion
                            return <tr key={`tabla-${ire}`}>
                            <th>{reg.referencia}</th>
                            <th>{reg.direccion}</th>
                            <th>{tarifa !== false ? formatoMoneda(tarifa) : false }</th>
                        </tr>
                        })
                    }
                </tbody>
            </Table>
                </Modal.Body>
            </Modal>
        </div>
    }
    const mostrarErrores = () => {
        if(errores.length < 1) return false
        return <div className="mt-3">
            <Card bg="danger" className="p-2 text-white">
            <h6 className="mb-0">Hay {errores.length} errores detectados <Button size="sm" variant="link text-white" onClick={() => setShowModalErrores(true)}><BiSolidChevronRight size={20} /> <b>VER PROBLEMAS</b></Button></h6>
            </Card>
            <Modal show={showModalErrores} size="lg" onHide={handleCloseErrores} centered >
                <Modal.Header closeButton>
                <Modal.Title>Errores detectados</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   {
                    errores.map((error,irr) => {
                        return <p className="mb-1" key={`error-${irr}`}><BiSolidChevronRight size={20} /> {error}</p>
                    })
                   }
                </Modal.Body>
            </Modal>
        </div>
    }

    const tablaRegistros = () => {
        if(registros.length < 1) return false

        return <div>
            <Table>
                <thead>
                    <tr>
                        <th>REFERENCIA</th>
                        <th>VEHÍCULO</th>
                        <th>DESTINATARIO</th>
                        <th>DIRECCION</th>
                        <th>ITEMS</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        registros.map((reg,ire) => {
                            return <tr key={`tabla-${ire}`}>
                            <th>{reg.referencia}</th>
                            <th>{reg.vehiculo}</th>
                            <th>{reg.nombre_contacto}</th>
                            <th>{reg.direccion}</th>
                            <th>{reg.productos.length}</th>
                        </tr>
                        })
                    }
                </tbody>
            </Table>
        </div>
    }

    const cambiarRangoFecha = (item) => {
        return setSelectionRange(item.selection)
    }

    const validarReferencias = async (pedidos) => {
        let referencias = []
        pedidos.map(pedido => referencias.push(pedido.referencia))
    
        setLoadingMaster(true)
        setLoadingOperaciones(pedidos.length)
        setOperacionesListas(0)
        setErrores([])
        const arraydividido = sliceIntoChunks(referencias, 10)
        let cantidad_procesada = 0
        let registros_duplicados = []
        for( const datos of arraydividido){
            const response = await end_point_validar_referencia(datos)
            const duplicados = response.filter(da => da.valido === false)
            if(duplicados.length > 0) duplicados.map(ped => errores.push(`Referencia ${ped.referencia} ya existe`))
            cantidad_procesada = cantidad_procesada + datos.length
            setOperacionesListas(cantidad_procesada)
            setErrores(prev => [...[], ...errores])
            setDuplicados(prev => [...prev, ...duplicados])
            registros_duplicados = [...registros_duplicados, ...duplicados]
        }

        setLoadingMaster(false)
        setLoadingOperaciones(0)
        setOperacionesListas(0)
        return registros_duplicados
    }

    const handleCheckReferencias = (e) => {
        const valor = e.target.checked
        return setOmitirEstados(valor)
    }

    const analizarResultadosCotizacion = (orders) => {
        for( const order of orders ){
            const i = registros.findIndex(o => o.referencia === order.referencia && o.direccion === order.direccion)
            if(i < 0) continue
            if(order.geo_datos) registros[i].geo_datos = order.geo_datos 
            if(order.tarifa){
                if(!showModalTarifas) setShowModalTarifas(true)
                registros[i].tarifa = order.tarifa
            }
        }
        setRegistros(prev => [...[], ...registros])
    }

    const cotizarOrdenes = async () => {
        if(!servicio) return toast.error("Selecciona un tipo de servicio para la cotización")
        const pedidos = JSON.parse( JSON.stringify( registros ) )

        const filtrar_sin_tarifa = pedidos //.filter(pe => !pe.tarifa || parseInt(pe.tarifa) < 1)
        //if(filtrar_sin_tarifa.length < 1) return toast.error('No hay pedidos pendientes de cotizar')
        
        // this.setState({ cotizando: true, operaciones_loading: filtrar_sin_tarifa.length, operaciones_loading_done:0, operaciones_mensajes: [] })
        setMensajeLoading('Cotizando')
        setLoadingMaster(true)
        setLoadingOperaciones(pedidos.length)
        setOperacionesListas(0)
        let total_procesadas = 0

        const dividir = sliceIntoChunks(filtrar_sin_tarifa, 50)
            for( const array of dividir ){
                const resultados = await cotizarOnline(array)
                analizarResultadosCotizacion(resultados)
                total_procesadas = total_procesadas + array.length
                setOperacionesListas(total_procesadas)
        }

        setLoadingMaster(false)
        toast.success("Progreso finalizado")

    }

    const onChangeServicio = (e) => {
        setServicio(e.value)
    }

    const cotizacionesAcciones = () => {
        if(!codigoProveedor) return false

        return <div>
            <h4>Cotizar órdenes</h4>
            <Row>
                <Col md={3}>
                <SelectorServicios titulo="Tipo de servicio a cotizar" onChange={data => onChangeServicio(data)} codigo_proveedor={codigoProveedor} />
                </Col>
                <Col md={3}>
                <label className='form-control-label d-block'>Click par cotizar</label>
                <Button disabled={loadingMaster}  onClick={() => cotizarOrdenes()} variant='light' >COTIZAR</Button>
                </Col>
            </Row>
            </div>
    }

    const accionesCarga = () => {
//         if(errores.length > 0) return false NO ESTOY SEGURO DE SI BORRAR
        if(registros.length < 1) return false
        let vehiculos = []
        registros.map(re => {
            const i = vehiculos.filter(v => v === re.vehiculo)
            if(i.length > 0) return 
            vehiculos.push(re.vehiculo)
            return
        })


        return <div className='mb-3'>
            {
                loadingCarga === true ? <div>
                    <Spinner animation='border' />
                    <h5>Espere un momento</h5>
                    </div> : <div>
                    <Row>
                        <Col md={3} className='mt-3'>
                            <h5>Rango de entrega</h5>
                            <DateRange
                            locale={es}
                            editableDateInputs={true}
                            onChange={item => cambiarRangoFecha(item)}
                            moveRangeOnFirstSelection={false}
                            ranges={[selectionRange]}
                            direction="vertical"
                            scroll={{ enabled: true }}
                            months={1}
                        />
                        </Col>
                        <Col md={9} className='mt-3'>
                        <h5>{registros.length} Registros cargados</h5>
                        <p className='mb-1'>Has click para crear <b className='text-primary'>{vehiculos.length} rutas</b> para <b className='text-primary'>{vehiculos.join(', ')}</b>, puedes asignar varios vehículos en un mismo excel, se creará una ruta por cada vehículo respectivamente.</p>
                        {mostrarErrores()}
                        <Form.Group className="mb-0 mt-3 mb-3" controlId="omitir_referencias">
                        <Form.Check type="switch" label="Omitir referencias duplicadas" onChange={handleCheckReferencias} />
                        </Form.Group>
                        {cotizacionesAcciones()}
                        <ButtonGroup>
                        <Button size="sm" variant="success" onClick={()=>subirRegistros()} >CREAR {vehiculos.length} RUTAS</Button>
                        </ButtonGroup>
                        </Col>
                    </Row>
                    </div>
            }
        </div>
    }

    const onChangeRuta = (data) => {
        return setIdRutaImportar(data.value)
    }

    const mostrarRegistros = () => {
        if(loadingExcel === true ) return cargandoScreen()
        return <div>
            {mostrarTarifas()}
            {accionesCarga()}
            {tablaRegistros()}
        </div>
    }

    const reemplazarLlave = (key) => {
        if(!formatoSeleccionado) return key
        const i = formatoSeleccionado.columnas.findIndex(e => e.target === key)
        if(i > -1) return formatoSeleccionado.columnas[i].key
        return key
    }

    const handleInputChange = (e) => {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        let hojas = []
        setErrores(prev => [])
        if (name === 'file') {
            setLoadingExcel(true)
            let reader = new FileReader()
            reader.readAsArrayBuffer(target.files[0])
            reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: 'array', cellDates:true, dateNF:'dd.mm.yyyy' });
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })

            if(!hojas[0]) return toast.error('No se encontraron datos')
            if(Array.isArray(hojas[0]) !== true) return toast.error('No se encontraron datos')
            if(hojas[0].length < 1) return toast.error('No se encontraron datos')
            if(!hojas[0][0]) return toast.error('No se encontraron datos')
            
            let errores = []
            let registros = []
            
            if(errores.length > 0) return toast.error(errores.join(', ')) // SI HAY ERRORES DETENER

            let columnas_buscar = columnasrequeridas

            if(formatoSeleccionado){
                if(formatoSeleccionado.columnas){
                    if( Array.isArray(formatoSeleccionado.columnas) !== false ){
                        for( const col of formatoSeleccionado.columnas ){
                            const { target } = col
                            const pos = columnas_buscar.findIndex(c => c === target)
                            if(pos > -1) columnas_buscar[pos] = col.key
                        }
                    }
                }
            }

            console.log(hojas[0])
                        
            hojas[0].map((row,irow) => {
                
                let campos_faltantes = []
                columnas_buscar.map(nombre => {
                    if(!row[nombre]) campos_faltantes.push(nombre)
                })

                if(campos_faltantes.length > 0){
                    errores.push(`Fila ${irow+2} le faltan campos obligatorios. ${campos_faltantes.join(', ')}`)
                    return false
                }

                const email = validateEmail(row[reemplazarLlave("email_contacto")] ? row[reemplazarLlave("email_contacto")].toString().trim() : '')
                if(!email){
                    console.log({ email, excel_email: row[reemplazarLlave("email_contacto")]  })
                    errores.push(`Fila ${irow+2} tiene un email inválido.`)
                    return false
                }

                const i_existe = registros.findIndex(re => re.referencia === row[reemplazarLlave("referencia")].toString().trim())
                let cantidad = parseInt(row[reemplazarLlave("cantidad")])
                if(isNaN(cantidad)) cantidad = 1

                if(i_existe > -1){
                    const i_producto_existe = registros[i_existe].productos.findIndex(pro => pro.codigo_item === row[reemplazarLlave("codigo_item")].toString())
                    if(i_producto_existe > -1){
                        registros[i_existe].productos[i_producto_existe].quantity = registros[i_existe].productos[i_producto_existe].quantity + cantidad
                    } else {
                        registros[i_existe].productos.push({ 
                            description: row[reemplazarLlave('item')] ? row[reemplazarLlave('item')].toString() : '',
                            quantity: cantidad,
                            codigo_item: row[reemplazarLlave('codigo_item')] ? row[reemplazarLlave('codigo_item')].toString() : '',
                            peso: row[reemplazarLlave("peso")] ? parseInt(row[reemplazarLlave("peso")]) : 0,
                            alto: row[reemplazarLlave("alto")] ? parseInt(row[reemplazarLlave("alto")]) : 0,
                            ancho: row[reemplazarLlave("ancho")] ? parseInt(row[reemplazarLlave("ancho")]) : 0,
                            largo: row[reemplazarLlave("largo")] ? parseInt(row[reemplazarLlave("largo")]) : 0,
                            price: row[reemplazarLlave("precio_item")] ? parseInt(row[reemplazarLlave("precio_item")]) : 0,
                        })
                    }
                    return
                }

                let nuevo_registro = {
                    referencia: row[reemplazarLlave("referencia")] ? row[reemplazarLlave("referencia")].toString().trim() : '',
                    vehiculo: row[reemplazarLlave("vehiculo")] ? row[reemplazarLlave("vehiculo")].toString() : '',
                    productos: [
                        { 
                            description: row[reemplazarLlave("item")] ? row[reemplazarLlave("item")].toString() : '',
                            peso: row[reemplazarLlave("peso")] ? parseInt(row[reemplazarLlave("peso")]) : 0,
                            alto: row[reemplazarLlave("alto")] ? parseInt(row[reemplazarLlave("alto")]) : 0,
                            ancho: row[reemplazarLlave("ancho")] ? parseInt(row[reemplazarLlave("ancho")]) : 0,
                            largo: row[reemplazarLlave("largo")] ? parseInt(row[reemplazarLlave("largo")]) : 0,
                            quantity: cantidad,
                            codigo_item: row[reemplazarLlave("codigo_item")] ? row[reemplazarLlave("codigo_item")].toString() : '',
                            price: row[reemplazarLlave("precio_item")] ? parseInt(row[reemplazarLlave("precio_item")]) : 0,
                        }
                    ],
                    identificador_contacto: row[reemplazarLlave("identificador_contacto")] ? row[reemplazarLlave("identificador_contacto")].toString() : '',
                    nombre_contacto: row[reemplazarLlave("nombre_contacto")] ? row[reemplazarLlave("nombre_contacto")].toString() : '',
                    telefono: row[reemplazarLlave("telefono")] ? corregirTelefono(row[reemplazarLlave("telefono")].toString()) : '',
                    email_contacto: row[reemplazarLlave("email_contacto")].toString().trim(),
                    direccion: row[reemplazarLlave("direccion")] ? row[reemplazarLlave("direccion")].toString() : '',
                    observaciones_direccion: row[reemplazarLlave("observaciones_direccion")] ? row[reemplazarLlave("observaciones_direccion")].toString() : '',
                    nota: row[reemplazarLlave("nota")] ? row[reemplazarLlave("nota")].toString() : '',
                    fecha_min_entrega: row[reemplazarLlave("fecha_min_entrega")] ? row[reemplazarLlave("fecha_min_entrega")] : '',
                    fecha_max_entrega: row[reemplazarLlave("fecha_max_entrega")] ? row[reemplazarLlave("fecha_max_entrega")] : '',
                    meta_data: []
                }
                
                if(camposPersonalizados.length > 0){
                    for( const meta of camposPersonalizados ){
                        if(row[meta.slug]){
                            nuevo_registro.meta_data.push({
                                key: meta.slug,
                                value: row[meta.slug]
                            })
                        }
                    }
                }

                // VERIFICAR CONCATENACIONES

                if(formatoSeleccionado){
                    if(formatoSeleccionado.columnas){
                        if(Array.isArray(formatoSeleccionado.columnas) !== false){
                            const filtradas_concatenaciones = formatoSeleccionado.columnas.filter(c => {
                                if(!c.concatenar) return false
                                if(Array.isArray(c.concatenar) !== true) return false
                                if(c.concatenar.length < 1) return false
                                return true
                            })
                            console.log({filtradas_concatenaciones})
                            if(filtradas_concatenaciones.length > 0){
                                for( const filtrada of filtradas_concatenaciones ){
                                    for( const concatenacion of filtrada.concatenar ){
                                        if(!concatenacion.columna) continue
                                        let valor = nuevo_registro[filtrada.target]
                                        if(concatenacion.texto) valor = `${valor}${concatenacion.texto}`
                                        if(row[concatenacion.columna]) valor = `${valor}${row[concatenacion.columna]}`
                                        nuevo_registro[filtrada.target] = valor
                                    }
                                }
                            }
                        }
                    }
                }

                registros.push(nuevo_registro)
            })
            
            console.log(registros)
            if(errores.length > 0){
                setErrores(errores)
                setLoadingExcel(false)
                return toast.error(`Hay errores en el archivo, corrijelos e intenta de nuevo`)
            }
            setLoadingExcel(false)
            return setRegistros(registros)
          }
        }
    }

    const mostrarCamposPersonalizados = () => {
        if(loadingCamposPersonalizados === true) return <div><Spinner animation='border' /></div>
        if(camposPersonalizados.length < 1) return false
        return <div>
            <h6 className="mb-0">Campos personalizados</h6>
            <p className="mb-0">Puedes agregar cualquier de estos campos personalizados a tu archivo excel</p>
            <hr className='hr' />
            {
                camposPersonalizados.map(campo => {
                    return <Button size="sm" variant="dark" className='mr-3' style={{ fontSize: 11 }}>{campo.slug}</Button>
                })
            }
            <hr className='hr' />
        </div>
    }

    const onChangeFecha = (e) => {
        setSelectionRangeBusqueda(e)
    }

    const onChangeTipoBusqueda = (e) => {
        setTipoFechaBusqueda(e)
    }

    const cotizarOnline = async (orders)=>{
        return fetch(`${urlapi}/pricing/quotation/batch`,{
            method:'POST',
            body: JSON.stringify({
                orders,
                codigo_proveedor: codigoProveedor,
                servicio
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(Array.isArray(res) !== false){
                return res
            }
            return []
        })
        .catch(error => [])
    }

    const obtenerOrdenes = async (cond)=>{
        setLoadingOrdenes(true)
        return fetch(`${urlapi}/ordenes/pedidos-custom-full`,{
            method:'POST',
            body: JSON.stringify({
                condicion: cond,
                campos: { 
                    estado_entrega: true, 
                    sub_estado_entrega: true,
                    pedido: true
                }
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingOrdenes(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingOrdenes(false)
            }
            if(Array.isArray(res) !== false){
                setOrdenes(res)
                // setTotal(res.total)
            }
            return setLoadingOrdenes(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingOrdenes(false)
        })
    }

    const buscarOrdenesFiltradas = async () => {
        const desde = selectionRangeBusqueda.startDate
        const hasta = addMoreDays(selectionRangeBusqueda.endDate,1)

        if(tipoFechaBusqueda === 'creacion'){
            const condicion = { createdAt: { $gte: desde, $lte: new Date(hasta.getTime()-1) }, status: 'active' }
            if(filtroEstado.length > 0) condicion["estado_entrega.codigo_estado"] = { $in: filtroEstado }
            setCondicionBusqueda(condicion)
            obtenerOrdenes(condicion)
        } else if(tipoFechaBusqueda === 'actualizacion'){
            const condicion = { updatedAt: { $gte: desde, $lte: new Date(hasta.getTime()-1) }, status: 'active' }
            if(filtroEstado.length > 0) condicion["estado_entrega.codigo_estado"] = { $in: filtroEstado }
            setCondicionBusqueda(condicion)
            obtenerOrdenes(condicion)
        } else if(tipoFechaBusqueda === 'maxima-entrega'){
            const condicion = { fecha_max_entrega: { $gte: new Date( formatDateHoy(selectionRange.startDate)), $lte: new Date(hasta.getTime()-1) } }
            if(filtroEstado.length > 0) condicion["estado_entrega.codigo_estado"] = { $in: filtroEstado }
            setCondicionBusqueda(condicion)
            obtenerOrdenes(condicion)
        } else if(tipoFechaBusqueda === 'todos'){
            const condicion = {  }
            if(filtroEstado.length > 0) condicion["estado_entrega.codigo_estado"] = { $in: filtroEstado }
            setCondicionBusqueda(condicion)
            obtenerOrdenes(condicion)
        }
    }

    const onChangeValueExcel = (e) => {
        setFormatoSeleccionado(e)
    }

    const importarDatos = (datos) => {

        datos.map(regi => {
            regi.crear = true
            regi.vehiculo = conductorSeleccionado.patente
            let productos = []
            if(regi.productos){
                if(Array.isArray(regi.productos) !== false){
                    if(regi.productos.length > 0){
                        productos = regi.productos
                    }
                }
            }
            regi.productos = productos
            console.log(regi)
            registros.push(regi)
        })

        validarReferencias(registros)

        return setRegistros([...[], ...registros])
      }

      const onClickEmpresa = (data) => {
        setCodigoProveedor(data.codigo)
    }

      const showAsignementBox = () => {
        if(tipoAsignacion === "vehiculo"){
            return <div>
                <Card className="p-3">
                    <h4><i className="fas fa-truck"></i> Asignar a conductor</h4>
                    <p className='mb-0'>Seleccionando un conductor se creará una ruta asignada a dicho conductor y se asignarán las órdenes correspondientemente.</p>
                    <BuscadorVehiculos onChange={data => setConductorSeleccinoado(data)} token={token} />
                </Card>
            </div>
        } else if( tipoAsignacion === "proveedor"){
            return <div>
                <h4>Selecciona un proveedor de transporte para gestionar tu carga</h4>
                <p>Toca el proveedor para seleccionarlo</p>
                <ProveedoresListadoProveedores hightLightCodigo={codigoProveedor} onClick={(data) => onClickEmpresa(data)} displayAll={true} condicion_default={{ propietario_cliente: session.data.propietario }} />
            </div>
        }
      }

      const handleChangeAsignacion = (e) => {
        const { value } = e.target
        return setTipoAsignacion(value)
      }

      const checkForAllow = () => {
        if(tipoAsignacion === "vehiculo"){
            return conductorSeleccionado ? true : false
        } else if( tipoAsignacion === "proveedor"){
            return codigoProveedor ? true : false
        }
        return false
      }

    return <div>
        <h4><i className="far fa-file-excel"></i> Importar datos</h4>
        <p>Puedes importar datos desde distintos canales</p>
        <Row>
            <Col><SelectorBodega onChange={(data) => seleccionarBodegaOrigen(data)} /></Col>
            <Col> <SelectorTipoAsignacion onChange={(e) => handleChangeAsignacion(e)} /></Col>
        </Row>
        <Row>
            { /* <Col md={6} className="mb-3">
                <Card className="p-3">
                    <h4><i className="fas fa-truck"></i> Asignar a ruta existente</h4>
                    <p className='mb-0'>Seleccionando una ruta a continuación se ignorará la creación de nuevas rutas y se asignarán las órdenes a la ruta seleccionada</p>
                    <BuscadorRutas token={token} onChangeValue={(data) => onChangeRuta(data)} />
                </Card>
            </Col> */}
            <Col md={12} className="mb-3">
            {showAsignementBox()}
            </Col>
            <Col md={12} className="mb-3">
            <Card className='p-3'>
            <h4 className='d-block mb-0'>Importadores</h4>
            <ImportadorIntegraciones exportarDatos={(datos) => importarDatos(datos)} permitir={ checkForAllow() } />
            </Card>
            </Col>
        </Row>
        
        
        <CargandoFlotante visualizar={loadingMaster} mensaje={mensajeLoading} procesando={loadingOperaciones} procesado={operacionesListas}  />
        {mostrarRegistros()}
    </div>

}

export default RutasImportadorAvanzado