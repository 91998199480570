import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { urlapi } from '../../../lib/backend/data';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';

const ImportadorIntegraciones = (props) => {
    const {
        permitir
    } = props
    const creador = props.creador
    const [ integraciones, setIntegraciones ] = useState([])
    const [ integracionActiva, setIntegracionActiva ] = useState(false)
    const [ configIntegracionActiva, setConfigIntegracionActiva ] = useState(false)
    const [ loadingConfig, setLoadingConfig ] = useState(false)
    const [ loadingDatos, setLoadingDatos ] = useState(false)
    const [ datosImportados, setDatosImportados ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const [ showForm, setShowForm ] = useState(false)
    const { tokenSession, data } = useSelector(state => state.miusuario)
    const permitirSeleccionarIntegracion = typeof permitir === "undefined" ? true : permitir


    const corregirTelefono = (num) => {
        if(!num){
          return "(en blanco)"
        }
        const digito = num.toString()
        const longitud = digito.length
        if(longitud === 0){
          return "(en blanco)"
        }
        const numero = digito.replace('+56', '56')
        if(longitud === 9){
          return `56${numero}`
        } else if(longitud === 8){
          return `569${numero}`
        }
        return numero
    }

    const exportarDatos = (datos) => {
        if(props.exportarDatos) {
            setIntegracionActiva(false)
            setDatosImportados(false)
            setConfigIntegracionActiva(false)
            return props.exportarDatos(datos)
        }
        return false
    }

    const seleccionarIntegracion =  async (id) => {
        if(!permitirSeleccionarIntegracion) return toast.error("Selecciona primero un transporte")
        setLoadingConfig(true)
        const i = integraciones.findIndex(integ => integ._id === id)
        const seleccionada = integraciones[i]
        const datos = await consultarConfigIntegracion(seleccionada.descripcion)
        setConfigIntegracionActiva(datos)
        setIntegracionActiva(id)
        return setLoadingConfig(false)
    }

    useEffect(() => {
        consultarIntegraciones()
    }, [])

    const handleNewRecord = (payload) => {
        if(props.handleNewRecord) return props.handleNewRecord(payload)
    }

    const handleChangeConfig = (e) => {
        const { name, value } = e.target
        const i = integraciones.findIndex(integ => integ._id === integracionActiva)
        if(!integraciones[i].formulario) integraciones[i].formulario = {}
        integraciones[i].formulario[name] = value
        return setIntegraciones(integraciones)
    }

    const consultarConfigIntegracion = async (nombre) => {
        const url = `${urlapi}/importador/config-by-integracion?nombre=${nombre}`
        return fetch(url,{
            method: "GET",
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${tokenSession}`
            }
        })
        .then(pros => pros.json())
        .then(pros => {
            if(!pros){
                toast.error('Ocurrió un error inesperado, Sin respuesta del servidor')
            } else if(pros.errorMessage){
                toast.error(pros.errorMessage)
            } else if(Array.isArray(pros) !== false){
                return pros
            }
            return false
        })
        .catch(error => {
            toast.error('Ocurrió un error inesperado')
            return false
        })
    }

    const importarDatosBeetrack = async () => {
        const i = integraciones.findIndex(integ => integ._id === integracionActiva)
        const seleccionada = integraciones[i]
        if(!seleccionada.formulario) return toast.error('Llena el formulario de configuración de esta integración')
        const transporte = seleccionada.formulario.transporte
        const fecha = seleccionada.formulario.fecha
        if(!fecha) return toast.error('fecha es requerida')
        if(!transporte) return toast.error('transporte es requerida')
        const url = `${urlapi}/importador/beetrack/routes`
        setLoadingDatos(true)
        return fetch(url, {
            method:'POST',
            body: JSON.stringify(seleccionada.formulario),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${tokenSession}`
            }
        })
        .then(pros => pros.json())
        .then(pros => {
            if(!pros){
                toast.error('Ocurrió un error inesperado, Sin respuesta del servidor')
                return setLoadingDatos(false)
            } else if(pros.errorMessage){
                toast.error(pros.errorMessage)
                return setLoadingDatos(false)
            } else if(pros._id){
                if(Array.isArray(pros.payload) !== false){
                    toast.success('Datos importados')
                    setDatosImportados(pros)
                } else if(pros.payload.errorMessage){
                    toast.error(pros.payload.errorMessage)
                }
            }
            setLoadingDatos(false)
        })
        .catch(error => {
            toast.error('Ocurrió un error inesperado')
            setLoadingDatos(false)
        })
    }

    const importarDatos = async (nombre) => {
        const i = integraciones.findIndex(integ => integ._id === integracionActiva)
        const seleccionada = integraciones[i]
        switch (seleccionada.descripcion) {
            case 'Beetrack':
                return importarDatosBeetrack()
            default:
                break;
        }
    }

    const validateEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    const marcarRutaImportar = (i) => {
        datosImportados.payload[i].seleccionada = datosImportados.payload[i].seleccionada === true ? false : true
        return setDatosImportados({...{}, ...datosImportados})
    }

    const importarRuta = (posruta) => {
        const i = integraciones.findIndex(integ => integ._id === integracionActiva)
        const seleccionada = integraciones[i]
        switch (seleccionada.descripcion) {
            case 'Beetrack':
                
                let datos_importar = []
                    for( const despacho of datosImportados.payload[posruta].dispatches){
                        const productos = despacho.items ? despacho.items : []

                        let identificador = despacho.identifier
                        const i_tag = despacho.tags.findIndex(ta => {
                            const llaves = Object.keys(ta)
                            if(llaves.filter(lla => lla === 'ENTREGA').length > 0) return true
                            return false
                        })
                        if(i_tag > -1) identificador = despacho.tags[i_tag].ENTREGA
                        const contar_bultos = despacho.items.reduce((prev, next) => {
                            let cantidad = parseInt(next.quantity)
                            if(isNaN(cantidad)) cantidad = 1
                            return prev + cantidad
                        },0)
                        const notas = productos.map(p => `${p.quantity} ${p.name}`).join(', ')

                        let nuevo_registro = {
                            referencia: identificador,
                            vehiculo: '',
                            productos: productos.map(pro => ({ 
                                description: pro.description,
                                quantity: pro.quantity,
                                codigo_item: pro.code
                            })),
                            identificador_contacto: despacho.contact_id,
                            nombre_contacto: despacho.contact_name,
                            telefono: corregirTelefono(despacho.contact_phone),
                            email_contacto: validateEmail(despacho.contact_email) !== true ? '' : despacho.contact_email,
                            direccion: despacho.contact_address,
                            observaciones_direccion: notas,
                            nota: notas,
                            fecha_min_entrega: '',
                            fecha_max_entrega: '',
                            bultos: contar_bultos,
                            meta_data: []
                        }

                        if(despacho.tags){
                            if(Array.isArray(despacho.tags) !== false){
                                if(despacho.tags.length > 0){
                                    for( const meta of despacho.tags ){
                                        Object.keys(meta).map(key => {
                                            const llave = key.toLowerCase()
                                            if(meta[key]){
                                                nuevo_registro.meta_data.push({
                                                    key: llave,
                                                    value: meta[key]
                                                })
                                            }
                                        })

                                    }
                                }
                            }
                        }

                        datos_importar.push(nuevo_registro)
                    }

                return exportarDatos(datos_importar)
            default:
                break;
        }
    }

    const importarSeleccion = () => {
        const i = integraciones.findIndex(integ => integ._id === integracionActiva)
        const seleccionada = integraciones[i]
        switch (seleccionada.descripcion) {
            case 'Beetrack':
                
                let datos_importar = []
                const seleccionadas = datosImportados.payload.filter(r => r.seleccionada === true).length
                if(seleccionadas < 1) return toast.error("No has seleccionado ninguna ruta")
                for( const ruta of datosImportados.payload.filter(r => r.seleccionada === true) ){
                    for( const despacho of ruta.dispatches){
                        const productos = despacho.items ? despacho.items : []

                        let identificador = despacho.identifier
                        const i_tag = despacho.tags.findIndex(ta => {
                            const llaves = Object.keys(ta)
                            if(llaves.filter(lla => lla === 'ENTREGA').length > 0) return true
                            return false
                        })
                        if(i_tag > -1) identificador = despacho.tags[i_tag].ENTREGA
                        const contar_bultos = despacho.items.reduce((prev, next) => {
                            let cantidad = parseInt(next.quantity)
                            if(isNaN(cantidad)) cantidad = 1
                            return prev + cantidad
                        },0)
                        const notas = productos.map(p => `${p.quantity} ${p.name}`).join(', ')
                        
                        let nuevo_registro = {
                            referencia: identificador,
                            vehiculo: '',
                            productos: productos.map(pro => ({ 
                                description: pro.description,
                                quantity: pro.quantity,
                                codigo_item: pro.code
                            })),
                            identificador_contacto: despacho.contact_id,
                            nombre_contacto: despacho.contact_name,
                            telefono: corregirTelefono(despacho.contact_phone),
                            email_contacto: validateEmail(despacho.contact_email) !== true ? '' : despacho.contact_email,
                            direccion: despacho.contact_address,
                            observaciones_direccion: notas,
                            nota: notas,
                            fecha_min_entrega: '',
                            fecha_max_entrega: '',
                            bultos: contar_bultos,
                            meta_data: [{
                                key: "guia",
                                value: despacho.identifier ? despacho.identifier.toString() : ""
                            }]
                        }


                        if(despacho.tags){
                            if(Array.isArray(despacho.tags) !== false){
                                if(despacho.tags.length > 0){
                                    for( const meta of despacho.tags ){
                                        Object.keys(meta).map(key => {
                                            const llave = key.toLowerCase()
                                            if(meta[key]){
                                                nuevo_registro.meta_data.push({
                                                    key: llave,
                                                    value: meta[key]
                                                })
                                            }
                                        })

                                    }
                                }
                            }
                        }
                        console.log(nuevo_registro)
                        datos_importar.push(nuevo_registro)
                    }
                }
                return exportarDatos(datos_importar)
            default:
                break;
        }
    }

    const importarTodo = () => {
        const i = integraciones.findIndex(integ => integ._id === integracionActiva)
        const seleccionada = integraciones[i]
        switch (seleccionada.descripcion) {
            case 'Beetrack':
                
                let datos_importar = []
                for( const ruta of datosImportados.payload ){
                    for( const despacho of ruta.dispatches){
                        const productos = despacho.items ? despacho.items : []

                        let identificador = despacho.identifier
                        const i_tag = despacho.tags.findIndex(ta => {
                            const llaves = Object.keys(ta)
                            if(llaves.filter(lla => lla === 'ENTREGA').length > 0) return true
                            return false
                        })
                        if(i_tag > -1) identificador = despacho.tags[i_tag].ENTREGA
                        const contar_bultos = despacho.items.reduce((prev, next) => {
                            let cantidad = parseInt(next.quantity)
                            if(isNaN(cantidad)) cantidad = 1
                            return prev + cantidad
                        },0)
                        const notas = productos.map(p => `${p.quantity} ${p.name}`).join(', ')
                        
                        let nuevo_registro = {
                            referencia: identificador,
                            vehiculo: '',
                            productos: productos.map(pro => ({ 
                                description: pro.description,
                                quantity: pro.quantity,
                                codigo_item: pro.code
                            })),
                            identificador_contacto: despacho.contact_id,
                            nombre_contacto: despacho.contact_name,
                            telefono: corregirTelefono(despacho.contact_phone),
                            email_contacto: validateEmail(despacho.contact_email) !== true ? '' : despacho.contact_email,
                            direccion: despacho.contact_address,
                            observaciones_direccion: notas,
                            nota: notas,
                            fecha_min_entrega: '',
                            fecha_max_entrega: '',
                            bultos: contar_bultos,
                            meta_data: [{
                                key: "guia",
                                value: despacho.identifier ? despacho.identifier.toString() : ""
                            }]
                        }

                        if(despacho.tags){
                            if(Array.isArray(despacho.tags) !== false){
                                if(despacho.tags.length > 0){
                                    for( const meta of despacho.tags ){
                                        Object.keys(meta).map(key => {
                                            const llave = key.toLowerCase()
                                            if(meta[key]){
                                                nuevo_registro.meta_data.push({
                                                    key: llave,
                                                    value: meta[key]
                                                })
                                            }
                                        })
                                    }
                                }
                            }
                        }

                        console.log(nuevo_registro)
                        datos_importar.push(nuevo_registro)
                    }
                }

                return exportarDatos(datos_importar)
            default:
                break;
        }
    }

    const consultarIntegraciones = async () => {
        const url = `${urlapi}/importador/availables`
        setLoading(true)
        return fetch(url, {
            method: "GET",
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${tokenSession}`
            }
        })
        .then(pros => pros.json())
        .then(pros => {
            if(!pros){
                toast.error('Ocurrió un error inesperado, Sin respuesta del servidor')
                return setLoading(false)
            } else if(pros.errorMessage){
                toast.error(pros.errorMessage)
                return setLoading(false)
            } else if(Array.isArray(pros) !== false){
                setIntegraciones(pros)
            }
            setLoading(false)
        })
        .catch(error => {
            toast.error('Ocurrió un error inesperado')
            setLoading(false)
        })
    }

    const mostrarWizardDatosImportados = (formulario) => {
        return <div className='mt-3'>
            <h4>Hemos encontrado {datosImportados.payload.length} Rutas para {formulario.transporte} el {formulario.fecha}</h4>
            <button className='btn btn-outline-success mb-3 mr-3' onClick={()=>importarTodo()}>IMPORTAR TODO</button>
            <button className='btn btn-outline-success mb-3 mr-3' onClick={()=>importarSeleccion()}>IMPORTAR SELECCIÓN</button>
            <div className='table-responsive'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Fecha Despacho</th>
                            <th>Transporte</th>
                            <th>Grupos</th>
                            <th>Órdenes</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        datosImportados.payload.map((ruta,iruta) => {
                            let grupos = []
                            if(Array.isArray(ruta.dispatches) !== false){
                                ruta.dispatches.map(despacho => {
                                    let grupo = false
                                    const i_tag = despacho.tags.findIndex(ta => {
                                        const llaves = Object.keys(ta)
                                        if(llaves.filter(lla => lla === 'GRUPO').length > 0) return true
                                        return false
                                    })
                                    if(i_tag > -1) grupo = despacho.tags[i_tag].GRUPO
                                    if(grupo){
                                        if(!grupos.includes(grupo)) grupos.push(grupo)
                                    }
                                })
                            }
                            return <tr key={ruta.id}>
                                <th>{ruta.id}</th>
                                <th>{ruta.dispatch_date}</th>
                                <th>{typeof ruta.truck === 'object' ? ruta.truck.identifier : 'Sin información' }</th>
                                <th>{grupos.join(', ')}</th>
                                <th>{Array.isArray(ruta.dispatches) !== false ? ruta.dispatches.length : 'Sin información' }</th>
                                <th>
                                    <button className='btn btn-outline' onClick={()=>marcarRutaImportar(iruta)}>
                                        { ruta.seleccionada === true ? <i className="fas text-success fa-check-circle"></i> : false } IMPORTAR
                                    </button>
                                    </th>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </div>
        </div>
    }

    const mostrarDatosImportados = () => {
        if(!datosImportados) return false
        const i = integraciones.findIndex(integ => integ._id === integracionActiva)
        const seleccionada = integraciones[i]
        switch (seleccionada.descripcion) {
            case 'Beetrack':
                return mostrarWizardDatosImportados(seleccionada.formulario)
            default:
                break;
        }
    }

    const mostrarFormularioOpciones = () => {
        if(loading===true) return <Spinner animation='border' />
        if(loadingConfig===true) return <div>
            <Spinner animation='border' />
            <h6 className='mb-0'>Cargando los datos de la integración</h6>
        </div>
        if(!integracionActiva) return <div>
            <p className='mb-2'>Selecciona una de las siguientes integraciones activas para importar pedidos</p>
            <div className='row'>
            {
                integraciones.map(integracion => {
                    return <div key={integracion._id} className="col-md-3" >
                        <div className='card p-3 hover' onClick={()=>seleccionarIntegracion(integracion._id)}>
                            <h4 className='mb-0'>{integracion.descripcion}</h4>
                            <p className='mb-0'>Versión: {integracion.nombre}</p>
                        </div>
                    </div>
                })
            }
            </div>
        </div>

        const i = integraciones.findIndex(integ => integ._id === integracionActiva)
        const seleccionada = integraciones[i]
        
        if(seleccionada.descripcion === 'Beetrack') return <div>
            <h4 className='mb-0'>Impordaror Beetrack</h4>
            <p>Consulta la información utilizando los filtros</p>
            <div className='row'>
                <div className='col-md-3'>
                    <label className='form-control-label d-block'>Fecha de consulta</label>
                    <input className='form-control' type="date" name="fecha" onChange={handleChangeConfig} />
                </div>
                <div className='col-md-3'>
                    <label className='form-control-label d-block'>Selecciona un vehículo de tu flota</label>
                    <select className='form-control' name="transporte" onChange={handleChangeConfig}>
                        <option value="">Selecciona</option>
                        {
                            configIntegracionActiva.filter(integr => integr.sub_tipo === 'beetrack_api_nombre_transporte').map(inte => {
                                return <option value={inte.valor}>{inte.valor}</option>
                            })
                        }
                    </select>
                </div>
                <div className='col-md-3'>
                    <label className='form-control-label d-block'>Click para consultar</label>
                    {
                        loadingDatos===true ? <div className="loadingboxsmall">
                            <Spinner animation='border' />
                            <h3>Cargando información...</h3>
                        </div> : <button className='btn btn-success' onClick={()=>importarDatos() } >CONSULTAR RUTAS</button>
                    }
                </div>
                <div className='col-md-12'>
                    {mostrarDatosImportados()}
                </div>
            </div>
        </div>
    }

    return <div className='mt-3'>
        {mostrarFormularioOpciones()}
    </div>
}

export default ImportadorIntegraciones