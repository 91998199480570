import { Doughnut } from "react-chartjs-2";
import { estilo_last_mile } from "../../../lib/global/styles";
import { stringByTitleCost } from "../../../lib/helpers/data/facturacion/main";

const GraficoDona = (props) => {
    const { 
        datos
    } = props

    const data = {
        labels: datos.map(da => stringByTitleCost(da.titulo)),
        datasets: [
          {
            label: 'Cantidad invertida',
            data: datos.map(da => da.total),
            backgroundColor: [
              estilo_last_mile.color_primary,
              estilo_last_mile.color_secondary,
              estilo_last_mile.color_third,
            ]
          },
        ],
      };

    return <div>
        <Doughnut data={data} />
    </div>
}

export default GraficoDona