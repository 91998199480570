import { useEffect, useState } from "react"
import { Card, Col, Row, Spinner } from "react-bootstrap"
import { toast } from "react-toastify"
import data from '../../lib/backend/data'

const ContadorEstadoV2 = (props) => {
    const condicion = props.condicion_default ? props.condicion_default : {}
    const [ conteos, setConteos ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const [ estados, setEstados ] = useState(props.estados ? props.estados : [])
    const token = props.token ? props.token : false

    const consultarEstados = () => {
        setLoading(true)
        return fetch(`${data.urlapi}/ordenes/conteo-estadocarga-v2`,{
            method:'POST',
            body: JSON.stringify({
                estados,
                condicion
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                setLoading(false)
                return toast.error('Sin datos')
            } else if(res.errorMessage){
                setLoading(false)
                return toast.error(res.errorMessage)
            } else if( res.conteo){
                if(Array.isArray(res.conteo) !== false){
                    setConteos(res.conteo)
                }
                if(Array.isArray(res.estados) !== false){
                    setEstados(res.estados)
                }
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error(error.message)
            return setLoading(false)
        })
    }

    useEffect(() => {
        consultarEstados()
    },[])

    if(loading) return <div>
        <Spinner animation="border" />
        <h6>Cargando estados</h6>
    </div>

    if(estados.length < 1) return false

    return <div>
        <Card className="p-3 mb-3">
        <Row>
            {
                estados.map(est => {
                    let cantidad = 0
                    const i = conteos.findIndex(c => c.codigo_estado === est.codigo_estado)
                    if(i > -1) cantidad = conteos[i].cantidad
                    return <Col key={`row-${est._id}`}>
                        <h6 style={{ fontSize: 11 }}>{est.titulo}</h6>
                        {loading ? <Spinner animation="border" /> : <h3>{cantidad}</h3>}
                    </Col>
                })
            }
        </Row>
        </Card>
    </div>
}

export default ContadorEstadoV2