import { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import StarkenPluginConfig from './formularios_config.js/starken';
import UrbanoPluginConfig from './formularios_config.js/urbano';

const CourriersListado = (props) => {

    return <div>
        <Row>
            <Col md={12}>
                
            <h4 className='mb-3'>Courriers</h4>
            <p>Toca el courrier para configurarlo</p>
            <Row>
                <Col md={4} xs={6}><StarkenPluginConfig /></Col>
                <Col md={4} xs={6}><UrbanoPluginConfig /></Col>
            </Row>
            </Col>
        </Row>
    </div>

}

export default CourriersListado