const pais_default = "CL"

function reducer( state = pais_default, { type, payload } ){
    switch( type ){
        case 'cambiarPais': {
            return payload
        }
        default:
            return state
    }
}

export default reducer