import { useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { urlapi } from '../../lib/backend/data';

const BuscadorVehiculos = (props) => {
    const {
        hideLabel,
        placeholderText
    } = props
    const [loading, setLoading] = useState(true)
    const [conductores, setConductores] = useState([])
    const [opciones, setOpciones] = useState([])
    const token = props.token ? props.token : false
    const isMulti = props.isMulti ? props.isMulti : false
    const placeholder = placeholderText ? placeholderText : "Seleccionar vehículo..."

    const obtenerProveedores = async () => {
        setLoading(true)
        return fetch(`${urlapi}/conductores/list-full`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoading(false)
            } else if(Array.isArray(res) !== false){
                setOpciones(res.map(c => ({ value: c._id, label: `${c.patente.toUpperCase()} · ${c.modelo.toUpperCase()}` })))
                setConductores(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoading(false)
        })
    }

    useEffect(() => {
        obtenerProveedores()
    }, [])

    const handleChangeSelectConductor = (e) => {
        if(isMulti === false){
            const i = conductores.findIndex(c => c._id === e.value)
            if(i > -1){
                if(props.onChange) props.onChange(conductores[i])
            }
        } else {
            let opciones = []
            if(e){
                e.map(valor => {
                    opciones.push(valor.value)
                })
            }
            const registros = conductores.filter(c => opciones.includes(c._id))
            if(props.onChange) props.onChange(registros)
        }
    }

    return <div>
        { hideLabel === true ? false : <label className='form-control-label d-block'>Selecciona el vehículo</label>}
        <Select 
                        style={{ marginBottom: 10 }}
                        onChange={handleChangeSelectConductor}
                        isLoading={loading}
                        isMulti={isMulti}
                        options={opciones}
                        placeholder={placeholder}
                        noOptionsMessage={()=>'Sin opciones'}
                        className="mb-0"
                        />
    </div>
}

export default BuscadorVehiculos