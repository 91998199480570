const recuperarCoordenadas = (arr) => {
    const result = [];
    
    const flattenHelper = (innerArr) => {
      for (const item of innerArr) {
        if (Array.isArray(item[0])) {
          flattenHelper(item);
        } else if (item.length === 2) {
          result.push(item);
        }
      }
    };
  
    flattenHelper(arr);
    return result;
  };


module.exports = {
    recuperarCoordenadas
}