import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button, Table, Tabs, Tab } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import data, { key_local_storage_user } from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import Moment from 'react-moment';
import 'moment/locale/es';
import PantallaCargando from '../../subComponents/general/pantalla_cargando'
import UsuariosListado from '../../subComponents/usuarios/listado'

class Usuarios extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem(key_local_storage_user)),
            loadingUsuarios: true,
            loadingMore: false,
            total: 0,
            usuarios: [],
            parametro_busqueda: '',
            loadingBusqueda: false,
            filtro_rol: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.buscarUsuario = this.buscarUsuario.bind(this)

    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    componentDidMount(){
        this.getUsuarios(false)
    }

    buscar(){
        const { parametro_busqueda, user } = this.state
        if(!parametro_busqueda) return toast.error('El valor de búsqueda es requerido')
        if(parametro_busqueda.length < 3) return toast.error('Ingresa un valor de búsqueda válido')
        let loading = 'loadingBusqueda'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/usuarios/search`,{
            method:'POST',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            },
            body: JSON.stringify({ parametro_busqueda })
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            if(res.length < 1){
                toast.warn('No se encontraron resultados')
                return this.setState({ [loading]: false })
            }
            return this.setState({ usuarios: res, filtro_rol:'', [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    async getUsuarios(cargarmas){
        const { user, usuarios } = this.state
        let loading = 'loadingUsuarios'
        if(cargarmas===true) loading = 'loadingMore'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/usuarios/list?skip=${usuarios.length}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            if(cargarmas===true){
                if(Array.isArray(res.datos) !== false){
                    const final_array = usuarios.concat(res.datos)
                    return this.setState({ usuarios: final_array, total: res.total, [loading]: false })
                } else {
                    return this.setState({ [loading]: false })
                }
            } else {
                this.setState({ usuarios: res.datos, total: res.total, [loading]: false })
            }
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    buscarUsuario(e){
        e.preventDefault()
        return this.buscar()
    }
    
    sinRegistros(){
        return <div className='p-5'>
            <Row className="justify-content-md-center">
                <Col md={6} className="text-center">
                <h4>Aún no tienes usuarios</h4>
                <p>Puedes crear diversos usuarios y asignar permisos a cada uno de ellos</p>
                </Col>
            </Row>
        </div>
    }

    mostrarUsuarios(){
        const { usuarios, loadingUsuarios, user, loadingMore, total, filtro_rol, loadingBusqueda } = this.state
        if(loadingUsuarios) return <PantallaCargando />
        if(usuarios.length < 1) return this.sinRegistros()

        return <div>            
            <Tabs defaultActiveKey="activos" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="activos" title="Activas">
                <UsuariosListado token={user.tokenSession} condicion_default={{ status: 'active' }} />
            </Tab>
            <Tab eventKey="archivados" title="Archivados">
                <UsuariosListado trash={true} token={user.tokenSession} condicion_default={{ status: 'trash' }} />
            </Tab>
            </Tabs>
            
            
        </div>
    }
    
    render(){
        return(
            <div className="fluid">
                <h4>Usuarios <Link to="/usuarios/nuevo"><i className="fa-solid fa-circle-plus"></i></Link></h4>
            <Card>
                <Card.Body>
                {this.mostrarUsuarios()}
                </Card.Body>
            </Card>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Usuarios);