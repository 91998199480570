import { useSelector } from "react-redux"
import { urlapi } from "../../lib/backend/data"
import { procesarDatosGraficosEstadisticaConductorLastMile, procesarOTIFParaGraficos } from "../../lib/helpers/conductores"
import { StringMes, formatDateAno } from "../../lib/helpers/dates"
import GraficosMesLastMile from "../graficos/graficos_last_mile"
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap"
import { toast } from "react-toastify"
import { useEffect, useState } from "react"
import { formatMes } from "../../lib/helpers/opcionesfecha"
import SinDatos from "../general/sin_registros"
import GraficoOtiff from "./grafico_otif"
import OrdenesListado from "../ordenes/listado"

const EstadisticaMensual = (props) => {
    const [ condicionBuquedaOrdenes, setCondicionBusquedaOrdenes ] = useState(false)
    const [ loadingOrdenes, setLoadingOrdenes ] = useState(true)
    const [ tipoVisualizacion, setTipoVisualizacion] = useState('asignadas')
    const [ datosGraficosHistoricoV2, setDatosGraficosHistoricosV2 ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ showModal, setShowModal ] = useState(false)
    const [ ano, setAno] = useState(formatDateAno(new Date()))
    const [ vehiculosSeleccionados, setVehiculosSeleccionados] = useState([])
    const [ otif, setOtif ] = useState(false)
    const [ ordenes, setOrdenes ] = useState([])
    const [ mes, setMes] = useState(formatMes(new Date()))
    const token = session.tokenSession
    const estilo_seleccionado = { border: "4px solid #1cb03a" }

    useEffect(() => {
        obtenerOrdenes(vehiculosSeleccionados, mes)
    }, [])

    const obtenerOrdenes = async (drivers, month) => {
        setLoadingOrdenes(true)
        return fetch(`${urlapi}/estadisticas/ordenes`,{
            method:'POST',
            body: JSON.stringify({
                drivers,
                mes: month,
                ano
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingOrdenes(false)

            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingOrdenes(false)

            } else if(Array.isArray(res) !== false){
                setOrdenes(res)
                const graphs_v2 = procesarDatosGraficosEstadisticaConductorLastMile(res)
                console.log({graphs_v2})
                const otif_mes = procesarOTIFParaGraficos(graphs_v2)
                setOtif(otif_mes)
                setDatosGraficosHistoricosV2(graphs_v2)
            }
            return setLoadingOrdenes(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingOrdenes(false)
        })
    }

    const refrescarDatos = () => {
        obtenerOrdenes(vehiculosSeleccionados, mes)
    }

    const handleChangeMes = (e) => {
        const { value } = e.target
        setMes(value)
        obtenerOrdenes(vehiculosSeleccionados, value)
    }

    const handleChangeAno = (e) => {
        const { value } = e.target
        return setAno(value)
    }

    const handleChangeTipo = (e) => {
        const { value } = e.target
        return setTipoVisualizacion(value)
    }

    const handleClose = () => {
        setShowModal(false)
    }

    const seleccionarDia = (data) => {

        const dia = data.dia < 10 ? `${0}${data.dia}` : data.dia

        const fecha = `${ano}-${mes}-${dia}`
        let ids_query = []
        if(tipoVisualizacion === "asignadas"){
            for(const info_dia of datosGraficosHistoricoV2.asignadas.dias ){
                const dia_iteracion = info_dia.dia < 10 ? `${0}${info_dia.dia}` : info_dia.dia
                
                if(dia.toString() !== dia_iteracion.toString()) continue
                const ids = info_dia.datos.map(d => d._id)
                ids_query = [...ids_query, ...ids]
            }
        } else if(tipoVisualizacion === "completadas"){
            for(const info_dia of datosGraficosHistoricoV2.completadas.dias ){
                const dia_iteracion = info_dia.dia < 10 ? `${0}${info_dia.dia}` : info_dia.dia
                if(dia.toString() !== dia_iteracion.toString()) continue
                const ids = info_dia.datos.map(d => d._id)
                ids_query = [...ids_query, ...ids]
            }
        } else if(tipoVisualizacion === "retrasadas"){
            for(const info_dia of datosGraficosHistoricoV2.rechazadas.dias ){
                const dia_iteracion = info_dia.dia < 10 ? `${0}${info_dia.dia}` : info_dia.dia
                if(dia.toString() !== dia_iteracion.toString()) continue
                const ids = info_dia.datos.map(d => d._id)
                ids_query = [...ids_query, ...ids]
            }
        }
        if(ids_query.length > 0){
            setCondicionBusquedaOrdenes({ _id: { $in: ids_query } })
            setShowModal(true)
        }
    }

    const mostrarGraficos = () => {


        if(!datosGraficosHistoricoV2) return <SinDatos />
        switch ( tipoVisualizacion ) {
            case 'asignadas':
                return datosGraficosHistoricoV2.asignadas ?  <GraficosMesLastMile titulo={tipoVisualizacion} onClick={data => seleccionarDia(data)} datos={datosGraficosHistoricoV2.asignadas} height={'200px'} /> : <SinDatos />
            case 'completadas':
                return datosGraficosHistoricoV2.completadas ?  <GraficosMesLastMile titulo={tipoVisualizacion} onClick={data => seleccionarDia(data)} datos={datosGraficosHistoricoV2.completadas} height={'200px'} color="green" /> : <SinDatos />
            case 'retrasadas':
                return datosGraficosHistoricoV2.rechazadas ?  <GraficosMesLastMile titulo={tipoVisualizacion} onClick={data => seleccionarDia(data)} datos={datosGraficosHistoricoV2.rechazadas} height={'200px'} color='#c50f0f' colors={['#c50f0f']} /> : <SinDatos />
            default:
                break;
        }
    }

    if(loadingOrdenes===true) return <Spinner animation='border' />

    return <div>
                <hr />
                <Modal show={showModal} size="xl" onHide={()=>handleClose()} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Órdenes relacionadas</Modal.Title>
                </Modal.Header>
                                                <Modal.Body>
                                                    <OrdenesListado upWhenClick={false} showSearch={false} showAddButton={false} showFilter={false} showExporter={false} condicion_default={condicionBuquedaOrdenes} />
                                                </Modal.Body>
                                            </Modal>
            <Row>
                <Col md={2} className="mb-4">
                    <label className='form-control-label d-block'>Año</label>
                    <input className='form-control' type="number" value={ano} name="ano"  onChange={handleChangeAno} />
                </Col>
                <Col md={3} className="mb-4">
                    <label className='form-control-label d-block'>Mes</label>
                    <select className='form-control' value={mes} onChange={handleChangeMes} >
                    <option value="01">Enero</option>
                    <option value="02">Febrero</option>
                    <option value="03">Marzo</option>
                    <option value="04">Abril</option>
                    <option value="05">Mayo</option>
                    <option value="06">Junio</option>
                    <option value="07">Julio</option>
                    <option value="08">Agosto</option>
                    <option value="09">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                    </select>
                </Col>
                <Col md={3} className="mb-4">
                    <label className='form-control-label d-block'>Refrescar datos</label>
                    <Button variant="outline-primary" onClick={()=>refrescarDatos()}>ACTUALIZAR</Button>
                </Col>
            </Row>
            <GraficoOtiff data={otif} />
            <Row className="mt-3">
                <Col className="hover"><Card style={tipoVisualizacion==="asignadas"   ? estilo_seleccionado : {}} onClick={() => setTipoVisualizacion("asignadas")} className="p-3 text-center"><p className="m-0">{StringMes(mes)}</p><h3 className="p-0 m-0">ASIGNADAS</h3></Card></Col>
                <Col className="hover"><Card style={tipoVisualizacion==="completadas" ? estilo_seleccionado : {}} onClick={() => setTipoVisualizacion("completadas")} className="p-3 text-center"><p className="m-0">{StringMes(mes)}</p><h3 className="p-0 m-0">COMPLETADAS</h3></Card></Col>
                <Col className="hover"><Card style={tipoVisualizacion==="retrasadas"  ? estilo_seleccionado : {}} onClick={() => setTipoVisualizacion("retrasadas")} className="p-3 text-center"><p className="m-0">{StringMes(mes)}</p><h3 className="p-0 m-0">RETRASADAS</h3></Card></Col>
            </Row>
            {mostrarGraficos()}       
    </div>
}

export default EstadisticaMensual