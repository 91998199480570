import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { urlapi } from '../../lib/backend/data';
import { fechaATextoSimple } from '../../lib/helpers/helpers';
import { useSelector } from 'react-redux';

const SelectorZonas = (props) => {
    const {
        fetchGeojson,
        titulo,
        hideLabel
    } = props
    const [ options, setOptions ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const pais = useSelector(state => state.pais)
    const session = useSelector(state => state.miusuario)
    const titulo_label = titulo ? titulo : "Buscar por zona"
    
    const listadoInicial = async (condicion, page) => {
        return fetch(`${urlapi}/geodata/zonas/list/select`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                geo_json: fetchGeojson,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoading(false)
            } else if(Array.isArray(res) !== false){
                if(props.onFetchedData) props.onFetchedData(res)
                setOptions(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    } 

    useEffect(() => {
        listadoInicial({},1)
    }, [])

    const buscar = async (condicion, page) => {
        if(!session.tokenSession) return []
        return fetch(`${urlapi}/geodata/zonas/list/select`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                geo_json: fetchGeojson,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                return []
            } else if(res.errorMessage){
                return []
            }
            setOptions(res)
            return res.length > 0 ? res.map(zona => ({ value: zona._id, label: `${zona.titulo} · ${zona.local_name}` })) : []
        })
        .catch(error => {
            toast.error(error.message)
            return false
        })
    }

    const promiseOptions = async (inputValue) => {
        return new Promise( async (resolve) => {
            setTimeout( async () => {
            const busqueda = await buscar({ $text: { $search: inputValue } }, 1)
              resolve(busqueda);
            }, 500)
        })
    }

    const handleChange = (e) => {
        const i = options.findIndex(zona => zona._id === e.value)
        if(i > -1 ){
            console.log(options[i])
            if(props.onChangeValue) props.onChangeValue(options[i])
        }
        return false
    }

    return <div>
        { hideLabel === true ? false :  <label className='form-control-label'>{titulo_label}</label> }
        <Select
            placeholder="Buscar zona..."
            options={options.map(zona => ({ value: zona._id, label: `${zona.titulo} · ${zona.local_name}` }))}
            isLoading={loading}
            noOptionsMessage={()=>"Sin opciones"}
            loadingMessage={()=>"Cargando información..."}
            onChange={handleChange}
            className="mb-2"
        />
                { /* <AsyncSelect 
                    cacheOptions  
                    placeholder="Buscar zona..."
                    defaultOptions={options.map(zona => ({ value: zona._id, label: `${zona.titulo} · ${zona.local_name}` }))}
                    isLoading={loading}
                    noOptionsMessage={()=>"Sin opciones"}
                    loadingMessage={()=>"Cargando información..."}
                    loadOptions={promiseOptions} 
                    onChange={handleChange}
                    className="mb-2"
/> */}
    </div>
}

export default SelectorZonas