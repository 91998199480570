import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ReactSelect from 'react-select'
import { confirmAlert } from 'react-confirm-alert'
import SelectorUsuariosBodega from '../bodega/usuarios/selector'
import { urlapi } from '../../lib/backend/data'
import { showByTipoServicio } from '../../lib/helpers/logistica'
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import SelectorEstados from '../estados_carga/selector'
import { rutas } from '../../lib/routes/routes'
import { host } from '../../lib/global/data'

const DetallesCanal = (props) => {
    const {
        idcanal
    } = props
    const [ canal, setCanal ] = useState({})
    const [ loading, setLoading ] = useState(false)
    const [ guardando, setGuardando ] = useState(false)
    const [ empresas, setEmpresas ] = useState([])
    const [ cliente, setCliente] = useState(false)
    const [ usuarioSeleccionado, setUsuarioSeleccionado] = useState(false)
    const [ loadingUsuarioAgregado, setLoadingUsuarioAgregado] = useState(false)
    const [ loadingEstados, setLoadingEstados] = useState(false)
    const [ usuariosCanal, setUsuariosCanal ] = useState([])
    const [ estadosCanal, setEstadosCanal ] = useState([])
    const [ estados, setEstados ] = useState([])
    const [ subEstados, setSubEstados ] = useState([])
    const [ estadoSeleccionado, setEstadoSeleccionado ] = useState(false)
    const [ loadingAgregado, setLoadingAgregado ] = useState(false)
    const [ loadingEliminandoUsuario, setLoadingEliminandoUsuario ] = useState(false)
    const [ loadingEliminado, setLoadingEliminado ] = useState(false)
    const [ guardandoEstado, setGuardandoEstado ] = useState(false)
    const session = useSelector(state => state.miusuario)

    const handleChange = e => {
        const { name, value } = e.target
        canal[name] = value
        return setCanal(canal)
    }

    useEffect(() => {
        obtenerDetallesCanal()
        console.log(session.data)
    }, [ idcanal ])

    const obtenerDetallesCanal = async () => {
        if(!idcanal) return
        setLoading(true)
        return fetch(`${urlapi}/bodega/canal?id=${idcanal}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${session.tokenSession}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error("Sin datos")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setCanal(res)
                if(res.estados){
                    if(Array.isArray(res.estados) !== false){
                        setEstadosCanal(res.estados)
                    }
                }
                if(res.usuarios){
                    if(Array.isArray(res.usuarios) !== false){
                        setUsuariosCanal(res.usuarios)
                    }
                }
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error(error.message)
            setLoading(false)
        })
    }

    const actualizarCanal = async () => {
        if(!canal.titulo) return toast.error("Título es requerido")
        if(!canal.descripcion) return toast.error("Descripción es requerido")
        setGuardando(true)
        return fetch(`${urlapi}/bodega/canal`,{
            method: "PUT",
            body: JSON.stringify(canal),
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${session.tokenSession}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error("Sin datos")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Creado exitosamente")
            }
            return setGuardando(false)
        })
        .catch(error => {
            toast.error(error.message)
            setGuardando(false)
        })
    }

    const solicitarEliminarCanal = () => {
        return confirmAlert({
            title: '¿Confirmas esta acción?',
            message: `Estás a punto de eliminar este canal y sus estados asociados, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => eliminarCanal()
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              },
            ],
          })
    }
    
    const eliminarCanal = async () => {
        setLoading(true)
        return fetch(`${urlapi}/bodega/canal?id=${idcanal}`,{
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${session.tokenSession}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error("Sin datos")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Eliminado exitosamente")
                setTimeout(() => {
                    window.location = `/${rutas.canales.slug}`
                }, 2000);
            }
        })
        .catch(error => {
            toast.error(error.message)
            setLoading(false)
        })
    }
    
    const agregarUsuarioACanal = async () => {
        const guardar = {
            id_canal: idcanal,
            label: usuarioSeleccionado.label,
            id_usuario: usuarioSeleccionado.value
        }
        setLoadingUsuarioAgregado(true)
        return fetch(`${urlapi}/bodegas/canal/usuario`,{
            method: "POST",
            body: JSON.stringify(guardar),
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error("Sin datos")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Creado exitosamente")
                setUsuariosCanal(prev => [...prev, ...[res]])
            }
            return setLoadingUsuarioAgregado(false)
        })
        .catch(error => {
            toast.error(error.message)
            return setLoadingUsuarioAgregado(false)
        })
    }

    const agregarEstadoACanal = async () => {
        let guardar = JSON.parse(JSON.stringify(estadoSeleccionado))
        delete guardar._id
        guardar.id_canal = idcanal
        guardar.id_estado = estadoSeleccionado._id
        guardar.propietario = session.data.propietario
        guardar.cliente = session.data.empresa.razon_social
        setLoadingAgregado(true)
        return fetch(`${urlapi}/bodega/canal/estado`,{
            method: "POST",
            body: JSON.stringify(guardar),
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${session.tokenSession}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error("Sin datos")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Creado exitosamente")
                setEstadosCanal(prev => [...prev, ...[res]])
            }
            return setLoadingAgregado(false)
        })
        .catch(error => {
            toast.error(error.message)
            setLoadingAgregado(false)
        })
    }
    
    const eliminarEstadoACanal = async (id) => {
        setLoadingEliminado(id)
        return fetch(`${urlapi}/bodega/canal/estado?id=${id}`,{
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${session.tokenSession}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error("Sin datos")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Eliminado exitosamente")
                const i = estadosCanal.findIndex(channel => channel._id === id)
                if(i > -1){
                    estadosCanal.splice(i,1)
                    setEstadosCanal(prev => [...[], ...estadosCanal])
                }
            }
            return setLoadingEliminado(false)
        })
        .catch(error => {
            toast.error(error.message)
            setLoadingEliminado(false)
        })
    }
    
    const eliminarUsuarioCanal = async (id) => {
        setLoadingEliminandoUsuario(id)
        return fetch(`${urlapi}/bodegas/canal/usuario?id=${id}`,{
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error("Sin datos")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Eliminado exitosamente")
                const i = usuariosCanal.findIndex(channel => channel._id === id)
                if(i > -1){
                    usuariosCanal.splice(i,1)
                    setUsuariosCanal(prev => [...[], ...usuariosCanal])
                }
            }
            return setLoadingEliminandoUsuario(false)
        })
        .catch(error => {
            toast.error(error.message)
            setLoadingEliminandoUsuario(false)
        })
    }
    
    const guardarEstadoACanal = async (id) => {
        const i = estadosCanal.findIndex(estado => estado._id === id)
        setGuardandoEstado(id)
        return fetch(`${urlapi}/bodega/canal/estado`,{
            method: "PUT",
            body: JSON.stringify(estadosCanal[i]),
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${session.tokenSession}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error("Sin datos")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Actualizado exitosamente")
                const i = estadosCanal.findIndex(channel => channel._id === id)
            }
            return setGuardandoEstado(false)
        })
        .catch(error => {
            toast.error(error.message)
            setGuardandoEstado(false)
        })
    }


    const mostrarUsuarios = () => {
        if(usuariosCanal.length < 1) return false

        return <div className='mt-3'>
            <h4 className='mb-0'>{usuariosCanal.length} Estados asignados a este canal</h4>
            <hr className='mb-2 mt-0' />
            {
                            usuariosCanal.map((estado,iestado) => {
                                return <div className='card p-3 mb-3' key={estado._id}>
                                    <h3>{estado.label}</h3>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                        { loadingEliminandoUsuario === estado._id ? <Spinner animation="border" /> : <button className='btn btn-sm btn-outline-danger' onClick={() => eliminarUsuarioCanal(estado._id)} >ELIMINAR</button> }
                                        </div>
                                    </div>
                                </div>
                            })
            }
        </div>
    }

    const mostrarEsquemaEstados = () => {
        if(estadosCanal.length < 1) return false

        const empresas = []

        for( const registro of estadosCanal){
            const i = empresas.findIndex(empresa => empresa.rut === registro.propietario)
            if(i < 0){
                empresas.unshift({
                    rut: registro.propietario,
                    titulo: registro.cliente,
                    estados: [
                        registro
                    ]
                })
            } else {
                if(!empresas[i].titulo) empresas[i].titulo = registro.cliente
                empresas[i].estados.unshift(registro)
            }
        }

        return <div className='mt-3'>
            <h4 className='mb-2'>{estadosCanal.length} Estados asignados a este canal</h4>
            <hr className='mb-2 mt-0' />
            {
                empresas.map((empresa,iemp) => {
                    return <div key={`empresa-${iemp}`}>
                        <h4 className='mb-2'><i class="fas fa-arrow-circle-right"></i> {empresa.titulo}</h4>
                        <div className='pl-3'>
                        {
                            empresa.estados.map((estado,iestado) => {
                                return <div className='card p-3 mb-3' key={estado._id}>
                                    <h5><i style={{ color: estado.color ? estado.color: 'black' }} class="fas fa-circle"></i> {estado.titulo}</h5>
                                    <h6 className='hover' onClick={()=>setBoleanProperty('forze',estado._id)}>{ estado.forze === true ? 
                                    <i className="fas text-success fa-check-square"></i> :
                                    <i className="far fa-square"></i> } Forzar estado</h6>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                        { guardandoEstado === estado._id ? <Spinner animation='border' /> : <button className='btn btn-sm btn-outline-success' onClick={() => guardarEstadoACanal(estado._id)} ><i className="fas fa-save"></i> GUARDAR CAMBIOS</button> }
                                        </div>
                                        <div className='col-md-6'>
                                        { loadingEliminado === estado._id ? <Spinner animation='border' /> : <button className='btn btn-sm btn-outline-danger' onClick={() => eliminarEstadoACanal(estado._id)} ><i className="fas fa-trash"></i> ELIMINAR</button> }
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                        </div>
                    </div>
                })
            }
        </div>
    }

    const obtenerEstados = (rut) => {
        if(!rut) return false
        setLoadingEstados(true)
        return fetch(`${urlapi}api/estadoscarga/details?propietario=${rut}`)
        .then(pros => pros.json())
        .then(data => {
            if(data.errorMessage){
                toast.error(data.errorMessage)
                return setLoadingEstados(false)
            }
            if(data.subestados){
                if(Array.isArray(data.subestados) !== false){

                    const opciones = data.subestados.map(e => {
                        let extra_titulo = ''
                        if(data.estados){
                            if(Array.isArray(data.estados) !== false){
                                const i_estado = data.estados.findIndex(estado => estado._id === e.idparent)
                                if(i_estado > -1){
                                    extra_titulo = data.estados[i_estado].titulo
                                    if(data.estados[i_estado].tipos_servicio.length > 0){
                                        const tipos_servicio = data.estados[i_estado].tipos_servicio.map(t => showByTipoServicio(t) ).join(', ')
                                        extra_titulo = `${extra_titulo} ${tipos_servicio}`
                                    }
                                }
                            }
                        }
                        let identificador = e.titulo
                        if(extra_titulo) identificador = `${identificador} · ${extra_titulo}`
                        return { value: e._id, label: identificador }
                    })

                    setEstados(opciones)
                    setSubEstados(data.subestados)
                }
            }
            setLoadingEstados(false)
        })
        .catch(error => {
            console.log(error.message)
            toast.error('No pudimos cargar la información')
            setLoadingEstados(false)
        })
    }

    const onChangeUsuario = e => {
        setUsuarioSeleccionado(e)
    }

    const handleChangeEstado = (e) => {
        const i = subEstados.findIndex(estado => estado._id === e.value)
        if(i < 0) return false
        let registro = subEstados[i]
        registro.titulo = e.label
        setEstadoSeleccionado(subEstados[i])
    }

    const accionesUsuarioSeleccionado = () => {
        if(loadingUsuarioAgregado) return <Spinner animation="border" />
        return <div>
            <button className='btn btn-success d-block' onClick={() => agregarUsuarioACanal()} >AGREGAR</button>
        </div>
    }

    const accionesEstadoSeleccionado = () => {
        if(!estadoSeleccionado) return false
        if(loadingAgregado) return <Spinner animation="border" />

        return <div>
            <button className='btn btn-success d-block' onClick={() => agregarEstadoACanal()} >AGREGAR</button>
        </div>
    }

    const agregarNuevosUsuarios = () => {
        return false
        return <div>
            <div className='row'>
                <div className='col-md-4'>
                    <label className='form-control-label d-block'>Seleccionar usuario</label>
                    <SelectorUsuariosBodega value={usuarioSeleccionado ? usuarioSeleccionado.value : false} onChange={e => onChangeUsuario(e)} />
                </div>
                <div className='col-md-4'>
                    <label className='form-control-label d-block'>Click para agregar</label>
                    {accionesUsuarioSeleccionado()}
                </div>
                </div>
        </div>
    }

    const setBoleanProperty = (key) => {
        const valor = canal[key] === true ? false : true
        canal[key] = valor
        return setCanal({...{}, ...canal})
    }

    const agregarNuevosEsquemas = () => {
        return <div>
            <div className='row'>
                <div className='col-md-4'>
                    <SelectorEstados full_payload={true} titulo="Seleccionar estado" onChange={(e) => {
                        console.log(e)
                        setEstadoSeleccionado(e)
                    }} />
                </div>
                <div className='col-md-4'>
                    <label className='form-control-label d-block'>Click para agregar</label>
                    {accionesEstadoSeleccionado()}
                </div>
            </div>


        </div>
    }

    if(!idcanal) return <div className='p-5'>
    <Row className="justify-content-md-center">
        <Col md={6} className="text-center">
          <img src="images/pedidos.png" style={{ width: "50%" }} />
        <h4>Selecciona un canal para visualizar sus detalles</h4>
        </Col>
    </Row>
    </div>

    if(loading) return <Row>
        <Col md={4}><Skeleton height={40} /></Col>
        <Col md={8}><Skeleton height={40} /></Col>
        <Col md={12} className='mt-3'><Skeleton height={40} /></Col>
        <Col md={12} className='mt-3'><Skeleton height={40} /></Col>
        <Col md={12} className='mt-3'><Skeleton height={20} /></Col>
        <Col md={12} className='mt-3 mb-3'><Skeleton height={20} /></Col>
        <Col md={4}><Skeleton height={40} /></Col>
        <Col md={4}><Skeleton height={40} /></Col>
        <Col md={4}><Skeleton height={40} /></Col>
    </Row>

    return <div>
        <Card className='p-3'>
        <h4>CANAL {canal.titulo ? canal.titulo.toUpperCase() : ""}</h4>
        <Row className=' mb-3'>
            <Col md={4}>
                <label className='form-control-label d-block'>Título</label>
                <input className='form-control' name="titulo" defaultValue={canal.titulo} onChange={handleChange}/>
            </Col>
            <Col md={8}>
                <label className='form-control-label d-block'>Descripción</label>
                <input className='form-control' name="descripcion" defaultValue={canal.descripcion} onChange={handleChange}/>
            </Col>
        </Row>
        <Row>
            <Col>{ guardando ? <Spinner animation='border' /> : <Button variant="success" className='btn mb-3 mr-3 w-100' onClick={() => actualizarCanal()} >GUARDAR CAMBIOS</Button>}</Col>
            <Col><Button variant="outline-danger" className='btn mb-3 w-100' onClick={() => solicitarEliminarCanal()} >ELIMINAR</Button></Col>
        </Row>

                        <hr className='mb-2'/>
        <div>
            <img src={`${host}/images/tasks.png`} style={{ height: 100 }} />
            <h3 className='mb-0'>Acciones</h3>
            <p>A continuación se muestran diferentes acciones que se pueden activar al procesar órdenes a través este canal</p>
            <Row>
                <Col md={4}>
                    <Card className='p-3 mb-3'>
                    <h6 className='hover' onClick={()=>setBoleanProperty('asignar_app')}>{ canal.asignar_app === true ? 
                    <i className="fas text-success fa-check-square"></i> :
                    <i className="far fa-square"></i> } Asignar App Conductor</h6>
                    <p className='mb-0'>Asignar órdenes al conductor a través en App Conductor</p>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className='p-3 mb-3'>
                    <h6 className='hover' onClick={()=>setBoleanProperty('crear_ruta')}>{ canal.crear_ruta === true ? 
                    <i className="fas text-success fa-check-square"></i> :
                    <i className="far fa-square"></i> } Crear ruta</h6>
                    <p className='mb-0'>Las órdenes se reasignarán a una nueva ruta, saldrán de la ruta actual</p>
                    </Card>
                </Col>
            </Row>
                        <hr className='mb-2'/>
        </div>

        <div>
        <img src={`${host}/images/status.png`} style={{ height: 100 }} />
            <h3 className='mb-0'>Estados asignados a este canal</h3>
            <p>Estos son los estados que se disparan al confirmar órdenes dentro de este canal.</p>
            {agregarNuevosEsquemas()}
            {mostrarEsquemaEstados()}
            {agregarNuevosUsuarios()}
            {mostrarUsuarios()}
        </div>
        </Card>
    </div>
}

export default DetallesCanal