import { useEffect, useState } from 'react'
import { Row, Col, Button, Spinner, Card, Tab, Tabs } from 'react-bootstrap'
import CargandoConteo from './cargando'
import data from '../../lib/backend/data'
import { toast } from 'react-toastify'
import Chart from 'react-google-charts'
import GraficoVehiculoProveedor from '../graficos/grafico_vehiculo_proveedor'
import SelectorRangoFechas from '../general/selector_rango_fechas'
import { obtenerRangos } from '../../lib/helpers/opcionesfecha'
import { useSelector } from 'react-redux'
import Recursos from '../ordenes/estadistica/recursos'
import ConteoEstatico from './conteo_estatico'

const MainConteo = (props) => {
    const {
        ordenes
    } = props
    const initialDate = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    const bg = props.bg ? props.bg : 'light'
    const [loading, setLoading] = useState(true)
    const [tipoGrafico, setTipoGrafico] = useState("Bar")
    const [datos, setDatos] = useState([])
    const [colores, setColores] = useState([])
    const [graficos, setGraficos] = useState([])
    const [graficosProveedores, setGraficosProveedores] = useState([])
    const [ condicion, setCondicion ] = useState({})
    const [ condicionProveedor, setCondicionProveedor ] = useState({})
    const [loadingGraficos, setLoadingGraficos] = useState(true)
    const [tipoVisualizacion, setTipoVisualizacion] = useState('vehiculos')
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const session = useSelector(state => state.miusuario)

    const obtenerDatos = async () => {
        const { desde, hasta } = obtenerRangos(selectionRange.startDate, selectionRange.endDate)
        const condicion_busqueda_rutas = { $or:[
            { entrega_desde: { $gte: desde, $lte: hasta } },
            { entrega_hasta: { $lte: hasta, $gte: desde } },
            { entrega_hasta: { $gte: hasta }, entrega_desde: { $lte: desde } },
        ]
        }
        const condicion_busqueda_ordenes = { $or:[
            { fecha_min_entrega: { $gte: desde, $lte: hasta } },
            { fecha_max_entrega: { $lte: hasta, $gte: desde } },
            { fecha_max_entrega: { $gte: hasta }, fecha_min_entrega: { $lte: desde } },
        ]
        }
        
        setCondicion(condicion_busqueda_ordenes)
        if(props.onChangeDates) props.onChangeDates({ desde, hasta })
        setLoading(true)
        return fetch(`${data.urlapi}/actividad`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda_rutas
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoading(false)

            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoading(false)

            } else if(Array.isArray(res) !== false){
                
                if(res.length > 0){
                    const seleccionados = res.filter(da => da.clickeable === true)
                    const informacion = res.map(da => {
                        if(da.clickeable === true){
                            da.seleccionado = true
                        }
                        return da
                    })
                    setDatos(informacion)
                    obtenerDatosPorEstado(seleccionados)
                    setColores(seleccionados.map(da => da.color))
                } else {
                    setDatos(res)
                }
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoading(false)
        })
    }

    useEffect(() => {
        obtenerDatos()
    },[])

    const obtenerDatosPorEstado = (informacion) => {
        const codigos = informacion.map(c => c.codigo_estado)
        const colores = informacion.map(c => c.color)
        const { desde, hasta } = obtenerRangos(selectionRange.startDate, selectionRange.endDate)
        setColores(colores)
        if(codigos.length < 1){
            setLoadingGraficos(false)
            return setGraficos([])
        }
        setLoadingGraficos(true)
        return fetch(`${data.urlapi}/actividad/por-estado`,{
            method:'POST',
            body: JSON.stringify({
                estados: codigos,
                condicion: { $or:[
                    { entrega_desde: { $gte: desde, $lte: hasta } },
                    { entrega_hasta: { $lte: hasta, $gte: desde } },
                    { entrega_hasta: { $gte: hasta }, entrega_desde: { $lte: desde } },
                ]
                }
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res.conductores){
                if(Array.isArray(res.conductores) !== false){
                    setGraficos(res.conductores)
                }
                if(Array.isArray(res.proveedores) !== false){
                    setGraficosProveedores(res.proveedores)
                }
            }
            return setLoadingGraficos(false)
        })
        .catch(error => {
            toast.error(error.message)
            return setLoadingGraficos(false)
        })
    }

    const seleccionar = (i) => {
        if(loadingGraficos===true) return true
        if(datos[i].clickeable !== true) return true
        datos[i].seleccionado = datos[i].seleccionado === true ? false : true
        const seleccionados = datos.filter(da => da.seleccionado === true)
        if(props.onChangeSelection) props.onChangeSelection(seleccionados)
        setDatos(prev => [...[], ...datos])
        return obtenerDatosPorEstado(seleccionados)
    }

    const handleChangeTipo = (e) => {
        const { value } = e.target
        return setTipoVisualizacion(value)
    }

    const handleChangeTipoGrafico = (e) => {
        const { value } = e.target
        return setTipoGrafico(value)
    }

    const mostrarGraficos = () => {
        if(loadingGraficos===true) return <div className='p-3 mt-3'><Spinner animation='border' /><h5 className='mb-0'>Cargando gráficos</h5></div>
        if(graficos.length < 1) return false

        let alto = 300
        if(graficos.length > 0){
            alto = graficos.length * 40
        }
        
        return <div className='mt-3'>

<Tabs defaultActiveKey="home" >
<Tab eventKey="home" title="Proveedores de transporte" className='pt-4'>
    <Recursos condicion_default={condicion} condicion_proveedor={condicionProveedor} />
</Tab>
<Tab eventKey="flota" title="Mi flota de vehículos" className='pt-4'>
{
                        graficos.length > 1 ? <div>
                        { /* <ConteoEstatico token={session.tokenSession} datos={ordenes} /> */}
                        <GraficoVehiculoProveedor rango_fechas={selectionRange} graficos={graficos} colores={colores} />
                        </div> : <div className='text-center'>
                        <img src="images/pedidos.png" style={{ width: "50%", maxWidth: 200 }} />
                        <h4>Sin datos para mostrar gráficos</h4>
                        </div>
}
</Tab>
</Tabs>
        </div>
    }


    const cambiarRangoFecha = (item) => {
        setSelectionRange(item.selection)
    }

    const mostrarDatos = () => {
        if(loading === true) return <CargandoConteo />
        let total = 0

        for(const item of datos){
            if(item.type !== 'numero') continue
            total = total + item.cantidad
        }

        return <Card bg={bg} className={`p-3 ${ bg === 'dark' ? 'text-white' : ''}`}>
        <h6 className='mb-3'>Estos datos corresponden a toda la operación logística en curso · <b style={{ color: "#007bff"}}>{total} órdenes</b></h6>
        <Row>
            {
                datos.map((info,i) => {
                    return <Col key={`c-${info.titulo}`} onClick={() => seleccionar(i)} className="hover">
                        <Card className={`p-2 ${info.seleccionado === true ? 'border-white' : ''}`} bg={bg}>
                            {
                                info.clickeable === true ? <div>
                                    {
                                        info.seleccionado === true ? <h6 style={{ fontSize: 12 }}><i className="fas fa-check-square"></i> {info.titulo}</h6> : <h6 style={{ fontSize: 12 }}><i className="far fa-square"></i> {info.titulo}</h6>
                                    }
                                    
                                </div> : <h6 style={{ fontSize: 12 }}>{info.titulo}</h6>
                            }
                            
                            <h2>{ info.type === 'porcentaje' ? `${info.cantidad.toFixed(0)}` : info.cantidad }</h2>
                        </Card>
                    </Col>
                })
            }
        </Row>
    </Card>
    }
    
    return <div className='mb-3'>
        <Row className='mb-3'>
            <Col md={6}><SelectorRangoFechas titulo_label="Rango de entrega" onChange={(item) => cambiarRangoFecha(item)} /></Col>
            <Col md={2}>
            <label className='form-control-label'>Click para refrescar resultados</label>
            <Button onClick={() => obtenerDatos()} variant='dark'>ACTUALIZAR DATOS</Button>
            </Col>
        </Row>
            {mostrarDatos()}
            <div className='pl-2 pr-2'>
            {mostrarGraficos()}
            </div>
    </div>
}

export default MainConteo