import React from 'react';
import { url_images } from '../../lib/global/data'
import { Link, Navigate, useParams } from 'react-router-dom';
import '../landing/actividadLanding.css'
import { Col, Row } from 'react-bootstrap';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { BsBarChartLine, BsBoxes, BsCodeSlash, BsFillBoxFill, BsFillBoxSeamFill, BsFillSendPlusFill, BsFillTagsFill, BsPlugin, BsShare, BsTruckFront, BsTruckFrontFill, BsUpcScan } from "react-icons/bs";
import { rutas } from "../../lib/routes/routes";
import { FiMapPin, FiUsers } from "react-icons/fi";
import { MdOutlineBusinessCenter, MdOutlineMobileFriendly, MdOutlineWebhook, MdSyncAlt } from "react-icons/md";
import { AiOutlineBarChart, AiOutlineBarcode, AiOutlineCode, AiOutlineFileExcel, AiOutlinePlusCircle, AiOutlineSetting } from "react-icons/ai";
import { LiaRouteSolid, LiaShippingFastSolid } from "react-icons/lia";
import { FaShippingFast } from "react-icons/fa";
import { PiFileHtmlDuotone, PiMonitorPlay } from "react-icons/pi";
import { BiCategoryAlt, BiMoneyWithdraw } from "react-icons/bi";
import { TbBuildingWarehouse } from "react-icons/tb";

const data_landing = [
    {
        slug: "actividad",
        title: "Actividad",
        text: "Visualizar dashboard de control de tu operación. Administrar entregas, conductores, estados de carga, rendimiento de proveedores y más...",
        slides: [
            {
                type: "banner-principal",
            }
        ],
        links: [
            {
                title: "Entregas",
                icon: BsBarChartLine,
                link: rutas.actividad.slug
            },
            {
                title: "Estados de carga",
                icon: FiMapPin,
                link: rutas.actividad_estados.slug
            },
            {
                title: "Auditoría App Móvil",
                icon: MdOutlineMobileFriendly,
                link: rutas.actividad_auditoria.slug
            },
            {
                title: "Rendimiento de proveedores",
                icon: AiOutlineBarChart,
                link: rutas.rendimiento_proveedores.slug
            },
            {
                title: "Entregas asignadas pendientes",
                icon: BsBoxes,
                link: rutas.operacion_en_curso.slug
            },
        ],
        areas:[
            {
                image: "",
                title: "Dashboard de control de tu operación",
                text: "Un completo panel que brinda una visión integral de tus operaciones logísticas. Monitoriza en tiempo real, analiza datos clave y toma decisiones informadas para optimizar la eficiencia"
            },
            {
                image: "",
                title: "Administrar entregas, conductores, estados de carga",
                text: "Simplifica la gestión logística con herramientas avanzadas. Desde el seguimiento de entregas hasta la asignación de conductores y el control de los estados de carga, todo en un solo lugar para una operación fluida"
            },
            {
                image: "",
                title: "Rendimiento de proveedores",
                text: "Evalúa y mejora el rendimiento de tus proveedores de transporte. Analiza tiempos de entrega, integridad de carga y eficiencia financiera. Facilita la toma de decisiones para una cadena de suministro eficiente."
            },
        ]
    },
    {
        slug: "cuenta",
        title: "Mi Cuenta",
        text: "Controla la forma en que tu equipo gestiona sus tareas y roles asignados dentro del sistema",
        slides: [
            {
                type: "banner-principal",
            }
        ],
        links: [
            {
                title: "Usuarios",
                icon: FiUsers,
                link: rutas.usuarios.slug
            },
            {
                title: "Configuracion",
                icon: AiOutlineSetting,
                link: rutas.avanzada.slug
            },
            {
                title: "Formatos excel",
                icon: AiOutlineFileExcel,
                link: rutas.excel_formats.slug
            },
        ],
        areas:[
            {
                image: "",
                title: "Gestiona los permisos y roles de los usuarios de la plataforma",
                text: "Administra de manera eficiente los accesos a tu cuenta. Define roles y asigna permisos según las responsabilidades, garantizando la seguridad y la colaboración efectiva en la plataforma"
            },
            {
                image: "",
                title: "Configura todos los aspectos del sistema",
                text: "Personaliza la plataforma según tus necesidades. Desde la apariencia hasta las funcionalidades específicas, configura cada detalle para adaptar el sistema a los procesos únicos de tu operación, asegurando máxima utilidad"
            },
            {
                image: "",
                title: "Gestiona los formatos Excel que importas y exportas del sistema",
                text: "Simplifica la integración de datos. Administra sin esfuerzo los formatos Excel al importar y exportar información. Asegura una fluidez eficiente entre el sistema y tus herramientas de trabajo habituales."
            },
        ]
    },
    {
        slug: "ordenes",
        title: "Órdenes de entrega",
        text: "Crea, modifica e importa órdenes de entrega a través de diversas herramientas",
        slides: [
            {
                type: "banner-principal",
            }
        ],
        links: [
            {
                title: "Mis órdenes",
                icon: BsFillBoxSeamFill,
                link: rutas.usuarios.slug
            },
            {
                title: "Crear orden",
                icon: AiOutlinePlusCircle,
                link: rutas.usuarios.slug
            },
            {
                title: "Importar desde integraciones",
                icon: BsFillSendPlusFill,
                link: rutas.avanzada.slug
            },
            {
                title: "Importador excel",
                icon: AiOutlineFileExcel,
                link: rutas.avanzada.slug
            },
            {
                title: "Configuración de excel",
                icon: AiOutlineSetting,
                link: rutas.excel_formats.slug
            },
        ],
        areas:[
            {
                image: "",
                title: "Integración de plataformas ecommerce y sistemas de terceros",
                text: "Optimiza tu proceso logístico conectando sin problemas tu plataforma ecommerce y sistemas externos. Importa órdenes directamente, eliminando redundancias y mejorando la eficiencia operativa"
            },
            {
                image: "",
                title: "Importa tus archivos Excel de forma fácil",
                text: "Simplifica la importación de datos. Configura el sistema para interpretar diversos formatos Excel, permitiéndote importar archivos de manera sencilla y rápida. Flexibilidad y comodidad en la gestión de información."
            },
            {
                image: "",
                title: "Administra las bodegas desde donde salen tus entregas",
                text: "Controla eficientemente el origen de tus entregas. Gestiona las bodegas desde donde se despachan los productos, optimizando rutas y asegurando una distribución logística efectiva y coordinada."
            },
            {
                image: "",
                title: "Importa las órdenes automáticamente y en tiempo real desde tu plataforma ecommerce",
                text: "Agiliza tu operación conectando directamente con tu plataforma ecommerce. Importa órdenes de forma automática y en tiempo real, eliminando demoras y garantizando una respuesta rápida a la demanda del mercado"
            },
        ]
    },
    {
        slug: "proveedores",
        title: "Proveedores de transporte",
        text: "Acceso a listado de proveedores logísticos integrados y proveedores líderes en el mercado.",
        slides: [
            {
                type: "banner-principal",
            }
        ],
        links: [
            {
                title: "Proveedores LastMile",
                icon: LiaShippingFastSolid,
                link: rutas.logistic_providers.slug
            },
            {
                title: "Proveedores Externos",
                icon: FaShippingFast,
            },
        ],
        areas:[
            {
                image: "",
                title: "Proveedores LastMile",
                text: "Explora un ecosistema completo de opciones. Descubre métricas de rating, opiniones de usuarios y servicios detallados de cada proveedor. Toma decisiones informadas y optimiza tu cadena de suministro"
            },
            {
                image: "",
                title: "Proveedores Externos",
                text: "Accede a las tarifas de los principales proveedores del mercado y optimiza tu logística. Gestiona y rastrea tus órdenes de manera eficiente, todo desde una plataforma integral que simplifica tu operación y maximiza la colaboración con tus socios de transporte. Con nuestra solución, la gestión de proveedores se convierte en un proceso fluido y eficaz."
            },
        ]
    },
    {
        slug: "flota",
        title: "Administrador de flota",
        text: "Administrar y gestionar todos los vehículos de tu flota. Identificar conductores y asignar permisos para App Conductor",
        slides: [
            {
                type: "banner-principal",
            }
        ],
        links: [
            {
                title: "Mi flota",
                icon: BsTruckFront,
                link: rutas.vehiculos.slug
            },
            {
                title: "Monitoreo",
                icon: PiMonitorPlay,
                link: rutas.actividad_flota.slug
            },
            {
                title: "Categorías",
                icon: BiCategoryAlt,
                link: rutas.proveedores.slug
            },
        ],
        areas:[
            {
                image: "",
                title: "Administra y gestiona tu flota vehicular de manera integral",
                text: "Simplifica la gestión de todos tus vehículos con una plataforma que brinda control total y eficiencia operativa."
            },
            {
                image: "",
                title: "Supervisa y gestiona a tus conductores de flota de manera efectiva",
                text: "Optimiza la administración de conductores con herramientas que facilitan la asignación, seguimiento y evaluación del rendimiento."
            },
            {
                image: "",
                title: "Eficientes protocolos de entrega con la App Conductor",
                text: "Implementa protocolos de entrega efectivos mediante nuestra aplicación para conductores, mejorando la precisión y la rapidez en cada entrega."
            },
            {
                image: "",
                title: "Construye un sistema de notificaciones e integraciones en tiempo real",
                text: "Desarrolla un flujo de comunicación dinámico. Recibe notificaciones instantáneas y logra integraciones en tiempo real con eventos emitidos por conductores, mejorando la visibilidad y la toma de decisiones."
            },
        ]
    },
    {
        slug: "clientes",
        title: "Gestiona tus clientes",
        text: "Gestiona todo lo relaciondo con los servicios que prestas a través de Last Mile, desde tus clientes hasta la facturación.",
        slides: [
            {
                type: "banner-principal",
            }
        ],
        links: [
            {
                title: "Mi clientes",
                icon: MdOutlineBusinessCenter,
                link: rutas.clientes.slug
            },
            {
                title: "Facturación",
                icon: BiMoneyWithdraw,
                link: rutas.facturacion.slug
            },
        ],
        areas:[
            {
                image: "",
                title: "Comparte las tarifas de tus servicios",
                text: "Simplifica la gestión tarifaria. Asigna y administra tarifas para tus clientes de manera eficiente directamente desde nuestra plataforma integral."
            },
            {
                image: "",
                title: "Facturación",
                text: "Centraliza la facturación en un módulo dedicado. Accede a detalles de facturación para cada cliente. Simplifica la gestión financiera y optimiza el proceso de facturación desde nuestra plataforma integrada."
            },
        ]
    },
    {
        slug: "rutas",
        title: "Rutas de entregas",
        text: "Crear, administrar y gestionar rutas con sus órdenes de entrega asociadas. Permite visualizar tu operación completa.",
        slides: [
            {
                type: "banner-principal",
            }
        ],
        links: [
            {
                title: "Mi rutas",
                icon: LiaRouteSolid,
                link: rutas.rutas.slug
            },
            {
                title: "Solicitudes",
                icon: BsShare,
                link: rutas.rutas_asignaciones.slug
            },
        ],
        areas:[
            {
                image: "",
                title: "Crea, administra y gestiona rutas con sus entregas asociadas",
                text: "Simplifica la logística con herramientas que facilitan la planificación y ejecución de rutas, optimizando la eficiencia operativa."
            },
            {
                image: "",
                title: "Visualiza tu operación completa con un solo vistazo:",
                text: "Accede a una visión integral de tu operación logística. Monitoriza y gestiona rutas y entregas desde una interfaz intuitiva y centralizada."
            },
            {
                image: "",
                title: "Ordena rutas según ubicaciones geográficas",
                text: "Optimiza la distribución geográfica de tus entregas. Organiza rutas de manera eficiente para minimizar tiempos y costos, mejorando la calidad del servicio."
            },
            {
                image: "",
                title: "Comparte tus rutas",
                text: "Podrás compartir tus rutas con otros proveedores, cotizarlas en base a tarifas pactadas o simplemente compartir un enlace público para que otros puedan acceder a ellas."
            },
        ]
    },
    {
        slug: "bodega",
        title: "Bodega",
        text: "Gestiona tus centros de distribución desde un solo lugar",
        slides: [
            {
                type: "banner-principal",
            }
        ],
        links: [
            {
                title: "Mis bodegas",
                icon: TbBuildingWarehouse,
                link: rutas.bodega_bodegas.slug
            },
            {
                title: "Expediciones de escaneo",
                icon: BsUpcScan,
                link: rutas.bodega_expediciones.slug
            },
            {
                title: "Etiquetas",
                icon: AiOutlineBarcode,
                link: rutas.etiquetas.slug
            },
        ],
        areas:[
            {
                image: "",
                title: "Administra todos tus centros de distribución",
                text: "Simplifica la gestión logística con nuestra plataforma integral. Desde un solo lugar, supervisa y optimiza todos tus centros de distribución, mejorando la eficiencia operativa y garantizando un control total"
            },
            {
                image: "",
                title: "Controla toda la operación dentro de cada bodega en tiempo real",
                text: "Aumenta la visibilidad operativa con nuestro sistema en tiempo real. Monitorea cada actividad dentro de tus bodegas mediante escaneos de la app, permitiendo un control preciso y una toma de decisiones informada en todo momento"
            },
            {
                image: "",
                title: "Identifica cada producto para obtener trazabilidad en la bodega",
                text: "Asegura la trazabilidad de tus productos desde el almacenamiento hasta la entrega. Con nuestra tecnología, identifica cada producto o bulto en la bodega, permitiendo un seguimiento detallado que garantice la calidad y la transparencia en toda la cadena de suministro."
            },
        ]
    },
    {
        slug: "integraciones",
        title: "Integraciones",
        text: "Facilitamos la integración sin esfuerzo de tu sistema o plataforma con el nuestro. Aprovecha nuestras diversas herramientas para una conexión fluida y eficiente, expandiendo así las posibilidades y funcionalidades de tu aplicación de manera sencilla y versátil",
        slides: [
            {
                type: "banner-principal",
            }
        ],
        links: [
            {
                title: "API Keys",
                icon: AiOutlineCode,
                link: `${rutas.integraciones.slug}/?tab=apikeys`
            },
            {
                title: "Webhooks",
                icon: MdOutlineWebhook,
                link: `${rutas.integraciones.slug}/?tab=webhooks`
            },
            {
                title: "Tracking embedido",
                icon: PiFileHtmlDuotone,
                link: `${rutas.integraciones.slug}/?tab=widgets`
            },
            {
                title: "Funciones",
                icon: BsCodeSlash,
                link: `${rutas.integraciones.slug}/?tab=functions`
            },
            {
                title: "Endpoints",
                icon: MdSyncAlt,
                link: `${rutas.integraciones.slug}/?tab=apiless`
            },
            {
                title: "Plugins",
                icon: BsPlugin,
                link: `${rutas.integraciones.slug}/?tab=plugins`
            },
        ],
        areas:[
            {
                image: "",
                title: "Conecta tu sitio web con nuestros plugins",
                text: "Optimiza tu sitio web con nuestra gama de plugins personalizables. Conéctalos fácilmente para mejorar la experiencia del usuario y potenciar las funcionalidades de tu plataforma de manera rápida y eficiente"
            },
            {
                image: "",
                title: "Conecta tu sitio web o sistema usando nuestra API",
                text: "Desbloquea todo el potencial de tu sistema integrando nuestra API. Conéctate de manera segura y eficiente, permitiendo una comunicación fluida entre plataformas y brindando a tus usuarios una experiencia sin interrupciones."
            },
            {
                image: "",
                title: "Integra un tracking personalizado en tu sitio web",
                text: "Proporciona a tus clientes información en tiempo real sobre la ubicación de sus envíos. Integra un sistema de seguimiento personalizado en tu sitio web para ofrecer una experiencia de rastreo transparente y confiable"
            },
        ]
    }
]

const ActividadLanding = () => {
    const route = useParams()
    const { slug } = route
    if(!slug) return <Navigate to="/" />

    const pos_data = data_landing.findIndex(d => d.slug === slug)
    if(pos_data < 0) return <Navigate to="/" />

    const info = data_landing[pos_data]

    return (
        <>
            {/* NAVIGATION */}
            <nav className="navbar navbar-expand-lg navbar-dark navbar-custom bg-primary d-flex justify-content-between" style={{ width: '100%' }}>
                    <div>
                        <Link to='/' className="navbar-brand" style={{ fontSize: 16 }}>
                        <BiLeftArrowAlt size={20} /> Volver a inicio</Link>
                    </div>
            </nav>
            {/* HEADER */}
            <div className="header-product masthead text-center text-white" style={{ width: '100%', padding: 50 }}>
                <div className="masthead-content">
                    <Row className='justify-content-center'>
                        <Col md={8} xs={12}>
                        <h1 className="masthead-heading mb-0" style={{ fontWeight: 900, fontSize: 50 }}>{info.title}</h1>
                        <h2 className="masthead-subheading mb-3" style={{ fontWeight: 400, fontSize: 20 }}>{info.text}</h2>
                        {
                            info.links ? <Row className='justify-content-center'>
                                {
                                    info.links.map((item,it) => {
                                        const styleIcon = { size: 30, color: "white" }
                                        const Icono = item.icon ? item.icon : false
                                        return <Col md={2} key={`item-${it}`} className='mb-3'>
                                                <Link to={`/${item.link}`} className='text-white'>
                                                {Icono ? <Icono {...styleIcon} /> : false}
                                                <h5 style={{ fontSize: 15 }} className='mb-0 mt-2'>{item.title}</h5>
                                                </Link>
                                        </Col>
                                    })
                                }
                            </Row> : false
                        }
                        </Col>
                    </Row>
                </div>
            </div>
            {/* SECTIONS */}
            {
                info.areas.map((area,ia) => {
                    const derecha = ia%2 ? true : false
                    const fontSizeTitle = 35
                    return <section id="scroll" key={`area-${ia}`}>
                    <div className="container px-0">
                    <div className="row gx-5 align-items-center">
                            <div className="col-lg-6 ">
                                <div className="p-5">
                                    <h2 className="display-4" style={{ fontWeight: 700, fontSize: fontSizeTitle }}>{area.title}</h2>
                                    <p>{area.text}</p>
                                </div>
                            </div>
                            <div className="col-lg-6" style={{ order: derecha ? -1 : 1 }}>
                                    <div className="p-5">
                                        { area.image ? <img className="img-fluid rounded" src={`${url_images}/landing-assets/ITAINNOVA-APROVISIONAMIENTO-DESTACADA.png`} alt="Imagen1" /> : false }
                                    </div>
                            </div>
                    </div>
                    </div>
                </section>
                })
            }
            {/* FOOTER */}
            <footer className="py-5 bg-black">
                <div className="container px-0"><p className="m-0 text-center text-primary small">Copyright &copy; Last-Mile 2023</p></div>
            </footer>
        </>
    )
};

export default ActividadLanding;