import { Card } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Header from '../../../../components/Header'
import FormularioLoteTarifa from './formulario_lote'

const CrearLoteTarifas = (props) => {
  const session = useSelector(state => state.miusuario)
  const pais = useSelector(state => state.pais)
    
        return <div className="fluid">
                    <h1 className="h2">Crear lote de tarifas</h1>
                    <FormularioLoteTarifa />
            </div>
}

export default CrearLoteTarifas