import { Card, Col, OverlayTrigger, ProgressBar, Row, Tooltip } from "react-bootstrap"
import GraficoVehiculo from "../actividad/flota/graficos_vehiculo"
import { obtenerRangos } from "../../lib/helpers/opcionesfecha"

const GraficoVehiculoProveedor = (props) => {
    const {
        rango_fechas
    } = props
    const graficos = props.graficos ? props.graficos : []
    const colores = props.colores ? props.colores : []
    const division = 6
    if(graficos.length < 1) return false
    
    const cabeceras = (datos) => {
        if(!datos) return false
        if(Array.isArray(datos) !== true) return false
        if(datos.length < 1) return false
        return datos[0]
    }
    
    const obtenerPorcentajes = (datos) => {
        if(!datos) return false
        if(Array.isArray(datos) !== true) return false
        if(datos.length < 1) return false

        let excluir_primero = datos.map((d,i) => i > 0 ? d : false)
        excluir_primero = excluir_primero.filter(d => d)
        let valores = []
        excluir_primero.map((d,pos) => {
            if(Array.isArray(d)){
                d.map((info,p) => {
                    if(!p) return false
                    if(p === (d.length-1)) return false // ES EL ULTIMO DEL ARRAY OSEA EL ID CONDUCTOR
                    if(typeof valores[p] === "undefined"){
                        valores.unshift(info)
                    } else {
                        valores[p] = Math.max(valores[p], info)
                    }
                })
            }
        })
        return valores
    }

    const estados = cabeceras(graficos)
    const porcentajes = obtenerPorcentajes(graficos)


    const calcularPorcentaje = (valor, total) => {
        const calculo = valor / total * 100
        return isNaN(calculo) ? 0 : calculo
    }

    const calcularColor = (pos) => {
        const color_default = "#383838"
        if(!colores) return color_default
        if(Array.isArray(colores) !== true) return color_default
        if(colores.length < 1) return color_default
        if(!colores[pos]) return color_default
        return colores[pos]
    }

    const calcularEstado = (pos) => {
        const dato_default = ""
        if(!estados) return dato_default
        if(Array.isArray(estados) !== true) return dato_default
        if(estados.length < 1) return dato_default
        if(!estados[pos]) return dato_default
        return estados[pos]
    }

    return <div>
        <Row>
        {
            graficos.map((dato,id) => {
                if(!id) return false
                let maximo              = 0
                let divisiones          = [...Array(division).keys()]
                const idconductor      = dato[(dato.length-1)]
                const { desde, hasta } = obtenerRangos(rango_fechas.startDate, rango_fechas.endDate)
                const condicion_vehiculo = { $or:[
                    { fecha_min_entrega: { $gte: desde, $lte: hasta }, idconductor },
                    { fecha_min_entrega: { $lte: hasta, $gte: desde }, idconductor },
                    { fecha_max_entrega: { $gte: hasta }, fecha_min_entrega: { $lte: desde }, idconductor},
                ]
                }

                return <Col md={6} key={`grafico-${id}`} className="mb-3">
                    
                    {
                        dato.map((info,i) => {
                            if(i) return false
                            return <Card className="p-3" key={`inf-${i}`}>
                                <p style={{ fontSize:13 }} className="mb-0">Vehículo</p>
                                <h6>{info}</h6>
                                <table>
                                {
                                    estados.map((est,is) => {
                                        if(!is) return false
                                        const valor = dato[is]
                                        const mayor = porcentajes.reduce((prev, next) => {
                                            return Math.max(prev, next)
                                        },0)
                                        maximo = Math.max(mayor,maximo)
                                        const percentage = calcularPorcentaje(valor, mayor)

                                        return <tr key={`estadoc-${is}`} >
                                            <td style={{ width: "17%" }}><p style={{ fontSize: 10, marginBottom: 6.5 }}><i style={{ color: calcularColor(is-1) }} className="fa-sharp fa-solid fa-square"></i> {est.substring(0,10)}{est.length > 10 ? "..." : ""}</p></td>
                                            <td><OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                          <Tooltip>{calcularEstado(is)}</Tooltip>
                                        }
                                        ><div style={{ background: "#e9ecef", height: "1.3rem", width: "100%" }}>
                                            {
                                                valor ? <div style={{ height: "1.3rem", minWidth: "3%", background: calcularColor(is-1), width: `${percentage > 100 ? 100 : percentage}%`, textAlign: "center", color: "white", fontSize:12 }}>{valor}</div>  : false
                                            }
                                        </div>
                                        </OverlayTrigger></td>
                                        </tr>
                                    })
                                }
                                </table> 
                                <div className="mb-3"></div> 
                                <GraficoVehiculo condicion_default={condicion_vehiculo}  />                         
                            </Card>
                        })
                    }
                </Col>
            })
        }
        </Row>
    </div>
}

export default GraficoVehiculoProveedor