import React, { useEffect, useState } from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Chart from "react-google-charts";
import { estilo_last_mile } from '../../lib/global/styles';

const GraficosMesLastMile = (props) => {
    const {
        color,
        titulo
    } = props
    const [ ordenes, setOrdenes ] = useState( props.datos ? props.datos : false)
    const height = props.height ? props.height : '300px'
    const colors = props.colors ? props.colors : ['#007bff']
    
    useEffect(() => {
        setOrdenes(props.datos)
    }, [ props.datos ])
    
    if(!ordenes) return false
      if(!ordenes.dias) return <div className='p-5'>
      <Row className="justify-content-md-center">
          <Col md={6} className="text-center">
          <h4>No hay datos suficientes para visualizar esta sección</h4>
          </Col>
      </Row>
      </div>

        const cantidad_columnas = 100 / ordenes.dias.length
        const color_highlight = color ? color : estilo_last_mile.color_primary
        let valorMaximo = 0
        for (let i = 0; i < ordenes.dias.length; i++) {
            if (ordenes.dias[i].cantidad > valorMaximo) {
              valorMaximo = ordenes.dias[i].cantidad;
            }
          }

          const porcentajeMaximo = (valorMaximo / valorMaximo) * 100;

          
    const seleccionarDia = (data) => {
        if(props.onClick) props.onClick(data)
    }

      return <div className='mt-4'>
        {
            ordenes.dias.map(fecha => {

                const porcentaje = ((fecha.cantidad / valorMaximo) * 100).toFixed(0)
                const porcentajeDelMaximo = (porcentaje / porcentajeMaximo) * 100;

                const barra = <div className='hover' style={{ borderTopLeftRadius:5, borderTopRightRadius: 5, height: porcentajeDelMaximo > 0 ? Math.max(porcentajeDelMaximo, 25) : 100, backgroundColor: porcentajeDelMaximo > 0 ? color_highlight : "#e0e0e0", width: "60%", margin: '0 auto' }}></div>

                return <div style={{ width: `${cantidad_columnas}%`, display: "inline-block" }} className='text-center' onClick={() => seleccionarDia(fecha)}>
                  <p style={{ fontSize: 9, color: "white", marginBottom: -20 }}>{fecha.cantidad}</p>
                  {
                    fecha.cantidad ? <OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>{fecha.cantidad} {titulo}</Tooltip>
                    }
                    >
                      {barra}
                    </OverlayTrigger> : barra
                  }                    
                    <p className='mb-0' style={{ fontSize:10 }}>{fecha.dia}</p>
                </div>
            })
        }
      </div>
}

export default GraficosMesLastMile