import { Card, Col, Row } from 'react-bootstrap'
import Header from '../Header/index.js';
import 'react-toastify/dist/ReactToastify.css'
import { useSelector } from 'react-redux'
// import Structure from '../Structure'
import "../Home/home.css"
import { Link } from 'react-router-dom';
import { rutas } from '../../lib/routes/routes.js';

const Home = (props) => {
  const session = useSelector(state => state.miusuario)
  const pais = useSelector(state => state.pais)
  const itemBox = [
    {
      titulo: "Cuenta",
      descripcion: "Cambiar datos generales, cambiar nombre, cambiar contraseña, configurar permisos.",
      itemIcon: "../images/sidebar/inicio.svg",
      slug: "cuenta"
    },
    {
      titulo: "Actividad",
      descripcion: "Visualizar dashboard de control de tu operación. Administrar entregas, conductores, estados de carga, rendimiento de proveedores, etc.",
      itemIcon: "../images/sidebar/actividad.svg",
      slug: "actividad"
    },
    {
      titulo: "Órdenes",
      descripcion: "Crear, modificar e importar órdenes de entrega. Importar desde Excel e importación avanzada.",
      itemIcon: "../images/sidebar/ordenes.svg",
      slug: "ordenes"
    },
    {
      titulo: "Proveedores",
      descripcion: "Acceso a listado completo de proveedores logísticos disponibles. Filtrar por tipo de servicios.",
      itemIcon: "../images/sidebar/proveedores.svg",
      slug: "proveedores"
    },
    {
      titulo: "Flota",
      descripcion: "Administrar y gestionar todos los vehículos de tu flota. Identificar conductores y asignar permisos para App Conductor.",
      itemIcon: "../images/sidebar/flota.svg",
      slug: "flota"
    },
    {
      titulo: "Clientes",
      descripcion: "Acceso a listado de clientes registrados.",
      itemIcon: "../images/sidebar/clientes.svg",
      slug: "clientes"
    },
    {
      titulo: "Rutas",
      descripcion: "Crear, administrar y gestionar rutas con sus órdenes de entrega asociadas. Permite visualizar tu operación completa.",
      itemIcon: "../images/sidebar/rutas.svg",
      slug: "rutas"
    },
    {
      titulo: "Bodegas",
      descripcion: "Crear bodegas, centros de distribución, expediciones y lotes de etiquetas para tus pedidos.",
      itemIcon: "../images/sidebar/bodega.svg",
      slug: "bodega"
    },
    {
      titulo: "Integraciones",
      descripcion: "Llaves de API, Webhooks, Widgets, Funciones, Conexiones sin API y plugins con Beetrack, JumpSeller, Shopify, Woocommerce y Mercado Libre.",
      itemIcon: "../images/sidebar/integraciones.svg",
      slug: "integraciones"
    }
  ]


  // const render = () => {
  return (
    <>
      <div style={{ padding: 50 }} className='homepage'>
        <Row className='mb-3 '>
          <Col xs={6}>
          <span style={{ fontWeight: 900, fontSize: 30, verticalAlign: "middle", color: '#1B3665' }}>
            ¡BIENVENIDO! 
          </span>
          </Col>
          <Col xs={6} className='text-right '>
          <img
              alt="QuestionIcon"
              src="images/sidebar/Question.svg"
              style={{ height: 35 }} />
          </Col>
        </Row>
        <Row>
          {itemBox.map((box, i) => {
            // return false
            return <Col key={`item-${i}`} md={4}>
              <Link to={`/${rutas.landing.actividad.slug}/${box.slug ? box.slug : ""}`}>
                <Card className='itemBox p-3 mb-3' style={{ minHeight:150 }}>
                  <Row>
                    <Col xs={3}>
                      <img alt='item-icon' style={{ width: "100%", padding: 3 }} src={box.itemIcon} />
                    </Col>
                    <Col xs={9} style={{ paddingLeft: 0 }}>
                      <span style={{ fontWeight: 700, fontSize: 22, color: '#1B3665' }}>
                        {box.titulo}
                      </span>
                      <p className='mt-0' style={{ fontWeight: 400, fontSize: 13, color: '#1B3665' }}>
                        {box.descripcion}
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Link>
            </Col>
          })}
        </Row>
        {/* <p className='version text-center' style={{ fontWeight: 400, fontSize: 14, color: '#1B3665', marginTop: 25 }}>VERSIÓN 4.1</p> */}
      </div>
    </>
  )
}


export default Home