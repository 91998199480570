import React, { useState} from 'react'
import { useSelector } from 'react-redux'
import { Nav, Tab } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import ApiKeysListado from '../../subComponents/integraciones/apikeys/apikeys_listado'
import Webhooks from '../../subComponents/integraciones/webhooks/webhooks'
import WidgetsListado from '../../subComponents/integraciones/widgets/widgets_listado'
import PluginsListado from '../../subComponents/integraciones/plugins/plugins_listado'
import ApiLess from '../../subComponents/integraciones/apiless'
import FuncionesListado from '../../subComponents/integraciones/funciones/listado'
import CourriersListado from '../../subComponents/integraciones/courriers/listado'
import { useQuery } from '../../lib/helpers/router'

const Integraciones = (props) => {  

    const user = useSelector(state => state.miusuario)
    const location = useLocation()
    const [ selectedTab, setSelectedTab ] = useState('')
    const navigate = useNavigate();
    const query = useQuery()
    let default_key = 'apikeys'
    if(query.get("tab")) default_key = query.get("tab")

    const handleChangeTab = (e) => {
      const newTab = e;
      const queryParams = new URLSearchParams();
      queryParams.set('tab', newTab);
      navigate({
        pathname: location.pathname,
        search: `?${queryParams.toString()}`,
      });
      return setSelectedTab(newTab);
    }

      return <div className="fluid">
        <h1 className="h2">Integraciones</h1>
        <Tab.Container id="left-tabs-example" defaultActiveKey={default_key} >
      <Row>
        <Col sm={3}>
          <Nav variant="pills" className="flex-column" onSelect={handleChangeTab}>
            <Nav.Item><Nav.Link eventKey="apikeys">Llaves de API</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link eventKey="webhooks">Webhooks</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link eventKey="widgets">Widgets</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link eventKey="functions">Funciones</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link eventKey="apiless">Conexión sin API</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link eventKey="plugins">Plugins</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link eventKey="courriers">Courriers</Nav.Link></Nav.Item>
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
          <Tab.Pane eventKey="apikeys"  >
                <ApiKeysListado trash={true} token={user.tokenSession} condicion_default={{ status: 'trash' }} />
            </Tab.Pane>
            <Tab.Pane eventKey="webhooks">
                <Webhooks token={user.tokenSession} />
            </Tab.Pane>
            <Tab.Pane eventKey="apiless">
                <ApiLess token={user.tokenSession} />
            </Tab.Pane>
            <Tab.Pane eventKey="functions">
                <FuncionesListado token={user.tokenSession} />
            </Tab.Pane>
            <Tab.Pane eventKey="widgets">
                <WidgetsListado token={user.tokenSession} />
            </Tab.Pane>
            <Tab.Pane eventKey="plugins">
                <PluginsListado token={user.tokenSession} />
            </Tab.Pane>
            <Tab.Pane eventKey="courriers">
                <CourriersListado />
            </Tab.Pane>
          </Tab.Content>
        </Col>
        </Row>
      </Tab.Container>
    </div>

}

export default Integraciones