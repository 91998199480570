import { Tab, Tabs } from "react-bootstrap"
import Header from "../../../components/Header"
import ListadoEstadosCargaTiempoReal from "./listado_tiempo_real"

const ActividadEstadosCarga = (props) => {

    return <div>
       <h3 className="mb-3">Estados de carga</h3>
      <ListadoEstadosCargaTiempoReal />
    </div>
}

export default ActividadEstadosCarga