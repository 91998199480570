import { useState } from "react"
import { Button, Col, Row, Spinner } from "react-bootstrap"
import { toast } from "react-toastify"
import TiposServicioSelector from "../../../ordenes/tipos_servicio/selector"
import SelectorServicios from "../../../servicios/selector"
import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../../../lib/backend/data"
import { cerrarSesion } from "../../../../redux/actions/session"

const FormularioRangos = (props) => {
    const {
        pais_code,
        propietario,
        servicio,
        rut_cliente
    } = props
    const [ loadingUpload, setLoadingUpload ] = useState(false)
    const [ rangos, setRangos ] = useState([])
    const [ disablePesoDesde, setDisablePesoDesde ] = useState(false)
    const [ peso_desde, setPesoDesde ] = useState(0)
    const [ peso_hasta, setPesoHasta ] = useState('')
    const dispatch = useDispatch()
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession

    const handleChangePesoDesde = (e) => {
        const { value } = e.target
        const numero = value.replace(/[^\d]/g, "");
        return setPesoDesde(parseFloat(numero))
    }
    const handleChangePesoHasta = (e) => {
        const { value } = e.target
        const numero = value.replace(/[^\d]/g, "");
        return setPesoHasta(parseFloat(numero))
    }

    const agregarRango = () => {
        if(peso_desde===0 && peso_hasta === 0) return toast.error(`Valores de rango inválidos`)
        if(peso_desde===peso_hasta) return toast.error(`Valores de rango inválidos`)

        const titulo = `rango${rangos.length+1}`

        const buscar_repetido = rangos.filter(ra => ra.peso_desde === peso_desde && ra.peso_hasta === peso_hasta-1)

        if(buscar_repetido.length > 0) return toast.error(`Este rango que intentas agregar está repetido`)
        setDisablePesoDesde(true)
        rangos.push({
            titulo,
            peso_desde,
            peso_hasta: peso_hasta-1,
            tipo:'simple',
            propietario,
            propietario_cliente: rut_cliente,
            pais_code
        })

        setPesoDesde(peso_hasta)
        setPesoHasta(peso_hasta)
        return setRangos(prev => [...[], ...rangos])
    }

    const guardarRangosPendientes = async () => {
        if(!servicio) return toast.error("Selecciona el tipo de servicio")
        const rangos_preparados = JSON.parse( JSON.stringify(rangos) ).map(ra => ({...ra, id_tipo_servicio: servicio._id}))
        setLoadingUpload(true)
        return fetch(`${urlapi}/pricing/rangos`,{
            method:'POST',
            body:JSON.stringify({
                rangos: rangos_preparados
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                if(res.length === rangos_preparados.length){
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                    return toast.success("Creado exitosamente")
                }
            }
            return setLoadingUpload(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingUpload(false)
        })
    }

    const reiniciarRangos = () => {
        setPesoDesde(0)
        setPesoHasta('')
        setDisablePesoDesde(false)
        return setRangos([...[], ...[]])
    }

    const ifRangosSinGuardar = () => {
        if(loadingUpload) return <Spinner animation="border" />

          const pendientes_de_guardar = rangos.filter(ra => !ra._id)
          if(pendientes_de_guardar.length > 0) return <div>
                <Button size="sm" variant="outline-danger" className="mr-3" onClick={() => reiniciarRangos()}>BORRAR TODOS</Button>
              { loadingUpload ? <Spinner animation="border" /> : <Button size="sm" variant="success " onClick={()=>guardarRangosPendientes()}>GUARDAR RANGOS</Button> }
          </div>
    }

    return <div>
        <Row>
            <Col md={3}>
                <label className="form-control-label">Peso desde (Gramos)</label>
                <input disabled={true} className="form-control" name="peso_desde" type="number" value={peso_desde} />
            </Col>
            <Col md={3}>
                <label className="form-control-label">Peso hasta (Gramos)</label>
                <input className="form-control" name="peso_hasta" type="number" value={peso_hasta} onChange={handleChangePesoHasta} />
            </Col>
            <Col md={3}>
                <label className="form-control-label d-block">Click para agregar</label>
                <Button  onClick={()=>agregarRango()}>AGREGAR RANGO</Button>
            </Col>

            <Col md={12} className="mt-3">
                {ifRangosSinGuardar()}
            </Col>

            <Col md={12} className="mt-3">
            <Row>
                    
                {  rangos.map((rango,irango) => {
                    return <div key={`rango${irango}`} className="col-md-3 mb-3">
                        <div className="card card-body">
                        {rango._id ? false : <span className="text-danger" style={{ fontSize: 11 }}>PENDIENTE DE GUARDAR</span> }
                        <h5 className="nomargin">{rango.titulo}</h5>
                        <h4 className="nomargin"><b>Desde: </b> {(rango.peso_desde/1000).toFixed(0)} <b style={{ fontSize: 15, fontWeight: "lighter", color: "gray" }}>Kg.</b></h4>
                        <h4 className="nomargin"><b>Hasta:</b> {(rango.peso_hasta/1000).toFixed(0)} <b style={{ fontSize: 15, fontWeight: "lighter", color: "gray" }}>Kg.</b></h4>
                        </div>
                    </div>
                })}
                </Row>
            </Col>
        </Row>
    </div>
}

export default FormularioRangos