import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Chart from "react-google-charts";
import { procesarDatosGraficos } from '../../lib/helpers/flota';

const GraficosOrdenes = (props) => {

    const ordenes  =  props.ordenes ? props.ordenes : []
    const height = props.height ? props.height : '300px'

    if(!ordenes) return false

    if(Array.isArray(ordenes) !== true) return false

    if(ordenes.length < 1) return <div className='p-5'>
    <Row className="justify-content-md-center">
      <Col md={6} className="text-center">
        <img src="images/pedidos.png" style={{ width: "50%" }} />
        <h4>No hay datos suficientes para visualizar esta sección</h4>
      </Col>
    </Row>
    </div>

    const graficos = procesarDatosGraficos(ordenes)

    if(graficos.datos.length < 1) return <h3>Gráficos no disponibles</h3>

    return <div className='p-3 bg-white'>
        <Chart
      width={'100%'}
      height={height}
      chartType="Bar"
      onLoad={(data) => console.log(data)}
      loader={<div>Cargando</div>}
      errorElement={<div>Error</div>}
      data={graficos.datos}
      options={{
        chart: {
          title: `Reporte`,
          subtitle: 'Estados de carga',
        },
        colors: graficos.colores
      }}
    />
    </div>
}

export default GraficosOrdenes