import { useEffect, useState } from "react"
import Select from 'react-select';
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';

const BuscadorUsuarios = (props) => {
    const [ usuarios, setUsuarios ] = useState([{ value:'all_access', label:'Todos los permisos'}])
    const [ loading, setLoading ] = useState(true)
    const label = props.label ? props.label : 'Usuarios'
    const multi = props.multi ? true : false
    const token = props.token ? props.token : false

    const handleChangeSelect = (e) => {
        if(props.onChange) return props.onChange(e)
        return
    }

    const obtenerUsuarios = async () => {
        return fetch(`${data.urlapi}/usuarios/list-full`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoading(false)
            } else if(Array.isArray(res) !== false){
                const opciones = res.map(u => ({ value: u._id, label: `${u.email} · ${u.nombres}` }))
                setUsuarios(prev => [...prev, ...opciones])
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    useEffect(() => {
        obtenerUsuarios()
    }, [])

    return <div>
        <label className="form-control-label">{label}</label>
        <Select 
                        style={{ marginBottom: 10 }}
                        onChange={handleChangeSelect}
                        options={usuarios}
                        isMulti={multi}
                        isLoading={loading}
                        noOptionsMessage={()=>'Sin opciones'}
                        placeholder="Selecciona un usuario..."
                    />
    </div>
}

export default BuscadorUsuarios