import { Col, Container, Row } from "react-bootstrap"

const PoliticasPrivacidad = () => {

    return <Container className="pt-5 pm-5">
                
    <Row className="justify-content-md-center">
        <Col md={12}>
        <img alt="logotipo" src="images/logo.png" style={{ width: 200 }} />
        <h4>Políticas de privacidad</h4>
        <p className='mb-5'>Estas políticas de privacidad (las "Políticas de Privacidad") regulan la forma en que Delpa Group, con domicilio legal en Santa Clara 301, Oficina 3802, Piso 3 comuna de Huechuraba, Santiago, Chile, ("DELPA GROUP"), tratará la información personal (la "Información Personal") y los datos personales (la "Datos personales") de los usuarios de la App Conductor, entendiendo por tales a los Consumidores, Delpa Group Conductores y/o visitantes de la Plataforma (el "Usuario" o los "Usuarios").<br/><br/>

Como parte normal de nuestras actividades, recopilamos información sobre nuestros Usuarios consumidores, Delpa Group Conductores y visitantes al sitio web (en adelante, el "Titular"), que acceden y utilizan nuestras aplicaciones y Plataformas (i) App Conductor; (ii) Soy Delpa Group – Sé un conductor (com.delpa.group.storekeeper);  (en adelante, de manera conjunta, las "Aplicaciones") y sitio web www.delpagroup.com (en adelante, el "Sitio Web"). Por tanto, en cada uno de los sistemas se encuentran las casillas correspondientes, las cuales deben ser leídas y aceptadas (según corresponda) para poder continuar con el uso de los servicios de Delpa Group.<br/><br/>

Estas Políticas de Privacidad describen, de forma detallada, la manera en que DELPA GROUP recoge, utiliza, protege y comparte los Datos Personales y la Información Personal de sus Usuarios, de conformidad con la Ley N°19.628 sobre Protección de la vida Privada.<br/><br/>

A través de la Transmisión o Transferencia de Datos Personales, Delpa Group podrá recolectar datos personales, como el número de teléfono y correo electrónico, utilizando APIs que podrán ser compartidos con terceros. Se aclara que estos terceros incluyen, pero no se limitan, al tercero denominado comercialmente como "Amplitude". Además, dentro del marco de las finalidades establecidas en esta Política de Tratamiento, Delpa Group podrá validar las demás aplicaciones instaladas en el dispositivo móvil del titular para asegurarse de que no haya otras aplicaciones que interfieran con la correcta presentación de los servicios ofrecidos por Delpa Group a través de sus distintas plataformas.<br/><br/>

Delpa Group se esforzará por garantizar que estas Políticas de Privacidad sean conocidas por todos los usuarios que accedan y utilicen la plataforma, asegurando su acceso.<br/><br/>

Estas Políticas de Privacidad entrarán en vigencia desde su aceptación por parte del usuario y estarán vigentes hasta que el usuario revoque la autorización para el tratamiento de sus datos. La revocación siempre deberá realizarse por escrito y no tendrá efecto retroactivo, sin perjuicio de que el usuario pueda solicitar la eliminación total de sus datos.<br/><br/>

Delpa Group podrá modificar estas Políticas de Privacidad en cualquier momento, notificando a los usuarios a través del envío de correos electrónicos, mensajes de texto (SMS), WhatsApp u otras aplicaciones de comunicación al teléfono celular, adjuntando la versión actualizada de las Políticas de Privacidad. Además, informará sobre la actualización de las Políticas de Privacidad en la página principal u otras secciones de la plataforma, con el fin de mantener a los usuarios siempre actualizados. Según lo establecido por la ley aplicable, al utilizar la plataforma después de haber recibido dicha notificación, se entenderá que el usuario acepta las actualizaciones de las Políticas de Privacidad.<br/><br/>

Los usuarios siempre podrán decidir si aceptan o no las modificaciones a las Políticas de Privacidad. En caso de que el usuario no acepte las nuevas Políticas de Privacidad, se disolverá el vínculo entre este y Delpa Group, y la información personal y datos personales de dicho usuario no se utilizarán de ninguna otra manera que no esté de acuerdo con las Políticas de Privacidad debidamente aceptadas. Además, si el usuario lo solicita, dicha información podrá ser eliminada de manera permanente de los registros de Delpa Group.<br/>

Lea detenidamente estas Políticas de Privacidad antes de acceder, utilizar o registrarse en la plataforma.</p>
<h4>PRIMERO. Recopilación de los Datos Personales y de la Información Personal</h4>
<p>1.1 Para utilizar la Plataforma, los Usuarios deben completar un registro (el “Registro”) con Datos Personales tales como nombre completo, número de cédula nacional de identidad o rol único tributario, dirección de correo electrónico, domicilio particular, número de teléfono y otros que le puedan ser solicitados y sean necesarios para el correcto uso de la Plataforma. Podría ocurrir que la plataforma no le solicite todos estos datos.</p>
<p>1.2 Asimismo, durante el uso de la Plataforma, los Usuarios proporcionarán a Delpa Group información relativa a sus intereses, gustos, contactos, incluyendo la grabación de llamados que los Usuarios puedan realizar al servicio de soporte técnico de la Plataforma (la “Información Personal”).</p>
<p>1.3 Los Usuarios asumen el compromiso de indicar en el Registro sus Datos Personales de manera exacta, precisa y verdadera, así como también la obligación de actualizar sus Datos Personales cada vez que ocurra una modificación de los mismos.</p>
<p>1.4 Los Datos Personales introducidos por el Usuario en el Registro deberán ser exactos, actuales y veraces en todo momento. Delpa Group se reserva el derecho a solicitar algún comprobante y/o dato adicional a efectos de corroborar los Datos Personales indicados en el Registro y de suspender, temporal y/o definitivamente, a aquel Usuario cuyos datos no hayan podido ser confirmados. No obstante lo anterior, Delpa Group no se responsabiliza por la certeza de los Datos consignados en el Registro, toda vez que los mismos son suministrados por el Usuario y son de su única y exclusiva responsabilidad. En este sentido el Usuario garantiza y responde, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de sus Datos Personales.</p>
<p>1.5 Los Datos Personales que el Usuario proporcione se integrarán en una base de datos personales de la que es responsable Delpa Group. Asimismo, la Información Personal de los Usuarios será recopilada y utilizada en la forma descrita en estas Políticas de Privacidad.</p>
        
<h4>SEGUNDO. Cuenta Personal.</h4>
<p>2.1 Una vez completado el Registro, Delpa Group otorgará al Usuario una cuenta personal para acceder a la Plataforma con la contraseña que dicho Usuario elija (la “Cuenta”). El Usuario accederá a su Cuenta mediante el ingreso de su apodo de usuario y clave de seguridad personal elegida.</p>
<p>2.2 La Cuenta es personal, única e intransferible, y está expresamente prohibido que un mismo Usuario se registre más de una vez o posea más de una Cuenta como Consumidor o como Conductor. En caso de que Delpa Group detecte o razonablemente considere que distintas Cuentas contienen datos coincidentes o relacionados, podrá cancelar, suspender o inhabilitar todas las Cuentas relacionadas sin que ello implique derecho a reclamo a alguno por parte de los Usuarios de dichas Cuentas, ya que podría ser considerado como un indicio de fraude.</p>
<p>2.3 El Usuario será el único responsable por el cuidado y buen uso de su Cuenta, así como de mantener su clave de acceso protegida. Si por cualquier razón un Usuario creyera que alguien puede conocer su clave de acceso, deberá modificarla ingresando a la opción de modificación dispuesta para tal fin en la Plataforma.</p>
<p>2.4 El Usuario que se registre en Delpa Group y mantenga una Cuenta, consiente expresamente que Delpa Group tenga acceso, en cualquier momento, a la totalidad de la información contenida en su Cuenta, incluyendo, en particular pero sin limitación alguna, a sus Datos Personales, e Información Personal sobre sus intereses, gustos, contactos y/o cualquier otro contenido alojado en su Cuenta y Registro.</p>

<h4>TERCERO. Uso de los Datos Personales y de la Información Personal</h4>
<p>3.1   A través de la aceptación de las Políticas de Privacidad, el Usuario declara que:

Autoriza a Delpa Group a recopilar, procesar, utilizar, almacenar y compartir sus Datos Personales, de acuerdo a estas Políticas de Privacidad y a la Ley Nº 19.628 de Protección de la Vida Privada.</p>

<p> Ordenar, catalogar, clasificar, dividir o separar la informaci&oacute;n suministrada;</p>

<p> Utilizar los datos suministrados en campa&ntilde;as de comunicaci&oacute;n, divulgaci&oacute;n, promoci&oacute;n u oferta de productos, actividades o servicios desarrollados como parte de estrategias internas de Delpa Group;</p>

<p> Utilizar los datos para fines administrativos internos o comerciales tales como: estudios de cr&eacute;dito, elaboraci&oacute;n y presentaci&oacute;n de cotizaciones, facturas, referencias comerciales de experiencia, investigaci&oacute;n de mercados, an&aacute;lisis estad&iacute;sticos, realizaci&oacute;n de encuestas sobre satisfacci&oacute;n;</p>

<p> Conservar registros hist&oacute;ricos de la compa&ntilde;&iacute;a y mantener contacto con los titulares del dato;</p>

<p> Verificar, comprobar o validar los datos suministrados;</p>

<p> Estudiar y analizar la informaci&oacute;n entregada para el seguimiento y mejoramiento del servicio y la atenci&oacute;n;</p>

<p> Recabar, tener, manejar y utilizar la informaci&oacute;n recibida por el titular de la informaci&oacute;n para realizar la vinculaci&oacute;n como cliente o proveedor;</p>

<p> Recabar, tener, manejar y utilizar la informaci&oacute;n para realizar control y prevenci&oacute;n de fraude, control y prevenci&oacute;n de lavado de activos y financiaci&oacute;n del terrorismo;</p>

<p> Celebrar, ejecutar y gestionar de las propuestas de negocios y contratos objeto de los servicios prestados;</p>

<p> Realizar, de conformidad con la ley, los reportes a centrales de riesgo por incumplimiento de las obligaciones financieras derivadas de la relaci&oacute;n comercial;</p>

<p> Realizar operaciones y gestiones relacionadas con la generaci&oacute;n y pago de facturas, emisi&oacute;n de certificaciones, contabilidad, etc;</p>

<p> Cumplir deberes y obligaciones legales;</p>

<p>Enviar comunicaciones a trav&eacute;s de cualquier canal incluyendo pero sin limitarse a, redes sociales, mensajes de texto, notificaciones push, correo electr&oacute;nico, llamada telef&oacute;nica, whatsapp o cualquier otra aplicaci&oacute;n de comunicaciones, a su tel&eacute;fono celular sobre publicidad y/o promociones que puedan ser de inter&eacute;s para los Usuarios, o informaci&oacute;n sobre los servicios de Delpa Group en general, que podr&aacute; incluir claves personales para el uso de la Plataforma, recordatorios de vencimientos de pagos y otras obligaciones adquiridas por el uso de la Plataforma. El Usuario siempre podr&aacute; solicitar que lo excluyan de las listas para el env&iacute;o de informaci&oacute;n promocional y/o publicitaria, mediante un correo electr&oacute;nico dirigido a contacto@delpagroup.com</p>

<p>Reconoce y acepta que Delpa Group revele, comparta o transfiera, total o parcialmente, sus Datos Personales e Informaci&oacute;n Personal con su matriz, personas relacionadas o empresas afiliadas o relacionadas con Delpa Group a cualquier t&iacute;tulo y en el momento, forma y condiciones que estime pertinente. En estos casos, Delpa Group velar&aacute; porque se cumplan ciertos est&aacute;ndares, mediante la firma de acuerdos o convenios cuyo objeto sea la privacidad de tales Datos Personales y de la Informaci&oacute;n Personal.</p>

<p>En particular, reconoce y acepta que Delpa Group revele, comparta o transfiera, total o parcialmente, sus Datos Personales e Informaci&oacute;n Personal con cualquiera de sus empresas relacionadas, con el objeto de sustentar y desarrollar la alianza de Delpa Group, mediante la cual se le ofrecer&aacute;n productos financieros a los usuarios/consumidores de Delpa Group, para lo cual se requiere el acceso a dicha informaci&oacute;n.</p>

<p>Reconoce que, en algunos casos, proveedores &ldquo;outsourcing&rdquo; de servicios podr&aacute;n recoger informaci&oacute;n directamente del Usuario, por ejemplo si se realizan encuestas o estudios. En tales casos, el Usuario podr&aacute; recibir una notificaci&oacute;n acerca de la participaci&oacute;n de un proveedor de servicios en tales actividades y quedar&aacute;, a su discreci&oacute;n, el acceder o no a proporcionarle sus Datos Personales u otra Informaci&oacute;n Personal que le sea solicitada, as&iacute; como autorizar a dichos proveedores a utilizar o compartir esa informaci&oacute;n. En caso que el Usuario facilite, voluntariamente y por propia iniciativa, sus Datos Personales u otra informaci&oacute;n adicional a tales proveedores, estos &uacute;ltimos usar&aacute;n esa informaci&oacute;n conforme a sus propias pol&iacute;ticas de privacidad, de forma que el Usuario acepta y reconoce que Delpa Group no se hace responsable por el uso indebido de sus Datos Personales y/u otra Informaci&oacute;n Personal que hagan los proveedores o compa&ntilde;&iacute;as o sitios de Internet que act&uacute;en por cuenta propia. De conformidad a la ley, el proveedor que requiera recolectar datos personales de los Usuarios deber&aacute; siempre informar acerca del car&aacute;cter facultativo de las respuestas y el prop&oacute;sito para el cual se est&aacute; solicitando la informaci&oacute;n.</p>

<p>Acepta que las llamadas que realice al servicio de soporte t&eacute;cnico de la Plataforma puedan ser grabadas con la finalidad de supervisar la prestaci&oacute;n del Servicio por parte del Delpa Group; se puedan remitir sus consultas, sugerencias o reclamos a quienes corresponda; y, se investiguen a profundidad situaciones graves que puedan ser denunciadas.</p>

<p>Autoriza a Delpa Group para comunicar su Informaci&oacute;n Personal y Datos Personales con la finalidad de cumplir la normativa aplicable y cooperar con las autoridades judiciales y/o gubernamentales, en la medida en que Delpa Group discrecionalmente lo entienda necesario y adecuado en relaci&oacute;n con cualquier investigaci&oacute;n de un il&iacute;cito o un fraude, infracci&oacute;n de derechos de propiedad industrial o intelectual, u otra actividad que sea ilegal o que pueda exponer a Delpa Group o a los Usuarios a cualquier responsabilidad legal. Este derecho podr&aacute; ser ejercido por Delpa Group a efectos de cooperar con el cumplimiento y ejecuci&oacute;n de la ley, independientemente que no exista una orden judicial o administrativa al efecto.</p>

<p>Reconoce que ser&aacute; el &uacute;nico responsable de todos los actos que tengan lugar mediante el uso de su Cuenta, lo que incluye hacerse cargo del pago de las tarifas que eventualmente se devenguen y de los perjuicios que puedan sufrir otros Usuarios y/o terceros por tal motivo.</p>

<p>3.2 Delpa Group recopilar&aacute;, utilizar&aacute; y compartir&aacute; sus Datos Personales e Informaci&oacute;n Personal a fin de proporcionar seguridad a los Usuarios y que los Servicios sean prestados correctamente.</p>

<p>De esta forma, Delpa Group podr&aacute; utilizar la informaci&oacute;n recopilada para:</p>

<p> Crear y actualizar la Cuenta del Usuario.</p>

<p> Verificar la identidad del Usuario.</p>

<p> Comprobar la veracidad de los Datos Personales de los Usuarios.</p>

<p> Permitir a Delpa Group hacer un seguimiento de su Solicitud y del recorrido del Conductor que le prestar&aacute; el Servicio, permiti&eacute;ndole tener acceso a su ubicaci&oacute;n en tiempo real.</p>

<p> Permitir al Delpa Group ubicar al Conductor que haya efectuado la Solicitud que ha aceptado, a fin de que este pueda prestarle un Servicio eficiente y r&aacute;pido, teniendo acceso a su ubicaci&oacute;n en tiempo real.</p>

<p> Permitir que el Delpa Group y el Conductor puedan comunicarse entre s&iacute; ante cualquier eventualidad que surja durante la prestaci&oacute;n del Servicio.</p>

<p> Permitir que el servicio de soporte t&eacute;cnico de la Plataforma pueda contactar a Delpa Group y/o Consumidores a fin de que el Servicio sea llevado a cabo de la forma m&aacute;s eficiente y r&aacute;pida posible.</p>

<p> Realizar pruebas, investigaciones y an&aacute;lisis respecto a la prestaci&oacute;n de los Servicios y de los productos adquiridos por los Consumidores.</p>

<p> Enviar a los Usuarios informaci&oacute;n respecto a promociones y/o publicidad de sus Servicios y de productos de sus Comercios Aliados.</p>

<p> Invitar a los usuarios a adquirir productos financieros u otros que se exhiban en Delpa Group</p>

<h4>CUARTO. Seguridad y Almacenamiento de los Datos Personales</h4>
<p>4.1 Delpa Group tratar&aacute; los Datos Personales de sus Usuarios de conformidad a lo dispuesto en la Ley N&ordm; 19.628 sobre Protecci&oacute;n de la Vida Privada y respetando siempre el pleno ejercicio de los derechos fundamentales de los Usuarios.</p>

<p>4.2 Delpa Group considera que los Datos Personales y otra Informaci&oacute;n Personal de sus Usuarios como un activo que debe ser protegido de cualquier p&eacute;rdida o acceso no autorizado, por lo que tomar&aacute; todas las precauciones para resguardarla mediante la implementaci&oacute;n de mecanismos de seguridad inform&aacute;tica eficaces, tales como cortafuegos (&ldquo;firewalls&rdquo;) y Secure Socket Layers (&ldquo;SSL&rdquo;). Delpa Group se obliga a cumplir con toda la normativa aplicable en materia de medidas de seguridad aplicables a los Datos Personales, utilizando los est&aacute;ndares de la industria entre materia de protecci&oacute;n y confidencialidad de su Informaci&oacute;n Personal.</p>

<p>4.3 No obstante, y considerando que internet es un sistema abierto, de acceso p&uacute;blico, Delpa Group no puede garantizar que terceros no autorizados no puedan eventualmente superar las medidas de seguridad y utilizar la Informaci&oacute;n Personal y/o Datos Personales del Usuario en forma indebida.</p>

<p>4.4 El Usuario reconoce y acepta expresamente que Delpa Group, a su exclusivo criterio, recabe, almacene y eventualmente monitoree el intercambio de mensajes y correos electr&oacute;nicos entre los Usuarios y que sean efectuados dentro de la Plataforma, con el objetivo de contribuir a la seguridad de las relaciones y comunicaciones que se desarrollan a trav&eacute;s de la Plataforma.</p>

<p>4.5 Delpa Group no se hace responsable por interceptaciones ilegales o violaci&oacute;n de sus sistemas o bases de datos por parte de personas no autorizadas. Delpa Group, tampoco se hace responsable por la indebida utilizaci&oacute;n de la informaci&oacute;n obtenida por esos medios.</p>

<p>4.6 Todos los Datos Personales ser&aacute;n almacenados en un archivo o soporte automatizado de datos personales.</p>

<p>4.7 Delpa Group no vender&aacute;, arrendar&aacute; ni compartir&aacute; los Datos Personales u otra Informaci&oacute;n Personal de sus Usuarios, excepto en las formas establecidas en estas Pol&iacute;ticas de Privacidad.</p>

<p>4.8 Delpa Group conservar&aacute; los Datos Personales y la Informaci&oacute;n Personal de los Usuarios por un periodo de diez (10) a&ntilde;os, a fin de resguardarse y poder resolver posibles disputas o reclamos, detectar problemas o incidencias y solucionarlos, y dar cumplimiento a lo dispuesto en los T&eacute;rminos y Condiciones.</p>

<h4>QUINTO. Derecho de Acceso, Modificaci&oacute;n, Rectificaci&oacute;n y Eliminaci&oacute;n de los Datos Personales.</h4>
<p>5.1 Los Usuarios podr&aacute;n ejercitar, en cualquier momento, los derechos de acceder, modificar, rectificar y eliminar todos sus Datos Personales recolectados por Delpa Group, incluyendo su direcci&oacute;n de e-mail, as&iacute; como a oponerse al tratamiento de los mismos y a ser informado de las cesiones y/o transferencias internacionales de sus Datos Personales, de conformidad a lo dispuesto en la Ley N&ordm; 19.628 sobre Protecci&oacute;n de la Vida Privada.</p>

<p>5.2 Conforme se establece en el art&iacute;culo 12 de la Ley N&ordm; 19.628, el Usuario titular de los Datos Personales y previa acreditaci&oacute;n de su identidad, tiene la facultad de ejercer el derecho de acceder, modificar, rectificar y/o eliminar todos sus Datos Personales, en cualquier momento y en forma gratuita, solicitud que deber&aacute; ser atendida dentro de los 2 (dos) d&iacute;as h&aacute;biles a que se efectuado.</p>

<p>5.3 La solicitud de acceso, modificaci&oacute;n, rectificaci&oacute;n y/o eliminaci&oacute;n de Datos Personales deber&aacute; efectuarse mediante correo electr&oacute;nico enviado a servicioalclientechile@delpagroup.com, incluyendo su nombre completo, c&eacute;dula nacional de identidad o rol &uacute;nico tributario, y n&uacute;mero de tel&eacute;fono celular. Sin perjuicio de los cambios que se realicen, Delpa Group podr&aacute; conservar la informaci&oacute;n personal anterior por motivos de seguridad y control del fraude, en caso de tener dudas razonables.</p>

<p style={{ marginBottom: 30 }}>5.4 En caso de infracci&oacute;n a las disposiciones anteriores, los Usuarios podr&aacute;n recurrir ante el juez de letras en lo civil de Santiago, solicitando amparo a los derechos consagrados en la Ley N&ordm; 19.628.</p>
        </Col>
    </Row>

    
    
</Container>
}

export default PoliticasPrivacidad