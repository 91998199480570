import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import data from '../../../lib/backend/data'
import { Alert, Col, Row, Spinner } from 'react-bootstrap';
import PantallaCargando from '../../general/pantalla_cargando';
import { useSelector } from 'react-redux';
import DetailEstadosCarga from '../../estados_carga/detail';
import { AiFillSignal } from 'react-icons/ai' 

const ListadoEstadosCargaTiempoReal = (props) => {
    const {
        condicion_default
    } = props
    const condicion = condicion_default ? condicion_default : {}
    const maximo_historial = 20
    const [ estados, setEstados ] = useState([])
    const [ loadingEstados, setLoadingEstados ] = useState(true)
    const [ cargadorMas, setCargarMas ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession

    const getEstados = (offset, paginar) => {
        if(paginar === true) setCargarMas(true)
        const url = `${data.urlapi}/estadoscarga/list`;
        return fetch(url,{
            method:'POST',
            body: JSON.stringify({
                offset,
                limit: maximo_historial,
                condicion
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(pros => {
            if(paginar === true) setCargarMas(false)
            return pros.json()}
        )
        .then(pros => {
            if(!pros){
                toast.error('Ocurrió un error inesperado, Sin respuesta del servidor')
                return setLoadingEstados(false)
            } else if(pros.errorMessage){
                toast.error(`Ocurrió un error inesperado: ${pros.errorMessage}`, )
                return setLoadingEstados(false)
            } else if(Array.isArray(pros) !== false){
                setEstados([ ...estados, ...pros ])
            }
            
            setLoadingEstados(false)
        })
        .catch(error => {
            toast.error('Ocurrió un error inesperado')
            setLoadingEstados(false)
        })
    }

    useEffect(() => {
        getEstados(estados.length)
    }, [])

    const onDelete = (id) => {
        const i = estados.findIndex(e => e._id === id)
        if(i < 0) return false
        estados.splice(i,1)
        return setEstados([...[], ...estados])
    }

    const obtenerPedido = (id) => {
        if(props.obtenerPedido) return props.obtenerPedido(id)
        return false
    }

    if(loadingEstados) return <PantallaCargando />

    return <div>
    <h6>Se muestran los últimos {maximo_historial} estados por defecto</h6>
    <Alert variant='info' style={{ padding: "5px 10px" }} ><AiFillSignal /> Esta pantalla funciona en tiempo real</Alert>

    <Row>
    { estados.map((estado,i) => {
        return <Col md={4} key={`es-${i}`}>
            <DetailEstadosCarga showLink={true} onDelete={(id) => onDelete(id)} size="sm" estado={estado} obtenerPedido={(id)=>obtenerPedido(id)} />
        </Col>
    }) }
    </Row>

    { cargadorMas ? <Spinner animation='border' /> : <button className='btn btn-sm btn-outline-primary' onClick={()=>getEstados(estados.length, true)}>CARGAR MÁS</button>}

    </div>
}

export default ListadoEstadosCargaTiempoReal