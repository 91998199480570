const validarDNIBrasil = cpf => {
  cpf = cpf.toString()
  // Eliminar caracteres especiales del CPF
  cpf = cpf.replace(/[^\d]/g, '');

  // Verificar si el CPF tiene una longitud válida
  if (cpf.length !== 11) {
    return false;
  }

  // Verificar si todos los dígitos son iguales (CPF inválido)
  if (/^(\d)\1+$/.test(cpf)) {
    return false;
  }

  // Calcular el primer dígito verificador
  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i);
  }
  let mod = sum % 11;
  let digit1 = mod < 2 ? 0 : 11 - mod;

  // Verificar el primer dígito verificador
  if (parseInt(cpf.charAt(9)) !== digit1) {
    return false;
  }

  // Calcular el segundo dígito verificador
  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i);
  }
  mod = sum % 11;
  let digit2 = mod < 2 ? 0 : 11 - mod;

  // Verificar el segundo dígito verificador
  if (parseInt(cpf.charAt(10)) !== digit2) {
    return false;
  }

  // El CPF es válido
  return true;
};

const formatearCPF = (cpf)  => {
  cpf = cpf.toString()
  // Eliminar cualquier caracter que no sea dígito
  cpf = cpf.replace(/\D/g, '');

  // Verificar si el CPF tiene la longitud correcta (11 dígitos)
  if (cpf.length !== 11) {
    return `CPF ${cpf} inválido: debe tener 11 dígitos`;
  }

  // Aplicar el formato con puntos y guiones
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

const formatearTelefonoMovilBrasil = (telefono) => {
  // Convertir el número a una cadena
  telefono = telefono.toString();

  // Eliminar cualquier caracter que no sea dígito
  telefono = telefono.replace(/\D/g, '');

  // Formatear el número con el código de área (dos primeros dígitos) y espacios en el resto del número
  return `${telefono.substr(0, 2)} ${telefono.substr(2, 2)} ${telefono.substr(4, 5)} ${telefono.substr(9)}`;
}


module.exports = {
    validarDNIBrasil,
    formatearTelefonoMovilBrasil,
    formatearCPF
}