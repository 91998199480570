import { Card, Form, Tab, Tabs } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Header from '../../components/Header'
import ClientesListado from './listado'
import { useState } from 'react'

const Clientes = (props) => {
  const session = useSelector(state => state.miusuario)
  const [ condicionDefault, setCondicionDefault ] = useState({ proveedor: true })
      
  const handleCheck = (e, name) => {
    const valor = e.target.checked
    return setCondicionDefault(prev => ({...prev, ...{} }))
    }

        return <div className="fluid">
            <h4>Clientes</h4>
            <ClientesListado condicion_default={condicionDefault}  />
            </div>
}

export default Clientes