import React, { useEffect, useState} from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { urlapi } from '../../lib/backend/data'
import { configuracion_fechas, configuracion_horas } from '../../lib/helpers/dates'

const Tracking = () => {
    const [ loadingPedido, setLoadingPedido ] = useState(true)
    const [ detalle_pedido, setDetallePedido ] = useState(false)
    const [ loadingEquipos, setLoadingEquipos ] = useState(true)
    const route = useParams()

    const obtenerPedido = () => {
        const { id } = route
        return fetch(`${urlapi}/ordenes/tracking?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingPedido(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingPedido(false)
            } else if(res.orden){
                setDetallePedido(res)
            }
            return setLoadingPedido(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingPedido(false)
        })
    }

    useEffect(() => {
        obtenerPedido()
    }, [])

    const mostrarSubEstadoHTML = (sub_estado_entrega) => {
        if(sub_estado_entrega){
            if(typeof sub_estado_entrega === 'object'){
            if(sub_estado_entrega.titulo){
                return <span className="spanstatus mr-2" style={{ backgroundColor: sub_estado_entrega.color ? sub_estado_entrega.color : '#a2a2a2', padding: "1px 10px", borderRadius: 5, color: "white" }}>{sub_estado_entrega.titulo}</span>
            }
            }
        }
        return false
        }

    const statusString = (status) => {

        let cadena = "";
        
        if (status) {
        if ( status == 1 ) {
        cadena = "Preparado";
        } else if( status == 2 ){
        cadena = "Leído en el móvil";
        } else if (status == 3) {
        cadena = "Retirado en origen";
        } else if (status == 4 ) {
        cadena = "En reparto";
        } else if (status == 5) {
        cadena = "Entregado";
        } else if (status == 6){
        cadena = "Entrega Confirmada";
        } else if (status == 7){
        cadena = "Entrega Confirmada";
        } else if (status == 9){
        cadena = "Retiro de bodega";
        } else if (status == 18){
        cadena = "En CD Delpa";
        } else if (status == 13){
        cadena = "No entregado";
        } else {
        cadena = "Sin datos";
        }
        } else {
        cadena = "Sin datos";
        }
        return cadena;
        }

    const statusColor = (level) => {

        let color = "#dbdbde";

    if ( level == 1 ) {
    color = "#cad004";
    } else if( level == 2 ){
    color = "#d09d04";
    } else if (level == 3) {
    color = "#d03e04";
    } else if (level == 4 ) {
    color = "#5e72e4";
    } else if (level == 5) {
    color = "#64b612";
    } else if (level == 6){
    color = "#64b612";
    } else if (level == 7){
    color = "#64b612";
    } else if (level == 9){
    color = "#ff71a6";
    } else if (level == 18){
    color = "#a31be1";
    } else if (level == 13){
    color = "#000";
    } 

    return color;

    }

    const sinRegistrosHTML = (palabra,archivo) => {
        return <div>
          <img src={`/images/${archivo}`} style={{ width: 70 }} />
          <h3>No hay {palabra} registrados</h3>
        </div>
      }

      const showComponentByTipoFormulario = (campo) =>{
        switch (campo.tipo_accion) {
          case 'campo-texto':
            return <div className="mb-3">
              <h6 className='mb-0'>{campo.titulo}</h6>
              <p className='mb-0'>{campo.valor ? campo.valor : 'Sin información'}</p>
            </div>
          case 'selector':
            return <div className="mb-3">
              <h6 className='mb-0'>{campo.titulo}</h6>
              <p className='mb-0'>{campo.valor ? campo.valor : 'Sin información'}</p>
            </div>
          case 'carga-imagenes':
            return <div className="mb-3">
              <h6 className='mb-2'>{campo.titulo}</h6>
              <Row>
              {
                campo.valores.length > 0 ? campo.valores.map((foto,i) => {
                  return <Col md={3} key={`foto-estado-${i}`}>
                    <a href={foto.url} target="_blank" className='pr-2'>
                    <img src={foto.url} style={{  height: 100 }} ></img>
                    </a>
                    </Col>
                }) : <Col md={12}><p>Sin información</p></Col>
              }
              </Row>
            </div>
          default:
            break;
        }
    }

      const mostrarMetaDatosSubEstado = (metadatos) => {
        if(!metadatos) return false
        if(Array.isArray(metadatos) !== true) return false
        if(metadatos.length < 1) return false
  
        return metadatos.map((meta,i) => {
          return <div key={`meta-${i}`}>
            {showComponentByTipoFormulario(meta)}
          </div>
        })
      }

    const mostrarEstados = (estados) => {
        if(!estados) return sinRegistrosHTML('estados', 'tracking-estados.png')
        if(Array.isArray(estados) !== true) return sinRegistrosHTML('estados', 'tracking-estados.png')
        if(estados.length < 1) return sinRegistrosHTML('estados', 'tracking-estados.png')
  
        return <div className='row' style={{ backgroundColor: '#eee', paddingTop: 15, marginBottom: 20 }}>
          {
            estados.map((estado,im) => {
              const fecha = new Date(estado.fecha)
              return <div className='col-md-4 mb-3' key={`estado-${im}`}>
                <div className='card shadow'>
                  <div className='p-3'>
                    <h1 className='bubleestado'>{im+1}</h1>
                  <h6 className='mb-0'>{fecha.toLocaleDateString('es-ES', configuracion_fechas )} · <b>{fecha.toLocaleTimeString('en-US', configuracion_horas)}</b></h6>
                  <span className="spanstatus mr-2" style={{backgroundColor: estado.estado_color ? estado.estado_color : '#babbbb', padding: "1px 10px", borderRadius: 5, color: "white" }}>{estado.estado}</span>
                  {mostrarSubEstadoHTML({ titulo: estado.subestado, color: estado.subestado_color, })}
                  <hr className='hr' />
                  {mostrarMetaDatosSubEstado(estado.metadatos)}
                  </div>
                </div>
                </div>
            })
          }
        </div>
      }

    const shoyEstadoEntregaHTML = (estadoentrega, estado_entrega, sub_estado_entrega) => {
        if(estado_entrega){
            if(typeof estado_entrega === 'object'){
            if(estado_entrega.titulo){
                return <div>
                <span className="spanstatus mr-2" style={{backgroundColor: estado_entrega.color ? estado_entrega.color : '#babbbb', padding: "1px 10px", borderRadius: 5, color: "white" }}>{estado_entrega.titulo}</span>
                {mostrarSubEstadoHTML(sub_estado_entrega)}
                </div>
            }
            }
        }
        return <span className="spanstatus" style={{backgroundColor: statusColor(estadoentrega)}}>{statusString(estadoentrega)}</span>
        }


    const Header = () => {
        return <div>
            <img src="https://ultimamilla.delpasystem.com/images/logo.png" style={{ width: 200 }} />
        </div>
    }

    const mostrarDatos = () => {
    if(loadingPedido) return <div className="loadingbox text-center mb-3">
        <Header />
        <Spinner animation='border' />
        <h4>Cargando información...</h4>
        </div>

    if(!detalle_pedido) return <div className="loadingbox text-center">
      <Header />
      <h2>Sin información</h2>
    </div>

    const orden = typeof detalle_pedido.orden.orden === 'object' ? detalle_pedido.orden.orden : {}
    const facturacion = typeof orden.billing === 'object' ? orden.billing : {}

    return <div>
        <Header />
      <div className='row'>
        <div className='col-md-4'>

          <label className='form-control-label mb-0' >Referencia</label>
          <h1 className='mb-0'>{detalle_pedido.orden.pedido}</h1>

          <label className='form-control-label mb-0' >Orden de transporte</label>
          <h1 className='mb-0'>{detalle_pedido.orden.ot}</h1>

          <label className='form-control-label mb-0 d-block' >Estado entrega</label>
          <p>
          {shoyEstadoEntregaHTML(detalle_pedido.orden.estadoentrega, detalle_pedido.orden.estado_entrega, detalle_pedido.orden.sub_estado_entrega)}</p>

          <label className='form-control-label mb-0' >Fecha Creación</label>
          <p className='mb-0'>{ orden.createdAt ? orden.createdAt : orden.createdAt}</p>
          
        </div>

        <div className='col-md-4'>
          <h2 className='mb-0 d-block' >Destinatario</h2>
          <div className='row' >
            <div className='col-md-12'>
              <label className='form-control-label'>Nombre</label>
              <h3>{facturacion.first_name}</h3>
            </div>
          </div>

          <label className='form-control-label mb-0 d-block' >Email</label>
              <h3>{facturacion.email}</h3>

          <label className='form-control-label mb-0 d-block' >Móvil</label>
              <h3>{facturacion.phone}</h3>

          <label className='form-control-label mb-0' >Dirección</label>
          <p>{facturacion.address_1}</p>

          <label className='form-control-label mb-0' >Detalles dirección</label>
          <p>{facturacion.address_2}</p>

        </div>

        <div className='col-md-4'>

        <h2 className='mb-0 d-block' >Bultos</h2>

        <div className='row'>

        <div className="col-md-4 mb-3">
              <label className="form-control-label">Secos</label>
              <h3>{detalle_pedido.orden.bultos}</h3>
        </div>
            
        <div className="col-md-4 mb-3">
              <label className="form-control-label">Congelados</label>
              <h3>{detalle_pedido.orden.congelado}</h3>
        </div>
  
        <div className="col-md-4 mb-3">
              <label className="form-control-label">Refrigerados</label>
              <h3>{detalle_pedido.orden.refrigerado}</h3>
        </div>
        

        </div>

        <label className='form-control-label mb-0' >Nota</label>
              <p>{detalle_pedido.orden.nota}</p>
        </div>

        <div className='col-md-12'>
          <hr />
          <h3>Estados de carga</h3>
          {mostrarEstados(detalle_pedido.estados)}
        </div>
      </div>
    </div>
    }

        return(
            <div className="fluid p-3">
                {mostrarDatos()}
                <div className='text-center'>
                  <h6>Powered By</h6>
                  <img src='https://clientes.tulogistica.cl/img/logo-delpa-gris.png' style={{ width: 140 }} alt="logo-delpa" />
              </div>
            </div>
        )
}

export default Tracking