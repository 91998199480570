import { Row, Col } from 'react-bootstrap'

const RegistrosVacios = (props) => {
    const {
        titulo,
        descripcion
    } = props
    return <div className='p-5'>
            <Row className="justify-content-md-center">
                <Col md={7} className="text-center">
                <h4>{titulo ? titulo : "Nada por aquí"}</h4>
                <p>{descripcion ? descripcion : "No hay datos para mostrar"}</p>
                </Col>
            </Row>
        </div>
}

export default RegistrosVacios