import 'moment/locale/es';
import { Accordion, Button, Card, Col, Container, Dropdown, ListGroup, Modal, Nav, Navbar, NavDropdown, Row, Spinner, Table } from 'react-bootstrap'
import { useEffect, useRef, useState } from "react";
import { fechaATexto, fechaATextoSimple } from "../../lib/helpers/helpers";
import SearchLocationInput from "../google_maps/searchLocationInput";
import { maps_key, urlapi } from "../../lib/backend/data";
import { toast } from "react-toastify";
import * as XLSX from 'xlsx'
import EtiquetasStatic from './visualizar_etiquetas_static';
import { confirmAlert } from "react-confirm-alert";
import { obtenerFechaHoraZonaHorariaLocal } from '../../lib/helpers/dates';
import { rutas } from '../../lib/routes/routes';
import BuscadorExcel from '../excel-formats/selector';

const DetailFull = (props) => {
    let [ ruta, setRuta] = useState(props.ruta ? props.ruta : false)
    let centro_mapa = { lat: -33.458829, lng: -70.654644 }
    const zoom_mapa = 11
    let [marcadores,setMarcadores] = useState([])
    const token = props.token ? props.token : false
    const [ modalParadas, setModalParadas ] = useState(false)
    const [ loadingOptimizacion, setLoadingOptimizacion] = useState(false)
    const [ paradasAgregar, setParadasAgregar] = useState([
      { direccion: "", destinatario: "" }
    ])
    const [ loadingAnalisisCruzado, setloadingAnalisisCruzado ] = useState(false)
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ errores, setErrores ] = useState([])
    const [ etiquetasNuevas, setEtiquetasNuevas ] = useState([])
    const [ etiquetasBase, setEtiquetasBase ] = useState([])
    const [ etiquetas, setEtiquetas ] = useState([])
    const [ etiquetasRepetidas, setEtiquetasRepetidas ] = useState([])
    const [ numeroEtiquetas, setNumeroEtiquetas ] = useState(0)
    const [ openModalEscaneo, setOpenModalEscaneo ] = useState(false)
    const [ mensaje_escaneo, setMensaje_escaneo ] = useState("CÓDIGO BULTO")
    const [ valorEscaneo, setValorEscaneo ] = useState("")
    const inputEscaneo = useRef(false)
    const [ loading, setLoading ] = useState(false)
    const [ loadingScan, setLoadingScan ] = useState(false)
    const [ etiquetaActual, setEtiquetaActual ] = useState(false)
    const [ tipoEscaneo, setTipoEscaneo ] = useState("orden")
    const [ formatoSeleccionado, setFormatoSeleccionado ] = useState(false)

    useEffect(() => {
      if(ruta){
        if(ruta.etiquetas){
          if(Array.isArray(ruta.etiquetas) !== false){
            if(ruta.etiquetas.length > 0){
                setEtiquetas(ruta.etiquetas)
            }
          }
        }
        if(ruta.label_numbers_base){
          if(Array.isArray(ruta.label_numbers_base) !== false){
            if(ruta.label_numbers_base.length > 0){
                setEtiquetasBase(ruta.label_numbers_base)
            }
          }
        }
      }
    }, [])

    const [ loadingParadasAgregar, setLoadingParadasAgregar ] = useState(false)

    const abrirModalParadas = () => {
      setModalParadas(true)
    }

    const handleClose = () => {
      setModalParadas(false)
      setOpenModalEscaneo(false)
    }

    const agregarParada = () => {
      return setParadasAgregar(prev => [...prev, ...[{ direccion: "", destinatario: "" }]])
    }

    const mostrarEtiquetasRepetidas = () => {
        if(etiquetasRepetidas.length < 1) return false
        return <Card bg="danger" className='p-3 mb-3 text-light'>
            <h5>LabelNumber repetidos</h5>
            <Table>
                <tbody>
                {
                    etiquetasRepetidas.map((l,i) => {
                        return <tr key={`l-${l}-${i}`} >
                            <th ><p className='mb-0 text-light'><b>{l}</b></p></th>
                        </tr>
                    })
                }
                </tbody>
            </Table>
        </Card>
    }

    const handleChangeStatus = (e) => {
        const { name, value } = e.target
        switch (name) {
            case "numeroEtiquetas":
                return setNumeroEtiquetas(value)
            default:
                break;
        }
    }

    const handleChangeDireccionParada = (e) => {
      const { name, value } = e.target
      const pos = e.target.getAttribute("pos")
      if(!paradasAgregar[pos]) return 
      paradasAgregar[pos][name] = value
      return setParadasAgregar(prev => [...[], ...paradasAgregar])
    }

    const confirmarEliminado = async (id) => {
      setLoadingExcel(true)
      return fetch(`${urlapi}/bodega/etiquetas/lotes?id=${id}`,{
        method:'DELETE',
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${token}`
        }
      })
      .then(res => {
          if(res.status === 401) return window.location = '/login'
          return res.json()
      })
      .then(res => {
          if(!res){
            toast.error('No hubo respuesta del servidor')
          } else if(res.errorMessage){
            toast.error(res.errorMessage)
          } else if(res._id){
            toast.success("Operación realizada exitosamente")
            setTimeout(() => {
              window.location = `/${rutas.etiquetas.slug}`
            }, 500);
          }
          setLoadingExcel(false)
      })
      .catch(error => {
          toast.error("No se pudo realizar esta operación")
          setLoadingExcel(false)
      })
    }

    const onChangeValueExcel = (e) => {
      setFormatoSeleccionado(e)
  }

    const reemplazarLlave = (key) => {
      if(!formatoSeleccionado) return key
      const i = formatoSeleccionado.columnas.findIndex(e => e.target === key)
      if(i > -1) return formatoSeleccionado.columnas[i].key
      return key
  }

    const crearParada = async (parada) => {
      parada.id_plan = ruta._id
      return fetch(`${urlapi}/planes/paradas`,{
        method:'POST',
        body: JSON.stringify(parada),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${token}`
        }
    })
    .then(res => {
        if(res.status === 401) return window.location = '/login'
        return res.json()
    })
    .then(res => res)
    .catch(error => false)
    }

    const confirmarParadas = async () => {
        const pendientes = paradasAgregar.filter(pa => !pa.geo_data)
        if(pendientes.length > 0) return toast.error("Hay paradas sin datos coordenadas")
        setLoadingParadasAgregar(true)
        setModalParadas(false)

        let nuevas_paradas = []
        for( const parada of paradasAgregar ){
          const nueva_parada = await crearParada(parada)
          if(!nueva_parada) continue
          if(!nueva_parada._id) continue
          nuevas_paradas.push(nueva_parada)
        }
        setParadasAgregar(prev => [...[], ...[]])
        setMarcadores(prev => [...marcadores, ...nuevas_paradas])
        return setLoadingParadasAgregar(false)
    }

    const eliminarParada = (pos) => {
      const nuevos = paradasAgregar.splice(pos,1)
      return setParadasAgregar(prev => [...[], ...nuevos])
    }

    const mostrarEtiquetaActual = () => {
      if(!etiquetaActual) return false
      return <Card className='p-3 mb-2'>
          <h3 className="mb-0">ID {etiquetaActual.id}</h3>
          { etiquetaActual.id_lote !== ruta._id ? <p className='text-danger'>LOTE DIFERENTE A ESTE</p> : false }
          { etiquetaActual.idrecurso ? <p className="mb-0">YA ESTÁ ASIGNADO A ORDEN {etiquetaActual.identificador}</p> : false }
          { etiquetaActual.value ? <p className="mb-0">LabelNumber {etiquetaActual.value}</p> : false }
          {etiquetaActual.escaneada ? <div>
          <p className="mb-0">ESCANEADA</p>
          { etiquetaActual.fecha_escaneada_zona_horaria_local ? <p className="mb-0">{fechaATexto(etiquetaActual.fecha_escaneada_zona_horaria_local)}</p> : false }
          </div> : false }
          <Button size='sm' variant='outline-success' className='mb-3' onClick={async () => {
            await crearNuevaEtiqueta(`${etiquetaActual.value}-${etiquetas.filter(e => e.idrecurso === etiquetaActual.idrecurso).length + 1}`)
            inputEscaneo.current.focus()
          }}>CREAR NUEVO CARTON</Button>
          <Button size='sm' variant='outline-danger' onClick={() => {
            setEtiquetaActual(false)
            inputEscaneo.current.focus()
          }}>CANCELAR</Button>
      </Card>
    }

    const mostrarModalParadas = () => {
      return <Modal show={modalParadas ? true : false } onHide={()=>handleClose()} centered 
        backdrop="static"
        size="xl"
        keyboard={false}
      >
          <Modal.Header closeButton>
            <Modal.Title>PARADAS</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <Button size="sm" variant="dark" className="mb-3 mr-3" onClick={() => agregarParada()}>AGREGAR PARADA</Button>
                <Button size="sm" variant="success" className="mb-3 mr-3" onClick={() => confirmarParadas()}>CONFIMRAR</Button>
              </Col>
              <Col md={6}>
                <h5 className="mb-3">Sube un archivo excel <Button size="sm" variant="outline-primary" className="mt-0">DESCARGAR MODELO</Button></h5>
                
                <input type="file" className="form-control" />
              </Col>
            </Row>            
            <hr/>
            
              {
                paradasAgregar.map((d,i) => {
                  return <Row key={`d-${i}`} className="mb-1">
                    <Col md={2}>
                      <input style={{ display: "inline-block"}} className="form-control" name="destinatario" placeholder="Destinatario" pos={i} onChange={handleChangeDireccionParada} />
                      <span style={{ display: "inline-block", fontSize: 12 }} onClick={() => eliminarParada(i)} className="text-danger hover" ><i className="fas fa-minus-circle"></i> ELIMINAR</span>
                    </Col>
                    <Col md={10}>
                    <SearchLocationInput
                      maps_key={maps_key}
                      onChange={(place) => {
                          if(!place) return false
                          const lat = place.geometry.location.lat()
                          const lng = place.geometry.location.lng()

                          let region = ''
                          const buscar_region = place.address_components.findIndex(comp => {
                              return comp.types.includes('administrative_area_level_1')
                          })
                          if(buscar_region > -1) region = place.address_components[buscar_region].long_name

                          let comuna = ''
                          const buscar_comuna = place.address_components.findIndex(comp => {
                              return comp.types.includes('administrative_area_level_2')
                          })
                          if(buscar_comuna > -1) comuna = place.address_components[buscar_comuna].long_name

                          const geo_data = {
                              lat, 
                              lng,
                              direccion: place.formatted_address,
                              region,
                              comuna,
                              status: true
                          }
                          paradasAgregar[i].geo_data = geo_data
                          paradasAgregar[i].direccion = place.formatted_address
                          return setParadasAgregar(prev => [...[], ...paradasAgregar])
                      }} />  
                      {d.geo_data ? <i class="fas text-success fa-check-circle"></i> : <i class="far fa-clock"></i> }
                    </Col>
                    {
                      paradasAgregar.length === (i+1) ? false : <Col md={12}><hr /></Col>
                    }
                    
                  </Row>
                })
              }
              
          </Modal.Body>
        </Modal>
    }

    if(!ruta) return <div className='p-5'>
    <Row className="justify-content-md-center">
        <Col md={6} className="text-center">
          <img src="images/pedidos.png" style={{ width: "50%" }} />
        <h4>Selecciona un lote para visualizarlo</h4>
        </Col>
    </Row>
    </div>

    const eliminarMarcador = (pos) => {
      marcadores.splice(pos,1)
      console.log({marcadores,pos})
      return setMarcadores(prev => [...[], ...marcadores])
    }

    const crearEtiquetasPorNumero = (id_lote) => {
      if(!numeroEtiquetas) return toast.error("Escriba primero un número de etiquetas")
      setLoadingOptimizacion(true)
      return fetch(`${urlapi}/bodega/etiquetas/lotes/numero`,{
        method:'POST',
        body: JSON.stringify({
            id_lote,
            cantidad: numeroEtiquetas
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${token}`
        }
    })
    .then(res => {
        if(res.status === 401) return window.location = '/login'
        return res.json()
    })
    .then(res => {
      if(!res){
        toast.error("Sin respuesta")
      } else if(res.errorMessage){
        toast.error(res.errorMessage)
      } else if(Array.isArray(res) !== false){
        let nuevas = [...etiquetas, ...res]
        setEtiquetas(prev => nuevas)
      }
      setLoadingOptimizacion(false)
    })
    .catch(error => {
      setLoadingOptimizacion(false)
    })
    }

    const optimizarPlan = (id_lote) => {
      setLoadingOptimizacion(true)
      return fetch(`${urlapi}/bodega/etiquetas/lotes/excel`,{
        method:'POST',
        body: JSON.stringify({
            id_lote,
            etiquetas: etiquetasNuevas
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${token}`
        }
    })
    .then(res => {
        if(res.status === 401) return window.location = '/login'
        return res.json()
    })
    .then(res => {
      if(!res){
        toast.error("Sin respuesta")
      } else if(res.errorMessage){
        toast.error(res.errorMessage)
        if(res.repetidas){
            if(Array.isArray(res.repetidas) !== false){
                setEtiquetasRepetidas(res.repetidas)
            }
        }
      } else if(Array.isArray(res) !== false){
        let nuevas = [...etiquetas, ...res]
        setEtiquetas(prev => nuevas)
      }
      setLoadingOptimizacion(false)
    })
    .catch(error => {
      setLoadingOptimizacion(false)
    })
    }

    const handleInputChangeBase = (e) => {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        let hojas = []
        setErrores(prev => [])
        if (name === 'file') {
            setLoadingExcel(true)
            let reader = new FileReader()
            reader.readAsArrayBuffer(target.files[0])
            reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, {type: 'array'});
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })

            if(!hojas[0]) return toast.error('No se encontraron datos')
            if(Array.isArray(hojas[0]) !== true) return toast.error('No se encontraron datos')
            if(hojas[0].length < 1) return toast.error('No se encontraron datos')
            if(!hojas[0][0]) return toast.error('No se encontraron datos en la primera fila')

            let nuevas = []
            for( const fila of hojas[0]){
                if(!fila[reemplazarLlave("labelnumber")]) continue
                const nombre = fila[reemplazarLlave("labelnumber")].toString().trim()
                const i = nuevas.findIndex(e => e.value === nombre)
                if(i > -1) {
                  toast.error(`${nombre} está repetido, duplicado omitido`)
                  continue
                }
                nuevas = [...nuevas, ...[{ value: nombre }]]
            }
            if(nuevas.length < 1) {
                toast.warn("No se encontraron valores en el documento")
                return setLoadingExcel(false)
            }
            console.log(nuevas)
            setEtiquetasBase(prev => [...[], ...nuevas])
            return setLoadingExcel(false)
          }
        }
    }

    const handleInputChange = (e) => {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        let hojas = []
        setErrores(prev => [])
        if (name === 'file') {
            setLoadingExcel(true)
            let reader = new FileReader()
            reader.readAsArrayBuffer(target.files[0])
            reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, {type: 'array'});
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })

            if(!hojas[0]) return toast.error('No se encontraron datos')
            if(Array.isArray(hojas[0]) !== true) return toast.error('No se encontraron datos')
            if(hojas[0].length < 1) return toast.error('No se encontraron datos')
            if(!hojas[0][0]) return toast.error('No se encontraron datos en la primera fila')

            let nuevas = []
            for( const fila of hojas[0]){
                if(!fila.labelnumber) continue
                const nombre = fila.labelnumber.toString()
                const i = nuevas.findIndex(e => e.value === nombre)
                if(i > -1) {
                  toast.error(`${nombre} está repetido, duplicado omitido`)
                  continue
                }
                nuevas = [...nuevas, ...[{ value: nombre, id_lote: ruta._id }]]
            }
            if(nuevas.length < 1) {
                toast.warn("No se encontraron valores en el documento")
                return setLoadingExcel(false)
            }
            console.log(nuevas)
            setEtiquetasNuevas(prev => [...[], ...nuevas])
            return setLoadingExcel(false)
          }
        }
    }

    const totales_asociadas = etiquetas.filter(e => e.idrecurso).length
    const totales_escaneadas = etiquetas.filter(e => e.escaneada === true).length

    const asociadas = etiquetas.filter(e => e.idrecurso && e.isExtra !== true).length
    const escaneadas = etiquetas.filter(e => e.escaneada === true && e.isExtra !== true).length
    const etiquetas_reales = etiquetas.filter(e => e.isExtra !== true).length
        
    const extra = etiquetas.filter(e => e.isExtra === true).length
    const extra_escaneadas = etiquetas.filter(e => e.escaneada === true && e.isExtra === true).length
    const extra_asociadas = etiquetas.filter(e => e.idrecurso && e.isExtra === true).length

    const descargarReporte = async (id) => {
      setLoadingExcel(true)
      const url = `${urlapi}/bodega/etiquetas/lotes/download`
      return fetch(url,{
        method:'POST',
        body: JSON.stringify({
          id_lote: ruta._id
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${token}`
        }
    })
    .then(res => {
        if(res.status === 401) return window.location = '/login'
        return res.blob()
    })
    .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            setLoadingExcel(false)
    })
    .catch(error => {
      toast.error(error.message)
      setLoadingExcel(false)
    })
    }

    const abrirOpcionesEscaneo = () => {
      setOpenModalEscaneo(true)
    }
    
    const handleChangeInputEscaneo = (e) => {
        const { value } = e.target
        return setValorEscaneo(value)
    }

    const handleSubmitCodigo = (e) => {
      e.preventDefault()
      escanear()
    }

    const asociarOrden = () => {
      setLoadingScan(true)
      const url = `${urlapi}/bodega/etiquetas/scan-associate`
        return fetch(url,{
            method: 'POST',
            body: JSON.stringify({
                valor: valorEscaneo,
                id_etiqueta: etiquetaActual._id
            }),
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer: ${token}`
            }
          })
          .then(res => res.json())
          .then(res => {
            console.log(res)
            setValorEscaneo("")
            if(!res){
              toast.error('No hubo respuesta del servidor')
            } else if(res.errorMessage){
              toast.error(res.errorMessage)
            } else if(res.success === true ){
              if(res.resultado){
                const i = etiquetas.findIndex(et => et._id === res.resultado._id)
                console.log({ i })
                if(i > -1){
                  const nueva_lista = etiquetas
                  nueva_lista[i] = res.resultado
                  setEtiquetas(prev => [...[], ...nueva_lista])
                }
              }
              setEtiquetaActual(false)
            }
            setLoadingScan(false)
          })
          .catch(error => {
            setLoadingScan(false)
            return toast.error(error.message)
          })
    }

    const descargarAnalisisCruzado = async () => {
      setloadingAnalisisCruzado(true)
      const url = `${urlapi}/bodega/etiquetas/lotes/analisis?id=${ruta._id}`
      ruta.label_numbers_base = etiquetasBase
      return fetch(url,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer: ${token}`
          }
        })
        .then(res => res.blob())
        .then(blob => {
          var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-analisis-lote-${ruta.id}-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            setloadingAnalisisCruzado(false)
        })
        .catch(error => {
          setloadingAnalisisCruzado(false)
          return toast.error(error.message)
        })
  }

    const actualizarLote = async () => {
      setLoadingScan(true)
      const url = `${urlapi}/bodega/etiquetas/lotes`
      ruta.label_numbers_base = etiquetasBase
      return fetch(url,{
          method: 'PUT',
          body: JSON.stringify(ruta),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer: ${token}`
          }
        })
        .then(res => res.json())
        .then(res => {
          setValorEscaneo("")
          if(!res){
            toast.error('No hubo respuesta del servidor')
          } else if(res.errorMessage){
            toast.error(res.errorMessage)
          } else if(res._id){
              setEtiquetaActual(res)
              etiquetas.unshift(res)
              setEtiquetas(prev => [...[], ...etiquetas])
          }
          setLoadingScan(false)
        })
        .catch(error => {
          setLoadingScan(false)
          return toast.error(error.message)
        })
  }

    const crearNuevaEtiqueta = async (nuevo) => {
      setLoadingScan(true)
      const url = `${urlapi}/bodega/etiquetas/new-label`
      return fetch(url,{
          method: 'POST',
          body: JSON.stringify({
              escaneada: true,
              isExtra: true,
              value: nuevo ? nuevo : valorEscaneo,
              id_lote: ruta._id
          }),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer: ${token}`
          }
        })
        .then(res => res.json())
        .then(res => {
          setValorEscaneo("")
          if(!res){
            toast.error('No hubo respuesta del servidor')
          } else if(res.errorMessage){
            toast.error(res.errorMessage)
          } else if(res._id){
              setEtiquetaActual(res)
              etiquetas.unshift(res)
              setEtiquetas(prev => [...[], ...etiquetas])
          }
          setLoadingScan(false)
        })
        .catch(error => {
          setLoadingScan(false)
          return toast.error(error.message)
        })
  }

    const asociarLabelNumber = () => {
      setLoadingScan(true)
      const url = `${urlapi}/bodega/etiquetas/scan-associate-labelnumber`
        return fetch(url,{
            method: 'POST',
            body: JSON.stringify({
                valor: valorEscaneo,
                id_etiqueta: etiquetaActual._id
            }),
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer: ${token}`
            }
          })
          .then(res => res.json())
          .then(res => {
            console.log(res)
            setValorEscaneo("")
            if(!res){
              toast.error('No hubo respuesta del servidor')
            } else if(res.errorMessage){
              toast.error(res.errorMessage)
            } else if(res.success === true ){
              if(res.resultado){
                const i = etiquetas.findIndex(et => et._id === res.resultado._id)
                console.log({ i })
                if(i > -1){
                  const nueva_lista = etiquetas
                  nueva_lista[i] = res.resultado
                  setEtiquetas(prev => [...[], ...nueva_lista])
                }
              }
              setEtiquetaActual(false)
            }
            setLoadingScan(false)
          })
          .catch(error => {
            setLoadingScan(false)
            return toast.error(error.message)
          })
    }

    const escanear = async () => {
      if(!valorEscaneo) return false
      if(etiquetaActual){
        if(tipoEscaneo === "orden"){
          return asociarOrden()
        } else {
          return asociarLabelNumber()
        }
      }
      const url = `${urlapi}/bodega/etiquetas/scan`
      setLoadingScan(true)
      return fetch(url,{
          method: 'POST',
          body: JSON.stringify({
              valor: valorEscaneo,
              id_lote: ruta._id
          }),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer: ${token}`
          }
        })
        .then(res => res.json())
        .then(res => {
          console.log(res)
          setValorEscaneo("")
          if(!res){
            toast.error('Ocurrió un error', 'No hubo respuesta del servidor')
          } else if(res.errorMessage){
            toast.error('Ocurrió un error', res.errorMessage)
          } else if(res.success === true ){
              if(res.status === "EXTRA"){
                setLoadingScan(false)
                return confirmAlert({
                  title: `Atención`,
                  message: res.message,
                  buttons: [
                    {
                      label: 'CONFIRMAR',
                      onClick: () => crearNuevaEtiqueta()
                    },
                    {
                      label: 'CANCELAR',
                      onClick: () => false
                    }
                  ]
                })
              } else {
                setMensaje_escaneo("ESCANEA EL CÓDIGO ETIQUETA")
                setEtiquetaActual(res.resultado)
              }
              if(res.message) toast.error(res.message)
          }
          setLoadingScan(false)
        })
        .catch(error => {
          setLoadingScan(false)
          return toast.error(error.message)
        })
  }

  const seleccionarTipoEscaneo = (value) => {
    setTipoEscaneo(value)
    inputEscaneo.current.focus()
  }

  const opcionesEscaneo = () => {

    let estilo_seleccionado = {
      bg: "success",
      text: "text-light"
    }

    let estilo_no_seleccionado = {
      bg: "white",
      text: "text-dark"
    }

    return <Row className='mb-3'>
    <Col>
      <Card className={`p-3 hover text-center`} bg={tipoEscaneo === "orden" ? estilo_seleccionado.bg : estilo_no_seleccionado.bg} onClick={() => seleccionarTipoEscaneo("orden")}>
        <h6 className={`mb-0 ${tipoEscaneo === "orden" ? estilo_seleccionado.text : ''} `}>ASOCIAR ORDEN</h6>
      </Card></Col>
    <Col>
      <Card className={`p-3 hover text-center`} bg={tipoEscaneo === "labelnumber" ? estilo_seleccionado.bg : estilo_no_seleccionado.bg} onClick={() => seleccionarTipoEscaneo("labelnumber")}>
        <h6 className={`mb-0 ${tipoEscaneo === "labelnumber" ? estilo_seleccionado.text : estilo_no_seleccionado.text} `}>ASOCIAR LABELNUMBER</h6>
      </Card>
    </Col>
  </Row>
  }

    const moduloEscaneo = () => {
      return <div className='mb-3'>
        <h4>ESCANEO</h4>
            <p className='mb-2'>Para una mejor experiencia, utiliza el <a href="" target='_blank'><b>App LastMile Bodega</b></a> </p>
            <form onSubmit={handleSubmitCodigo}>
              {opcionesEscaneo()}
              { loadingScan ? <Spinner animation='border' /> : <input ref={inputEscaneo} autoFocus placeholder={mensaje_escaneo} value={valorEscaneo} className='form-control' onChange={handleChangeInputEscaneo} /> }
            </form>
      </div>
    }

    const mostrarEtiquetasBase = () => {

      if(etiquetasBase.length < 1) return false
      return <div>
          <h6>{etiquetasBase.length} Valores cargados</h6>
          {
            loadingAnalisisCruzado ? <Spinner animation='border' /> : <Button size="sm" className='mb-3' variant="outline-secondary" onClick={() => descargarAnalisisCruzado()}>DESCARGAR ANÁLISIS CRUZADO</Button>
          }
        <Table>
              <thead>
                <tr>
                  <th>LABELNUMBER</th>
                </tr>
              </thead>
            <tbody>        

          {
            etiquetasBase.map((ob,i) => {
              return <tr key={`l-${i}-${ob.value}`}>
                <th>{ob.value}</th>
              </tr>
            })
          }
          </tbody>
          </Table>
          </div>
    }

    const solicitarEliminarLote = (id) => {
      return confirmAlert({
          title: `Eliminar lote`,
          message: `Confirma que deseas eliminar este recurso, esta acción no se puede deshacer`,
          buttons: [
            {
              label: 'CONFIRMAR',
              onClick: () => confirmarEliminado(ruta._id)
            },
            {
              label: 'CANCELAR',
              onClick: () => false
            }
          ]
        })
    }

    const modalEscaneo = () => {
      return <Modal show={openModalEscaneo} onHide={()=> handleClose()} centered 
      backdrop="static"
      keyboard={false}
      >
          <Modal.Header closeButton >
            <Modal.Title>FORMATO BASE</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className='mb-2'>En esta sección podrás subir un archivo de labelnumber esperados para cotejar contra el reporte de este lote</p>
            <BuscadorExcel onChangeValue={(e) => onChangeValueExcel(e)} token={token} />
            <label className='form-control-label'>Sube un archivo de etiquetas</label>
                <input
                    type="file" 
                    name="file" 
                    id="file" 
                    className="form-control mb-3"
                    onChange={handleInputChangeBase} 
                    placeholder="Archivo de excel" 
                />
                { etiquetasBase.length > 0 ? <div>

                  <Button size="sm" className='mb-3 mr-3' variant="outline-primary" onClick={() => setEtiquetasBase([]) }>LIMPIAR IMPORTACIÓN</Button>
                  {
                    loadingScan ? <Spinner animation='border' /> : <Button size="sm" className='mb-3 mr-3' variant="success" onClick={() => actualizarLote() }>GUARDAR CAMBIOS</Button>
                  }

                </div> : false }

                {mostrarEtiquetasBase()}
          </Modal.Body>
        </Modal>
    }

    return <div style={{ height: "90vh", overflowY: "scroll", overflowX: "hidden" }}>
      {mostrarModalParadas()}
        <Row>
            <Col md={4}>
              <Card bg="primary" className="p-3 mb-3 text-white">
                <p style={{ fontSize: 11 }} className="mb-0"><b>Creado</b> {fechaATexto(ruta.createdAt)}</p>
                <Row>
                  <Col xs={9}>
                    <h3 className="mt-2 mb-0">Lote {ruta.id} {loadingExcel===true?<Spinner animation='border'/>:false}</h3>
                  </Col>
                  <Col xs={3} className="text-right">
                  <Dropdown>
                    <Dropdown.Toggle  id="dropdown-basic"></Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => descargarReporte(ruta._id)}>EXPORTAR</Dropdown.Item>
                      <Dropdown.Item onClick={() => abrirOpcionesEscaneo()} >FORMATO BASE</Dropdown.Item>
                      <Dropdown.Item className="text-danger" onClick={() => solicitarEliminarLote()}>ELIMINAR</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  </Col>
                </Row>
                    <hr className="hr" />
                    <h5><i className="fas fa-barcode mb-0"></i> Etiquetas totales</h5>
                    <Row>
                      <Col md={4}>
                        <h6 style={{ fontSize: 11 }} className='mb-0'>Totales</h6>
                        <p>{etiquetas.length}</p>
                      </Col>
                      <Col md={4}>
                        <h6 style={{ fontSize: 11 }} className='mb-0'>Escaneadas</h6>
                        <p>{totales_escaneadas}</p>
                      </Col>
                      <Col md={4}>
                        <h6 style={{ fontSize: 11 }} className='mb-0'>Asociadas</h6>
                        <p>{totales_asociadas}</p>
                      </Col>
                    </Row>

                    <hr className="hr" />
                    <h5><i className="fas fa-barcode mb-0"></i> Etiquetas esperadas</h5>
                    <Row>
                      <Col md={4}>
                        <h6 style={{ fontSize: 11 }} className='mb-0'>Totales</h6>
                        <p>{etiquetas_reales}</p>
                      </Col>
                      <Col md={4}>
                        <h6 style={{ fontSize: 11 }} className='mb-0'>Escaneadas</h6>
                        <p>{escaneadas}</p>
                      </Col>
                      <Col md={4}>
                        <h6 style={{ fontSize: 11 }} className='mb-0'>Asociadas</h6>
                        <p>{asociadas}</p>
                      </Col>
                    </Row>
                    <hr className="hr" />
                    <h5><i className="fas fa-barcode mb-0"></i> Etiquetas extra</h5>
                    <Row>
                      <Col md={4}>
                        <h6 style={{ fontSize: 11 }} className='mb-0'>Totales</h6>
                        <p>{extra}</p>
                      </Col>
                      <Col md={4}>
                        <h6 style={{ fontSize: 11 }} className='mb-0'>Escaneadas</h6>
                        <p>{extra_escaneadas}</p>
                      </Col>
                      <Col md={4}>
                        <h6 style={{ fontSize: 11 }} className='mb-0'>Asociadas</h6>
                        <p>{extra_asociadas}</p>
                      </Col>
                    </Row>
                <hr className="hr" />
                <h6>Crear etiquetas masivas</h6>
                <input className='form-control' placeholder='Número de etiquetas a crear' name="numeroEtiquetas" type="number" onChange={handleChangeStatus} />
                { loadingOptimizacion ? <Spinner animation="border" /> : <Button variant="dark" size="sm" onClick={() => crearEtiquetasPorNumero(ruta._id)}>CREAR {numeroEtiquetas ? numeroEtiquetas : false} ETIQUETAS</Button> }
                <a target="_blank" href={`${urlapi}/bodega/etiquetas/lotes/download?id=${ruta._id}`}>EXPORTAR</a>
                <hr className="hr" />
                <h5 >Subir mi archivo de etiquetas con números propios</h5>
                <a href={`/models/labels_numbers.xlsx`} ><Button variant="outline-light" size="sm" className="mb-1">DESCARGAR MODELO EXCEL</Button></a>
                
                <hr className="hr" />
                <label className='form-control-label'>Sube un archivo de etiquetas</label>
                <input
                    type="file" 
                    name="file" 
                    id="file" 
                    className="form-control mb-3"
                    onChange={handleInputChange} 
                    placeholder="Archivo de excel" 
                />
                { loadingOptimizacion ? <Spinner animation="border" /> : <div>
                    { etiquetasNuevas.length > 0 ? <Button variant="dark" size="sm" style={{ width: "100%" }} className="mb-3" onClick={() => optimizarPlan(ruta._id)}>CREAR {etiquetasNuevas.length} ETIQUETAS DE EXCEL</Button> : false }
                </div> }
                
              </Card>

              {mostrarEtiquetasRepetidas()}
              <Accordion defaultActiveKey="paradas">
                        <Card className="p-0">
                            <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor:'white' }} eventKey={`paradas`}>
                            <div className="ml-3 pb-0">
                            <h5 className="mb-0">¿Cómo funciona?</h5>
                            </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={`paradas`}>
                            <Card.Body>
                              <p className='mb-0'>Aquí va información relevante para los usuarios</p>
                              
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className="p-0">
                            <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor:'white' }} eventKey={`vehiculos`}>
                            <div className="pl-3 pb-0">
                            <h5 className="mb-0">Recursos</h5>
                            </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={`vehiculos`}>
                            <Card.Body>
                              <p className='mb-0'>Descarga la App de Bodega en el siguiente link</p>
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                </Accordion>

            </Col>
            <Col md={8}>
                {modalEscaneo()}
                {moduloEscaneo()}
                {mostrarEtiquetaActual()}
                <EtiquetasStatic etiquetas={etiquetas} />
            </Col>
        </Row>
    </div>
}

export default DetailFull