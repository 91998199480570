const obtenerCodigo = code => {
    if(!code) return 101010
    if(code==='0') return 101010
    if(typeof code === 'undefined') return 101010
    if(isNaN(parseInt(code)) !== false) return 101010
    return code
}

export const procesarDatosGraficos = ordenes => {
    if(!ordenes) return { datos:[], colores:[], encabezados:[] }
    if(Array.isArray(ordenes) !== true) return { datos:[], colores:[], encabezados:[] }
    if(ordenes.length < 1) return { datos:[], colores:[], encabezados:[] }
    let estados = []
    let encabezados = ['Pedidos']
    let datos = ['Estado']
    let colores = []
    for( const orden of ordenes ){
        if(!orden) continue
        let estado = {
            code: 101010,
            titulo: "Sin datos",
            color: '#dbdbde'
        }
        if(typeof orden.estado_entrega === 'object'){
            estado = {
                code: obtenerCodigo(orden.estado_entrega.codigo_estado),
                titulo: orden.estado_entrega.titulo,
                color: orden.estado_entrega.color
            }
        }
        const i = estados.findIndex(es => es.code === estado.code)

        if(i > -1){
            estados[i].cantidad = estados[i].cantidad + 1
        } else {
            estados.push({
                code: obtenerCodigo(estado.code),
                titulo: estado.titulo,
                color: estado.color,
                cantidad: 1
            })
        }
    }
    
    for( const estado of estados){
        colores.push(estado.color)
        datos.push(estado.cantidad)
        encabezados.push(estado.titulo)
    }

    return { datos: [ encabezados, datos ], colores }
}