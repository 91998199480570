import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Card, Nav, Tab, Tabs } from 'react-bootstrap'
import Header from '../Header'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'react-bootstrap'
import { key_local_storage_user } from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import ApiKeysListado from '../../subComponents/integraciones/apikeys/apikeys_listado'
import Webhooks from '../../subComponents/integraciones/webhooks/webhooks'
import EstadosCarga from '../EstadosCarga'
import FlujosEstadosCarga from '../../subComponents/estados_carga/flujos'
import CamposPersonalizadosListado from '../../subComponents/campos-personalizados/list'
import ExcelFormats from '../ExcelFormats'
import ExcelFormatosListado from '../../subComponents/excel-formats/listado'
import TiposServicioListado from '../../subComponents/ordenes/tipos_servicio/list'
import ListadoCanales from '../../subComponents/canales/listado'
import ServiciosListado from '../../subComponents/servicios/list'
import CostosListado from '../../subComponents/costos-tipos/list'

class Configuracion extends Component {
    constructor(){
        super()
        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            errorLogin: '',
            user: JSON.parse(localStorage.getItem(key_local_storage_user)),
            loadinguser: true,
            loadinginsignias: true,
            insignias: []
        }
    }

    ifactive(status){
      switch(status){
        case true:
          return <p className="nomargin" style={{ fontSize: 12 }} ><i style={{ color: '#00b23d'}} className="fas fa-check-circle"></i> ACTIVO</p>
        default:
            return <p className="nomargin" style={{ fontSize: 12 }} ><i className="far fa-clock"></i> INACTIVO</p>
      }
    }
    
    misDatos(){
      const { user } = this.state
      return <Row className="bienvenida">

      
        <Col xs={12} className="mt-3"> <h5>Mis datos</h5>  </Col>

        <Col md={3}>
        <Card >
          <Card.Body>
            <p className="text-primary nomargin" >Estado del usuario</p>
            <p className="nomargin" style={{ fontSize: 12 }} >{this.ifactive(user.data.activo) }</p>
          </Card.Body>
        </Card>
        </Col>


        <Col md={3}>
        <Card >
          <Card.Body>
            <p className="text-primary nomargin" >Email</p>
            <p className="nomargin" style={{ fontSize: 12 }} >{user.data.email }</p>
          </Card.Body>
        </Card>
        </Col>

      </Row>

    }

    render(){
        const { user } = this.state
        return(
            <div className="fluid">
        <h1 className="h2">Configuración general</h1>
        <Tab.Container id="left-tabs-example" defaultActiveKey="estados_carga">
      <Row>
        <Col sm={3}>
          <Nav variant="pills" className="flex-column">
            <Nav.Item><Nav.Link eventKey="estados_carga">Estados de carga</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link eventKey="flujos_carga">Flujo de estados de carga</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link eventKey="campos_personalizados">Campos personalizados de órdenes</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link eventKey="tipos_servicio">Tipos de servicio</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link eventKey="servicios">Servicios</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link eventKey="canales">Canales</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link eventKey="formatos_excel">Formatos excel</Nav.Link></Nav.Item>
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane eventKey="estados_carga" >
                <EstadosCarga />
            </Tab.Pane>
            <Tab.Pane eventKey="flujos_carga" >
              <FlujosEstadosCarga user={user} />
            </Tab.Pane>
            <Tab.Pane eventKey="campos_personalizados" >
              <CamposPersonalizadosListado token={user.tokenSession} condicion_default={{}} />
            </Tab.Pane>
            <Tab.Pane eventKey="canales" >
            <ListadoCanales />
            </Tab.Pane>
            <Tab.Pane eventKey="tipos_servicio" >
              <TiposServicioListado token={user.tokenSession} condicion_default={{ tipo: "logistica", sub_tipo: "tipos-servicio" }} />
            </Tab.Pane>
            <Tab.Pane eventKey="servicios" >
              <ServiciosListado condicion_default={{}} />
            </Tab.Pane>
            <Tab.Pane eventKey="formatos_excel" >
              <ExcelFormatosListado token={user.tokenSession} condicion_default={{}} />
            </Tab.Pane>
          </Tab.Content>
        </Col>
        </Row>
      </Tab.Container>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Configuracion);