import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Header from '../Header'
import { key_local_storage_user } from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import 'moment/locale/es';
import AsignacionesListado from '../../subComponents/rutas/asignaciones/listado'

const RutasAsignaciones = (props) => {

    return <div className="fluid"><AsignacionesListado condicion_default={{}} /></div>
}

export default RutasAsignaciones