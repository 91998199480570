import { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Pagination, Card, OverlayTrigger, Tooltip, Spinner, Form } from 'react-bootstrap'
import data, { configs3 } from '../../../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import { confirmAlert } from 'react-confirm-alert'
import S3FileUpload from "react-s3/lib/ReactS3"
import { useSelector } from 'react-redux';

const BeetrackPluginConfig = (props) => {
    const [ conductores, setConductores ] = useState([])
    const [ creando, setCreando ] = useState(false)
    const [ nuevaKey, setNuevaKey ] = useState({})
    const [ openModal, setOpenModal ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const [ showModal, setShowModal ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ config, setConfig ] = useState({ tipo: 'importador', sub_tipo: 'beetrack_api_token', descripcion: "Beetrack", nombre: "v1", })
    const [ keyCreada, setKeyCreada ] = useState(false)
    const token = session.tokenSession

    const guardarCambios = async () => {
        const requeridos = []
        let faltantes = []
        requeridos.map(campo => {
            if(!config[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)
        setCreando(true)
        return fetch(`${data.urlapi}/integraciones/plugin`, {
            method:'PUT',
            body: JSON.stringify(config),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            setCreando(false)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Actualizado exitosamente")
                setConfig(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            setCreando(false)
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })

    }

    const obtenerConfig = async () => {
        return fetch(`${data.urlapi}/integraciones/plugin?tipo=${config.tipo}&subtipo=${config.sub_tipo}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
            } else if(res._id){
                setConfig(prev => ({...prev, ...res}))
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const handleClose = () => {
        setShowModal(false)
    }

    const handleChangeNuevaKey = (e) => {
        const { name, value } = e.target
        config[name] = value
        console.log(config)
        return setConfig(config)
    }

    const mostrarFormulario = () => {
        if(loading) return cargandoScreen()
        return <div>
            <Row>
                <Col xs={12} className="mb-2">
                    <label className='d-block form-control-label'>Llave</label>
                    <input className='form-control' name="valor" defaultValue={config.valor} onChange={handleChangeNuevaKey} />
                </Col>
                <Col xs={6} className="mb-2">

                </Col>
                <Col md={12}>
                    { creando === true ? <Spinner animation='border' /> : <Button size="sm" variant="success" onClick={() => guardarCambios()}>GUARDAR CAMBIOS</Button> }
                </Col>
            </Row>
        </div>
    }
    const modalFiltro = () => {

        return <Modal show={showModal} size="lg" onHide={handleClose}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Configuración de beetrack</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {mostrarFormulario()}
        </Modal.Body>
        
      </Modal>
    }


    useEffect(() => {
        obtenerConfig()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const cargandoScreen = () => {
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }
    

    return <Card className='p-2 mb-3 text-center centrador' style={{ minHeight: 130 }} >
        <div>
        {modalFiltro()}
        <img alt="BEETRACK" onClick={() => setShowModal(true)} src="https://miro.medium.com/v2/resize:fit:1400/1*UGMshcHev8UL-RSDgt0IEg.png" style={{ maxHeight: 100, maxWidth: "70%", margin: "0 auto" }} /> 

        </div>
    </Card>

}

export default BeetrackPluginConfig