const { Card, Row, Col } = require("react-bootstrap")
const { obtenerGeoValor } = require("../../lib/helpers/pedidos/pedidos")

const MostrarGeoDatos = (props) => {
    const pedido = props.pedido ? props.pedido : false
    if(!pedido) return false
    return (
        <Card className="p-3 mt-2">
            <h5>GEO DATOS <a href={`https://google.com/maps?q=${obtenerGeoValor(pedido,"lat")},${obtenerGeoValor(pedido,"lng")}`} target="_blank"><i className="fas text-warning fa-map-marker-alt"></i></a></h5>
            <h6>País: {obtenerGeoValor(pedido,"pais")}</h6>
            <p style={{ fontSize: 11 }}><b>{obtenerGeoValor(pedido,"formatted_address")}</b></p>
            <Row>
                <Col><h6 style={{ fontWeight: "lighter", fontSize: 13 }}>Nivel 1: <b>{obtenerGeoValor(pedido,"level1")}</b></h6></Col>
                <Col><h6 style={{ fontWeight: "lighter", fontSize: 13 }}>Nivel 2: <b>{obtenerGeoValor(pedido,"level2")}</b></h6></Col>
                <Col><h6 style={{ fontWeight: "lighter", fontSize: 13 }}>Nivel 3: <b>{obtenerGeoValor(pedido,"level3")}</b></h6></Col>
            </Row>                                            
        </Card>
    )
}

export default MostrarGeoDatos