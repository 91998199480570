import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { configuracion_fechas, configuracion_horas, formatDate } from '../../lib/helpers/dates';
import { statusColor, statusString } from '../../lib/helpers/logistica';
import { canalEstadosString } from '../../lib/helpers/pedidos/pedidos';
import { rutas } from '../../lib/routes/routes';
import { Link } from 'react-router-dom'
import { Button, Spinner } from 'react-bootstrap';
import { urlapi } from '../../lib/backend/data';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
const DetailEstadosCarga = (props) => {
    const {
      size,
      hideMetaData,
      hideCreator,
      showLink
    } = props
    const estado = props.estado ? props.estado : false
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const [ loading, setLoading ] = useState(false)

    const auditarEstado = async (opcion) => {
      setLoading(true)
      const url = `${urlapi}/flota/auditoria-pedido?id=${estado._id}&opcion=${opcion}`;
      return fetch(url,{
          method:'GET',
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${token}`
          }
      })
      .then(pros => pros.json())
      .then(pros => {
          if(!pros){
              toast.error('Ocurrió un error inesperado, Sin respuesta del servidor')
          } else if(pros.errorMessage){
              toast.error(`Ocurrió un error inesperado: ${pros.errorMessage}`, )
          } else if(pros._id){
            if(props.onDelete) props.onDelete(pros._id)
          }
          setLoading(false)
      })
      .catch(error => {
          toast.error('Ocurrió un error inesperado')
          setLoading(false)
      })
  }

    const mostrarMetaDatosSubEstado = (metadatos) => {
      if(hideMetaData === true) return false
        if(!metadatos) return false
        if(Array.isArray(metadatos) !== true) return false
        if(metadatos.length < 1) return false
  
        return metadatos.map((meta,i) => {
          return <div key={`meta-${i}`}>
            {showComponentByTipoFormulario(meta)}
          </div>
        })
    }

    const sizeImage = () => {
      if(size==="sm") return 50
      return 100
    }

    const showComponentByTipoFormulario = (campo = Object) => {
      const height = sizeImage()
        switch (campo.tipo_accion) {
          case 'campo-texto':
            return <div>
              <h6 className='mb-0' style={{ fontSize: 14 }}>{campo.titulo}</h6>
              <p className='mb-0'>{campo.valor ? campo.valor : 'Sin información'}</p>
            </div>
          case 'selector':
            return <div>
              <h6 className='mb-0' style={{ fontSize: 14 }}>{campo.titulo}</h6>
              <p className='mb-0'>{campo.valor ? campo.valor : 'Sin información'}</p>
            </div>
          case 'carga-imagenes':
            return <div>
              <h6 className='mb-0' style={{ fontSize: 14 }}>{campo.titulo}</h6>
              {
                campo.valores.length > 0 ? campo.valores.map((foto,i) => {
                  return <a href={foto.url} target="_blank" key={`foto-estado-${i}`} style={{ display:"inline-block", paddingRight: 5}}>
                    <img src={foto.url} style={{  height }} ></img>
                    </a>
                }) : <div className='col-md-12'><p>Sin información</p></div>
              }
            </div>
          default:
            break;
        }
      }

      const sizeSpan = () => {
        if(size==="sm") return 10
        return 14
      }

      const mostrarCreador = (dato,ref) => {
        if(hideCreator === true) return false

        return <div>
            <hr className='hr' />
            <div className='row'>
            <div className='col-md-4'><h6 className='mb-0' style={{ fontSize: 14 }}>REF: <b>{ref}</b></h6></div>  
            <div className='col-md-4'><h6 className='mb-0' style={{ fontSize: 14 }}>{dato.identificador_creador}</h6></div>  
            <div className='col-md-4'><h6 className='mb-0' style={{ fontSize: 14 }}>{canalEstadosString(dato.canal)}</h6></div>  
            </div>
            <hr className='hr' />
        </div>
      }

    const mostrarSubEstadoHTML = (sub_estado_entrega = String) => {
      const fonSize = sizeSpan()
        if(sub_estado_entrega){
          if(typeof sub_estado_entrega === 'object'){
            if(sub_estado_entrega.titulo){
              return <span className="spanstatus mr-2 text-white" style={{ fontSize: fonSize, padding: '2px 10px', borderRadius:5, backgroundColor: sub_estado_entrega.color ? sub_estado_entrega.color : '#a2a2a2' }}>{sub_estado_entrega.titulo}</span>
            }
          }
        }
        return false
    }

    const accionesAuditoria = () => {
      if(estado.notificar_planel_flota !== true) return false
      if(estado.auditado !== false) return false
      return <div>
        { loading === true ? <Skeleton height={20} /> : <Button size="sm" style={{ fontSize: 10 }} onClick={() => auditarEstado("1")} variant='outline-dark'>MARCAR AUDITADO</Button> }
      </div>
    }

    const mostrarLink = (id) => {
      if(showLink === true) return <a target="_blank" href={`/${rutas.ordenes.slug}/${id}`}>Ver orden <i className="fas fa-external-link-alt"></i></a>
    }

    if(!estado) return <h6>Sin información</h6>
    
    const fecha = new Date(estado.fecha)
    let pedido = ''

    if(estado.detalles){
      if(typeof estado.detalles === 'object'){
        pedido = estado.detalles.pedido
      }
    }

    const vistaSM = () => {
      const fonSize = sizeSpan()
      return <div className='card shadow mb-3'>
      <div className='p-2'>
      {accionesAuditoria()}
      <p className='mb-0'>{fecha.toLocaleDateString('es-ES', configuracion_fechas )} · <b>{fecha.toLocaleTimeString('en-US', configuracion_horas)}</b> {mostrarLink(estado.idrecurso)}</p>
      <span className="spanstatus mr-2 text-white" style={{ fontSize: fonSize, padding: '2px 10px', borderRadius:5, backgroundColor: estado.estado_color ? estado.estado_color : '#babbbb' }}>{estado.estado}</span>
      {mostrarSubEstadoHTML({ titulo: estado.subestado, color: estado.subestado_color })}
      { /* <button className='btn btn-sm btn-outline-primary' style={{ fontSize: 12 }} onClick={()=>obtenerPedido(estado.idrecurso)} >VER DETALLES DE LA ORDEN</button> */ }
      {mostrarCreador(estado, pedido)}
      {mostrarMetaDatosSubEstado(estado.metadatos)}
      </div>
    </div>
    }

    if(size === "sm") return vistaSM()
                            
    return <div className='card shadow mb-3'>
      <div className='p-3'>
      <p className='mb-0'>{fecha.toLocaleDateString('es-ES', configuracion_fechas )} · <b>{fecha.toLocaleTimeString('en-US', configuracion_horas)}</b> {mostrarLink(estado.idrecurso)}</p>
      {accionesAuditoria()}
      <span className="spanstatus mr-2 text-white" style={{ padding: '2px 10px', borderRadius:5, backgroundColor: estado.estado_color ? estado.estado_color : '#babbbb' }}>{estado.estado}</span>
      {mostrarSubEstadoHTML({ titulo: estado.subestado, color: estado.subestado_color })}
      { /* <button className='btn btn-sm btn-outline-primary' style={{ fontSize: 12 }} onClick={()=>obtenerPedido(estado.idrecurso)} >VER DETALLES DE LA ORDEN</button> */ }
      {mostrarCreador(estado, pedido)}
      {mostrarMetaDatosSubEstado(estado.metadatos)}
      </div>
    </div>
}

export default DetailEstadosCarga