import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Header from '../Header'
import { key_local_storage_user } from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import 'moment/locale/es';
import ConductoresListado from '../../subComponents/conductores/list'

class Vehiculos extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem(key_local_storage_user))
        }
    }
    render(){
        const { user } = this.state
        return <div className="fluid">
              <h4>Vehículos <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >Crear nuevo</Tooltip>
                            }
                            >
                            <Link to="/vehiculos/nuevo" className='mr-2'><i className="fa-solid fa-circle-plus"></i></Link>
                          </OverlayTrigger>              
              </h4>
              <Row>
                  <Col md={12}>
                    <ConductoresListado token={user.tokenSession} condicion_default={{}} />
                  </Col>
              </Row>
      </div>
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Vehiculos);