import { useState } from "react"
import { urlapi } from "../../../lib/backend/data"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { Spinner } from "react-bootstrap"

const EscaneoBultoInput = (props) => {
    const [ codigo, setCodigo ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const session = useSelector(state => state.miusuario)

    const handleSubmitEstaneo = (e) => {
        e.preventDefault()
        consultarCodigo()
    }

    const consultarCodigo = async () => {
        if(!codigo) return toast.error("Marca un código primero")
        setLoading(true)
        return fetch(`${urlapi}/bodega/expediciones/escanear-bulto`, {
            method: 'POST',
            body: JSON.stringify({
                idbulto: codigo
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
          })
          .then(pros => {
            setCodigo('')
            return pros.json()
          })
          .then(async pros => {
              if(!pros){
                toast.error('Sin datos del servidor')
              } else if(pros.errorMessage){
                toast.error(pros.errorMessage)
              } else if(pros._id){
                toast.success("Escanedo exitosamente")
                if(props.onScanned) props.onScanned(pros)
              }
              setLoading(false)
          })
          .catch(async error => {
              console.log(error)
              setLoading(false)
            return toast.error("No pudimos consultar la información de este pedido, intente de nuevo recargando la página")
          })
    }

    const handleChangeCodigo = (e) => {
        const { value } = e.target
        return setCodigo(value)
    }

    const formulario = () => {
        if(loading) return <Spinner animation="border" />

        return <form onSubmit={handleSubmitEstaneo}>
            <label className="form-control-label d-block"><i className="text-success fa-solid fa-circle-plus"></i> AGREGAR BULTO</label>
            <input className="form-control" value={codigo} placeholder="ESCANEA BULTO AQUÍ" autoFocus onChange={handleChangeCodigo} />
        </form>
    }

    return <div>
        {formulario()}
    </div>
}

export default EscaneoBultoInput