import { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { DateRangePicker } from 'react-date-range';
import { es } from 'date-fns/locale'

const FiltroFechas = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const defaultValue = typeof props.defaultValue !== "undefined" ? props.defaultValue : "todos"
    const [ tipoFechaBusqueda, setTipoFechaBusqueda ] = useState(defaultValue)

    const handleChangeFechaBusqueda = (e) => {
        const { value } = e.target
        if(props.onChangeTipoBusqueda) props.onChangeTipoBusqueda(value)
        return setTipoFechaBusqueda(value)
    }

    const cambiarRangoFecha = (item) => {
        if(props.onChangeFecha) props.onChangeFecha(item.selection)
        return setSelectionRange(item.selection)
    }

    return <div>
        <Row>
            <Col xs={12} className="mb-2">
                    <label className='d-block form-control-label'>Fecha</label>
                    <select name="tipo_fecha" className='form-control' value={tipoFechaBusqueda} onChange={handleChangeFechaBusqueda} >
                        <option value="creacion">Creación</option>
                        <option value="actualizacion">Actualización</option>
                        <option value="maxima-entrega">Máxima fecha entrega</option>
                        <option value="todos">Cualquiera</option>
                    </select>
            </Col>
            {
                    tipoFechaBusqueda !== "todos" ? <Col xs={12} className="mb-2">
                    <DateRangePicker
                        locale={es}
                        showSelectionPreview={true}
                        editableDateInputs={true}
                        onChange={item => cambiarRangoFecha(item)}
                        moveRangeOnFirstSelection={false}
                        ranges={[selectionRange]}
                        direction="vertical"
                        scroll={{ enabled: true }}
                        months={1}
                    />
                    </Col> : false
                }
        </Row>
    </div>
}

export default FiltroFechas