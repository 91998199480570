import { Card, Col, Row } from "react-bootstrap"
import { fechaATexto } from "../../lib/helpers/helpers"

const BoxList = (props) => {
    const {
        registro
    } = props
    const seleccionado = props.seleccionado ? props.seleccionado : false
    
    const clickPedido = (id) => {
        if(props.onClick) return props.onClick(id)
        return false
    }

    return <Card bg={ seleccionado === true ? 'primary' : 'white' } className={`hover p-3 ${seleccionado === true ? 'text-white' : 'text-dark'}`} onClick={() => clickPedido(registro._id)}>
        <h5 className="mb-0">{registro.titulo}</h5>
        <p className="mb-0">{registro.descripcion ? registro.descripcion.substring(0, 35) : '' }{registro.descripcion.length >= 35 ? '...' : false}</p>
        <h6 style={{ fontSize:13, fontWeight:'lighter' }} className="mb-0">{ fechaATexto(registro.createdAt)}</h6>
    </Card>
}

export default BoxList