import { useEffect, useState } from "react"
import { Button, Col, Modal, Row } from "react-bootstrap"
import Select from "react-select"
import { toast } from 'react-toastify';
import data from '../../../lib/backend/data'
import { useSelector } from "react-redux";

const SelectorBodega = (props) => {
    const {
        isMulti
    } = props
    const [ openModal, setOpenModal ] = useState(false)
    const [ proveedores, setProveedores ] = useState([])
    const [ bodegasDatos, setBodegasDatos ] = useState([])
    const [ loadingProveedores, setLoadingProveedores ] = useState(true)
    const [ seleccionProveedores, setSeleccionProveedores ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const seleccionMultiple = typeof isMulti !== "undefined" ? isMulti : false

    const obtenerProveedores = async (condicion)=>{
        setLoadingProveedores(true)
        return fetch(`${data.urlapi}/bodega/list-full`,{
            method:'POST',
            body: JSON.stringify({
                condicion
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingProveedores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingProveedores(false)
            } else if(Array.isArray(res) !== false){
                const opciones = JSON.parse( JSON.stringify(res) )
                let data = res.map(p => ({ value: p._id, label: p.titulo }))
                setProveedores([...[{ value: "", label: "Seleccione"}], ...data])
                setBodegasDatos(opciones)
            }
            return setLoadingProveedores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingProveedores(false)
        })
    }

    useEffect(()=>{
        obtenerProveedores({})
    },[])

    const abrirFiltro = () => {
        return setOpenModal(true)
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const handleChangeProveedor = (e) => {
        if(!e) return false
        if(seleccionMultiple === true){
            let opciones = []
            if(e){
                e.map(valor => {
                    opciones.push(valor.value)
                })
            }
            if(props.onChange) props.onChange(opciones)
            return setSeleccionProveedores(opciones)
        } else {
            const i = bodegasDatos.findIndex(bodega => bodega._id === e.value)
            let resultado = false
            if(i > -1){
                resultado = bodegasDatos[i]
            }
            if(props.onChange) props.onChange(resultado)
            return setSeleccionProveedores(e)
        }
    }

    const actualizar = async () => {
        if(seleccionProveedores.length > 0){
            if(props.onActualizar) props.onActualizar(seleccionProveedores)
            setOpenModal(false)
        }
        return 
    }


    return <div className="mb-3">
                    <label className='d-block form-control-label'>Seleccione la bodega de origen</label>
                    <Select
                                    isLoading={loadingProveedores} 
                                    loadingMessage={()=>'Cargando información'}
                                    placeholder="Selecciona una opción"
                                    noOptionsMessage={()=>'Sin opciones'}
                                    onChange={handleChangeProveedor}
                                    isMulti={seleccionMultiple}
                                    options={proveedores} 
                                />
    </div>
}

export default SelectorBodega