import { getDaysInMonth } from "date-fns"
import { DateTime } from "luxon"
import { timezone } from "../backend/data"

export const procesarDatosGraficosEstadisticaConductor = ordenes => {
    if(!ordenes) return { datos:[], colores:[], encabezados:[] }
    if(Array.isArray(ordenes) !== true) return { datos:[], colores:[], encabezados:[] }
    if(ordenes.length < 1) return { datos:[], colores:[], encabezados:[] }

    const primera_fecha = new Date(ordenes[0].createdAt)
    const dias = getDaysInMonth(new Date())
    const array_dias = Array.from(Array(dias).keys())

    let encabezados = ['Día','Operaciones']
    let colores = []
    let resultados_asignadas = [encabezados]
    let resultados_completadas = [encabezados]
    let resultados_retrasadas = [encabezados]
    for( const day of array_dias ){
        const num_dia = day+1
        const cantidad_asignada = ordenes.filter(o => {
            if(!o.createdAt) return false
            const dia = new Date(o.createdAt)
            const num = dia.getDate()
            return num === num_dia ? true : false
        }).length
        const cantidad_completada = ordenes.filter(o => {
            if(!o.fecha_finalizado) return false
            const dia = new Date(o.fecha_finalizado)
            const num = dia.getDate()
            return num === num_dia ? true : false
        }).length
        const hoy = new Date()
        const cantidad_rechazada = ordenes.filter(o => {
            const dia = new Date(o.fecha_finalizado)
            const num = dia.getDate()
            if(num !== num_dia) return false

            if(!o.fecha_max_entrega) return false
            if(o.finalizado === true){
                if(!o.fecha_finalizado) return false
                if(o.fecha_finalizado > o.fecha_max_entrega) return true
            }
            const fecha = new Date(o.fecha_max_entrega)
            if(fecha < hoy) return true
            return false
        }).length
        let dato = [ num_dia.toString(), cantidad_asignada ]
        let dato_completado = [ num_dia.toString(), cantidad_completada ]
        let dato_rechazado = [ num_dia.toString(), cantidad_rechazada ]
        resultados_asignadas.push(dato)
        resultados_completadas.push(dato_completado)
        resultados_retrasadas.push(dato_rechazado)
    }    
    return { asignadas: resultados_asignadas, completadas: resultados_completadas, rechazadas: resultados_retrasadas }
}

export const procesarDatosGraficosEstadisticaConductorLastMile = ordenes => {
    if(!ordenes) return { asignadas: { dias: [] }, completadas: { dias: [] }, rechazadas: { dias: [] } }
    if(Array.isArray(ordenes) !== true) return { asignadas: { dias: [] }, completadas: { dias: [] }, rechazadas: { dias: [] } }
    if(ordenes.length < 1) return { asignadas: { dias: [] }, completadas: { dias: [] }, rechazadas: { dias: [] } }

    const primera_fecha = new Date(ordenes[0].createdAt)
    const dias = getDaysInMonth(new Date())
    const array_dias = Array.from(Array(dias).keys())

    let encabezados = ['Día','Operaciones']
    let colores = []
    let resultados_asignadas = { dias: [] }
    let resultados_completadas = { dias: [] }
    let resultados_retrasadas = { dias: [] }
    for( const day of array_dias ){
        const num_dia = day+1
        const asignadas = ordenes.filter(o => {
            if(!o.createdAt) return false
            const dia = DateTime.fromISO(o.createdAt).day
            const num = dia
            return num === num_dia ? true : false
        })
        const cantidad_asignada = asignadas.length

        const completadas = ordenes.filter(o => {
            if(!o.fecha_finalizado) return false
            const dia = DateTime.fromISO(o.fecha_finalizado).day
            const num = dia
            return num === num_dia ? true : false
        })
        const cantidad_completada = completadas.length

        const hoy = DateTime.now().setZone(timezone)
        const rechazadas = ordenes.filter(o => {
            if(!o.fecha_finalizado){
                const dia_creacion                       = DateTime.fromISO(o.createdAt, { setZone: false }).setZone('utc').day
                if(dia_creacion !== num_dia) return false

                const parseada = DateTime.fromISO(o.fecha_max_entrega, { setZone: false }).setZone('utc')
                const hoy = DateTime.now() 
                if(parseada < hoy) return true
            }
            const dia                       = DateTime.fromISO(o.fecha_finalizado).day
            const num = dia
            if(num !== num_dia) return false
            
            if(!o.fecha_max_entrega) return false

            const fecha_maximo_entrega      = DateTime.fromISO(o.fecha_max_entrega.substring(0,23))
            const fecha_finalizado      = DateTime.fromISO(o.fecha_finalizado.substring(0,23))
            if(o.finalizado === true){
                if(!o.fecha_finalizado) return false
                if(fecha_finalizado <= fecha_maximo_entrega) return false
            }
            if(fecha_maximo_entrega < hoy) return true
            return false
        })

        const cantidad_rechazada = rechazadas.length

        let dato = { dia: num_dia.toString(), cantidad: cantidad_asignada, datos: asignadas }
        let dato_completado = { dia: num_dia.toString(), cantidad: cantidad_completada, datos: completadas }
        let dato_rechazado = { dia: num_dia.toString(), cantidad: cantidad_rechazada, datos: rechazadas }
        resultados_asignadas.dias.push(dato)
        resultados_completadas.dias.push(dato_completado)
        resultados_retrasadas.dias.push(dato_rechazado)
    }    
    return { asignadas: resultados_asignadas, completadas: resultados_completadas, rechazadas: resultados_retrasadas }
}

const calcularPorcentaje = (montoTotal, montoActual) => {
    const porcentaje = (montoActual / montoTotal) * 100;
    return porcentaje;
}
  
export const procesarOTIFParaGraficos = datos => {
    const { asignadas, completadas, rechazadas } = datos

    const total_asignadas = asignadas.dias.reduce((prev,next) => {
        const { cantidad } = next
        return cantidad + prev
    }, 0)

    const total_completadas = completadas.dias.reduce((prev,next) => {
        const { cantidad } = next
        return cantidad + prev
    }, 0)
    const total_rechazadas = rechazadas.dias.reduce((prev,next) => {
        const { cantidad } = next
        return cantidad + prev
    }, 0)

    const total_otif = total_asignadas - total_rechazadas

    const porcentaje = calcularPorcentaje(total_asignadas, total_otif)
    return {
        otif: porcentaje.toFixed(0),
        total_asignadas,
        total_completadas,
        total_rechazadas
    }
}