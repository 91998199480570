import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import Header from '../Header'
import { key_local_storage_user } from '../../lib/backend/data'
import 'moment/locale/es';
import RutasImportador from '../../subComponents/rutas/importador/importador'
import RutasImportadorAvanzado from '../../subComponents/rutas/importador/importador_avanzado'

class RutasImportarAvanzado extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem(key_local_storage_user))
        }
    }
    render(){
        const { user } = this.state
        return(
            <div className="fluid">
              <h4>Importar Ruta Avanzado</h4> 
              <RutasImportadorAvanzado token={user.tokenSession} />
      </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(RutasImportarAvanzado);