const { virtual_tour_settings } = require("../global/data")

const encontrarLlaveValorEnEsquema = (key, esquema) => {
    const i = esquema.findIndex(e => e.key === key)
    if(i > -1) return esquema[i].active
    return false
}

const obtenerEstadoTour = (slug, interfaz) => {
    if(interfaz.tour_dismiss === true) return false
    if(!interfaz.tour_details) return virtual_tour_settings[slug]
    if(typeof interfaz.tour_details !== "object") return virtual_tour_settings[slug]
    if(!interfaz.tour_details[slug]) return virtual_tour_settings[slug]
    if(typeof interfaz.tour_details[slug] !== "object") return virtual_tour_settings[slug]
    if(interfaz.tour_details[slug].dismiss === true) return false
    return virtual_tour_settings[slug]
}

module.exports = {
    encontrarLlaveValorEnEsquema,
    obtenerEstadoTour
}