import { Col, Row } from "react-bootstrap"

const BoxConductor = (props) => {
    const {
        typeView
    } = props
    const conductor = props.conductor ? props.conductor : false

    if(!conductor) return <div>
        <h5>Sin datos del conductor</h5>
    </div>

    const vistaSimple = () => {
        return <div className="mb-2">
            <h6 className="mb-0">{conductor.nombres ? conductor.nombres.toUpperCase() : "SIN INFORMACIÓN"}</h6>
            <h6 className="mb-0">{conductor.modelo} {conductor.ano} </h6>
            <h4 className="mb-0">{conductor.patente ? conductor.patente.toUpperCase() : "SIN INFORMACIÓN"}</h4>
        </div>
    }

    const visualizar = () => {
        if(typeView === "simple") return vistaSimple()

        return <div>
        <h6>Detalles del condutor</h6>
        <h5 className="mb-0">Conductor</h5>
        <h6 className="mb-3">{conductor.nombres ? conductor.nombres.toUpperCase() : "SIN INFORMACIÓN"}</h6>
        <h6>Detalles del vehículo</h6>
        <h6 className="mb-0">{conductor.modelo} {conductor.ano} </h6>
        <h4 className="mb-0">{conductor.patente ? conductor.patente.toUpperCase() : "SIN INFORMACIÓN"}</h4>
    </div>
    }

    return <div>
        {visualizar()}
    </div>

}

export default BoxConductor