const formatoMoneda = value => {
    return Intl.NumberFormat("en-DE").format(Math.round(value))
}

const sortDynamic = (key, order = 'asc') => {
    const sortOrder = order === 'asc' ? 1 : -1
    return (a, b) => {
        const A = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
        const B = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];
        if ( A < B ){
            return sortOrder * -1
        }else if ( A > B ) {
            return sortOrder * 1
        } else {
            return 0
        }
    }
}

module.exports = {
    formatoMoneda,
    sortDynamic
}