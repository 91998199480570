import Map, { Marker } from 'react-map-gl';
import { mapbox_token, urlapi } from '../../lib/backend/data';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as turf from '@turf/turf';
import Pin from '../../subComponents/mapas/pin';
import { verificarRetraso } from '../../lib/helpers/estadisticas/main';
import { obtenerCentroMapaPorPais } from '../../lib/helpers/data/internationa';

const MapaEstadisticaProveedor = (props) => {
    const {
        height,
        markers
    } = props
    const [ marcadores, setMarcadores ] = useState(markers)
    const sesion = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const defaultViewState = obtenerCentroMapaPorPais(pais)
    const [viewState, setViewState] = useState(defaultViewState);
    const [ zoomInit, setZoomInit ] = useState(false) 
    const mapRef = useRef()

    const ajustarCentro = (puntos) => {
        if(zoomInit) return false
        if(puntos.length < 1) return false
        setZoomInit(true)
        const points = puntos.map(marker => turf.point([marker.longitude, marker.latitude]));
      const collection = turf.featureCollection(points);
      const bounds = turf.bbox(collection);

      const newViewport = {
        ...viewState,
        latitude: (bounds[1] + bounds[3]) / 2,
        longitude: (bounds[0] + bounds[2]) / 2
      };

      const options = {
        padding: 30 // Ajusta el valor de padding según tus necesidades
      };

      setViewState(newViewport);
      mapRef.current?.fitBounds(bounds, options);
    }

      useEffect(() => {
        setTimeout(() => {
            ajustarCentro(marcadores.map(marker => ({ latitude: marker.lat, longitude: marker.lng })))
        }, 500);
      }, [])

      const handleChangeLoad = () => {
        setTimeout(() => {
          mapRef.current?.resize()
        }, 200);
      }

    return <Map
      ref={mapRef}
      {...viewState}
      onMove={evt => setViewState(evt.viewState)}
      style={{ marginTop: 20, marginBottom: 20, width: "100%", height: 300}}
      scrollZoom={false}
      dragPan={false}
      pitch={20}
      mapStyle="mapbox://styles/mapbox/light-v9"
      mapboxAccessToken={mapbox_token}
      onLoad={handleChangeLoad}
    >
    {
        marcadores.map(marker => {

            let finalizada      = marker.detalles.finalizado
            let retrasada       = verificarRetraso(marker.detalles)
            console.log({ retrasada, finalizada,  detalles: marker.detalles })

            let color = "#007bff"

            if(finalizada)  color = "green"
            if(retrasada)   color = "red"

            return <Marker
                    key={marker._id}
                    longitude={marker.lng}
                    latitude={marker.lat}
                >
                <div>
                    <Pin size={10} color={color} />
                </div>
            </Marker>
        })
    }
  </Map>
}

export default MapaEstadisticaProveedor