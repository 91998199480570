import 'moment/locale/es';
import { Button, Card, Col, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap'
import { useState } from "react";
import { getNombreDestinatario } from "../../../lib/helpers/pedidos/pedidos";
import BoxList from "../../ordenes/box-list";
import { fechaATexto, fechaATextoSimple } from "../../../lib/helpers/helpers";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import data, { timezone } from '../../../lib/backend/data'
import { colorByStatusAsignacionRuta, obtenerZonasDePedidos, stringByStatusAsignacionRuta } from "../../../lib/helpers/rutas";
import MapaRutaAsignacion from "../../../components/Mapas/mapa_ruta_preview";
import { GrCalendar, GrCircleAlert } from 'react-icons/gr'
import SelectorRangoFechas from '../../general/selector_rango_fechas';
import { DateTime } from 'luxon';

const DetailFull = (props) => {
    const {
        asignacion
    } = props
    let pedido = false
    let ruta = false
    const centro_mapa = { lat: -33.458829, lng: -70.654644 }
    const zoom_mapa = 11
    let marcadores = []
    const token = props.token ? props.token : false
    const idasignacion = asignacion ? asignacion._id : ''
    const [loadingStatus, setLoadingStatus] = useState(false)
    const [permitirAcceso, setPermitirAcceso] = useState(props.status_asignacion ? props.status_asignacion : 'pendiente')
    const [ showModalCambioFecha, setShowModalCambioFecha ] = useState(false)
    const [ fechaFinal , setFechaFinal ] = useState('')
    
    if(props.ruta){
        if(props.ruta){
            ruta = props.ruta
        }
    }

    if(ruta.pedidos){
      if(Array.isArray(ruta.pedidos) !== false){
        for( const orden of ruta.pedidos ){
          if(orden.location){
              if(Array.isArray(orden.location.coordinates)){
                  if(orden.location.coordinates.length > 0){
                      marcadores.push({
                          lat: orden.location.coordinates[1],
                          lng: orden.location.coordinates[0],
                          ref: orden.pedido,
                          destinatario: getNombreDestinatario(pedido)
                      })
                  }
              }
          }
        }
      }
    }

    const proponerFecha = (quienpropone) => {
        if(!fechaFinal) return toast.error("Selecciona una fecha")

        const propuesta = DateTime.fromISO(fechaFinal, { zone: 'utc' })
        const desde = DateTime.fromISO(ruta.entrega_desde, { setZone: true })
        console.log( { dia_desde_rute: desde.day, desde: ruta.entrega_desde, propuesta: propuesta.day })
        if(propuesta < desde) return toast.error("Fecha propuesta no puede ser menor a fecha de inicio")

        setLoadingStatus(true)
        return fetch(`${data.urlapi}/rutas/asignaciones/proponerfecha`,{
            method:'PUT',
            body: JSON.stringify({
                idasignacion,
                fecha: fechaFinal,
                quienpropone
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingStatus(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingStatus(false)
            } else if(res._id){
                toast.success('Operación realizada exitosamente')
                return setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
            return setLoadingStatus(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingStatus(false)
        })
    }

    const performarAccion = (status) => {
        setLoadingStatus(true)
        return fetch(`${data.urlapi}/rutas/asignaciones`,{
            method:'PUT',
            body: JSON.stringify({
                idasignacion,
                update: { status }
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingStatus(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingStatus(false)
            } else if(res._id){
                toast.success('Operación realizada exitosamente')
                setTimeout(() => {
                    return window.location.reload()
                }, 1000);
                setPermitirAcceso(status)
            }
            return setLoadingStatus(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingStatus(false)
        })
    }

    const abrirModalFecha = () => {
        setShowModalCambioFecha(true)
    }

    const handleClose = () => {
        setShowModalCambioFecha(false)
    }

    const handleChangeFecha = (e) => {
        const { value } = e.target
        return setFechaFinal(value)
    }

    const modalFiltro = () => {
        const estados = []
        const sub_estados = []
        return <Modal show={showModalCambioFecha} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Filtrar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <SelectorRangoFechas />
        </Modal.Body>
        
      </Modal>
    }

    const mostrarVehiculo = (driver) => {
      if(!driver) return false
      return <div>
          <Link className="text-white" to={`/vehiculos/edit-${driver._id}`}><h6 className="mb-0" style={{ fontWeight: 'lighter' }}><b>{driver.modelo}</b> {driver.ano}</h6></Link>
      </div>
    }

    const acciones = () => {
        if(["aceptada-proveedor","aceptada-cliente","rechazada-cliente","rechazada-proveedor","rechazada-cliente"].includes(asignacion.status)) return false
        if(loadingStatus===true) return <Spinner animation="border" />

        if(showModalCambioFecha && fechaFinal) return <div>
            <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >Si propones una fecha y el cliente acepta, la asignación quedará confirmada y asignada a tí automáticamente</Tooltip>
                            }
                            >
            <Button size="sm" style={{ width:'100%', fontSize: 12 }} variant="warning" onClick={() => proponerFecha("proveedor")} >PROPONER FECHA DE COMPROMISO</Button>
            </OverlayTrigger>
            <hr className="hr" />
        </div>

        return <div>
            <Row>
            <Col md={12}>

            </Col>
            <Col md={6}><Button size="sm" style={{ width:'100%', fontSize: 9 }} variant="success" onClick={() => performarAccion('aceptada-proveedor')} >ACEPTAR</Button></Col>
            <Col md={6}><Button size="sm" style={{ width:'100%', fontSize: 9 }} variant="danger" onClick={() => performarAccion('rechazada-proveedor')} >RECHAZAR</Button></Col>
            </Row>
            <hr className="hr" />
        </div>
    }

    const modificarFechas = () => {
        if(["aceptada-cliente","aceptada-proveedor","rechazada-cliente","rechazada-proveedor"].includes(asignacion.status)) return false
        if(!showModalCambioFecha) return <div>
            <Button size="sm" className='w-100' style={{ fontSize: 11 }} variant="outline-light" onClick={() => abrirModalFecha()} ><GrCalendar color="white" className='mr-2' />MODIFICAR FECHA DE COMPROMISO</Button>
            <hr className='hr' /></div>
        return <div>
            <Button className='mb-2' style={{ fontSize: 10 }} variant='outline-light' onClick={() => handleClose()}>CANCELAR</Button>
            <label className='form-control-label'>Selecciona fecha límite que propones al cliente</label>
            <input defaultValue={fechaFinal} type="date" className='form-control' onChange={handleChangeFecha} />
            <hr className='hr' />
        </div>
    }

    if(!ruta) return <div className='p-5'>
    <Row className="justify-content-md-center">
        <Col md={6} className="text-center">
          <img src="images/pedidos.png" style={{ width: "50%" }} />
        <h4>Selecciona una ruta para visualizarla</h4>
        </Col>
    </Row>
    </div>


    let zonas = obtenerZonasDePedidos(ruta.pedidos)
    console.log(zonas)

    const mostrarEmpresa = () => {
        if(!asignacion.empresa) return false
        if(typeof asignacion.empresa !== "object") return false
        return <Card className="p-2 mt-2 mb-2 text-dark">
            <span style={{ fontSize: 9}}>EMPRESA</span>
            <h6 className="mb-0">{asignacion.empresa.razon_social}</h6>
            </Card>
    }

    const mostrarPropuesta = (asignacion) => {
        if(!asignacion.propuesta_entrega_proveedor) return false
        if(typeof asignacion.propuesta_entrega_proveedor !== "object") return false
        
        return <div>
            <h6 className="mb-0">Tu propuesta</h6>
                <p style={{ fontSize: 11 }} className="mb-0"><b>Desde</b> {fechaATextoSimple(asignacion.propuesta_entrega_proveedor.entrega_desde)}</p>
                <p style={{ fontSize: 11 }} className="mb-0"><b>Hasta</b> {fechaATextoSimple(asignacion.propuesta_entrega_proveedor.entrega_hasta)}</p>
                <hr className="hr" />
        </div>
    }

    return <div>
        <Row>
            <Col md={4}>
              <Card bg="primary" className="p-3 mb-3 text-white">
                {mostrarEmpresa()}
                <p style={{ fontSize: 11 }} className="mb-0"><b>Creado</b> {fechaATexto(ruta.createdAt)}</p>
                <h4 className="mb-0">Ruta {ruta.id} <Link to={`/rutas-${ruta._id}`}></Link> </h4>
                <p className="mb-0">{ruta.descripcion}</p>
                <hr className="hr" />
                <h4 style={{ color: colorByStatusAsignacionRuta(asignacion.status), fontSize: 20 }}><GrCircleAlert /> {stringByStatusAsignacionRuta(asignacion.status)}</h4>
                <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >Esta es la fecha en que el cliente te solicita que entregues la carga, puedes modificarla</Tooltip>
                            }
                            >
                <div>
                <h6 className="mb-0">Entrega</h6>
                <p style={{ fontSize: 11 }} className="mb-0"><b>Desde</b> {fechaATextoSimple(ruta.entrega_desde)}</p>
                <p style={{ fontSize: 11 }} className="mb-0"><b>Hasta</b> {fechaATextoSimple(ruta.entrega_hasta)}</p>
                <hr className="hr" />
                </div>
                </OverlayTrigger>
                {mostrarPropuesta(asignacion)}
                {modificarFechas()}
                {acciones()}
                
                {mostrarVehiculo(ruta.vehiculo)}
                <h6 className="mb-2" style={{ fontSize: 14 }}>{zonas.length} Zonas detectadas en {ruta.pedidos.length} Órdenes</h6>
                { zonas.map((com,iz) => {
                            return <span style={{ backgroundColor: "black", borderRadius: 5, color: "white", padding: "2px 10px", display: "inline" }} key={`z-${iz}`} className='mr-3 hover mb-2' >{com}</span>
                }) }  
              </Card>
              { /* <h6>{ruta.pedidos.length} Órdenes</h6> */}
                {
                  ruta.pedidos.map(orden => {
                    return false
                    return <div key={orden._id} className="mb-3">
                    <BoxList pedido={orden} seleccionado={false} />
                </div>
                  })
                }
            </Col>
            <Col md={8}>
              <MapaRutaAsignacion markers={marcadores} />
            </Col>
        </Row>
    </div>
}

export default DetailFull