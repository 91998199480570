const miusuario = JSON.parse(localStorage.getItem('user'))

function reducer( state = miusuario, { type, payload } ){
    switch( type ){
        case 'guardarUsuario': {
            return payload
        }
        default:
            return state
    }
}

export default reducer