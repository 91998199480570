import 'moment/locale/es';
import { Accordion, Alert, Button, Card, Col, Dropdown, Row, Spinner } from 'react-bootstrap'
import { useState } from "react";
import { getDireccion, getNombreDestinatario } from "../../lib/helpers/pedidos/pedidos";
import BoxList from "../ordenes/box-list";
import { fechaATexto, fechaATextoSimple } from "../../lib/helpers/helpers";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { urlapi } from "../../lib/backend/data";
import Etiquetas from "../etiquetas";
import { colorByStatusAsignacionRuta, detectarPedidosSinGeoDatos, obtenerZonasDePedidos, stringByStatusAsignacionRuta } from "../../lib/helpers/rutas";
import BoxEditarGeoDatosOrden from "../ordenes/box_editar_geodata";
import EmitirEstadoModalMasivoRuta from "../estados_carga/emitir_modal_masivo_ruta";
import ContadorEstadoV2 from "../ordenes/contador_estados_v2";
import { obtenerFechaHoraZonaHorariaLocal } from "../../lib/helpers/dates";
import MapaRutaAsignacion from "../../components/Mapas/mapa_ruta_preview";
import { GrCalendar, GrCircleAlert } from 'react-icons/gr'
import { useSelector } from 'react-redux';


const DetailFull = (props) => {
    let pedido = false
    const estados = props.estados ? props.estados : []
    const [ loadingMaster, setLoadingMaster ] = useState(false)
    const [ loadingEtiquetas, setLoadingEtiquetas ] = useState(false)
    const [ etiquetas, setEtiquetas ] = useState([])
    const [ loadingStatus, setLoadingStatus ] = useState(false)
    let ruta = false
    let marcadores = []
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession

    if(props.ruta){
        if(props.ruta){
            ruta = props.ruta
        }
    }

    if(ruta.pedidos){
      if(Array.isArray(ruta.pedidos) !== false){
        for( const orden of ruta.pedidos ){
          if(orden.location){
              if(Array.isArray(orden.location.coordinates)){
                  if(orden.location.coordinates.length > 0){
                      marcadores.push({
                          lat: orden.location.coordinates[1],
                          lng: orden.location.coordinates[0],
                          ref: orden.pedido,
                          destinatario: getNombreDestinatario(pedido)
                      })
                  }
              }
          }
        }
      }
    }

    const generarEtiquetas = () => {
      setLoadingEtiquetas(true)
      return fetch(`${urlapi}/bodega/etiquetas/multiples-por-idruta`,{
        method:'POST',
        body: JSON.stringify({
            id_ruta: ruta._id
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${token}`
        }
    })
    .then(res => {
        if(res.status === 401) return window.location = '/login'
        return res.json()
    })
    .then(res => {
      console.log(res)
      if(!res){
        toast.error("Sin respuesta")
      } else if(res.errorMessage){
        toast.error(res.errorMessage)
      } else if(Array.isArray(res) !== false){
        setEtiquetas(res)
      }
      return setLoadingEtiquetas(false)
    })
    .catch(error => {
      toast.error(error.message)
      return setLoadingEtiquetas(false)
    })
    }

    const descargarRuta = async () => {
      setLoadingMaster(true)
      return fetch(`${urlapi}/reporte/ordenes/ruta`,{
          method:'POST',
          body: JSON.stringify({
              id_ruta: ruta._id
          }),
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${token}`
          }
      })
      .then(res => {
          if(res.status === 401) return window.location = '/login'
          return res.blob()
      })
      .then(blob => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          const fecha = obtenerFechaHoraZonaHorariaLocal()
          a.download = `ruta-${ruta.id}-${fecha}.xlsx`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();
          return setLoadingMaster(false)
      })
      .catch(error => {
          toast.error("Error al consultar la información, intente nuevamente")
          return setLoadingMaster(false)
      })
  }
    const descargarRutaProductos = async () => {
      setLoadingMaster(true)
      return fetch(`${urlapi}/reporte/ordenes/ruta-productos`,{
          method:'POST',
          body: JSON.stringify({
              id_ruta: ruta._id
          }),
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${token}`
          }
      })
      .then(res => {
          if(res.status === 401) return window.location = '/login'
          return res.blob()
      })
      .then(blob => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          const fecha = obtenerFechaHoraZonaHorariaLocal()
          a.download = `ruta-${ruta.id}-${fecha}.xlsx`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();
          return setLoadingMaster(false)
      })
      .catch(error => {
          toast.error("Error al consultar la información, intente nuevamente")
          return setLoadingMaster(false)
      })
  }
  
  const acciones = (asignacion) => {
    if(loadingStatus===true) return <Spinner animation="border" />

    if(["rechazada-cliente"].includes(asignacion.status)) return false
    if(["pendiente-cliente"].includes(asignacion.status)) {
        return <div>
          <Button variant="success" className="w-100 mt-3" size="sm" onClick={() => performarAccion('aceptada-cliente', asignacion._id)} >APROBAR</Button>
          <Button variant="outline-danger" className="w-100 mt-3" size="sm" onClick={() => performarAccion('rechazada-cliente', asignacion._id)} >ANULAR INVITACIÓN</Button>
        </div>
    }
    
    return <div>
      <Button variant="outline-danger" className="w-100 mt-3" size="sm" onClick={() => performarAccion('rechazada-cliente', asignacion._id)} >ANULAR INVITACIÓN</Button>
    </div>
  }

  const alertaValoresDistintos = (asignacion) => {
    if(!asignacion.propuesta_entrega_proveedor) return false
    if(asignacion.propuesta_entrega.entrega_desde !== asignacion.propuesta_entrega_proveedor.entrega_desde || asignacion.propuesta_entrega.entrega_hasta !== asignacion.propuesta_entrega_proveedor.entrega_hasta){
      return <div>
        <Alert variant="warning"><h6 className="mb-0" style={{ fontSize: 15 }}><GrCircleAlert /> Las fechas cambiaron</h6></Alert>
      </div>
    }
  }

  const mostrarFechaPropuesta = (asignacion_) => {

    const desde = fechaATextoSimple(asignacion_.propuesta_entrega.entrega_desde)
    const hasta = fechaATextoSimple(asignacion_.propuesta_entrega.entrega_hasta)
    if(!asignacion_) return false
    if(!asignacion_.propuesta_entrega_proveedor) return false
    if( typeof asignacion_.propuesta_entrega_proveedor !== "object") return false

    const desde_proveedor = fechaATextoSimple(asignacion_.propuesta_entrega_proveedor ? asignacion_.propuesta_entrega_proveedor.entrega_desde :  asignacion_.propuesta_entrega.entrega_desde)
    const hasta_proveedor = fechaATextoSimple(asignacion_.propuesta_entrega_proveedor ? asignacion_.propuesta_entrega_proveedor.entrega_hasta :  asignacion_.propuesta_entrega.entrega_hasta)

    if( desde_proveedor === desde && hasta_proveedor === hasta ) return false

    return <div>
      <hr className="hr" />
            <p className="mb-0"><b><GrCalendar /> Fecha propuesta por proveedor</b></p>
            <p className="mb-0" style={{ fontSize: 11 }}><b>Desde</b> {desde_proveedor}</p>
            <p className="mb-0" style={{ fontSize: 11 }}><b>Hasta</b> {hasta_proveedor}</p>
    </div>
  }
    
  const mostrarInvitaciones = () => {
    if(!ruta.asignaciones) return false

    return <div>
      <h6 className="mb-0">Asignaciones {ruta.asignaciones.length}</h6>
      <Accordion defaultActiveKey={0}>
      {
        ruta.asignaciones.map((asignacion,ia) => {
          return <div key={asignacion._id}>
          <Card className="text-dark mt-3">
            <Accordion.Toggle as={Card.Header} eventKey={ia.toString()}>
            <p className="mb-0" style={{ fontSize: 10 }}><b>CREADA:</b> {fechaATexto(asignacion.createdAt)}</p>
            <p className="mb-0" style={{ fontSize: 10 }}><b>ACTUALIZADA:</b> {fechaATexto(asignacion.updatedAt)}</p>
            <h6 style={{ color: colorByStatusAsignacionRuta(asignacion.status) }}><GrCircleAlert /> {stringByStatusAsignacionRuta(asignacion.status)}</h6></Accordion.Toggle>
            <Accordion.Collapse eventKey={ia.toString()}>
              <Card.Body>
                {alertaValoresDistintos(asignacion)}
            <p className="mb-0"><b><GrCalendar /> Fecha propuesta</b></p>
            <p className="mb-0" style={{ fontSize: 11 }}><b>Desde</b> {fechaATextoSimple(asignacion.propuesta_entrega.entrega_desde)}</p>
            <p className="mb-0" style={{ fontSize: 11 }}><b>Hasta</b> {fechaATextoSimple(asignacion.propuesta_entrega.entrega_hasta)}</p>
            {mostrarFechaPropuesta(asignacion)}
              {acciones(asignacion)}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
            
          </div>
        })
      }
      </Accordion>
      <hr className="hr" /> 
    </div>
  }

  const performarAccion = (status,idasignacion) => {
    setLoadingStatus(true)
    return fetch(`${urlapi}/rutas/asignaciones`,{
        method:'PUT',
        body: JSON.stringify({
            idasignacion,
            update: { status }
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${token}`
        }
    })
    .then(res => {
        if(res.status === 401) return window.location = '/login'
        return res.json()
    })
    .then(res => {
        if(!res){
            toast.error('Sin datos')
            return setLoadingStatus(false)
        } else if(res.errorMessage){
            toast.error(res.errorMessage)
            return setLoadingStatus(false)
        } else if(res._id){
            toast.success('Operación realizada exitosamente')
            setTimeout(() => {
                window.location.reload()
            }, 500);
        }
        return setLoadingStatus(false)
    })
    .catch(error => {
        toast.error("Error al consultar la información, intente nuevamente")
        return setLoadingStatus(false)
    })
}

    const mostrarVehiculo = (driver) => {
      if(!driver) return false
      return <div>
          <Link className="text-white" to={`/vehiculos/edit-${driver._id}`}><h6 className="mb-0" style={{ fontWeight: 'lighter' }}><b>{driver.modelo}</b> {driver.ano}</h6></Link>
          <h6 className="mb-0" style={{ fontWeight: 'lighter' }}>PATENTE: <b>{driver.patente ? driver.patente.toUpperCase() : "SIN INFORMACIÓN"}</b></h6>
          <hr className="hr" /> 
      </div>
    }

    if(!ruta) return <div className='p-5'>
    <Row className="justify-content-md-center">
        <Col md={6} className="text-center">
          <img alt="pedidos" src="images/pedidos.png" style={{ width: "50%" }} />
        <h4>Selecciona una ruta para visualizarla</h4>
        </Col>
    </Row>
    <hr className="hr" /> 
    </div>

    const copiarDirecciones = () => {
      const texto = ruta.pedidos.map(p => {
        const direccion = getDireccion(p)
        return direccion ? direccion : false
      })
      const string = texto.join(`\n\n`)
      navigator.clipboard.writeText(string)
      return toast.info("Texto copiado")
    }

    let zonas = obtenerZonasDePedidos(ruta.pedidos)
    let invalidData = detectarPedidosSinGeoDatos(ruta.pedidos)

    return <div style={{ height: "90vh", overflowY: "scroll", overflowX: "hidden" }}>
        <Row>
            <Col md={4}>
              <Card bg="primary" className="p-3 mb-3 text-white">
                <p style={{ fontSize: 11 }} className="mb-0"><b>Creado</b> {fechaATexto(ruta.createdAt)}</p>
                <Row>
                  <Col xs={9}>
                    <h3 className="mb-0">Ruta {ruta.id}</h3>
                    {loadingMaster?<Spinner animation="border"/> : false }
                  </Col>
                  <Col xs={3}>
                  <Dropdown>
                    <Dropdown.Toggle  id="dropdown-basic"></Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item><Link style={{ textDecoration: "none", color:"black" }} className="no-decoration" to={`/rutas/${ruta._id}`}>EDITAR</Link></Dropdown.Item>
                      <Dropdown.Item onClick={() => generarEtiquetas()}>GENERAR ETIQUETAS PARA IMPRIMIR</Dropdown.Item>
                      <Dropdown.Item onClick={() => descargarRuta()}>DESCARGAR RUTA</Dropdown.Item>
                      <Dropdown.Item onClick={() => descargarRutaProductos()}>DESCARGAR PRODUCTOS</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  </Col>
                </Row>
                <p className="mb-0">{ruta.descripcion}</p>
                <EmitirEstadoModalMasivoRuta onStatusEmited={() => window.location.reload()} buttonStyle="outline-light" token={token} ruta={ruta} />
                <hr className="hr" />
                <h6 className="mb-0">Entrega</h6>
                <p style={{ fontSize: 11 }} className="mb-0"><b>Desde</b> {fechaATextoSimple(ruta.entrega_desde)}</p>
                <p style={{ fontSize: 11 }} className="mb-0"><b>Hasta</b> {fechaATextoSimple(ruta.entrega_hasta)}</p>
                <hr className="hr" />
                {mostrarInvitaciones()}
                {mostrarVehiculo(ruta.vehiculo)}
                <h6 className="mb-2">Zonas detectadas</h6>
                { zonas.map((com,iz) => {
                            return <span style={{ backgroundColor: "black", borderRadius: 5, color: "white", padding: "2px 10px", display: "inline" }} key={`z-${iz}`} className='mr-3 hover mb-2' >{com}</span>
                }) }              
              </Card>
              <h6>{ruta.pedidos.length} Órdenes</h6>
              <Button size="sm" variant="outline-primary" className="mb-3" onClick={() => copiarDirecciones()}>COPIAR DIRECCIONES AL PORTAPAPELES</Button>
                {
                  ruta.pedidos.map(orden => {
                    return <div key={orden._id} className="mb-3">
                    <BoxList showGeoData={true} pedido={orden} seleccionado={false} />
                    </div>
                  })
                }
            </Col>
            <Col md={8}>
              <ContadorEstadoV2 token={token} estados={estados} condicion_default={{ _id: { $in: ruta.pedidos.map(pe => pe._id) } }} />
              <BoxEditarGeoDatosOrden pedidos={invalidData.invalidos} token={token} />
              {
                loadingEtiquetas ? <div className="mb-3"><Spinner animation="border" /><h4 className="mb-0"><i className="fas fa-barcode mb-0"></i> Consultando/generando etiquetas</h4></div> : false
              }
              { etiquetas.length > 0 ? <div>
                <Button variant="outline-primary" className="mb-3" size="sm" onClick={() => setEtiquetas(prev => [...[], ...[]]) }>CERRAR VISUALIZACIÓN ETIQUETAS</Button>
                <Etiquetas etiquetas={etiquetas} />
              </div> : false }
                <MapaRutaAsignacion markers={marcadores} />
            </Col>
        </Row>
    </div>
}

export default DetailFull