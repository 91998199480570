const { validarDNIArgentina } = require("./argentina");
const { validarDNIBrasil, formatearCPF, formatearTelefonoMovilBrasil } = require("./brasil");
const { validarRUTChileno, formatearRUTChileno, formatearTelefonoMovilChile } = require("./chile");
const { validarDNIColombia, formatearTelefonoMovilColombia } = require("./colombia");
const { validarDNIMexico, formatearTelefonoMovilMexico } = require("./mexico");

const formatearDNIPorPais = (value, pais_code) => {
    if(!value) return ''
    switch (pais_code) {
        case "CL":
            return formatearRUTChileno(value)
        case "AR":
            return value
        case "BR":
            return formatearCPF(value)
        case "MX":
            return value
        case "CO":
            return value
        default:
            break;
    }
}

const validarDNIPorPais = (value, pais_code) => {
    switch (pais_code) {
        case "CL":
            return value
        case "AR":
            return validarDNIArgentina(value)
        case "BR":
            return validarDNIBrasil(value)
        case "MX":
            return validarDNIMexico(value)
        case "CO":
            return validarDNIColombia(value)
        default:
            return true
    }
}

const nombreDNIPorPais = (pais_code) => {
    switch (pais_code) {
        case "CL":
            return "RUT"
        case "AR":
            return "DNI"
        case "BR":
            return "CPF"
        case "MX":
            return "CURP"
        case "CO":
            return "CÉDULA"
        default:
            break;
    }
}

const formatoAyudaDNIPorPais = (pais_code) => {
    switch (pais_code) {
        case "CL":
            return "Formato: 22222222K"
        case "AR":
            return "DNI"
        case "BR":
            return "CPF"
        case "MX":
            return "CURP"
        case "CO":
            return "CÉDULA"
        default:
            break;
    }
}

const formatoAyudaMovilPorPais = (pais_code) => {
    switch (pais_code) {
        case "CL":
            return "Formato: 912345678"
        case "AR":
            return "Formato: "
        case "BR":
            return "Formatar: 55123412349"
        case "MX":
            return "Formato: "
        case "CO":
            return "Formato: "
        default:
            break;
    }
}

const obtenerCentroMapaPorPais = (pais_code) => {
    switch (pais_code) {
        case "CL":
            return { longitude: -70.670112, latitude: -33.447471, zoom: 10 }
        case "CO":
            return { longitude: -74.072786, latitude: 4.707831, zoom: 10 }
        case "MX":
            return { longitude: -99.135499, latitude: 19.426035, zoom: 10 }
        case "BR":
            return { longitude: -47.894820, latitude: -15.797968, zoom: 10 }
        case "PY":
            return { longitude: -57.579851, latitude: -25.266461, zoom: 10 }
        case "CR":
            return { longitude: -84.107208, latitude: 9.943912, zoom: 10 }
        case "PA":
            return { longitude: -79.508749, latitude: 9.020142, zoom: 10 }
        case "GT":
            return { longitude: -90.5749633, latitude: 14.6263717, zoom: 10 }
        case "DO":
            return { longitude: -70.0292827, latitude: 18.4801874, zoom: 10 }
        case "PE":
            return { longitude: -76.932455, latitude: -11.997784, zoom: 10 }
        case "BO":
            return { longitude: -68.1566552, latitude :-16.5070378, zoom: 10 }
        default:
            return { longitude: -70.670112, latitude: -33.447471, zoom: 10 }
    }
}

const obtenerNumeroCelularPorPais = (pais_code) => {
    switch (pais_code) {
        case "CL":
            return "56";
        case "CO":
            return "57";
        case "MX":
            return "52";
        case "BR":
            return "55";
        case "PY":
            return "595";
        case "CR":
            return "506";
        case "PA":
            return "507";
        case "DO":
            return "1";
        case "PE":
            return "51";
        case "BO":
            return "591";
        // Agrega más casos para otros países si es necesario
        default:
            return ""; // Código de país no encontrado
    }
}


const formatearMovilPorPais = (value, pais_code) => {
    if(!value) return ''
    switch (pais_code) {
        case "CL":
            return formatearTelefonoMovilChile(value)
        case "AR":
            return value
        case "BR":
            return formatearTelefonoMovilBrasil(value)
        case "MX":
            return formatearTelefonoMovilMexico(value)
        case "CO":
            return formatearTelefonoMovilColombia(value)
        default:
            return value
    }
}

const stringByTipoNotification = (type, lang) => {
    switch (type) {
        case "info":
            return "INFORMATIVA"
        case "warning":
            return "ADVERTENCIA"
        case "error":
            return "CRÍTICA"
        default:
            return "POR DEFECTO"
    }
}

const procesarZonasExcelModelo = (rangos, zonas, pais_code) => {
    switch (pais_code) {
        case "CL":
            const cabeceras = ["region","destino", ...rangos.map(r => r.titulo)]
            let data_exportar = []
            for ( const z of zonas ){

                if(z.local_name === "comuna") if(z.properties) if(typeof z.properties === "object") if(typeof z.properties.codregion !== "undefined"){
                    const i = zonas.findIndex(zona => {
                        if(zona.properties) if(typeof zona.properties === "object") if(zona.properties.codregion === z.properties.codregion && zona.local_name === "region" ) return true
                        return false
                    })
                    if(i > -1) z.region = zonas[i].titulo
                }
                data_exportar.push([z.region, z.titulo])
                
            }
            return {
                cabeceras,
                data: data_exportar
            }
        default:
            break;
    }
}

module.exports = {
    stringByTipoNotification,
    validarDNIPorPais,
    obtenerCentroMapaPorPais,
    formatoAyudaMovilPorPais,
    formatearMovilPorPais,
    formatoAyudaDNIPorPais,
    formatearDNIPorPais,
    nombreDNIPorPais,
    obtenerNumeroCelularPorPais,
    procesarZonasExcelModelo
}