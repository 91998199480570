import { Col, Row } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"

const CargandoDetalleFull = () => {
    return <Row>
        <Col md={4}>
            <Skeleton height={30} className="mb-3" />
            <Skeleton height={30} className="mb-3" />
            <Skeleton height={30} className="mb-3" />
            <Skeleton height={30} className="mb-3" />
            <Skeleton height={30} className="mb-3" />
            <Skeleton height={30} className="mb-3" />
            <Skeleton height={30} className="mb-3" />
            <Skeleton height={30} className="mb-3" />
        </Col>
        <Col md={8}>
            <Skeleton height={380} className="mb-3" />
        </Col>
        <Col md={12}>
            <Row>
                <Col md={4}>
                <Skeleton height={20} className="mb-3" />
                <Skeleton height={20} className="mb-3" />
                <Skeleton height={20} className="mb-3" />
                </Col>
                <Col md={8}>
                <Skeleton height={20} className="mb-3" />
                <Skeleton height={20} className="mb-3" />
                <Skeleton height={20} className="mb-3" />
                </Col>
            </Row>
        </Col>
    </Row>
}

export default CargandoDetalleFull