import Header from "../../../components/Header"
import MapaMonitoreoFlota from "../../mapas/mapa_monitoreo_floja"
import Recursos from "../../ordenes/estadistica/recursos"
import OperacionEnCurso from "./estadistica_operacion_curso"

const OperacionMisClientes = (props) => {

    return <OperacionEnCurso />
}

export default OperacionMisClientes