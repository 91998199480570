import { useEffect, useState } from 'react'
import { Row, Col, Button, Table, Card, Spinner } from 'react-bootstrap'
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import { host } from '../../lib/global/data';
import { DateRange } from 'react-date-range';
import { es } from 'date-fns/locale'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addMoreDays } from '../../lib/helpers/dates';
import Select from 'react-select';
import { corregirTelefono, validateEmail } from '../../lib/helpers/helpers';
import BuscadorRutas from '../rutas/buscador';

const CrearOrden = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: addMoreDays(new Date(),1),
        key: 'selection',
    }
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ orden, setOrden ] = useState({})
    const [ conductores, setConductores ] = useState([])
    const [ loadingConductores, setLoadingConductores ] = useState(true)
    const [ conductor, setConductor ] = useState(false)
    const [ loadingCreacion, setLoadingCreacion ] = useState(false)
    const [ descripcion, setDescripcion ] = useState('')
    const [ referenciaValida, setReferenciaValida ] = useState(false)
    const [ estadoReferencia, setEstadoReferencia ] = useState('')
    const [ loadingEstadoReferencia, setLoadingEstadoReferencia ] = useState(false)
    const token = props.token ? props.token : false

    useEffect(() => {
        obtenerProveedores()
    },[])

    const obtenerProveedores = async () => {
        setLoadingConductores(true)
        return fetch(`${data.urlapi}/conductores/list-full`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            } else if(Array.isArray(res) !== false){
                setConductores(res.map(c => ({ value: c._id, label: `${c.patente.toUpperCase()} · ${c.modelo.toUpperCase()}` })))
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingConductores(false)
        })
    }

    const crearNuevaRuta = async () => {

        const requeridos = [
            { value:"pedido", label: "pedido" },
            { value:"destinatario", label: "destinatario" },
            { value:"direccion", label: "direccion" },
            { value:"direccion_2", label: "direccion_2" },
            { value:"bultos", label: "bultos" },
            { value:"ruta", label: "Número de Ruta" }
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!orden[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)

        const email = validateEmail(orden.email ? orden.email.toString() : '')
                if(!email){
                    return toast.error('Email inválido')
        }

        orden.telefono = corregirTelefono(orden.telefono.toString())
        orden.fecha_min_entrega = selectionRange.startDate
        orden.fecha_max_entrega = selectionRange.endDate
        setLoadingCreacion(true)
        return fetch(`${data.urlapi}/ordenes`,{
            method:'POST',
            body: JSON.stringify(orden),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingCreacion(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCreacion(false)
            } else if(res._id){
                toast.success('Creada exitosamente')
                return setTimeout(() => {
                    window.location = '/ordenes'
                }, 1000)
            }
            return setLoadingCreacion(false)
        })
        .catch(error => {
            toast.error(error.message)
            return setLoadingCreacion(false)
        })
    }

    const handleChangeDescripcion = (e) => {
        const { value } = e.target
        return setDescripcion(value)
    }

    const cambiarRangoFecha = (item) => {
        return setSelectionRange(item.selection)
    }

    const handleChangeSelectConductor = (e) => {
        return setConductor(e.value)
    }

    const mostrarEstadoReferencia = () => {
        if(loadingEstadoReferencia === true) return <p style={{ position:'absolute', right: 30, bottom: 13, fontSize: 10 }}><Spinner size="sm" animation='border' /></p>
        return false
    }

    const mostrarTextoEstadoReferencia = () => {
        if(referenciaValida === true) return <p style={{ fontSize: 12, fontWeight:'normal', marginBottom: 10 }} className='text-success'><i className="fa-solid fa-circle-check"></i> {estadoReferencia}</p>
        if(estadoReferencia) return <p style={{ fontSize: 12, fontWeight:'normal', marginBottom: 10 }} className='text-danger'><i className="fa-solid fa-triangle-exclamation"></i> {estadoReferencia}</p>
        return <label className='form-control-label d-block'>Referencia *</label>
    }

    const handleChangeOrden = (e) => {
        const { name, value } = e.target
        orden[name] = value
        return setOrden(orden)
    }

    const validarReferencia = async () => {
        if(!orden.pedido){
            setReferenciaValida(false)
            return setEstadoReferencia('Referencia inválida')
        }
        setLoadingEstadoReferencia(true)
        return fetch(`${data.urlapi}/ordenes/validar/referencia?referencia=${orden.pedido}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingEstadoReferencia(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingEstadoReferencia(false)
            } else if(typeof res === 'object'){
                setReferenciaValida(res.valido === true ? true : false)
                setEstadoReferencia(res.valido === true ? 'Referencia válida' : 'Referencia inválida')
            }
            return setLoadingEstadoReferencia(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingEstadoReferencia(false)
        })
    }

    const onChangeRuta = (e) => {
        orden.ruta = e.value
        return setOrden(orden)
    }

    return <div>
    <Row>
        <Col md={3} className="mb-3">
            <h5>Rango de entrega</h5>
            <p>Este es el rango de fechas en los que se podrá entregar esta orden</p>
            <DateRange
            locale={es}
            editableDateInputs={true}
            onChange={item => cambiarRangoFecha(item)}
            moveRangeOnFirstSelection={false}
            ranges={[selectionRange]}
            direction="vertical"
            scroll={{ enabled: true }}
            months={1}
            />
        </Col>
        <Col md={9}>
            <h5>Datos generales</h5>
            <Row>
                <Col md={2}>
                    {mostrarTextoEstadoReferencia()}
                    {mostrarEstadoReferencia()}
                    <input className='form-control mb-3' style={{ paddingRight:10 }} name="pedido" onBlur={()=>setTimeout(() => {
                        return validarReferencia()
                    }, 500)} onChange={handleChangeOrden} />
                </Col>
                <Col md={2}>
                    <label className='form-control-label d-block'>Destinatario *</label>
                    <input className='form-control mb-3' name="destinatario" onChange={handleChangeOrden} />
                </Col>
                <Col md={4}>
                    <label className='form-control-label d-block'>Dirección *</label>
                    <input className='form-control mb-3' name="direccion" onChange={handleChangeOrden} />
                </Col>
                <Col md={4}>
                    <label className='form-control-label d-block'>Observaciones de la dirección *</label>
                    <input className='form-control mb-3' name="direccion_2" onChange={handleChangeOrden} />
                </Col>
                <Col md={2}>
                    <label className='form-control-label d-block'>Móvil</label>
                    <input className='form-control mb-3' name="telefono" onChange={handleChangeOrden} />
                </Col>
                <Col md={2}>
                    <label className='form-control-label d-block'>Email</label>
                    <input className='form-control mb-3' name="email" onChange={handleChangeOrden} />
                </Col>
                <Col md={2}>
                    <label className='form-control-label d-block'>Bultos secos *</label>
                    <input className='form-control mb-3' type="number" min="0" name="bultos" onChange={handleChangeOrden} />
                </Col>
                <Col md={2}>
                    <label className='form-control-label d-block'>Bultos congelados</label>
                    <input className='form-control mb-3' type="number" min="0" name="congelados" onChange={handleChangeOrden} />
                </Col>
                <Col md={2}>
                    <label className='form-control-label d-block'>Bultos refrigerados</label>
                    <input className='form-control mb-3' type="number" min="0" name="refrigerado" onChange={handleChangeOrden} />
                </Col>
                <Col md={2}>
                    <label className='form-control-label d-block'>Peso</label>
                    <input className='form-control mb-3' type="number" min="0" name="peso" onChange={handleChangeOrden} />
                </Col>
                <Col md={2}>
                    <label className='form-control-label d-block'>Volumen</label>
                    <input className='form-control mb-3' type="number" min="0" name="volumen" onChange={handleChangeOrden} />
                </Col>
                <Col md={4}>
                    <label className='form-control-label d-block'>Nota </label>
                    <input className='form-control mb-3' name="nota" onChange={handleChangeOrden} />
                </Col>
                <Col md={6}>
                    <BuscadorRutas token={token} onChangeValue={(e) => onChangeRuta(e)} />
                </Col>
            </Row>
            {
                loadingCreacion === true ? <Spinner animation='border' /> : <Button size="sm" variant="success" onClick={()=>crearNuevaRuta()}>CREAR</Button>
            }
        </Col>
    </Row>
</div>
}

export default CrearOrden