import { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import BeetrackPluginConfig from './formularios_config.js/beetrack';
import JumpsellerPluginConfig from './formularios_config.js/jumpseller';
import ShopifyPluginConfig from './formularios_config.js/shopify';
import WoocommercePluginConfig from './formularios_config.js/woocommerce';
import MercadoLibrePluginConfig from './formularios_config.js/mercado_libre';

const PluginsListado = (props) => {

    return <div>
        <Row>
            <Col md={12}>
                
            <h4 className='mb-3'>Plugins</h4>
            <p>Toca el plugin para configurarlo</p>
            <Row>
                <Col md={4} xs={6}><BeetrackPluginConfig /></Col>
                <Col md={4} xs={6}><JumpsellerPluginConfig /></Col>
                <Col md={4} xs={6}><ShopifyPluginConfig /></Col>
                <Col md={4} xs={6}><WoocommercePluginConfig /></Col>
                <Col md={4} xs={6}><MercadoLibrePluginConfig /></Col>
            </Row>
            </Col>
        </Row>
    </div>

}

export default PluginsListado