import { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Pagination, Card, OverlayTrigger, Tooltip, Table } from 'react-bootstrap'
import data from '../../../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import { confirmAlert } from 'react-confirm-alert'
import { useSelector } from 'react-redux';
import { AiOutlineDelete } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { rutas } from '../../../../lib/routes/routes';
import { fechaUTCATexto } from '../../../../lib/helpers/helpers';
import { stringByStatusLote } from '../../../../lib/helpers/pricing/lotes';
import { RxOpenInNewWindow } from 'react-icons/rx';

const LotesTarifasListado = (props) => {
    const {
        rut_cliente
    } = props
    const [ proveedores, setRegistros ] = useState([])
    const [ loadingProveedores, setLoadingProveedores ] = useState(true)
    const [ removiendo, setRemoviendo ] = useState(false)
    const [ openModal, setOpenModal ] = useState(false)
    const [ pagina, setPagina ] = useState(1)
    const [ total, setTotal ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const trash = props.trash ? props.trash : false

    const obtenerRegistros = async (page, query)=>{
        setLoadingProveedores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/pricing/lotes/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingProveedores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingProveedores(false)
            } else if(Array.isArray(res.datos) !== false){
                setRegistros(res.datos)
                setTotal(res.total)
            }
            return setLoadingProveedores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingProveedores(false)
        })
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const modalFiltro = () => {
        const estados = []
        const sub_estados = []
        return <Modal show={openModal} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Filtrar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col xs={12} className="mb-2">
                    <label className='d-block form-control-label'>Fecha</label>
                    <select name="tipo_fecha" className='form-control'>
                        <option value="createdAt">Creación</option>
                        <option value="updatedAt">Actualización</option>
                        <option value="compromiso">Compromiso</option>
                        <option value="ruta">Fecha ruta</option>
                    </select>
                </Col>
                <Col md={12}>
                    <Button size="sm" variant="success">FILTRAR</Button>
                </Col>
            </Row>
        </Modal.Body>
        
      </Modal>
    }

    useEffect(() => {
        obtenerRegistros(1, false)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const cargandoScreen = () => {
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }

    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/pricing/lotes?id=${id}&rut=${rut_cliente}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res.deletedCount){
                toast.success("Eliminado exitosamente")
                return setTimeout(() => {
                    window.location.reload()
                }, 1000);
                if(props.onFieldDeleted) props.onFieldDeleted(res._id)
            }
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerRegistros(page)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        return <div>
            <Pagination size='sm'>
                {
                    Array.from(Array(cantidad).keys()).map(number => {
                        const active = pagina === (number+1) ? true : false
                        return <Pagination.Item key={number+1} active={active} onClick={()=>paginar(number+1,active)} >{number+1}</Pagination.Item>
                    })
                }
            </Pagination>
        </div>
    }

    const mostrarServicio = (item) => {
        try {
            return item.detalles_servicio.nombre
        } catch (error) {
            return "Sin información"
        }
    }

    const tabla_registros = (registros) => {
        if(loadingProveedores===true) return cargandoScreen()

        const registros_validos = registros.filter(orden => {
            return true
        })

        if(registros_validos.length < 1) return  <div>
            <h5>Nada por aquí</h5>
            <p>No hay registros en esta sección</p>
        </div>

        return <div>
            <h5>{(registros.length * pagina) - registros.length + 1} - {registros.length * pagina} <b style={{ fontWeight:'lighter' }}>de {total}</b></h5>
            {paginacion(data.pagina, total)}
            <Table>
                <thead>
                    <tr>
                        <th>Título</th>
                        <th>Servicio</th>
                        <th>Estado</th>
                        <th>En producción</th>
                        <th>Fecha de creación</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        registros_validos.map((reg,ireg) => {
                            return <tr key={`r-${ireg}`}>
                                <th><Link to={`/${rutas.pricing_lotes.slug}/${reg._id}`}>{reg.nombre}</Link> </th>
                                <th>{mostrarServicio(reg)}</th>
                                <th>{stringByStatusLote(reg.status)}</th>
                                <th>{reg.published ? "SI" : "NO"}</th>
                                <th>{fechaUTCATexto(reg.createdAt)}</th>
                            </tr>
                        })
                    }
                </tbody>
            </Table>
        </div>
    }
    
    return <div>
        {tabla_registros(proveedores)}
    </div>

}

export default LotesTarifasListado