import { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Pagination, Spinner, Card, Tooltip, OverlayTrigger } from 'react-bootstrap'
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import BoxList from '../conductores/box-list';
import GraficosOrdenes from '../ordenes/graficos';
import BuscadorProveedores from '../proveedores/buscador';
import ConteoEstatico from '../actividad/conteo_estatico';
import GraficosMes from './graficos';
import { procesarDatosGraficosEstadisticaConductor, procesarDatosGraficosEstadisticaConductorLastMile } from '../../lib/helpers/conductores';
import ListadoEstadosCarga from '../estados_carga/list';
import { formatDateAno, formatMes, StringMes } from '../../lib/helpers/dates';
import GraficosMesLastMile from '../graficos/graficos_last_mile';

const MainEstadisticas = (props) => {
    const token = props.token ? props.token : false
    const [ vehiculos, setVehiculos ] = useState([])
    const [ loadingVehiculos, setLoadingVehiculos ] = useState(true)
    const [ loadingOrdenes, setLoadingOrdenes ] = useState(true)
    const [ ordenes, setOrdenes ] = useState([])
    const [ datosGraficosHistorico, setDatosGraficosHistoricos ] = useState([])
    const [ datosGraficosHistoricoV2, setDatosGraficosHistoricosV2 ] = useState([])
    const [ tipoVisualizacion, setTipoVisualizacion] = useState('asignadas')
    const [ vehiculosSeleccionados, setVehiculosSeleccionados] = useState([])
    const [ ano, setAno] = useState(formatDateAno(new Date()))
    const [ mes, setMes] = useState(formatMes(new Date()))

    const onClickConductor = async (id) => {
        vehiculos.map((v,iv) => vehiculos[iv].seleccionado = false )
        const i = vehiculos.findIndex(v => v._id === id)
        if(i > -1){
            vehiculos[i].seleccionado = true
            setVehiculos(prev => [...[], ...vehiculos ])
            const seleccion = vehiculos.filter(v => v.seleccionado === true).map(d => d._id)
            setVehiculosSeleccionados(prev => [...[], ...seleccion])
            return obtenerOrdenes(seleccion)
        }
        return 
    }

    const obtenerDatos = async () => {
        return fetch(`${data.urlapi}/estadisticas/conductores`,{
            method:'POST',
            body: JSON.stringify({
                condicion: {}
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingVehiculos(false)

            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingVehiculos(false)

            } else if(Array.isArray(res) !== false){
                setVehiculos(res.map(e => {
                    e.seleccionado = true
                    return e
                }))
                setVehiculosSeleccionados(prev => [...[], ...res.map(v => v._id)])
                obtenerOrdenes(res.map(v => v._id))
            }
            return setLoadingVehiculos(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingVehiculos(false)
        })
    }

    const obtenerOrdenes = async (drivers) => {
        console.log({ idconductor: { $in: drivers } })
        setLoadingOrdenes(true)
        return fetch(`${data.urlapi}/estadisticas/ordenes`,{
            method:'POST',
            body: JSON.stringify({
                drivers,
                mes,
                ano
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingOrdenes(false)

            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingOrdenes(false)

            } else if(Array.isArray(res) !== false){
                setOrdenes(res)
                const graphs = procesarDatosGraficosEstadisticaConductor(res)
                const graphs_v2 = procesarDatosGraficosEstadisticaConductorLastMile(res)
                console.log(graphs_v2)
                setDatosGraficosHistoricos(graphs)
                setDatosGraficosHistoricosV2(graphs_v2)
            }
            return setLoadingOrdenes(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingOrdenes(false)
        })
    }

    useEffect(() => {
        obtenerDatos()
    },[])

    const cargandoScreen = () => {
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }

    const tabla_registros = (datos) => {
        if(loadingVehiculos) return cargandoScreen()
        if(datos.length < 1) return  <div>
            <h5>Nada por aquí</h5>
            <p>No hay registros en esta sección</p>
        </div>
        return <div>
            {
                        datos.map(ve => {
                            return <div key={ve._id} className="mb-3">
                                <BoxList conductor={ve} seleccionado={ve.seleccionado} onClickConductor={(id) => onClickConductor(id)} />
                            </div>
                        })
            }
        </div>
    }

    const onChangeSelection = (drivers) => {
        return obtenerOrdenes(drivers.map(d => d._id))
    }

    const handleChangeTipo = (e) => {
        const { value } = e.target
        return setTipoVisualizacion(value)
    }

    const handleChangeMes = (e) => {
        const { value } = e.target
        return setMes(value)
    }

    const handleChangeAno = (e) => {
        const { value } = e.target
        return setAno(value)
    }

    const refrescarDatos = () => {
        obtenerOrdenes(vehiculosSeleccionados)
    }

    const mostrarGraficos = () => {
        switch ( tipoVisualizacion ) {
            case 'asignadas':
                return <GraficosMesLastMile datos={datosGraficosHistoricoV2.asignadas} height={'200px'} />
            case 'completadas':
                return <GraficosMesLastMile datos={datosGraficosHistoricoV2.completadas} height={'200px'} color="green" />
            case 'retrasadas':
                return <GraficosMesLastMile datos={datosGraficosHistoricoV2.rechazadas} height={'200px'} color='#c50f0f' colors={['#c50f0f']} />
            default:
                break;
        }
    }

    const mostrarDataOrdenes = () => {
        if(loadingOrdenes===true) return <Spinner animation='border' />

        return <div>
            <GraficosOrdenes ordenes={ordenes} />
                <hr />
            <Row>
                <Col md={2} className="mb-4">
                    <label className='form-control-label d-block'>Año</label>
                    <input className='form-control' type="number" value={ano} name="ano"  onChange={handleChangeAno} />
                </Col>
                <Col md={3} className="mb-4">
                    <label className='form-control-label d-block'>Mes</label>
                    <select className='form-control' value={mes} onChange={handleChangeMes} >
                    <option value="01">Enero</option>
                    <option value="02">Febrero</option>
                    <option value="03">Marzo</option>
                    <option value="04">Abril</option>
                    <option value="05">Mayo</option>
                    <option value="06">Junio</option>
                    <option value="07">Julio</option>
                    <option value="08">Agosto</option>
                    <option value="09">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                    </select>
                </Col>
                <Col md={3} className="mb-4">
                    <label className='form-control-label d-block'>Tipo de visualización</label>
                    <select className='form-control' onChange={handleChangeTipo} value={tipoVisualizacion}>
                        <option value="asignadas">Asignadas</option>
                        <option value="completadas">Completadas</option>
                        <option value="retrasadas">Retrasadas</option>
                    </select>
                </Col>
                <Col md={3} className="mb-4">
                    <label className='form-control-label d-block'>Refrescar datos</label>
                    <Button variant="outline-primary" onClick={()=>refrescarDatos()}>ACTUALIZAR</Button>
                </Col>
            </Row>
            <h4>{StringMes(mes)} <b style={{ fontWeight: 'lighter' }}>{tipoVisualizacion}</b></h4>
            {mostrarGraficos()}            
            <ListadoEstadosCarga token={token} conductores={vehiculosSeleccionados} mes={mes} ano={ano} />
        </div>
    }

    const mostrarVehiculosSeleccionados = () => {
        const filtrados = vehiculos.filter(v => v.seleccionado === true)
        if(filtrados.length < 1) return false
        return <OverlayTrigger
        placement={'left'}
        overlay={
          <Tooltip >
            A continuación los datos son solo de los vehículos seleccionados
          </Tooltip>
        }
        >
        <div>
        <h6 className='mb-3'>Los siguientes datos corresponden a: {filtrados.map(v => <span className='mr-2' style={{ background: '#cfcfcf', fontSize:11, padding: '4px 15px', borderRadius: 5 }}>{v.patente ? v.patente.toUpperCase() : ''}</span>)}</h6>
        </div>
      </OverlayTrigger>
    }

    const onActualizar = (data) => {
        vehiculos.map((v,i) => vehiculos[i].seleccionado = false)
        vehiculos.map((v,i) => {
            if(!v.id_proveedor) return
            if(data.includes(v.id_proveedor)) vehiculos[i].seleccionado = true
            return
        })
        setVehiculos(prev => [...[], ...vehiculos ])
        const seleccion = vehiculos.filter(v => v.seleccionado === true).map(d => d._id)
        setVehiculosSeleccionados(prev => [...[], ...seleccion])
        return obtenerOrdenes(seleccion)
    }

    return <div>
        
        <Row>
            <Col md={3}>
                <h4>Estadísticas</h4>
                <BuscadorProveedores token={token} onActualizar={(data) => onActualizar(data)} />
                {tabla_registros(vehiculos)}
            </Col>
            <Col md={9}>
                    {mostrarVehiculosSeleccionados()}
                    <ConteoEstatico token={token} datos={ordenes} />
                <Card className='p-3'>
                    {mostrarDataOrdenes()}
                </Card>
            </Col>
        </Row>
    </div>
}

export default MainEstadisticas