import { createStore, combineReducers, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import miusuario from '../redux/reducers/miusuario'
import pais from '../redux/reducers/pais'
import idioma from '../redux/reducers/idioma'
import esquema_tablas from '../redux/reducers/esquema_tablas'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
 
const thunkMiddleware = require('redux-thunk').default

const rootReducer = combineReducers({
    miusuario,
    pais,
    esquema_tablas,
    idioma
})

const persistConfig = {
  key: 'root',
  storage,
}
 
const persistedReducer = persistReducer(persistConfig, rootReducer)
 
export default () => {
  let store = createStore(persistedReducer, applyMiddleware(thunkMiddleware))
  let persistor = persistStore(store)
  return { store, persistor }
}