import { Row, Col } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'

const PantallaCargando = () => {
    return <Row>
                <Col md={3} className="mb-4"><Skeleton height={40} /></Col>
                <Col md={3} className="mb-4"><Skeleton height={40} /></Col>
                <Col md={3} className="mb-4"><Skeleton height={40} /></Col>
                <Col md={3} className="mb-4"><Skeleton height={40} /></Col>
                <Col md={3} className="mb-4"><Skeleton height={40} /></Col>
                <Col md={3} className="mb-4"><Skeleton height={40} /></Col>
                <Col md={3} className="mb-4"><Skeleton height={40} /></Col>
                <Col md={3} className="mb-4"><Skeleton height={40} /></Col>
                <Col md={3} className="mb-4"><Skeleton height={40} /></Col>
                <Col md={3} className="mb-4"><Skeleton height={40} /></Col>
                <Col md={3} className="mb-4"><Skeleton height={40} /></Col>
                <Col md={3} className="mb-4"><Skeleton height={40} /></Col>
                <Col md={3} className="mb-4"><Skeleton height={40} /></Col>
                <Col md={3} className="mb-4"><Skeleton height={40} /></Col>
                <Col md={3} className="mb-4"><Skeleton height={40} /></Col>
                <Col md={3} className="mb-4"><Skeleton height={40} /></Col>
            </Row>
}

export default PantallaCargando