const { DateTime } = require("luxon")
const { timezone } = require("../../backend/data")

const verificarRetraso = (o) => {
    const hoy = DateTime.now().setZone(timezone)
    if(!o.fecha_finalizado){

        const parseada = DateTime.fromISO(o.fecha_max_entrega, { setZone: false }).setZone('utc')
        const hoy = DateTime.now() 
        if(parseada < hoy) return true
    }
    
    if(!o.fecha_finalizado) return false
    if(!o.fecha_max_entrega) return false

    const fecha_maximo_entrega      = DateTime.fromISO(o.fecha_max_entrega.substring(0,23))
    const fecha_finalizado      = DateTime.fromISO(o.fecha_finalizado.substring(0,23))
    if(o.finalizado === true){
        if(!o.fecha_finalizado) return false
        if(fecha_finalizado <= fecha_maximo_entrega) return false
    }
    if(fecha_maximo_entrega < hoy) return true
    return false
}

module.exports = {
    verificarRetraso
}