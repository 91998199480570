const { DateTime } = require("luxon")
const opcionesfecha = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

const formatDateHoy = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    return [year, month, day].join('-')
}

const formatMes = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    return month;
}

const formatDateMonth = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    return [year, month ].join('-')
}

const formatDateYear = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    return [year].join('-')
}

const addMoreDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result
  }

const restDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    return result
}

const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

const configuracion_fechas = {
    weekday: 'long', 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric'
}

const configuracion_horas = {
    timeZone: 'America/Santiago',
    hour: '2-digit',
    minute: '2-digit'
}

const horas = [
    { num: 0, text: "00:00"},
    { num: 1, text: "01:00"},
    { num: 2, text: "02:00"},
    { num: 3, text: "03:00"},
    { num: 4, text: "04:00"},
    { num: 5, text: "05:00"},
    { num: 6, text: "06:00"},
    { num: 7, text: "07:00"},
    { num: 8, text: "08:00"},
    { num: 9, text: "09:00"},
    { num: 10, text: "10:00"},
    { num: 11, text: "11:00"},
    { num: 12, text: "12:00"},
    { num: 13, text: "13:00"},
    { num: 14, text: "14:00"},
    { num: 15, text: "15:00"},
    { num: 16, text: "16:00"},
    { num: 17, text: "17:00"},
    { num: 18, text: "18:00"},
    { num: 19, text: "19:00"},
    { num: 20, text: "20:00"},
    { num: 21, text: "21:00"},
    { num: 22, text: "22:00"},
    { num: 23, text: "23:00"}
]

const obtenerRangos = (start, end) => {
    const fecha1 = formatDateHoy(start)
    const fecha2 = formatDateHoy(end)
    let desde = new Date(fecha1)
    let hasta = addMoreDays(fecha2,1)
    if(fecha1 === fecha2){
        desde = DateTime.fromISO(fecha1).startOf("day").toISO({ includeOffset: false })
        hasta = DateTime.fromISO(fecha1).endOf("day").set({ minute: 58 }).toISO({ includeOffset: false })
    } else {
        desde = DateTime.fromISO(fecha1).startOf("day").toISO({ includeOffset: false })
        hasta = DateTime.fromISO(fecha2).endOf("day").set({minute: 58 }).toISO({ includeOffset: false })
    }
    return { desde: `${desde}+00:00`, hasta: `${hasta}+00:00` }
}

module.exports = {
    horas,
    opcionesfecha: opcionesfecha,
    formatDateHoy,
    formatMes,
    formatDateMonth,
    formatDateYear,
    addMoreDays,
    restDays,
    formatDate,
    obtenerRangos,
    configuracion_fechas,
    configuracion_horas
}