import Map, { Marker } from 'react-map-gl';
import { mapbox_token, urlapi } from '../../lib/backend/data';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as turf from '@turf/turf';
import { RiMapPinTimeFill } from 'react-icons/ri'
import { colores_diversos } from '../../lib/global/data';
import { obtenerCentroMapaPorPais } from '../../lib/helpers/data/internationa';

const MapaRuteo = (props) => {
    const {
        rutas,
        height,
        markers
    } = props

    console.log({rutas})
    const [ marcadores, setMarcadores ] = useState([])
    const sesion = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const defaultViewState = obtenerCentroMapaPorPais(pais)
    const [viewState, setViewState] = useState(defaultViewState);
    const [ zoomInit, setZoomInit ] = useState(false) 
    const mapRef = useRef()

    const ajustarCentro = (puntos) => {
        if(zoomInit) return false
        if(puntos.length < 1) return false
        setZoomInit(true)
        const points = puntos.map(marker => turf.point([marker.longitude, marker.latitude]));
      const collection = turf.featureCollection(points);
      const bounds = turf.bbox(collection);

      const newViewport = {
        ...viewState,
        latitude: (bounds[1] + bounds[3]) / 2,
        longitude: (bounds[0] + bounds[2]) / 2
      };

      const options = {
        padding: 30 // Ajusta el valor de padding según tus necesidades
      };

      setViewState(newViewport);
      mapRef.current?.fitBounds(bounds, options);
    }

    const procesarPuntos = () => {
        const puntos = []
        rutas.map((ruta,i) => {


            if(ruta.pedidos){
                for( const e of ruta.pedidos ){
                    if(!e.location) return false
                    if(typeof e.location !== "object") return false
                    if(!e.location.coordinates) return false
                    if(Array.isArray(e.location.coordinates) !== true) return false
                    if(e.location.coordinates.length < 2) return false
                    const lat = e.location.coordinates[1]
                    const lng = e.location.coordinates[0]

                    puntos.push({
                        _id: e._id,
                        pos: i,
                        lat,
                        lng
                    })
                }
            }

        })
        setMarcadores(puntos)
        return puntos
    }

      useEffect(() => {

        const puntos = procesarPuntos()
        console.log({ puntos })
        
        setTimeout(() => {
            ajustarCentro(puntos.map(marker => ({ latitude: marker.lat, longitude: marker.lng })))
        }, 500);
        
      }, [ rutas ])

      const refrescarListado = async ()=>{
        console.log("se dispara")
        return fetch(`${urlapi}/conductores/vehicles-actives`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return res.json() //dispatch(cerrarSesio())
            return res.json()
        })
        .then(res => {
            if(!res){
                return false
            } else if(res.errorMessage){
                return false
            } else if(Array.isArray(res) !== false){
                ajustarCentro(res.map(marker => ({ latitude: marker.lat, longitude: marker.lng })))
                setMarcadores(prev => [...[], ...res])
            }
            return false
        })
        .catch(error => {
            return false
        })
    }

    return <Map
    ref={mapRef}
    {...viewState}
    onMove={evt => setViewState(evt.viewState)}
    style={{width: "100%", height: 600}}
    pitch={25}
    mapStyle="mapbox://styles/mapbox/streets-v9"
    mapboxAccessToken={mapbox_token}
  >
    
    {
        marcadores.map(marker => {
            return <Marker
                    key={marker._id}
                    longitude={marker.lng}
                    latitude={marker.lat}
                >
                    <RiMapPinTimeFill style={{ fontSize: 20, color: colores_diversos[marker.pos] }} />
            </Marker>
    })
    }
  </Map>
}

export default MapaRuteo