export const getNombreDestinatario = (pedido) => {
    let destinatario = ''
    if(pedido.orden){
        if(typeof pedido.orden === 'object'){
            if(pedido.orden.billing){
                if(typeof pedido.orden.billing === 'object'){
                    destinatario = `${pedido.orden.billing.first_name ? pedido.orden.billing.first_name : ''} ${pedido.orden.billing.last_name ? pedido.orden.billing.last_name : ''}`
                }
            }
        }
    }
    return destinatario
}

export const getDireccion = (pedido) => {
    let direccion = ''
    if(pedido.orden){
        if(typeof pedido.orden === 'object'){
            if(pedido.orden.billing){
                if(typeof pedido.orden.billing === 'object'){
                    direccion = `${pedido.orden.billing.address_1} ${pedido.orden.billing.address_2 ? pedido.orden.billing.address_2 : ''}`
                }
            }
        }
    }
    return direccion
}

export const getDireccion1 = (pedido) => {
    let direccion = ''
    if(pedido.orden){
        if(typeof pedido.orden === 'object'){
            if(pedido.orden.billing){
                if(typeof pedido.orden.billing === 'object'){
                    direccion = pedido.orden.billing.address_1
                }
            }
        }
    }
    return direccion
}

export const getDireccion2 = (pedido) => {
    let direccion = ''
    if(pedido.orden){
        if(typeof pedido.orden === 'object'){
            if(pedido.orden.billing){
                if(typeof pedido.orden.billing === 'object'){
                    direccion = pedido.orden.billing.address_2
                }
            }
        }
    }
    return direccion
}

export const getEmail = (pedido) => {
    let valor = ''
    if(pedido.orden){
        if(typeof pedido.orden === 'object'){
            if(pedido.orden.billing){
                if(typeof pedido.orden.billing === 'object'){
                    valor = `${pedido.orden.billing.email}`
                }
            }
        }
    }
    return valor
}

export const getTelefono = (pedido) => {
    let valor = ''
    if(pedido.orden){
        if(typeof pedido.orden === 'object'){
            if(pedido.orden.billing){
                if(typeof pedido.orden.billing === 'object'){
                    valor = `${pedido.orden.billing.phone}`
                }
            }
        }
    }
    return valor
}

export const canalEstadosString = (str = String) => {
    switch (str) {
        case 'app_conductor':
            return 'App Conductor'
        case 'sistema-web':
            return 'Sistema Web'
        default:
            return str
    }
}

export const showByTipoServicio = (tipo, tipos) => {
    const i = tipos.findIndex(tip => tip.value === tipo)
    if(i > -1) return tipos[i].label
    return `No identificado`
}

export const obtenerGeoValor = (pedido, key) => {
    let direccion = ''
    if(!key) return "Llave es requerida"
    if(pedido.orden){
        if(typeof pedido.orden === 'object'){
            if(pedido.orden.geo_datos){
                if(typeof pedido.orden.geo_datos === 'object'){
                        direccion = pedido.orden.geo_datos[key]
                }
            }
        }
    }
    return direccion ? direccion : "Sin información"
}

export const obtenerGeoDatosGoogle = (informacion) => {
    if(informacion.address_components){
        if(Array.isArray(informacion.address_components) !== false){
            if(informacion.address_components.length > 0){
                let pais = ''
                let codigo_pais = ''
                let level1 = ''
                let level2 = ''
                let level3 = ''
                let lat = 0
                let lng = 0
                let formatted_address = ''

                if(informacion.geometry){
                    if(typeof informacion.geometry === 'object'){
                        if(informacion.geometry.location){
                            if(typeof informacion.geometry.location === 'object'){
                                if(informacion.geometry.location.lat){
                                    lat = informacion.geometry.location.lat
                                    lng = informacion.geometry.location.lng
                                }
                            }
                        }
                    }
                }

                if(informacion.formatted_address){
                    formatted_address = informacion.formatted_address
                }

                const i_pais = informacion.address_components.findIndex(a => a.types.includes('country'))
                if(i_pais > -1){
                    pais = informacion.address_components[i_pais].long_name
                    codigo_pais = informacion.address_components[i_pais].short_name
                }

                const l1 = informacion.address_components.findIndex(a => a.types.includes('administrative_area_level_1'))
                if(l1 > -1) level1 = informacion.address_components[l1].long_name
                
                const l2 = informacion.address_components.findIndex(a => a.types.includes('administrative_area_level_2'))
                if(l2 > -1) level2 = informacion.address_components[l2].long_name
                
                const l3 = informacion.address_components.findIndex(a => a.types.includes('administrative_area_level_3'))
                if(l3 > -1) level3 = informacion.address_components[l3].long_name

                return {
                    pais,
                    codigo_pais,
                    formatted_address,
                    level1,
                    level2,
                    level3,
                    lat,
                    lng
                }
            }
        }
    }
    return false
}