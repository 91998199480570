import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import data, { urlapi } from '../../lib/backend/data'
import PantallaCargando from '../general/pantalla_cargando';
import { Button, Spinner } from 'react-bootstrap';

const FlujosEstadosCarga = (props) => {

    const [ flujos, setFlujos ] = useState([])
    const [ nuevoFlujo, setNuevoFlujo ] = useState({})
    const [ estadosCarga, setEstadosCarga ] = useState([])
    const [ subEstadosCarga, setSubEstadosCarga ] = useState([])
    const [ loadingEstadosCarga, setLoadingEstadosCarga ] = useState(true)
    const [ loadingFlujos, setLoadingFlujos ] = useState(true)
    const [ loadingCreacionFlujo, setLoadingCreacionFlujo ] = useState(false)
    const [ loadingCreacionSegmento, setLoadingCreacionSegmento ] = useState(false)
    const [ clienteConsultar, setClienteConsultar ] = useState({ value: props.owner, label: '' })
    const [ idGuardandoSegmento, setGuardandoSegmento ] = useState(false)
    const [ idGuardandoFlujo, setGuardandoFlujo ] = useState(false)
    const [ tipos_servicio, setTipoServicio ] = useState([])
    const [ loadingTiposServicio, setLoadingTipoServicio ] = useState(true)
    const user = props.user

    useEffect(() => {
        getFlujos()
        setEstadosCarga([])
        obtenerEstados()
        getConfiguracion('logistica','tipos-servicio')
    }, [])
    
    const getConfiguracion = (tipo, subtipo) => {
        setLoadingTipoServicio(true)
        return fetch(`${urlapi}/configuracion/tipo?tipo=${tipo}&subtipo=${subtipo}`,{
            method: "GET",
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            },
        })
        .then(pros => pros.json())
        .then(data => { 
            console.log(data)
            if(data.errorMessage){
                toast.error(data.errorMessage)
                return setLoadingTipoServicio(false)
            } else if(Array.isArray(data) !== false){
                if(data.length > 0){
                    const datos = data.map(e => ({ value: e._id, label: e.valor }))
                    setTipoServicio(datos)
                }
            }
            return setLoadingTipoServicio(false)
        })
        .catch(error => {
            toast.error('No pudimos cargar la información')
            return setLoadingTipoServicio(false)
        })
    }

    const showByTipoServicio = (tipo) => tipo

    const handleChangeNuevoFlujo = (e) => {
        const { name, value } = e.target
        nuevoFlujo[name] = value
        return setNuevoFlujo(nuevoFlujo)
    }

    const handleChangeSelectEstadoCarga = (e,id_segmento,id_flujo) => {
        const i_flujo = flujos.findIndex(flu => flu.flujo._id === id_flujo)
        const i_segmento = flujos[i_flujo].segmentos.findIndex(seg => seg._id === id_segmento)
        flujos[i_flujo].segmentos[i_segmento].estado_requerido = e.value
        return setFlujos(flujos)
    }
    const handleChangeSelectEstadosDisponibles = (e,id_segmento,id_flujo) => {
        let opciones = []
        if(e){
            e.map(valor => {
                opciones.push(valor.value)
            })
        }
        const i_flujo = flujos.findIndex(flu => flu.flujo._id === id_flujo)
        const i_segmento = flujos[i_flujo].segmentos.findIndex(seg => seg._id === id_segmento)
        flujos[i_flujo].segmentos[i_segmento].estados_disponibles = opciones
        return setFlujos(flujos)
    }

    const handleChangeSelectTipoServicio = (e) => {
        let opciones = []
        if(e){
            e.map(valor => {
                opciones.push(valor.value)
            })
        }
        nuevoFlujo.tipos_servicio = opciones
        return setNuevoFlujo(nuevoFlujo)
    }

    const handleChangeEditarFlujo = (e) => {
        const { name, value } = e.target
        const id_flujo = e.target.getAttribute('idflujo')
        console.log(id_flujo)
        const i_flujo = flujos.findIndex(flu => flu.flujo._id === id_flujo)
        flujos[i_flujo].flujo[name] = value
        return setFlujos(flujos)
    }

    const handleChangeSelectTipoServicioEditar = (e,id_flujo) => {
        let opciones = []
        if(e){
            e.map(valor => {
                opciones.push(valor.value)
            })
        }
        const i_flujo = flujos.findIndex(flu => flu.flujo._id === id_flujo)
        flujos[i_flujo].flujo.tipos_servicio = opciones
        return setFlujos(flujos)
    }

    const obtenerEstados = async () => {
        setLoadingEstadosCarga(true)
        return fetch(`${data.urlapi}/estadoscarga/details`,{
            method: "GET",
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(pros => pros.json())
        .then(data => { 
            if(data.errorMessage){
                toast.error(data.errorMessage)
                return setLoadingEstadosCarga(false)
            }
            if(data.estados.length < 1) toast.warn('No hay estados de carga para este cliente')
            if(Array.isArray(data.estados) !== false) setEstadosCarga(data.estados)
            if(Array.isArray(data.subestados) !== false) setSubEstadosCarga(data.subestados)
            setLoadingEstadosCarga(false)
        })
        .catch(error => {
            toast.error('No pudimos cargar la información')
            setLoadingEstadosCarga(false)
        })
    }

    const crearFlujo = () => {
        const requeridos = [
            { value:'titulo', label: 'Título' },
            { value:'descripcion', label: 'Descripción' },
            { value:'tipos_servicio', label: 'Tipos de servicio' },
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!nuevoFlujo[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)
        setLoadingCreacionFlujo(true)
        return fetch(`${data.urlapi}/estadoscarga/flujos`,{
            method:'POST',
            body: JSON.stringify( nuevoFlujo ),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error('Sin respuesta del servidor')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res.flujo){ 
                toast.success('Guardado exitosamente')
                flujos.push(res)
                setFlujos(flujos)
            } else {
                toast.error('Ocurrió un error inesperado')
            }
            setLoadingCreacionFlujo(false)
        })
        .catch(error => {
            setLoadingCreacionFlujo(false)
            toast.error("Error al realizar esta operación, intente nuevamente")
        })
    }

    const agregarSegmento = async (idflujo) => {
        setLoadingCreacionSegmento(true)
        return fetch(`${data.urlapi}/estadoscarga/flujos/segmentos`,{
            method:'POST',
            body: JSON.stringify( {
                idflujo
            } ),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            if(res._id){ 
                toast.success('Guardado exitosamente')
                const i = flujos.findIndex(flu => flu.flujo._id === idflujo)
                flujos[i].segmentos.push(res)
                setFlujos(flujos)
            } else {
                toast.error('Ocurrió un error inesperado')
            }
            setLoadingCreacionSegmento(false)
        })
        .catch(error => {
            console.log(error)
            setLoadingCreacionSegmento(false)
            toast.error("Error al realizar esta operación, intente nuevamente")
        })
    }

    const getFlujos = () => {
        setLoadingFlujos(true)
        const url = `${data.urlapi}/estadoscarga/flujos/list`;
        return fetch(url,{
            method: "GET",
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(pros => pros.json())
        .then(pros => {
            if(!pros){
                toast.error('Ocurrió un error inesperado, Sin respuesta del servidor')
                return setLoadingFlujos(false)
            } else if(pros.errorMessage){
                toast.error(`Ocurrió un error inesperado: ${pros.errorMessage}`, )
                return setLoadingFlujos(false)
            }
            if(Array.isArray(pros) !== false) setFlujos(pros)
            setLoadingFlujos(false)
        })
        .catch(error => {
            console.log(error)
            toast.error('Ocurrió un error inesperado')
            setLoadingFlujos(false)
        })
    }

    const handleChangeSelectClientev2 = (e) => {
        return setClienteConsultar(e)
    }

    const formulario_crear_flujo = () => {
        return <div>
            <div className='row'>
                <div className='col-md-12 mb-2'>
                    <label className='form-control-label d-block'>Título</label>
                    <input className='form-control' name="titulo" placeholder='Escribe un nombre corto para recordar este flujo' onChange={handleChangeNuevoFlujo} />
                </div>
                <div className='col-md-12 mb-2'>
                    <label className='form-control-label d-block'>Descripción</label>
                    <input className='form-control' name="descripcion" placeholder='Describe este flujo' onChange={handleChangeNuevoFlujo} />
                </div>
                <div className='col-md-12 mb-2'>
                    <label className='form-control-label d-block'>Descripción</label>
                    <Select 
                    style={{ marginBottom: 10 }}
                    onChange={handleChangeSelectTipoServicio}
                    options={tipos_servicio}
                    isMulti={true}
                    placeholder="Tipo de servicio..."
                    />
                </div>
                <div className='col-md-12 mb-2'>
                    <label className='form-control-label d-block'>Click para crear</label>
                    {
                        loadingCreacionFlujo === true ? <Spinner animation='border' /> : <Button variant="success" className='w-100' onClick={()=>crearFlujo()}>CREAR</Button>
                    }
                    
                </div>
            </div>
        </div>
    }

    const mostrarTiposServicioConcatenados = (tipos_servicio) => {
        if(!tipos_servicio) return false
        if(Array.isArray(tipos_servicio) !== true) return false
        if(tipos_servicio.length < 1) return false
        return tipos_servicio.map(tipo => <span className='spanguia mr-2' style={{ fontSize: 11 }}>{showByTipoServicio(tipo)}</span>)
    }

    const guardarCambiosSegmento = (segmento) => {
        setGuardandoSegmento(segmento._id)
        return fetch(`${data.urlapi}/estadoscarga/flujos/segmentos`,{
            method:'PUT',
            body: JSON.stringify( segmento ),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(res._id){ 
                toast.success('Guardado exitosamente')
            } else {
                toast.error('Ocurrió un error inesperado')
            }
            setGuardandoSegmento(false)
        })
        .catch(error => {
            setGuardandoSegmento(false)
            toast.error("Error al realizar esta operación, intente nuevamente")
        })
    }

    const eliminarSegmento = async (id, idflujo) => {
        setGuardandoSegmento(id)
        return fetch(`${data.urlapi}/estadoscarga/flujos/segmentos?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error('No se obtuvieron datos del servidor')
            } else if(res.errorMessage){ 
                toast.success(res.errorMessage)
            } else if(res._id){ 
                const i_flujo = flujos.findIndex(flu => flu.flujo._id === idflujo)
                const i_segmento = flujos[i_flujo].segmentos.findIndex(seg => seg._id === id)
                flujos[i_flujo].segmentos.splice(i_segmento,1)
                setFlujos(flujos)
                toast.success('Eliminado exitosamente')

            }
            setGuardandoSegmento(false)
        })
        .catch(error => {
            setGuardandoSegmento(false)
            toast.error("Error al realizar esta operación, intente nuevamente")
        })
    }
    const eliminarFlujo = async (id) => {
        setGuardandoSegmento(id)
        return fetch(`${data.urlapi}/estadoscarga/flujos?id=${id}`,{
            method:'DELETE',
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error('No se obtuvieron datos del servidor')
            } else if(res.errorMessage){ 
                toast.success(res.errorMessage)
            } else if(res._id){ 
                const i_flujo = flujos.findIndex(flu => flu.flujo._id === id)
                flujos.splice(i_flujo,1)
                setFlujos(flujos)
                toast.success('Eliminado exitosamente')

            }
            setGuardandoSegmento(false)
        })
        .catch(error => {
            setGuardandoSegmento(false)
            toast.error("Error al realizar esta operación, intente nuevamente")
        })
    }

    const solicitarEliminarFlujo = (id) => {
        return confirmAlert({
            title: '¿Estás seguro?',
            message: `¿Deseas eliminar este item? Esta opción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => eliminarFlujo(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              },
            ],
          })
    } 

    const actualizarFlujo = async (flujo) => {
        setGuardandoFlujo(flujo._id)
        return fetch(`${data.urlapi}/estadoscarga/flujos`,{
            method:'PUT',
            body: JSON.stringify( flujo ),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error('No se obtuvo respuesta del servidor')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){ 
                toast.success('Guardado exitosamente')
            }
            setGuardandoFlujo(false)
        })
        .catch(error => {
            setGuardandoFlujo(false)
            toast.error("Error al realizar esta operación, intente nuevamente")
        })
    }

    const solicitarEliminarSegmento = (id, idflujo) => {
        return confirmAlert({
            title: '¿Estás seguro?',
            message: `¿Deseas eliminar este item? Esta opción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => eliminarSegmento(id, idflujo)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              },
            ],
          })
    } 

    const mostrar_registros = (registros) => {
        if(loadingFlujos === true) return <PantallaCargando />
        if(loadingEstadosCarga === true) return <PantallaCargando />

        if(registros.length < 1) return <div className='p-4'>
            <div className='row justify-content-md-center'>
                <div className='col-md-4 text-center'>
                    <h2 className='mb-0'>No hay flujos creados</h2>
                    <p className='mb-0'>Puedes crear flujos para controlar la forma en que los estados estarán disponibles en App Conductores</p>
                    {formulario_crear_flujo()}
                </div>
            </div>
        </div>

        const estados_carga_selector = estadosCarga.map(estado => ({ value: estado._id, label: estado.titulo })) 
        estados_carga_selector.unshift({ value:'', label:'Ninguno' })
        return <div>
            <div className='p-4'>
                <div className='row justify-content-md-center'>
                    <div className='col-md-4 text-center'>
                        <h2 className='mb-0'>Crear nuevo flujo</h2>
                        {formulario_crear_flujo()}
                    </div>
                </div>
            </div>
            <h2 className='mt-3 mb-3'>Mis flujos</h2>
            {
                registros.map(flujo => {
                    const detalles_flujo = flujo.flujo ? flujo.flujo : {}

                    let tipos_servicio_elegidos = []

                                if(detalles_flujo.tipos_servicio){
                                    if(Array.isArray(detalles_flujo.tipos_servicio) !== false){
                                        if(detalles_flujo.tipos_servicio.length > 0){
                                            tipos_servicio_elegidos = tipos_servicio.filter(op => {
                                                return detalles_flujo.tipos_servicio.includes(op.value)
                                            })
                                        }
                                    }
                                }

                    return <div className='card p-3 mb-3' key={detalles_flujo._id}>
                        
                        <div className='row'>
                            <div className='col-md-4 mb-2'>
                                <label className='form-control-label d-block'>Título</label>
                                <input className='form-control' name="titulo" idflujo={detalles_flujo._id} placeholder='Escribe un nombre corto para recordar este flujo' defaultValue={detalles_flujo.titulo} onChange={handleChangeEditarFlujo} />
                            </div>
                            <div className='col-md-4 mb-2'>
                                <label className='form-control-label d-block'>Descripción</label>
                                <input className='form-control' name="descripcion" idflujo={detalles_flujo._id} placeholder='Describe este flujo' defaultValue={detalles_flujo.descripcion} onChange={handleChangeEditarFlujo} />
                            </div>
                            <div className='col-md-4 mb-2'>
                                <label className='form-control-label d-block'>Tipos servicio</label>
                                <Select 
                                style={{ marginBottom: 10 }}
                                onChange={(e) => handleChangeSelectTipoServicioEditar(e,detalles_flujo._id)}
                                defaultValue={tipos_servicio_elegidos}
                                options={tipos_servicio}
                                isMulti={true}
                                placeholder="Tipo de servicio..."
                                />
                            </div>
                            <div className='col-md-12 mb-2'>
                                {
                                                idGuardandoFlujo === detalles_flujo._id ? 
                                                <Spinner animation='border' />
                                                : 
                                                <button className='btn btn-sm btn-success mr-2' onClick={()=>actualizarFlujo(detalles_flujo)}>GUARDAR CAMBIOS</button>
                                }
                                <button className='btn btn-sm btn-outline-danger' onClick={()=>solicitarEliminarFlujo(detalles_flujo._id)} >ELIMINAR</button>
                            </div>
                        </div>
                        
                        {
                            loadingCreacionSegmento === true ? <Spinner animation='border' /> : <button className='btn btn-secundary mb-3' onClick={()=>agregarSegmento(detalles_flujo._id)}><i className="fas fa-plus-circle"></i> AGREGAR SEGMENTO</button>
                        }
                        <h4>{flujo.segmentos.length < 1 ? 'Sin segmentos creados' : `${flujo.segmentos.length} segmentos`}</h4>
                        <hr className='hr' />
                        {
                            flujo.segmentos.map((segmento,i) => {
                                let opciones_tipo_servicio_elegidas = []

                                if(segmento.estados_disponibles){
                                    if(Array.isArray(segmento.estados_disponibles) !== false){
                                        if(segmento.estados_disponibles.length > 0){
                                            opciones_tipo_servicio_elegidas = estados_carga_selector.filter(op => {
                                                return segmento.estados_disponibles.includes(op.value)
                                            })
                                        }
                                    }
                                }

                                let opcion_defecto =  false
                                if(typeof segmento.estado_requerido === 'string'){
                                    const i = estados_carga_selector.findIndex(es => es.value === segmento.estado_requerido)
                                    if(i > -1) opcion_defecto = estados_carga_selector[i]
                                }
                                return <div className='card p-3 mb-3'>
                                    <h3>Segmento {i+1}</h3>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label className='form-control-label d-block'>Estado requerido</label>
                                            <Select 
                                                style={{ marginBottom: 10 }}
                                                onChange={(e)=>handleChangeSelectEstadoCarga(e,segmento._id,detalles_flujo._id)}
                                                options={estados_carga_selector}
                                                defaultValue={opcion_defecto}
                                                placeholder={`Estado requerido...`}
                                                />    
                                        </div>
                                        <div className='col-md-4 '>
                                            <label className='form-control-label d-block'>Estados permitidos</label>
                                            <Select 
                                                style={{ marginBottom: 10 }}
                                                onChange={(e)=>handleChangeSelectEstadosDisponibles(e,segmento._id,detalles_flujo._id)}
                                                isMulti
                                                options={estados_carga_selector}
                                                defaultValue={opciones_tipo_servicio_elegidas}
                                                placeholder="Estado requerido..."
                                                />    
                                        </div>
                                        <div className='col-md-2'>
                                            <label className='form-control-label d-block'>Click para guardar</label>
                                            {
                                                idGuardandoSegmento === segmento._id ? 
                                                <Spinner animation='border' />
                                                : 
                                                <button className='btn btn-sm btn-success' onClick={()=>guardarCambiosSegmento(segmento)} >GUARDAR CAMBIOS</button>
                                            }
                                        </div>
                                        <div className='col-md-2'>
                                            <label className='form-control-label d-block'>Click para eliminar</label>
                                            {
                                                idGuardandoSegmento === segmento._id ? 
                                                <Spinner animation='border' />
                                                : 
                                                <button className='btn btn-sm btn-outline-danger' onClick={()=>solicitarEliminarSegmento(segmento._id, detalles_flujo._id)} >ELIMINAR</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                })
            }
        </div>
    }

                            
    return <div className='p-3'>
        {mostrar_registros(flujos)}
    </div>
}

export default FlujosEstadosCarga