import Moment from 'react-moment'
import 'moment/locale/es';
import { Button, Card, Col, Dropdown, DropdownButton, OverlayTrigger, Row, Spinner, Tab, Tabs, Tooltip } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'
import { useEffect, useState } from "react";
import { canalEstadosString, getDireccion, getEmail, getNombreDestinatario, getTelefono } from "../../lib/helpers/pedidos/pedidos";
import { fechaATexto, fechaATextoSimple } from "../../lib/helpers/helpers";
import BoxConductor from "../conductores/box_conductor";
import TablaProductos from './tabla_productos'
import EmitirEstadoModal from '../estados_carga/emitir_modal'
import AccionesModal from "./acciones_modal";
import LogsListado from '../integraciones/logs/listado';
import { centerMapCoordinates } from '../../lib/helpers/maps';
import GeneradorEtiquetas from '../etiquetas/generador_etiquetas';
import BoxEditarGeoDatosOrdenIndividual from './box_editar_geodata_individual';
import Header from '../../components/Header';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { urlapi } from '../../lib/backend/data';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import MapaDetallePedido from '../../components/Mapas/mapa_detalle_pedido';
import SinDatos from '../general/sin_registros';
import { formatDateHoy } from '../../lib/helpers/opcionesfecha';
import MetaDatosOrden from './metadata/metadatos_orden';
import { AiFillCheckCircle, AiFillClockCircle } from 'react-icons/ai'
import { Esquema } from '../../lib/esquemas/tablas';

const DetallesPedido = (props) => {
    const [ pedido, setPedido ] = useState(false)
    const [ pedidoEditar, setPedidoEditar ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(true)
    const [ estados, setEstados ] = useState([])
    const [ etiquetas, setEtiquetas ] = useState([])
    const [ marcadores, setMarcadores ] = useState([])
    const [ lineaTiempo, setLineaTiempo ] = useState([])
    const [ cambiosOrden, setCambiosOrden ] = useState({})
    const [ loadingFicha, setLoadingFicha ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const { id } = useParams()

    const obtenerPedido = async () => {
        if(!id) return setLoadingDetalle(false)
        setLoadingDetalle(true)
        return fetch(`${urlapi}/ordenes/details-full?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingDetalle(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingDetalle(false)
            } else if(res.orden){
                if(res.time_line){
                    if(Array.isArray(res.time_line) !== false) setLineaTiempo(res.time_line)
                }
                if(res.orden) {
                    setPedido(res.orden)
                    setPedidoEditar(res.orden)
                    setEtiquetas(res.etiquetas)
                  }
                  let puntos = []
                  if(res.estados) setEstados(res.estados)
                  if(res.orden){
                    if(res.orden.location){
                      if(Array.isArray(res.orden.location.coordinates)){
                          if(res.orden.location.coordinates.length > 0){
                            puntos.push({
                              lat: res.orden.location.coordinates[1],
                              lng: res.orden.location.coordinates[0],
                              ref: res.orden.pedido,
                              destinatario: getNombreDestinatario(res.orden)
                            })
                            setMarcadores(puntos)
                          }
                      }
                    }
                  }
            }
            return setLoadingDetalle(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingDetalle(false)
        })
    }
    
    useEffect(() => {
      obtenerPedido()
    },[])


    const showComponentByTipoFormulario = (campo) =>{
        switch (campo.tipo_accion) {
          case 'campo-texto':
            return <div className="mb-3">
              <h6 className='mb-0'>{campo.titulo}</h6>
              <p className='mb-0'>{campo.valor ? campo.valor : 'Sin información'}</p>
            </div>
          case 'selector':
            return <div className="mb-3">
              <h6 className='mb-0'>{campo.titulo}</h6>
              <p className='mb-0'>{campo.valor ? campo.valor : 'Sin información'}</p>
            </div>
          case 'carga-imagenes':
            return <div className="mb-3">
              <h6 className='mb-2'>{campo.titulo}</h6>
              
              {
                campo.valores.length > 0 ? campo.valores.map((foto,i) => {
                  return <div md={3} key={`foto-estado-${i}`} style={{ display: "inline-block" }}>
                    <a href={foto.url} target="_blank" className='pr-2'>
                    <img src={foto.url} style={{  height: 70 }} ></img>
                    </a>
                    </div>
                }) : <Col md={12}><p>Sin información</p></Col>
              }
            </div>
          default:
            break;
        }
    }

    const actualizarEstadoListado = (estado_entrega, sub_estado_entrega) => {
      if(props.actualizarEstadoListado) return props.actualizarEstadoListado(estado_entrega, sub_estado_entrega)
    }

    const actualizarEstadoActual = (data) => {
        const actualizar_estado = {
          codigo_estado: data.codigo_estado,
          titulo: data.estado,
          color: data.estado_color ? data.estado_color : '#babbbb',
        }
        
        const actualizar_sub_estado = {
            codigo_estado: data.codigo_subestado,
            titulo: data.subestado,
            color: data.subestado_color ? data.subestado_color : '#babbbb',
        }
        actualizarEstadoListado(actualizar_estado, actualizar_sub_estado)
        return setEstados(prev => [ ...prev, ...[data] ])
    }

    const onSucessChange = (data) => {
      const { direccion, direccion2 } = data
      if(!direccion || !direccion2) return false
      pedido.orden.billing.address_1 = direccion
      pedido.orden.billing.address_2 = direccion2
      return setPedido(prev => ({...{}, ...pedido}))
    }

    const mostrarMetaDatosSubEstado = (metadatos) => {
        if(!metadatos) return false
        if(Array.isArray(metadatos) !== true) return false
        if(metadatos.length < 1) return false
  
        return metadatos.map((meta,i) => {
          return <div key={`meta-${i}`}>
            {showComponentByTipoFormulario(meta)}
          </div>
        })
      }

      const mostrarCamposPersonalizados = () => {
        if(!pedido.meta_data) return <SinDatos />
        if(Array.isArray(pedido.meta_data) !== true ) return <SinDatos />
        if(pedido.meta_data.length < 1) return <SinDatos />
        return <div>
            <Row>
            {
                pedido.meta_data.map((val,pos) => {
                    return <Col className='mb-3' md={3} xs={6} key={`campo-${pos}`}>
                        <label className="form-control-label mb-0 d-block" style={{ fontSize: 14}}><b>{val.key ? val.key.toUpperCase() : "SIN INFORMACIÓN"}</b></label>
                        <input className='form-control' defaultValue={val.value} />
                    </Col>
                  })
            }
            </Row>
        </div>
      }

      const handleChangeOrdenBilling = (e) => {
        const { name, value } = e.target
        let target = JSON.parse( JSON.stringify(pedidoEditar) )
        target.orden.billing[name] = value
        return setPedidoEditar(target)
      }

      const handleChangeOrden = (e) => {
        const { name, value } = e.target
        let target = JSON.parse( JSON.stringify(pedidoEditar) )
        target[name] = value
        return setPedidoEditar(target)
      }

      const guardarCambiosFicha = async () => {
        setLoadingFicha(id)
        return fetch(`${urlapi}/ordenes`,{
            method:'PUT',
            body: JSON.stringify(pedidoEditar),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
          console.log(res)
          if(!res){
            toast.error("Sin respuesta")
          } else if(res.errorMessage){
            toast.error(res.errorMessage)
          } else if(res._id){
            
          }
          return setLoadingFicha(false)
        })
        .catch(error => {
          toast.error(error.message)
          return setLoadingFicha(false)
        })

      }

      const agregarProducto = () => {
        if(!pedido.productos) pedido.productos = []
        pedido.productos.push({})
        return setPedido({ ...{}, ...pedido })
      }

      const onDelete = (pos) => {
        pedido.productos.splice(pos,1)
        return setPedido({...{}, ...pedido})
      }

      const mostrarLineaTiempo = () => {
        if(lineaTiempo.length < 1) return false

        return <div className='mb-4 '>
            <h4 className='text-center'>Línea de tiempo</h4>
            <Row>
            {
                lineaTiempo.map((evento,i) => {
                    if(evento.opcional_linea_tiempo === true && !evento.emitido) return false
                    return <Col key={evento._id}>
                        {evento.emitido === true ? <AiFillCheckCircle color='green' /> : <AiFillClockCircle color='black' />}
                        <h6 className='mb-0'>{evento.titulo}</h6>
                        { evento.fecha_emitido ? <span style={{ color:"#b2b2b2", fontSize: 10 }} className='d-block'>{fechaATexto(evento.fecha_emitido)}</span> : <span style={{ color:"#b2b2b2", fontSize: 10 }} className='d-block'>Sin información</span> }
                    </Col>
                })
            }
            </Row>
        </div>
      }
      
      const mostrarInformacion = () => {

        if(loadingDetalle) return  <div className="container-fluid">
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        </div>

        if(!pedido) return <div className="container-fluid">
        <Row className="justify-content-md-center">
            <Col md={6} className="text-center">
            <img src="images/pedidos.png" style={{ width: "50%" }} />
            <h4>No hay suficientes datos para mostrar esta sección</h4>
            </Col>
        </Row>
        </div>

        let defaultAccordion = false

        if(estados.length > 0) defaultAccordion = `estado-${estados.length}`



        return <div>
                <MapaDetallePedido markers={marcadores} />
                <div className="container-fluid" style={{ position: "relative", zIndex: 9 }}>
                    
            <Row>
            <Col md={12} className='mb-3'>
            <Card className='p-3' style={{ marginTop: -70 }} shadow >
                <h4>ORDEN {pedido.pedido}</h4>
                <AccionesModal token={token} pedido={pedido} />
                <hr className='mt-4 mb-4'/>
                <Row>
                    <Col md={3} xs={12}></Col>
                    <Col md={6} xs={12}>{mostrarLineaTiempo()}</Col>
                    <Col md={3} xs={12}></Col>
                </Row>
                <h6 style={{ fontWeight: 'lighter'}}>Itinerario y detalles del conductor</h6>
                <EmitirEstadoModal token={token} pedido={pedido} onStatusEmited={(data) => actualizarEstadoActual(data)} />

                <div className="mb-2"></div>

                <Accordion defaultActiveKey={''} className="mb-3">
                {
                    estados.map((estado,i) => {

                      let lat = 0
                      let lng = 0

                      if(estado.location){
                        if(Array.isArray(estado.location.coordinates) !== false){
                          if(estado.location.coordinates.length > 1){
                            lat = estado.location.coordinates[0]
                            lng = estado.location.coordinates[1]
                          }
                        }
                      }

                        return <Card className="p-0">
                            <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor:'white' }} eventKey={`estado-${i+1}`}>
                            <div className="pl-2 pb-0">
                            <span style={{ fontWeight: 'lighter' }}>{fechaATexto(estado.fecha)} · <a href={`https://google.com/maps?q=${lat},${lng}`} target="_blank"><i className="fas text-dark fa-map-marker-alt"></i></a></span>
                            <p className="mb-0"><i className="fa-solid fa-circle" style={{ color: estado.estado_color }}></i> <b>{estado.estado}</b> <b>{estado.subestado}</b></p>
                            </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={`estado-${i+1}`}>
                            <Card.Body>
                              <p className="mb-0"><b>Canal:</b> {canalEstadosString(estado.canal)}</p>
                              <p className="mb-0"><b>Por :</b> {estado.identificador_creador}</p>
                              <hr />
                                {
                                    mostrarMetaDatosSubEstado(estado.metadatos)
                                }
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    })
                }
                </Accordion>
                </Card>
            </Col>
            <Col md={12} className="pt-4" style={{ borderTop:'1px solid #e8e8e8', marginBottom: 30 }}>
                <Row>
                    <Col md={12}>
                        <Row>
                          <Col md={12}>

                          <Tabs defaultActiveKey="info">
                          
                          <Tab eventKey="info" title="Información general" className='pt-4'>
                            <Row>
                                <Col md={6}>
                                <h6>Ficha</h6>
                                <Row>
                                <Col md={4} className='mb-3'>
                                    <label className="mb-0 form-control-label d-block">Destinatario</label>
                                    <input className='form-control' name="destinatario" defaultValue={getNombreDestinatario(pedido)} onChange={handleChangeOrdenBilling} />
                                </Col>
                                <Col md={4} className='mb-3'>
                                    <label className="mb-0 form-control-label d-block">Email</label>
                                    <input className='form-control' name="email" defaultValue={getEmail(pedido)} onChange={handleChangeOrdenBilling} />
                                </Col>
                                <Col md={4} className='mb-3'>
                                    <label className="mb-0 form-control-label d-block">Teléfono</label>
                                    <input className='form-control' name="movil" defaultValue={getTelefono(pedido)} onChange={handleChangeOrdenBilling} />
                                </Col>
                                <Col md={12} className='mb-3'>
                                    <label className="mb-0 form-control-label">Observaciones</label>
                                    <input className='form-control' name="nota" defaultValue={pedido.nota} onChange={handleChangeOrden} />
                                </Col>
                                <Col md={12} className='mb-3'>
                                    <h6><i className="fas fa-clock"></i> Fecha de compromiso</h6>
                                </Col>
                                <Col md={6} className='mb-3'>
                                    <label className="mb-0 form-control-label d-block">Fecha mínima de entrega</label>
                                    <input type='date' className='form-control' name="fecha_min_entrega" defaultValue={formatDateHoy(pedido.fecha_min_entrega)} onChange={handleChangeOrden} />
                                </Col>
                                <Col md={6} className='mb-3'>
                                    <label className="mb-0 form-control-label d-block">Fecha tope de entrega</label>
                                    <input type='date' className='form-control' name="fecha_max_entrega" defaultValue={formatDateHoy(pedido.fecha_max_entrega)} onChange={handleChangeOrden} />
                                </Col>
                                <Col  className="mb-3">
                          <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >
                                Peso en Kilogramos
                              </Tooltip>
                            }
                            >
                            <div>
                            <p className="mb-0" style={{ fontSize: 14}}><b>Peso</b></p>
                            <input type="number" className='form-control' name="peso" defaultValue={pedido.peso} onChange={handleChangeOrden} />
                            </div>
                          </OverlayTrigger>
                          </Col>
                          <Col  className="mb-3">
                          <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >
                                Metros Cúbicos
                              </Tooltip>
                            }
                            >
                            <div>
                            <p className="mb-0" style={{ fontSize: 14}}><b>Volumen</b></p>
                            <input type="number" className='form-control' name="volumen" defaultValue={pedido.volumen} onChange={handleChangeOrden} />
                            </div>
                          </OverlayTrigger>
                          
                          </Col>
                          <Col  className="mb-3">
                          <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >
                                Bultos secos
                              </Tooltip>
                            }
                            >
                            <div>
                              <p className="mb-0" style={{ fontSize: 14}}><b>Bultos</b></p>
                              <input type="number" className='form-control' name="bultos" defaultValue={pedido.bultos} onChange={handleChangeOrden} />
                            </div>
                          </OverlayTrigger>
                          </Col>
                          <Col  className="mb-3">
                          <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >
                                Bultos regrigerados
                              </Tooltip>
                            }
                            >
                            <div>
                              <p className="mb-0" style={{ fontSize: 14}}><b>Bultos R.</b></p>
                              <input type="number" className='form-control' name="refrigerados" defaultValue={pedido.refrigerados} onChange={handleChangeOrden} />
                            </div>
                          </OverlayTrigger>
                          </Col>
                          <Col  className="mb-3">
                          <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >
                                Bultos congelados
                              </Tooltip>
                            }
                            >
                            <div>
                              <p className="mb-0" style={{ fontSize: 14}}><b>Bultos C.</b></p>
                              <input type="number" className='form-control' name="congelados" defaultValue={pedido.congelados} onChange={handleChangeOrden} />
                            </div>
                          </OverlayTrigger>
                          </Col>
                            </Row>
                                </Col>
                                <Col md={6}>
                                    <h6>Datos geográficos</h6>

                                    <label className='form-control-label'>Origen</label>
                                    
                                    <select className='form-control mb-3'>
                                        <option value="">Selecciona la bodega de origen</option>
                                    </select>

                                    <BoxEditarGeoDatosOrdenIndividual pedido={pedido} token={token} onSucessChange={(data) => onSucessChange(data)} />

                                <div className="mb-3 card p-3" style={{ backgroundColor: "#eceef1" }}>
                                <BoxConductor conductor={pedido.conductor} />
                                    </div>
                                </Col>
                            </Row>
                            { loadingFicha ? <Spinner animation='border' /> : <Button variant="success" onClick={() => guardarCambiosFicha()} >GUARDAR CAMBIOS DE LA FICHA</Button>}
                          </Tab>
                          
                          <Tab eventKey="products" title="Productos" className='pt-4'>
                            <Row>
                            
                            </Row>
                            <h6 className='mb-0'>Productos</h6>
                            <Button size='sm' variant='outline-primary mb-3' onClick={() => agregarProducto()}><i className="fa-solid fa-circle-plus"></i> AGREGAR</Button>
                          <TablaProductos onDelete={pos => onDelete(pos)} productos={pedido.productos} />
                          </Tab>
                          <Tab eventKey="home" title="Campos personalizados" className='pt-4'>
                             <MetaDatosOrden id_orden={id} />
                          </Tab>
                          <Tab eventKey="webhooks" title="Webhooks" className='pt-4'>
                            <LogsListado token={token} condicion_default={{ id_recurso: pedido._id }} />

                          </Tab>
                          <Tab eventKey="etiquetas" title="Etiquetas" className='pt-4'>
                            <Row>
                                <Col md={3}>
                                    <GeneradorEtiquetas idrecurso={pedido._id} etiquetas={etiquetas} token={token} />
                                </Col>
                            </Row>

                          </Tab>
                        </Tabs>

                            
                          </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
       
        </div>
        </div>
      }

    return mostrarInformacion()
}

export default DetallesPedido