import { useState } from "react"
import Header from "../../../components/Header"
import SelectorRangoFechas from "../../general/selector_rango_fechas"
import MapaMonitoreoFlota from "../../mapas/mapa_monitoreo_floja"
import Recursos from "../../ordenes/estadistica/recursos"
import { obtenerRangos } from "../../../lib/helpers/opcionesfecha"
import { Button, Col, Row } from "react-bootstrap"

const RendimientoProveedores = (props) => {
  const initialDate = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
}
const [ selectionRange, setSelectionRange ] = useState(initialDate)
const { desde, hasta } = obtenerRangos(initialDate.startDate, initialDate.endDate)
const condicion_busqueda_ordenes = { $or:[
  { fecha_min_entrega: { $gte: desde, $lte: hasta } },
  { fecha_max_entrega: { $lte: hasta, $gte: desde } },
  { fecha_max_entrega: { $gte: hasta }, fecha_min_entrega: { $lte: desde } },
]
}
  const [ condicion, setCondicion ] = useState(condicion_busqueda_ordenes)

  const cambiarRangoFecha = (item) => {
    setSelectionRange(item.selection)
  }

  const actualizarCondicion = () => {
    const { desde, hasta } = obtenerRangos(selectionRange.startDate, selectionRange.endDate)
    const condicion_busqueda = { $or:[
      { fecha_min_entrega: { $gte: desde, $lte: hasta } },
      { fecha_max_entrega: { $lte: hasta, $gte: desde } },
      { fecha_max_entrega: { $gte: hasta }, fecha_min_entrega: { $lte: desde } },
    ]
    }
    setCondicion(condicion_busqueda)
  }

    return <div>
        <Row className='mb-3'>
                  <Col md={6}><SelectorRangoFechas titulo_label="Rango de entrega" onChange={(item) => cambiarRangoFecha(item)} /></Col>
                  <Col md={2}>
                  <label className='form-control-label'>Click para refrescar resultados</label>
                  <Button variant='dark' onClick={() => actualizarCondicion()}>ACTUALIZAR DATOS</Button>
                  </Col>
              </Row>
                <Recursos condicion_default={condicion} />
    </div>
}

export default RendimientoProveedores