const idioma_default = "ES"

function reducer( state = idioma_default, { type, payload } ){
    switch( type ){
        case 'cambiarIdioma': {
            return payload
        }
        default:
            return state
    }
}

export default reducer