import { Tab, Tabs } from "react-bootstrap"
import Header from "../../../components/Header"
import ListadoEstadosCargaTiempoReal from "./listado_tiempo_real"

const ActividadAuditoria = (props) => {

    return <div>
       <h3 className="mb-3">Estados de carga</h3>
              <Tabs defaultActiveKey="auditar">
              <Tab eventKey="auditar" title="Sin auditar" className='pt-4'>
              <ListadoEstadosCargaTiempoReal condicion_default={{ notificar_planel_flota: true, auditado: false }} />
              </Tab>
              <Tab eventKey="auditado" title="Auditadas" className='pt-4'>
              <ListadoEstadosCargaTiempoReal condicion_default={{ notificar_planel_flota: true, auditado: true }} />
              </Tab>
              </Tabs>
    </div>
}

export default ActividadAuditoria