import 'moment/locale/es';
import { Button, Card, Col, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap'
import { useState } from "react";
import data from '../../lib/backend/data'
import { toast } from "react-toastify";
import { fechaATexto } from "../../lib/helpers/helpers";
import { Link } from "react-router-dom";
import OrdenesListado from '../ordenes/listado';

const DetailFull = (props) => {
    let vehiculo = false
    const centro_mapa = { lat: -33.458829, lng: -70.654644 }
    const zoom_mapa = 14
    let marcadores = []
    const [codigos, setCodigos] = useState(props.vehiculo.codes)
    const [eliminandoCodigo, setEliminandoCodigo] = useState(false)
    const [loadingCodigo, setLoadingCodigo] = useState(false)
    const token = props.token ? props.token : false

    if(props.vehiculo){
        if(props.vehiculo){
            vehiculo = props.vehiculo
        }
    }

    const eliminarCodigo = async (id) => {
        setEliminandoCodigo(true)
        return fetch(`${data.urlapi}/conductores/deletecode?id=${id}`, {
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(pros => pros.json())
        .then(pros => {
            if(!pros){
                toast.error('Sin datos')
                return setLoadingCodigo(false)
            } else if(pros.errorMessage){
                toast.error(pros.errorMessage)
                return setLoadingCodigo(false)
            } else if(pros.deletedCount === 1){
                const i = codigos.findIndex(co => co._id === id)
                if(i > -1){
                    codigos.splice(i,1)
                    toast.success('Borrado exitosamente')
                    setCodigos(codigos)
                }
            }
            return setEliminandoCodigo(false)
        })
        .catch(error => {
            toast.error('No se pudo realizar esta operación')
            return setEliminandoCodigo(false)
        })
    }
    
    const mostrarCodigos = (codes) => {
        if(codes.length < 1) return <p>No hay códigos generados</p>
        return <div>
            {
                codes.map((codigo,i) => {
                    return <Card key={`code-${codigo._id}`} className="p-2 mb-3">
                        <h5 className="mb-0">{codigo.code} { eliminandoCodigo === true ? <Spinner animation="border" /> : <Button style={{ fontSize: 11 }} size="sm" variant="link" className="text-danger p-0" onClick={() => eliminarCodigo(codigo._id)} >ELIMINAR</Button> }</h5>
                        <p className="mb-0" style={{ fontSize: 12 }}><b>Creado:</b> {fechaATexto(codigo.createdAt)}</p>
                        </Card>
                })
            }
        </div>
    }

    const crearCodigo = () => {
        setLoadingCodigo(true)
        return fetch(`${data.urlapi}/conductores/newcode?id=${vehiculo._id}`, {
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(pros => pros.json())
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingCodigo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCodigo(false)
            } else if(res._id){
                codigos.push(res)
                toast.success('Código creado exitosamente')
                setCodigos(codigos)
            }
            return setLoadingCodigo(false)
        })
        .catch(error => {
            toast.error('No se pudo realizar esta operación')
            setLoadingCodigo(false)
        })
    }

    if(!vehiculo) return <div className='p-5'>
    <Row className="justify-content-md-center">
        <Col md={6} className="text-center">
          <img alt="pedidos" src="images/pedidos.png" style={{ width: "50%" }} />
        <h4>Selecciona un vehículo para visualizarlo</h4>
        </Col>
    </Row>
    </div>

    return <div>
        <Row>
            <Col md={12}>
              <Card bg="primary" className="p-3 mb-3 text-white">
                <h4>Conductor</h4>

                <label className="mb-0 form-control-label d-block">Nombres y apellidos</label>
                <h5>{vehiculo.nombres} {vehiculo.apellidos}</h5>
                <label className="mb-0 form-control-label d-block">ID</label>
                <h5>{vehiculo.rut ? vehiculo.rut : 'Sin datos'}</h5>
                <label className="mb-0 form-control-label d-block">Móvil</label>
                <h5 className="mb-0">{vehiculo.phone}</h5>

              </Card>
                <h4><i className="fas fa-truck"></i> Vehículo <Link to={`/vehiculos/edit/${vehiculo._id}`}><Button style={{ fontSize: 11 }} size="sm" variant="outline-primary">EDITAR</Button></Link> </h4>
                <label className="mb-0 form-control-label d-block">Modelo</label>
                <h5>{vehiculo.modelo}</h5>
                <label className="mb-0 form-control-label d-block">Año</label>
                <h5>{vehiculo.ano}</h5>
                <label className="mb-0 form-control-label d-block">Patente</label>
                <h5>{vehiculo.patente ? vehiculo.patente.toUpperCase() : '' }</h5>

                <hr />
                <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >
                                Código de acceso para app móvil
                              </Tooltip>
                            }
                            >
                            <div>
                            <h4><i class="fas fa-lock"></i> Códigos { loadingCodigo === true ? <Spinner animation="border" /> : <Button style={{ fontSize: 11 }} size="sm" variant="outline-primary" onClick={()=>crearCodigo()}>CREAR NUEVO</Button> }</h4>
                            </div>
                          </OverlayTrigger>
                {mostrarCodigos(codigos)}
            </Col>
            <Col md={12}>
                <hr/>
                <OrdenesListado titulo="Órdenes activas" upWhenClick={false} showAddButton={false} showExporter={false} showFilter={false} token={token} condicion_default={{ idconductor: vehiculo._id, show_on_app: true }} />
            </Col>
        </Row>
    </div>
}

export default DetailFull