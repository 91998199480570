import { useSelector } from "react-redux"
import { urlapi } from "../../../lib/backend/data"
import { toast } from "react-toastify"
import { useEffect, useState } from "react"
import Cargando from "./cargando"
import { Accordion, Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import EstadisticaMapa from "../../estadisticas/proveedores/estadistica_mapa"
import SinDatos from "../../general/sin_registros"
import { procesarDatosGraficosEstadisticaConductorLastMile, procesarOTIFParaGraficos } from "../../../lib/helpers/conductores"
import GraficoOtiff from "../../estadisticas/grafico_otif"
import MapaEstadisticaProveedor from "../../../components/Mapas/mapa_estadistica_proveedor"
import GraficoVehiculo from "../../actividad/flota/graficos_vehiculo"
import IdentificadorConductor from "../../actividad/flota/identificador_vehiculo"

const Recursos = (props) => {
    const {
        condicion_default
    } = props
    const [ filtros, setFiltros ] = useState([])
    const [ condicion_proveedor, setCondicionProveedor ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const session = useSelector(state => state.miusuario)

    const obtenerDatos = async () => {
        setLoading(true)
        return fetch(`${urlapi}/ordenes/recursos`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_default
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setFiltros(res)

                const ordenes = res.reduce((prev,next) => {
                    let nuevos = []

                    if(next.items){
                        if(Array.isArray(next.items) !== false){
                            for( const recurso of next.items ){
                                if(recurso.items){
                                    if(Array.isArray(recurso.items) !== false) nuevos = recurso.items
                                }
                            }
                        }
                    }
                    return [...prev, ...nuevos]
                }, [])
                const ids_ordenes = ordenes.map(o => o._id)
                setCondicionProveedor({ id_pedido: { $in: ids_ordenes } })

            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoading(false)
        })
    }

    useEffect(() => {
        obtenerDatos()
    }, [ condicion_default ])

    const mostrarItems = (items) => {
        if(items.length < 1) return <h5>Nada por aquí</h5>

        return items.map((filtro,ifil) => {

            const graphs_v2     = procesarDatosGraficosEstadisticaConductorLastMile(filtro.items)
            const otif_mes      = procesarOTIFParaGraficos(graphs_v2)
            let puntos          = []
            const pedidos       = filtro.items
            let ids_conductores = []

            if(pedidos){
                if(Array.isArray(pedidos) !== false){
                    for( const pedido of pedidos ){

                        if(pedido.idconductor) if(!ids_conductores.includes(pedido.idconductor)) ids_conductores.push(pedido.idconductor)

                        if(pedido.location){
                            if(pedido.location.coordinates){
                                if(Array.isArray(pedido.location.coordinates) !== false){
                                    if(pedido.location.coordinates.length > 1){
                                        puntos.push({
                                            lat: pedido.location.coordinates[1],
                                            lng: pedido.location.coordinates[0],
                                            detalles: pedido
                                        })
                                    }
                                }
                            }
                        }
                    }
                }
            }


            return <div key={`filtr_${(ifil+1)}`} className="mb-3">
            <Card className="text-dark m-0">
            <OverlayTrigger
                              placement="auto"
                              overlay={
                                <Tooltip >Toca para ver este proveedor</Tooltip>
                              }
                              >
              <Accordion.Toggle as={Card.Header} eventKey={(ifil+1).toString()}>
                <Row>
                    <Col md={2} xs={12}><h6 className="mb-0"><p className="mb-0">Nombre</p><b className="text-primary hover">{filtro.razon_social}</b></h6></Col>
                    <Col md={10} xs={12}><GraficoOtiff typeView="barras" data={otif_mes} /></Col>
                </Row>
              </Accordion.Toggle>
              </OverlayTrigger>
              <Accordion.Collapse eventKey={(ifil+1).toString()}>
                <Card.Body>
                <Row>
                    <Col><h6 className="mb-0"><p className="mb-0">Rut</p>{filtro.rut}</h6></Col>
                    <Col><h6 className="mb-0"><p className="mb-0">Código</p>{filtro.codigo}</h6></Col>
                    <Col><h6 className="mb-0"><p className="mb-0">Cantidad de órdenes gestionadas</p>{filtro.cantidad_registros}</h6></Col>
                </Row>
                <EstadisticaMapa hideMonthSelection={true} condicion_default={condicion_proveedor} propietario={filtro.rut} />
                <Row>
                {
                    ids_conductores.map(idconductor => {
                        return <Col md={6} className="mb-3" key={`idconductor-${idconductor}`}>
                        <IdentificadorConductor id={idconductor} />
                        <GraficoVehiculo condicion_default={{...condicion_default, idconductor }} /> 
                        </Col>
                    })
                }
                </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
              
            </div>
          })
    }

    const mostrarDatos = () => {
        if(loading) return <Cargando />


        return filtros.map((segmento,i) => {
            if(segmento.items.length < 1) return <SinDatos/>
            return <div key={`segmento-${i}`}>
                <h4>{segmento.label}</h4>
                <input className="form-control mb-3" placeholder="BUSCAR PROVEEDOR" />
            <Accordion>
                {mostrarItems(segmento.items)}
            </Accordion>
            
        </div>
        })
        

    }

    return <div>
        {mostrarDatos()}
    </div>
}

export default Recursos