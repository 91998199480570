import { useState } from "react"
import { Button, Card, Col, Modal, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap"
import { confirmAlert } from "react-confirm-alert"
import Skeleton from "react-loading-skeleton"
import { toast } from "react-toastify"
import data from '../../../lib/backend/data'
import { eventos, eventoToString } from "../../../lib/helpers/integraciones"
import Switch from "react-switch";
import { fechaATexto } from "../../../lib/helpers/helpers"

const BoxWebhook = (props) => {
    const token = props.token ? props.token : false
    const [webhook, setWebhook] = useState(props.webhook ? props.webhook : false)
    const [editar, setEditar] = useState(false)
    const [removiendo, setRemoviendo] = useState(false)
    const [newWebhook, setNewWebhook] = useState(webhook)
    const [loadingCreacion, setLoadingCreacion] = useState(false)

    if(!webhook) return false

    if(removiendo === true) return <div>
        <Skeleton  height={200}/>
    </div>

    const editarWH = () => {
        return setEditar(true)
    }
    
    const handleChange = (e) => {
        const { name, value } = e.target
        newWebhook[name] = value
        return setNewWebhook({...{}, ...newWebhook})
    }

    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/integraciones/webhook?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                if(props.onDeleteItem) props.onDeleteItem(res._id)
            }
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const guardarCambios = async () => {
        setLoadingCreacion(true)
        return fetch(`${data.urlapi}/integraciones/webhook`,{
            method:'PUT',
            body: JSON.stringify(newWebhook),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingCreacion(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCreacion(false)
            } else if(res._id){
                toast.success('Guardado exitosamente')
                setWebhook({...{}, ...res})
                setEditar(false)
            }
            return setLoadingCreacion(false)
        })
        .catch(error => {
            toast.error(error.message)
            return setLoadingCreacion(false)
        })
    }

    const handleChangeSwitch = (e) => {
        newWebhook.active = e
        return setNewWebhook({...{}, ...newWebhook})
    }

    const formularioEditar = () => {
        return <Row>
        <Col xs={12}>
            <Button size="sm" variant="outline-primary" className="mb-2 mr-2" style={{ fontSize: 11 }} onClick={() => setEditar(false)} >CANCELAR</Button>
            <Button size="sm" variant="outline-danger" className="mb-2" style={{ fontSize: 11 }} onClick={() => solicitarEliminar(webhook._id)} >ELIMINAR</Button>
        </Col>
        <Col md={12}>
            <p className="mb-2">Url</p>
            <input defaultValue={webhook.url} name="url" className="form-control mb-2" onChange={handleChange} />
        </Col>
        <Col md={4}>
            <p className="mb-0">Evento</p>
            <select className="form-control" name="evento" defaultValue={webhook.evento} onChange={handleChange}>
                        <option value="" >Seleccione</option>
                        {
                            eventos.map((e,i) => {
                                return <option key={`op-${i}`} value={e.value} >{e.label}</option>
                            })
                        }
                    </select>
        </Col>
        <Col md={2}>
            <p className="mb-2">Activo</p>
            <Switch checked={newWebhook.active} onChange={handleChangeSwitch} />
        </Col>
        <Col md={2}>
            <p className="mb-0">Formato</p>
            <h4>{webhook.format ? webhook.format.toUpperCase() : 'Sin información'}</h4>
        </Col>
        <Col md={2}>
            <p className="mb-0">Versión del API</p>
            <h4>{webhook.version}</h4>
        </Col>
        <Col md={12} className="mt-2">
            {
                loadingCreacion === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" className="mb-2 mr-2" style={{ fontSize: 13 }} onClick={() => guardarCambios()} >GUARDAR CAMBIOS</Button>
            }
        </Col>
    </Row>
    }

    if(editar===true) return formularioEditar()

    return <Row>
    <Col xs={12}><Button size="sm" variant="outline-primary" className="mb-2" style={{ fontSize: 11 }} onClick={() => editarWH()} >EDITAR</Button></Col>
    <Col md={12}>
        <p className="mb-2">Url</p>
        <h6>{webhook.url}</h6>
    </Col>
    <Col md={4}>
        <p className="mb-0">Evento</p>
        <h4>{eventoToString(webhook.evento)}</h4>
    </Col>
    <Col md={2}>
        <p className="mb-0">Formato</p>
        {webhook.active === true ? <h4 className="text-success">ACTIVO</h4> : <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >Pausado el {fechaATexto(webhook.ultima_fecha_pausado)}</Tooltip>
                            }
                            ><h4 className="text-secondary">PAUSADO</h4></OverlayTrigger> }
    </Col>
    <Col md={2}>
        <p className="mb-0">Formato</p>
        <h4>{webhook.format ? webhook.format.toUpperCase() : 'Sin información'}</h4>
    </Col>
    <Col md={2}>
        <p className="mb-0">Versión del API</p>
        <h4>{webhook.version}</h4>
    </Col>
</Row>
}

export default BoxWebhook