import { useEffect, useState } from 'react'
import { Row, Col, Button, Table, Card, Spinner, Accordion, OverlayTrigger, Tooltip, Form, Tabs, Tab } from 'react-bootstrap'
import data, { urlapi } from '../../lib/backend/data'
import { toast } from 'react-toastify';
import { DateRange } from 'react-date-range';
import { es } from 'date-fns/locale'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addMoreDays, formatDateHoy, obtenerRangos } from '../../lib/helpers/dates';
import Select from 'react-select';
import { confirmAlert } from 'react-confirm-alert';
import { obtenerZonasDePedidos } from '../../lib/helpers/rutas';
import BoxEditarGeoDatosOrden from '../ordenes/box_editar_geodata';
import FiltroFechas from '../general/filtro_fechas';
import SelectorEstados from '../estados_carga/selector';
import ContadorEstado from '../ordenes/contador_estados';
import { getDireccion, getNombreDestinatario, obtenerGeoValor } from '../../lib/helpers/pedidos/pedidos';
import TablaOrdenes from '../ordenes/tabla_registros';
import { useParams } from 'react-router-dom';
import BoxAsignacionRuta from './asignaciones/box_asignacion';
import MapaRuteo from '../../components/Mapas/mapa_ruteo';
import CotizadorProveedorTransporte from '../proveedores_transporte/cotizador';
import DetailFull from './detalle-full';

const EditarRuta = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: addMoreDays(new Date(),1),
        key: 'selection',
    }
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ ruta, setRuta ] = useState({})
    const [ conductores, setConductores ] = useState([])
    const [ loadingConductores, setLoadingConductores ] = useState(true)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(false)
    const [ loadingRuta, setLoadingRuta ] = useState(true)
    const [ conductor, setConductor ] = useState(false)
    const [ loadingCreacion, setLoadingCreacion ] = useState(false)
    const [ descripcion, setDescripcion ] = useState('')
    const [ showButtonUpdateDate, setShowButtonUpdateDate ] = useState(false)
    const [ capacidad, setCapacidad ] = useState(30)
    const [ pedidos, setPedidos ] = useState([])
    const [ rutasCreadas, setRutasCreadas ] = useState([])
    const [ pedidosSinLatLong, setPedidosSinLatLong ] = useState(0)
    const [ loadingReasignacion, setLoadingReasignacion ] = useState(false)
    const [ seleccionPedidos, setSeleccionPedidos ] = useState(false)
    const [ pedidosInvalidos, setPedidosInvalidos ] = useState([])
    const [ loadingOrdenes, setLoadingOrdenes ] = useState(false)
    const [ selectionRangeBusqueda, setSelectionRangeBusqueda ] = useState(initialDate)
    const [ filtroEstado, setFiltroEstado ] = useState([])
    const [ ordenes, setOrdenes ] = useState([])
    const [ comunascoordenadas, setComunasCoordenadas ] = useState([])
    const [ checlBoxIds, setCheckBoxIds ] = useState([])
    const [ loadingZonas, setLoadingZonas ] = useState([])
    const [ tipoFechaBusqueda, setTipoFechaBusqueda ] = useState('todos')
    const token = props.token ? props.token : false
    const {
        id
    } = useParams()
    const idruta = id


    const onChangeFecha = (e) => {
        console.log(e)
        setSelectionRangeBusqueda(e)
    }

    const onChangeTipoBusqueda = (e) => {
        setTipoFechaBusqueda(e)
    }

    const reasignarPedidos = async (cond)=>{
        setLoadingReasignacion(true)
        const ids = ordenes.map(o => o._id)
        return fetch(`${urlapi}/ordenes/reasignar`,{
            method:'POST',
            body: JSON.stringify({
                id_ruta: ruta._id,
                ordenes: ids
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingReasignacion(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingReasignacion(false)
            } else if(res.success === true){
                toast.success("Reasignado exitosamente")
                setTimeout(() => {
                    window.location = '/rutas'
                }, 1000);
            }
            return setLoadingReasignacion(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingReasignacion(false)
        })
    }

    const obtenerOrdenes = async (cond)=>{
        setLoadingOrdenes(true)
        return fetch(`${urlapi}/ordenes/pedidos-custom-full`,{
            method:'POST',
            body: JSON.stringify({
                condicion: cond,
                campos: { 
                    estado_entrega: true, 
                    sub_estado_entrega: true,
                    pedido: true,
                    orden: true
                }
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingOrdenes(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingOrdenes(false)
            }
            if(Array.isArray(res) !== false){
                setOrdenes(res)
                // setTotal(res.total)
            }
            return setLoadingOrdenes(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingOrdenes(false)
        })
    }

    const buscarOrdenesFiltradas = async () => {
        const { desde, hasta } = obtenerRangos(selectionRangeBusqueda.startDate, selectionRangeBusqueda.endDate)
        console.log({ desde, hasta, selectionRangeBusqueda })
        if(tipoFechaBusqueda === 'creacion'){
            const condicion = { createdAt: { $gte: desde, $lte: hasta }, status: 'active' }
            if(filtroEstado.length > 0) condicion["estado_entrega.codigo_estado"] = { $in: filtroEstado }
            setCondicionBusqueda(condicion)
            obtenerOrdenes(condicion)
        } else if(tipoFechaBusqueda === 'actualizacion'){
            const condicion = { updatedAt: { $gte: desde, $lte: hasta }, status: 'active' }
            if(filtroEstado.length > 0) condicion["estado_entrega.codigo_estado"] = { $in: filtroEstado }
            setCondicionBusqueda(condicion)
            obtenerOrdenes(condicion)
        } else if(tipoFechaBusqueda === 'maxima-entrega'){
            //const condicion = { fecha_max_entrega: { $gte: new Date( formatDateHoy(selectionRange.startDate)), $lte: new Date(hasta.getTime()-1) } }
            const condicion = { $or:[
                { fecha_min_entrega: { $gte: desde, $lte: hasta } },
                { fecha_max_entrega: { $lte: hasta, $gte: desde } },
                { fecha_max_entrega: { $gte: hasta }, fecha_min_entrega: { $lte: desde } },
                ]
            }
            if(filtroEstado.length > 0) condicion["estado_entrega.codigo_estado"] = { $in: filtroEstado }
            setCondicionBusqueda(condicion)
            obtenerOrdenes(condicion)
        } else if(tipoFechaBusqueda === 'todos'){
            const condicion = {  }
            if(filtroEstado.length > 0) condicion["estado_entrega.codigo_estado"] = { $in: filtroEstado }
            setCondicionBusqueda(condicion)
            obtenerOrdenes(condicion)
        }
    }

    useEffect(() => {
        obtenerRuta(idruta)
        obtenerProveedores()
        obtenerZonas("CL")
    },[])

    const handleChangeCapacidad = (e) => {
        const { name, value } = e.target
        return setCapacidad(value)
    }

    const obtenerRuta = async (id) => {
        setLoadingRuta(true)
        return fetch(`${data.urlapi}/rutas?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingRuta(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingRuta(false)
            } else if(res._id){
                setRuta(res)
                setConductor(res.id_conductor)
                setDescripcion(res.descripcion)
                setSelectionRange({
                    startDate: new Date(res.entrega_desde),
                    endDate: new Date(res.entrega_hasta),
                    key: 'selection',
                })
                if(res.pedidos){
                    if(Array.isArray(res.pedidos) !== false){
                        setPedidos(res.pedidos)
                    }
                }
            }
            return setLoadingRuta(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingConductores(false)
        })
    }

    const obtenerZonas = async (codigopais) => {
        setLoadingZonas(true)
        return fetch(`${data.urlapi}/rutas/zonas?codigopais=${codigopais}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos de zonas')
                return setLoadingZonas(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingZonas(false)
            } else if(Array.isArray(res) !== false){
                setComunasCoordenadas(res)
            }
            return setLoadingZonas(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingZonas(false)
        })
    }

    const obtenerProveedores = async () => {
        setLoadingConductores(true)
        return fetch(`${data.urlapi}/conductores/list-full`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            } else if(Array.isArray(res) !== false){
                setConductores(res.map(c => ({ value: c._id, label: `${c.patente.toUpperCase()} ${c.modelo ? `· ${c.modelo.toUpperCase()}` : ""}` })))
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingConductores(false)
        })
    }

    const actualizarRuta = async () => {
        if(!conductor) return toast.error('Vehículo es requerido')
        const nuevo = {
            _id: ruta._id,
            id_conductor: conductor,
            descripcion
        }
        setLoadingCreacion(true)
        return fetch(`${data.urlapi}/rutas`,{
            method:'PUT',
            body: JSON.stringify(nuevo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingCreacion(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCreacion(false)
            } else if(res._id){
                toast.success('Guardada exitosamente')
                return setTimeout(() => {
                    window.location = '/rutas'
                }, 1000);
            }
            return setLoadingCreacion(false)
        })
        .catch(error => {
            toast.error(error.message)
            return setLoadingCreacion(false)
        })
    }

    const handleChangeDescripcion = (e) => {
        const { value } = e.target
        return setDescripcion(value)
    }

    const cambiarRangoFecha = (item) => {
        setShowButtonUpdateDate(true)
        return setSelectionRange(item.selection)
    }

    const handleChangeSelectConductor = (e) => {
        return setConductor(e.value)
    }

    const confirmarCambioFecha = async () => {
        setLoadingCreacion(true)
        return fetch(`${data.urlapi}/rutas/fecha`,{
            method:'PUT',
            body: JSON.stringify({
                id: ruta._id,
                fecha_min_entrega: selectionRange.startDate,
                fecha_max_entrega: selectionRange.endDate,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingCreacion(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCreacion(false)
            } else if(res._id){
                toast.success('Actualizado exitosamente')
            }
            return setLoadingCreacion(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingCreacion(false)
        })
    }

    const solicitarCambioFecha = (id) => {
        return confirmAlert({
            title: `¿Cambio de fechas?`,
            message: `Actualizar el rango de entrega modificará individualmente cada orden siempre y cuando éstas no estén finalizadas.`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarCambioFecha()
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const mostrarBotonActualizarFecha = () => {
        if(!showButtonUpdateDate) return false
        return <Button size="sm" className='w-100' onClick={()=>solicitarCambioFecha()} >ACTUALIZAR RANGO DE ENTREGA</Button>
    }

    const confirmarEliminadoRuta = () => {
        setLoadingRuta(true)
        return fetch(`${data.urlapi}/rutas?id=${ruta._id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingRuta(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingRuta(false)
            } else if(res._id){
                toast.success('Eliminada exitosamente')
                return setTimeout(() => {
                    window.location = '/rutas'
                }, 1000);
            }
            return setLoadingRuta(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingRuta(false)
        })
    }

    const solicitarEliminarRuta = () => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Estás a punto de eliminar esta ruta y todas las órdenes asociadas`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminadoRuta()
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    if(loadingRuta === true) return <Spinner animation='border' />
    if(loadingConductores === true) return <Spinner animation='border' />
    if(Object.keys(ruta).length < 1) return <Row className="justify-content-md-center">
    <Col md={6} className="text-center">
      <img src="images/pedidos.png" style={{ width: "50%" }} />
    <h4>No hay datos suficientes para visualizar esta sección</h4>
    </Col>
    </Row>
    const i = conductores.findIndex(c => c.value === ruta.id_conductor)
    let default_vehiculo = false
    if(i > -1) default_vehiculo = conductores[i]

    const inArray = (needle, haystack) => {
        var length = haystack.length;
        for(var i = 0; i < length; i++) {
            if(haystack[i].toLowerCase() == needle.toLowerCase()) return true;
        }
        return false;
    }

    const distance = (lat1, lon1, lat2, lon2, unit) =>  {
        if ((lat1 == lat2) && (lon1 == lon2)) {
            return 0;
        }
        else {
            var radlat1 = Math.PI * lat1/180;
            var radlat2 = Math.PI * lat2/180;
            var theta = lon1-lon2;
            var radtheta = Math.PI * theta/180;
            var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = dist * 180/Math.PI;
            dist = dist * 60 * 1.1515;
            if (unit=="K") { dist = dist * 1.609344 }
            if (unit=="N") { dist = dist * 0.8684 }
            return dist;
        }
    }

    const reordenarArrayComunas = (array) => {

        let nuevoarray = []
        let comunasusadas = []
  
  
        for (var i = 0; i < array.length; i++) {
  
          const comuna = nuevoarray[nuevoarray.length - 1] ? nuevoarray[nuevoarray.length - 1] : array[i]
          let distancias = []
  
          array.map(co => {
  
            if(inArray(comuna.nombre, comunasusadas)){
              return false
            }
  
            if(inArray(co.nombre, comunasusadas)){
              return false
            }
              const calculodistancia = distance(comuna.x, comuna.y, co.x, co.y, "K");
  
              if(calculodistancia > 0){
              distancias.push({nombre: co.nombre, distancia: calculodistancia, x: co.x, y: co.y }) 
              }
            
          })
  
          comunasusadas.push(comuna.nombre)
          
          if(i === 0){
            nuevoarray.push({nombre: array[0].nombre, disancia: 0, x: array[0].x, y: array[0].y})
          }
  
           if(distancias.length > 0){
  
            const minimo = distancias.reduce(function(prev, curr) {
                          return prev.distancia < curr.distancia ? prev : curr;
                      });
  
             nuevoarray.push(minimo)
  
           }
           
        }
  
        return nuevoarray;
  
      }

      const compareValues = (key, order = 'asc') => {
        return function innerSort(a, b) {
          if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            return 0;
          }
      
          const varA = (typeof a[key] === 'string')
            ? a[key].toUpperCase() : a[key];
          const varB = (typeof b[key] === 'string')
            ? b[key].toUpperCase() : b[key];
      
          let comparison = 0;
          if (varA > varB) {
            comparison = 1;
          } else if (varA < varB) {
            comparison = -1;
          }
          return (
            (order === 'desc') ? (comparison * -1) : comparison
          );
        };
      } 

      const seleccionarUbicacion = (payload) => {
        setSeleccionPedidos(payload)
        desactivarCheckbox()
      }

      const desactivarCheckbox = () => {
        const list = document.querySelectorAll(`input[type=checkbox]`);
            for (const checkbox of list) {
            checkbox.checked = false;
            }
            setCheckBoxIds([])
      }

      const moverPedidosARutaUnoAUno = (pos) => {

        let pedidos_mover = []
        let eliminar = []
        rutasCreadas.map((ruta,iruta) => {
            return ruta.pedidos.map((pedido,ipedido) => {
                console.log(pedido._id)
                if(!checlBoxIds.includes(pedido._id)) return false
                console.log(ipedido)
                pedidos_mover.push(pedido)
                eliminar.push({ ruta: iruta, pedido: ipedido })
            })
        })
        console.log(checlBoxIds)
        console.log(`${pedidos_mover.length} pedidos y ${checlBoxIds.length} ids`)
        rutasCreadas[pos].pedidos = [...rutasCreadas[pos].pedidos, ...pedidos_mover]
        for(const d of eliminar ){
            rutasCreadas[d.ruta].pedidos.splice(d.pedido,1)
        }
        setRutasCreadas(prev => [...[], ...rutasCreadas])
        setCheckBoxIds([])
        return desactivarCheckbox()
    }

      const moverPedidosARuta = (pos) => {
        const pedidos_a_mover = rutasCreadas[seleccionPedidos.indexRuta].pedidos.filter(pedido => {
            if(!pedido.orden) {
                return false
            }
            if(typeof pedido.orden !== "object") {
                return false
            }
            if(!pedido.orden.geo_datos) {
                return false
            }
            if(typeof pedido.orden.geo_datos !== "object") {
                return false
            }
            if(!pedido.orden.geo_datos.lat) {
                return false
            }
            if(!pedido.orden.geo_datos.level3) {
                return false
            }
            return pedido.orden.geo_datos.level3 === seleccionPedidos.comuna
        })
        
        const pedidos_editados = rutasCreadas[seleccionPedidos.indexRuta].pedidos.filter(pedido => {
            if(!pedido.orden) {
                return false
            }
            if(typeof pedido.orden !== "object") {
                return false
            }
            if(!pedido.orden.geo_datos) {
                return false
            }
            if(typeof pedido.orden.geo_datos !== "object") {
                return false
            }
            if(!pedido.orden.geo_datos.lat) {
                return false
            }
            if(!pedido.orden.geo_datos.level3) {
                return false
            }
            return pedido.orden.geo_datos.level3 !== seleccionPedidos.comuna
        })

        rutasCreadas[seleccionPedidos.indexRuta].pedidos = pedidos_editados
        rutasCreadas[pos].pedidos = [...rutasCreadas[pos].pedidos, ...pedidos_a_mover]
        setRutasCreadas(prev => [...[], ...rutasCreadas])
        return setSeleccionPedidos(false)

      }

      const deseleccinoarTodos = (query) => {
        const list = document.querySelectorAll(`${query} input[type=checkbox]`);
            for (const checkbox of list) {
            checkbox.checked = false;
            }
            verificarCheckBoxes()
      }

      const seleccinoarTodos = (query) => {
        const list = document.querySelectorAll(`${query} input[type=checkbox]`);
            for (const checkbox of list) {
            checkbox.checked = true;
            }
            verificarCheckBoxes()
      }

      const activarDesactivarModal = (ids) => {
        if(ids.length > 0){
            setSeleccionPedidos(false)
        }
      }

      const verificarCheckBoxes = (e) => {
        return setTimeout(() => {
            const list = document.querySelectorAll('input[type=checkbox]:checked');
            let ids = []
            for (const checkbox of list) {
                ids.push(checkbox.id)
            }
            activarDesactivarModal(ids)
            setCheckBoxIds(ids)
            return ids
        }, 100);
      }

      const MoverEntreRutasBox = () => {
        if(!seleccionPedidos){

            if(checlBoxIds.length > 0){
                return <Card className='mb-3 p-3' style={{ position: "fixed", bottom: 10, right: 10, zIndex:10 }}>
                    <h5 className='mb-0'>{checlBoxIds.length} Pedidos seleccionados</h5>
                    <p className='mb-2'>Toca la ruta para mover estos pedidos dentro</p>
                    <div style={{ maxHeight: 200, overflow: "scroll" }}>
                    {
                        rutasCreadas.map((route,ir) => {
                            return <div key={`ruta-${ir}`}>
                                <h5 className='hover' onClick={() => moverPedidosARutaUnoAUno(ir)}><i className="fas fa-arrow-circle-right"></i> Ruta {ir+1}</h5>
                                <hr className='hr'/>
                                </div>
                        })
                    }
                    </div>
                </Card>
            }

            return false
        }
        return <Card className='mb-3 p-3' style={{ position: "fixed", bottom: 10, right: 10, zIndex:10 }}>
            <h5>{seleccionPedidos.comuna} en ruta {seleccionPedidos.indexRuta+1} seleccionada</h5>
            <p className='mb-2'>Toca la ruta para mover estos pedidos dentro</p>
            <div style={{ maxHeight: 200, overflow: "scroll" }}>
            {
                rutasCreadas.map((route,ir) => {
                    return <div key={`ruta-${ir}`}>
                        <h5 className='hover' onClick={() => moverPedidosARuta(ir)}><i className="fas fa-arrow-circle-right"></i> Ruta {ir+1}</h5>
                        <hr className='hr'/>
                        </div>
                })
            }
            </div>
        </Card>
      }

      const mostrarRutasCreadas = () => {
          return <div>
            <MoverEntreRutasBox />
            <h5>{rutasCreadas.length} Rutas</h5>
            <BoxEditarGeoDatosOrden token={token} pedidos={pedidosInvalidos} />
            {
                rutasCreadas.map((route,ir) => {
                    
                    let zonas = obtenerZonasDePedidos(route.pedidos)

                    return <div key={`ruta-${ir}`}>
                        <h3 className='mb-0'>Ruta {ir+1}</h3>
                        <p className='mb-0'>Selecciona la ubicación para reasignar a otra ruta</p>
                        { zonas.map((com,iz) => {
                            let color =  "black"
                            if(seleccionPedidos !== false){
                                if(typeof seleccionPedidos === "object"){
                                    if(seleccionPedidos.indexRuta === ir && com === seleccionPedidos.comuna) color = '#007bff'
                                }
                            }
                            return <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip>Toca para reasignar</Tooltip>
                            }
                            ><span style={{ backgroundColor: color, borderRadius: 5, color: "white", padding: "2px 10px", display: "inline-block", fontSize: 11 }} key={`z-${iz}`} className='mr-3 hover mb-2'  onClick={() => seleccionarUbicacion({ comuna: com, indexRuta: ir })}>{com}</span></OverlayTrigger>
                        }) }
                        <Accordion className="mb-3">
                        <Card className="p-0">
                            <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor:'white' }} eventKey={`ruta-${ir}`}>
                            <div className="pl-2 pb-0"><OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip>Toca para ver las órdenes</Tooltip>
                            }
                            ><h5 className='mb-0'>{route.pedidos.length} pedidos</h5></OverlayTrigger></div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={`ruta-${ir}`}>
                            <Card.Body>
                                <h6>Detalle de los pedidos</h6>
                                <Button variant="outline-secondary" size="sm" className='mr-3 mb-3' onClick={() => seleccinoarTodos(`.ruta-${ir}`)}>{}Seleccionar todos</Button>
                                <Button variant="outline-secondary" size="sm" className='mr-3 mb-3' onClick={() => deseleccinoarTodos(`.ruta-${ir}`)}>Deseleccionar todos</Button>
                                <div className='table-responsive'>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Ref</th>
                                            <th>Destinatario</th>
                                            <th>Comuna</th>
                                            <th>Dirección</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        route.pedidos.map((pedido,ipedido) => {
                                            return <tr>
                                                <th>
                                                <Form.Check
                                                    inline
                                                    name={pedido._id}
                                                    type="checkbox"
                                                    className={`delpa-checkpedido ruta-${ir}`}
                                                    id={pedido._id}
                                                    onChange={verificarCheckBoxes}
                                                />
                                                </th>
                                                <th>{pedido.pedido}</th>
                                                <th>{getNombreDestinatario(pedido, ipedido)}</th>
                                                <th>{obtenerGeoValor(pedido,"level3")}</th>
                                                <th>{getDireccion(pedido)}</th>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                </Table>
                                </div>
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        </Accordion>
                        <hr/>
                        </div>
                })
            }
        </div>
      }

    const calcularRutasFinal = () => {

        let sinlatlong = 0;
        let invalidos = []
        let comunas = []
        let ordenes = []

        for( const pedido of pedidos ){
            ordenes.push(pedido)
            if(!pedido.orden) {
                invalidos.push(pedido)
                sinlatlong++
                continue
            }
            if(typeof pedido.orden !== "object") {
                invalidos.push(pedido)
                sinlatlong++
                continue
            }
            if(!pedido.orden.geo_datos) {
                invalidos.push(pedido)
                sinlatlong++
                continue
            }
            if(typeof pedido.orden.geo_datos !== "object") {
                invalidos.push(pedido)
                sinlatlong++
                continue
            }
            if(!pedido.orden.geo_datos.lat) {
                invalidos.push(pedido)
                sinlatlong++
                continue
            }
            if(!pedido.orden.geo_datos.level3) {
                invalidos.push(pedido)
                sinlatlong++
                continue
            }

            comunas.push(pedido.orden.geo_datos)

        }

        const cuantosporcomuna = comunas.reduce((contadorcomuna, comuna) => {
            contadorcomuna[comuna.level3] = (contadorcomuna[comuna.level3] || 0) + 1;
            return contadorcomuna;
        }, {})

        let cuantosporcomunaArray = Object.keys(cuantosporcomuna).map((key) => { return {nombre: key, valor: cuantosporcomuna[key]} } )
        cuantosporcomunaArray.sort(compareValues('valor', 'desc'))

        let comunas_sin_geo = []

        cuantosporcomunaArray.map((comuna,icomuna) => {
            const ubicacion = comunascoordenadas.findIndex(c => c.level3 === comuna.nombre)
            if(ubicacion > -1){
                cuantosporcomunaArray[icomuna].x = comunascoordenadas[ubicacion].lat
                cuantosporcomunaArray[icomuna].y = comunascoordenadas[ubicacion].lng
            } else {
                comunas_sin_geo.push(comuna)
                cuantosporcomunaArray[icomuna].x = 0
                cuantosporcomunaArray[icomuna].y = 0
            }
        })

/*
        for (var i = cuantosporcomunaArray.length - 1; i >= 0; i--) {

            if(typeof comunascoordenadas[cuantosporcomunaArray[i].nombre] !== 'undefined'){
            cuantosporcomunaArray[i].x = comunascoordenadas[cuantosporcomunaArray[i].nombre].lat
            cuantosporcomunaArray[i].y = comunascoordenadas[cuantosporcomunaArray[i].nombre].lng
            } else {
                console.log()
                cuantosporcomunaArray[i].x = 0
                cuantosporcomunaArray[i].y = 0
            }
        }
*/
        let comunasordenadas = reordenarArrayComunas(cuantosporcomunaArray)
        let contadorruta = 0
        let rutas = []
        const cantidadzonas = comunasordenadas.length

        comunasordenadas.map((c, pos) => {

            if(typeof rutas[contadorruta] == 'undefined'){
                rutas.push({ pedidos: []})
            }

            if(c.distancia > 8){
                          contadorruta++
                          rutas.push({ pedidos: []})
                        }

            for( const ped of ordenes ){
                if(!ped.orden) {
                    continue
                }
                if(typeof ped.orden !== "object") {
                    continue
                }
                if(!ped.orden.geo_datos) {
                    continue
                }
                if(typeof ped.orden.geo_datos !== "object") {
                    continue
                }
                if(!ped.orden.geo_datos.lat) {
                    continue
                }
                if(!ped.orden.geo_datos.level3) {
                    continue
                }

                if(ped.orden.geo_datos.level3 === c.nombre){
                    rutas[contadorruta].pedidos.push(ped)
                }

                
                if(typeof rutas[contadorruta] !== 'undefined'){

                    if(cantidadzonas === pos + 1){

                    } else {
                        if(rutas[contadorruta].pedidos.length >= capacidad){
                        contadorruta++
                        rutas.push({ pedidos: []})
                        }
                    }
                }      
            }
        })
        setPedidosSinLatLong(sinlatlong)
        setPedidosInvalidos(invalidos)
        return setRutasCreadas(rutas)
    }

    const accionesCrearRutas = () => {
        if(rutasCreadas.length < 1) return false
        return <div>
            <Button size="sm" variant="success" onClick={() => reasignarRuta()}>REDISTRIBUIR RUTA</Button>
        </div>
    }

    const reasignarRuta = () => {
        setLoadingCreacion(true)

        const rutasDividir = rutasCreadas.map(r => {
            const ids = r.pedidos.map(p => p._id)
            r.pedidos = ids
            return r
        })

        return fetch(`${urlapi}/rutas/reasignar`,{
            method:'POST',
            body: JSON.stringify({
                id_ruta: ruta._id,
                rutas: rutasDividir
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingCreacion(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCreacion(false)
            } else if(res.success === true){
                toast.success('Proceso realizado exitosamente')
                return setTimeout(() => {
                    window.location = '/rutas'
                }, 1000);
            }
            return setLoadingCreacion(false)
        })
        .catch(error => {
            toast.error(error.message)
            return setLoadingCreacion(false)
        })
    }

    return <div>
        <Tabs
            defaultActiveKey="detalles_ruta"
            className="mb-3"
        >
            <Tab eventKey="detalles_ruta" title="Detalles de la ruta">
                <DetailFull ruta={ruta} />
            </Tab>
            <Tab eventKey="editar_ruta" title="Editar ruta">
                <Row>
                    <Col md={4}>
                    <h5>Datos generales</h5>
                    <h3>#{ruta.id}</h3>
                    <label className='form-control-label d-block'>Descripción</label>
                    <input className='form-control mb-3' name="descripcion" defaultValue={ruta.descripcion} placeholder='Describe la ruta si lo deseas' onChange={handleChangeDescripcion} />


                    <label className='form-control-label d-block'>Selecciona el vehículo asignado para esta ruta</label>
                    <Select 
                                style={{ marginBottom: 10 }}
                                onChange={handleChangeSelectConductor}
                                isLoading={loadingConductores}
                                options={conductores}
                                defaultValue={default_vehiculo}
                                placeholder="Seleccionar vehículo..."
                                noOptionsMessage={()=>'Sin opciones'}
                                className="mb-3"
                                />

                    { loadingCreacion === true ? <Spinner animation='border' /> : <Button size="sm" variant="success" className='mb-5' onClick={()=>actualizarRuta()}>GUARDAR CAMBIOS</Button> }

                                <h5>Eliminar ruta</h5>
                    <p className='mb-0'>Eliminar la ruta también eliminará las órdenes asociadas, esta acción no se puede deshacer</p>
                    <Button size="sm" variant="link" className='p-0 text-danger' onClick={()=>solicitarEliminarRuta()}>ELIMINAR</Button>
                    </Col>
                    <Col md={8}>
                    <h5>Rango de entrega</h5>
                    <p>Este es el rango de fechas que tendrán las órdenes para entregarse</p>
                    {mostrarBotonActualizarFecha()}
                    <DateRange
                    locale={es}
        //            minDate={addDays(new Date(), -10)}
                    editableDateInputs={true}
                    onChange={item => cambiarRangoFecha(item)}
                    moveRangeOnFirstSelection={false}
                    ranges={[selectionRange]}
                    direction="vertical"
                    scroll={{ enabled: true }}
                    months={1}
                    />
                    </Col>
                </Row>
            </Tab>
            <Tab eventKey="agregar_ordenes" title="Adicionar órdenes">
            <Row>
        
                <Col md={6} className="mb-3">
                        <Card className='p-3'>
                            <h4>Incluir órdenes existentes</h4>
                            <p>Esta opción está diseñada para que además de importar un excel, puedas reasignar otras órdenes dentro de la ruta que estás a punto de crear.</p>
                            <h5>Buscar las órdenes</h5>
                            <Row>
                                <Col md={12}>
                                    <FiltroFechas defaultValue={tipoFechaBusqueda} onChangeFecha={onChangeFecha} onChangeTipoBusqueda={onChangeTipoBusqueda} />
                                </Col>
                                <Col md={12}>
                                    <SelectorEstados onChange={(data) => {
                                        console.log(data)
                                        return setFiltroEstado([data.value])
                                    }} token={token} />
                                    <Button size="sm" variant="outline-primary" className='mb-3' onClick={() => buscarOrdenesFiltradas()}>BUSCAR ÓRDENES</Button>
                                    {
                                        loadingOrdenes ? <div>
                                            <Spinner animation='border' />
                                            <h6>Cargando órdenes</h6>
                                        </div> : <div>
                                            {
                                                ordenes.length > 0 ? <ContadorEstado token={token} estados={filtroEstado} condicion_default={condicion_busqueda} /> : <h6>Sin datos encontrados</h6>
                                            }
                                        </div>
                                    }
                                    <OverlayTrigger
                                    placement={'top'}
                                    overlay={
                                    <Tooltip>Esta opción reordenará los pedidos en esta ruta y los sacará de sus respectivas rutas</Tooltip>
                                    }
                                    >
                                        {
                                            loadingReasignacion === true ? <Spinner animation='border' /> : <Button size="sm" variant="success" onClick={() => reasignarPedidos()}>REORDENAR {ordenes.length} ÓRDENES</Button>
                                        }
                                </OverlayTrigger>
                                <TablaOrdenes ordenes={ordenes} /> 
                                </Col>
                            </Row>
                        </Card>
                    </Col>
            </Row>
            </Tab>
            <Tab eventKey="ruteo" title="Rutear">
                <Row>
                <Col md={12}>
                    <h5>Gestionar {pedidos.length}</h5>
                    <label className='form-control-label'>Capacidad por vehículo</label>
                    <input className='form-control mb-3' value={capacidad} onChange={handleChangeCapacidad} />
                    <Button variant="outline-primary" className='mb-3' size="sm" onClick={() => calcularRutasFinal()}>CALCULAR</Button>
                    {accionesCrearRutas()}
                    <Row>
                        <Col md={4}>
                            {mostrarRutasCreadas()}
                        </Col>
                        <Col md={8}>
                            {rutasCreadas.length > 0 ? <MapaRuteo rutas={rutasCreadas} /> : false}
                        </Col>
                    </Row>
                </Col>
                </Row>
            </Tab>
            <Tab eventKey="invitacion" title="Asignar a un proveedor">
                <Row>
                <Col md={12}>
                    <BoxAsignacionRuta id_ruta={idruta} />
                </Col>
                </Row>
            </Tab>
            <Tab eventKey="cotizar" title="Cotización proveedor externo">
                <Row>
                <Col md={12}>
                    <CotizadorProveedorTransporte id_ruta={idruta} ordenes={pedidos} />
                </Col>
                </Row>
            </Tab>
        </Tabs>
</div>
}

export default EditarRuta