import { Row, Col } from 'react-bootstrap'

const PantallaError = (props) => {
    const {
        mensaje
    } = props
    return <div className='p-5'>
            <Row className="justify-content-md-center">
                <Col md={7} className="text-center">
                <h4>Lo sentimos</h4>
                <p>Parece que hubo un error crítico en la forma en que se visualizan estos datos, te sugerimos intenar de nuevo</p>
                { mensaje ? <p>{mensaje}</p> : false }
                </Col>
            </Row>
        </div>
}

export default PantallaError