import { useState } from "react"
import { fechaATextoSimple } from "../../lib/helpers/helpers"
import { Button, Card, Modal } from "react-bootstrap"
import { DateRangePicker } from "react-date-range"
import { es } from 'date-fns/locale'

const SelectorRangoFechas = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    const {
        titulo_label
    } = props
    const [ showModal ,setShowModal ] = useState(false)
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const titulo = titulo_label ? titulo_label : "Fecha de consulta"
    const cambiarRangoFecha = (item) => {
        if(props.onChange) props.onChange(item)
        return setSelectionRange(item.selection)
    }

    const handleClose = () => {
        setShowModal(false)
    }

    return <div>
        <Modal show={showModal} size="xl" onHide={()=>handleClose()} centered >
            <Modal.Header closeButton>
                <Modal.Title>Selecciona un rango de fechas</Modal.Title>
            </Modal.Header>
                                            <Modal.Body>
                                            <DateRangePicker
                                                    locale={es}
                                                    showSelectionPreview={false}
                                                    editableDateInputs={true}
                                                    onChange={item => cambiarRangoFecha(item)}
                                                    moveRangeOnFirstSelection={false}
                                                    ranges={[selectionRange]}
                                                    direction="horizontal"
                                                    scroll={{ enabled: true }}
                                                    months={2}
                                                />
                                                <Button className="d-block" onClick={() => handleClose()}>CONFIRMAR</Button>
                                            </Modal.Body>
                                        </Modal>
        <label className='form-control-label'>{titulo}</label>
        <Card className='p-2 text-dark' onClick={() => setShowModal(true)}><p className='mb-0 '>{fechaATextoSimple(selectionRange.startDate)} - {fechaATextoSimple(selectionRange.endDate)}</p></Card>
        
    </div>
}

export default SelectorRangoFechas