const control = {}

control.tiposServicioLogistica = () => {
    const datos = [
        { value: "23", label: 'Contenedores FCL'},
        { value: "24", label: 'Camiones LTL'},
        { value: "25", label: 'Condolidado Marítimo LCL'},
        { value: "26", label: 'Camiones FTL'},
        { value: "27", label: 'Bodega'},
        { value: "28", label: 'Aeropuerto'},
        { value: "100", label: 'Ecommerce Logística'},
        { value: "101", label: 'Bodega Cross Docking'},
        { value: "102", label: 'Bodegas Fullfilment'},
        { value: "103", label: 'Proyectos'},
    ]
    return datos
}

control.showByTipoServicio = (tipo) => {
    
    switch (tipo) {
        case "23": 
        return 'Contenedores FCL'
        case "24": 
            return 'Camiones LTL'
        case "25": 
            return 'Condolidado Marítimo LCL'
        case "26": 
            return 'Camiones FTL'
        case "27": 
            return 'Bodega'
        case "28": 
            return 'Aeropuerto'
        case "100": 
            return 'Ecommerce Logística'
        case "101": 
            return '>Bodega Cross Docking'
        case "102": 
            return 'Bodegas Fullfilment'
        case "103": 
            return 'Proyectos'
        default:
            return 'Ecommerce Logística'
    }

}

control.statusColor = (level) => {

    let color = "#dbdbde";

if ( level == 1 ) {
color = "#cad004";
} else if( level == 2 ){
color = "#d09d04";
} else if (level == 3) {
color = "#d03e04";
} else if (level == 4 ) {
color = "#5e72e4";
} else if (level == 5) {
color = "#64b612";
} else if (level == 6){
color = "#64b612";
} else if (level == 7){
color = "#64b612";
} else if (level == 9){
color = "#ff71a6";
} else if (level == 18){
color = "#a31be1";
} else if (level == 13){
color = "#000";
} 

return color;

}

control.statusString = (status) => {

let cadena = "";

if (status) {
if ( status == 1 ) {
cadena = "Preparado";
} else if( status == 2 ){
cadena = "Leído en el móvil";
} else if (status == 3) {
cadena = "Retirado en origen";
} else if (status == 4 ) {
cadena = "En reparto";
} else if (status == 5) {
cadena = "Entregado";
} else if (status == 6){
cadena = "Entrega Confirmada";
} else if (status == 7){
cadena = "Entrega Confirmada";
} else if (status == 9){
cadena = "Retiro de bodega";
} else if (status == 18){
cadena = "En CD Delpa";
} else if (status == 13){
cadena = "No entregado";
} else {
cadena = "Sin datos";
}
} else {
cadena = "Sin datos";
}
return cadena;
}

const statusString = (status) => {

    let cadena = "";
    
    if (status) {
    if ( status == 1 ) {
    cadena = "Preparado";
    } else if( status == 2 ){
    cadena = "Leído en el móvil";
    } else if (status == 3) {
    cadena = "Retirado en origen";
    } else if (status == 4 ) {
    cadena = "En reparto";
    } else if (status == 5) {
    cadena = "Entregado";
    } else if (status == 6){
    cadena = "Entrega Confirmada";
    } else if (status == 7){
    cadena = "Entrega Confirmada";
    } else if (status == 9){
    cadena = "Retiro de bodega";
    } else if (status == 18){
    cadena = "En CD Delpa";
    } else if (status == 13){
    cadena = "No entregado";
    } else {
    cadena = "Sin datos";
    }
    } else {
    cadena = "Sin datos";
    }
    return cadena;
}

control.shoyEstadoEntrega = (estadoentrega, estado_entrega) => {

    let estado_final = statusString(estadoentrega)
    if(estado_entrega){
      if(typeof estado_entrega === 'object'){
        if(estado_entrega.titulo){
          estado_final = estado_entrega.titulo
        }
      }
    }
  
    return estado_final
  
}

module.exports = control