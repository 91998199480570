import { Card, Col, Row } from "react-bootstrap"
import { fechaATexto } from "../../lib/helpers/helpers"
import { GrConnectivity } from 'react-icons/gr'

const BoxList = (props) => {
    const ruta = props.ruta ? props.ruta : false
    const seleccionado = props.seleccionado ? props.seleccionado : false

    if(!ruta) return false

    const clickRuta = (id) => {
        if(props.onClickRuta) return props.onClickRuta(id)
        return false
    }

    const mostrarVehiculo = (driver) => {
        if(!driver) return false
        return <div>
            <hr className="hr" />
            <h6 className="mb-0" style={{ fontWeight: 'lighter' }}><b>{driver.modelo}</b> {driver.ano}</h6>
            <h6 className="mb-0" style={{ fontWeight: 'lighter' }}>PATENTE: <b>{driver.patente ? driver.patente.toUpperCase() : "SIN PATENTE"}</b></h6>
        </div>
    }

    return <Card bg={ seleccionado === true ? 'primary' : 'white' } className={`p-3 ${seleccionado === true ? 'text-white' : 'text-dark'}`} onClick={() => clickRuta(ruta._id)}>
        { ruta.tipo_asignacion === "proveedor" ? <h6 className="mb-0" style={{ color: "black" }}><GrConnectivity size={20} color="white" /> GESTIONADA</h6> : false }
        <p style={{ fontSize: 11 }} className="mb-0"><b>Creado</b> {fechaATexto(ruta.createdAt)}</p>
        <h6 className="mb-0">RUTA {ruta.id} · <b style={{ fontWeight:'lighter' }}>{ruta.pedidos} ÓRDENES</b></h6>
        { ruta.descripcion ? <span style={{ fontSize:11, fontWeight: "normal" }}>{ruta.descripcion}</span> : false }
        { ruta.finalizado ? <h6 className="mb-0 mt-1">FINALIZADA</h6> : <h6 className="mb-0 mt-1">EN CURSO</h6> }
        <h6 className="mb-0"></h6>
        {mostrarVehiculo(ruta.vehiculo)}
</Card>
}

export default BoxList