import { Row, Col, Card } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'

const CargandoMainConteos = () => {
    return <Card className='p-3 mb-3'>
        <Row>
        <Col md={2}><Skeleton height={80} /></Col>
        <Col md={2}><Skeleton height={80} /></Col>
        <Col md={2}><Skeleton height={80} /></Col>
        <Col md={2}><Skeleton height={80} /></Col>
        <Col md={2}><Skeleton height={80} /></Col>
        <Col md={2}><Skeleton height={80} /></Col>
    </Row>
    </Card>
}

export default CargandoMainConteos