const validarDNIArgentina = (dni) => {
    const dniRegex = /^\d{7,8}$/; // Expresión regular para validar el formato de DNI

    // Validar el formato del DNI
    if (!dniRegex.test(dni)) {
        return false;
    }

    // Validar el número de verificación del DNI
    const dniNumero = parseInt(dni.substring(0, dni.length - 1), 10);
    const dniVerificacion = parseInt(dni[dni.length - 1], 10);
    const verificador = dniNumero % 23;
    const letrasValidas = 'ABCDEFGHJKLMNPQRSTUVWXY';
    const letraValida = letrasValidas[verificador];

    if (dniVerificacion !== letraValida) {
        return false;
    }

    return true;

}

module.exports = {
    validarDNIArgentina
}