import React, { Component} from 'react'
import { connect } from 'react-redux'
import { userService } from '../../services/user.service'
import { Row, Col, Container, Form, Button } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import { validateEmail } from '../../lib/helpers/helpers'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

class OlvideContrasena extends Component {
    constructor(props){
        super(props)
        userService.logout();
        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            errorLogin: ''
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleSubmit(){

        this.setState({ submitted: true });
        const { username, password } = this.state;

        if(validateEmail(username) !== true) return toast.error('Email inválido')

        this.setState({ loading: true });
        return setTimeout(() => {
            this.setState({ loading: false });
            return toast.success('Enviamos un correo electrónico, revisa tu bandeja de entrada')
        }, 1000);
        userService.login(username, password )
            .then(
                user => {
                    if(!user){
                        this.setState({ errorLogin: "Email o Contraseña erroneas", loading: false })
                    } else {
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                    }
                    
                },
                error => this.setState({ error, loading: false })
            );
    }
    
    render(){
        const { loading, errorLogin } = this.state
        return(
            <Container style={{ backgroundColor:'black' }}>
                
        <Row className="justify-content-md-center">
            <Col md={4} className="centerv">
            <img alt="logotipo" src="images/logo.png" style={{ width: 200 }} />
            <h5 className='mb-5' style={{ fontWeight:'lighter' }}>Transformamos la experiencia en software</h5>
                <h4>Ingres tu email para recuperar tu contraseña</h4>
            <Form.Group >
    <Form.Control type="email" name="username" placeholder="Email" onChange={this.handleChange} />
    </Form.Group>

  <Form.Group >  
    { loading ? <Spinner animation="border" /> : <Button onClick={() => this.handleSubmit() }  block variant="primary">ENTRAR</Button> }  
            { errorLogin ? <p className="text-danger">{errorLogin}</p> : false }
  </Form.Group>
    <h6 className='mb-5'>¿Ya tienes una cuenta? <Link to={`/login`}>Iniciar sesión</Link> </h6>

    <Row>
        <Col md={6}><Link to={'/terminos-condiciones'}>Términos y condiciones</Link> </Col>
        <Col md={6}><Link to={'/politicas-privacidad'}>Política de privacidad</Link> </Col>
    </Row>
    <p style={{ fontSize:12 }}>Al ingresar aceptas los Términos y Condiciones y la Política de Privacidad</p>
            </Col>
        </Row>

        
        
    </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(OlvideContrasena);