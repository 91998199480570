import React, { useState, useRef } from "react";
import { Button, Col, Row } from "react-bootstrap";
import QRCode from 'qrcode.react';
import { useReactToPrint } from 'react-to-print';
const Barcode = require('react-barcode');

const MostrarEtiquetas = React.forwardRef((props, ref) => {
  if(!props.etiquetas) return false
  if(props.etiquetas.length < 1) return false
  return <div ref={ref} >
    {
      props.etiquetas.map((etiqueta,i) => {
        const cantidad_total = props.etiquetas.filter(e => e.idrecurso === etiqueta.idrecurso).length
        return <div className="p-2 etiquetabodega" key={`etiqueta-${etiqueta._id}`} style={{ width: '100%' }}>
            <div style={{ textAlign: "center", padding: 5 }}>
              <img src="/images/logo.png" style={{ width: '40%' }} />
              { etiqueta.id ? <h4><b>ID</b>: {etiqueta.id}</h4> : false }
              <div className="qr" style={{  margin: '0 auto' }}><QRCode value={etiqueta._id} renderAs="svg" /></div>
            </div>
            <div style={{ textAlign: "center" }}>
              <h3>REF: {etiqueta.identificador}</h3>
              {etiqueta.destinatario ? <p className="mb-0" style={{ fontSize: 25, fontWeight: "bold" }} >{etiqueta.destinatario} { etiqueta.telefono ? <b>TELF. {etiqueta.telefono}</b> : false }</p> : false }
              {etiqueta.direccion ? <p className="mb-0" style={{ fontSize: 25, fontWeight: "bold" }} >{etiqueta.direccion}</p> : false }
              <h3>BULTO {etiqueta.numero} de {cantidad_total}</h3>
              <h3>Tracking ID</h3>
              <div className="barcode-etiqueta"><Barcode height={80} value={etiqueta._id} /></div>
            </div>
          { i < props.etiquetas.length -1 ? <div className="page-break"></div> : false }
        </div>
      })
    }
  </div>
})

const Etiquetas = (props) => {
  const etiquetas = props.etiquetas
  const componentRef = useRef()
  const pageStyle = `
  @media print {
    .page-break {
      page-break-before: always;
    }
    h3 {
      font-size: 40px !important;
    }
    .qr svg {
      width: 50%;
      height: auto;
      display: block;
      margin: 0 auto;
    }
    .etiquetabodega {
      width: 100%
    }
  }  
`;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle
  })

  const render = () => {

    return <div>
          <Button size="sm" style={{ marginBottom: 10 }} variant="success" onClick={() => handlePrint()}><i className="fas fa-barcode mb-0"></i> IMPRIMIR {etiquetas.length} ETIQUETAS</Button>
         <MostrarEtiquetas etiquetas={etiquetas} ref={componentRef} />
    </div>
  }

  return (
    <div>
      {render()}
    </div>
  );
}

export default Etiquetas;