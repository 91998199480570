const validarDNIColombia = (cedula) => {
    cedula = cedula.replace(/[^\d]/g, ''); // Eliminar caracteres no numéricos
    if (cedula.length !== 10 || !/^\d{10}$/.test(cedula)) {
      return false;
    }
  
    const coeficientes = [71, 67, 59, 53, 47, 43, 41, 37, 29, 23];
    let suma = 0;
  
    for (let i = 0; i < 10; i++) {
      const digito = parseInt(cedula.charAt(i), 10);
      suma += digito * coeficientes[i];
    }
  
    const resto = suma % 11;
    const digitoVerificador = (resto !== 0) ? 11 - resto : 0;
    return digitoVerificador === parseInt(cedula.charAt(9), 10);
}

const formatearTelefonoMovilColombia = (telefono) => {
  // Convertir el número a una cadena
  telefono = telefono.toString();

  // Eliminar cualquier caracter que no sea dígito
  telefono = telefono.replace(/\D/g, '');

  // Formatear el número con el código de área (dos primeros dígitos) y espacios en el resto del número
  return `${telefono.substr(0, 2)} ${telefono.substr(2, 3)} ${telefono.substr(5)}`;
}
module.exports = {
    validarDNIColombia,
    formatearTelefonoMovilColombia
}