import { Card } from "react-bootstrap"

const BoxList = (props) => {
    const conductor = props.conductor ? props.conductor : false
    const seleccionado = props.seleccionado ? props.seleccionado : false

    if(!conductor) return false

    const clickConductor = (id) => {
        if(props.onClickConductor) return props.onClickConductor(id)
        return false
    }

    return <Card className={`p-3 text-dark hover`} onClick={() => clickConductor(conductor._id)}>
    <h6 style={{ fontSize: 13 }} className="mb-0">{ seleccionado === true ? <i className="fa-solid fa-square-check text-primary"></i> : <i className="fa-regular fa-square"></i> } {conductor.nombres} {conductor.apellidos}</h6>
    <h5 style={{ fontWeight: 'lighter', fontSize: 15 }} className="mb-0">{conductor.modelo ? conductor.modelo.toUpperCase() : '' } {conductor.ano}</h5>
    <h4 className="mb-0" style={{ fontSize: 18 }}>{conductor.patente ? conductor.patente.toUpperCase() : "Sin información"}</h4>
</Card>
}

export default BoxList