import { useState } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { estilo_last_mile } from '../../lib/global/styles';
import { Link } from 'react-router-dom';
import { rutas } from '../../lib/routes/routes';
import { BiMenu } from 'react-icons/bi';
import { url_images } from '../../lib/global/data';
import { FaArrowLeft } from 'react-icons/fa';
import TopBar from './topbar';
import { useDispatch } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';

const Structure = (props) => {
    const {
        router
    } = props
    const [collapsed, setCollapsed] = useState(false);
    const icons_style = { maxWidth: 25 }
    const dispatch = useDispatch()
    const menuItemStyles = {
        root: {
          fontSize: 14,
          fontWeight: 700,
          color: 'white', // Ajusta el color del texto a blanco
        },
        button: {
            '&:hover': {
              backgroundColor: estilo_last_mile.color_primary
            }
        },
        SubMenuExpandIcon: {
            color: 'white',
          },
        subMenuContent: ({ level }) => ({
            backgroundColor: level === 0 ? estilo_last_mile.color_primary_dark : 'transparent', // Cambia el color de fondo del submenú aquí
        })
      }
    const menuItemStylesSignOut = {
        root: {
          fontSize: 14,
          fontWeight: 700,
          color: 'white', // Ajusta el color del texto a blanco,
          backgroundColor: "#fa941f"
        },
        button: {
            '&:hover': {
              backgroundColor: "#df8115"
            }
        },
        SubMenuExpandIcon: {
            color: 'white',
          },
        subMenuContent: ({ level }) => ({
            backgroundColor: level === 0 ? estilo_last_mile.color_primary_dark : 'transparent', // Cambia el color de fondo del submenú aquí
        })
      }

            return <div style={{ height: "100%"}}>
                <TopBar />
                <div style={{ display: 'flex', height: '100%', minHeight: '100%' }}>
                <Sidebar collapsed={collapsed} backgroundColor={estilo_last_mile.color_primary}>

                    <div style={{ padding: "20px 20px 0px 20px" }}>
                    <div className='hover' style={{ textAlign: collapsed ? "center" : "right", }} onClick={() => setCollapsed(!collapsed)}>
                        { !collapsed ? <div><FaArrowLeft size={20} color='white' /></div> : <BiMenu size={30} style={{  backgroundColor: "white", padding: "1px 2px", borderRadius: 5 }} /> }
                    </div>
                    </div>
                    <Menu menuItemStyles={menuItemStyles}>
                        <MenuItem icon={<img style={icons_style} src={`${url_images}/sidebar/Menu_Lateral/svg/inicio_v2.svg`} />} component={<Link to={`/`} />}>Inicio</MenuItem>

                        <SubMenu icon={<img style={icons_style} src={`${url_images}/sidebar/Menu_Lateral/svg/Actividad_v2.svg`} />} label="Actividad" >
                        <MenuItem component={<Link to={`/${rutas.actividad.slug}`}/>}> Entregas</MenuItem>
                        <MenuItem component={<Link to={`/${rutas.actividad_estados.slug}`} />} >Estados de carga</MenuItem>
                        <MenuItem component={<Link to={`/${rutas.actividad_auditoria.slug}`} />} >Auditoria App Movil</MenuItem>
                        <MenuItem component={<Link to={`/${rutas.rendimiento_proveedores.slug}`} />} >Rendimiento de proveedores</MenuItem>
                        <MenuItem component={<Link to={`/${rutas.operacion_en_curso.slug}`} />} >Entregas asignadas pendientes</MenuItem>
                        </SubMenu>

                        <SubMenu icon={<img style={icons_style} src={`${url_images}/sidebar/Menu_Lateral/svg/Ordenes_v2.svg`} />} label="Órdenes">
                        <MenuItem component={<Link to={`/${rutas.ordenes.slug}`} />} >Mis Órdenes</MenuItem>
                        <MenuItem component={<Link to={`/${rutas.ordenes_crear.slug}`} />} >Crear nueva</MenuItem>
                        <MenuItem component={<Link to={`/${rutas.rutas_importar_excel.slug}`} />} >Importar por excel</MenuItem>
                        <MenuItem component={<Link to={`/${rutas.rutas_importar_avanzado.slug}`} />} >Importar avanzado</MenuItem>
                        </SubMenu>

                        <SubMenu icon={<img style={icons_style} src={`${url_images}/sidebar/Menu_Lateral/svg/Proveedor_v2.svg`} />} label="Proveedores">
                            <MenuItem component={<Link to={`/${rutas.logistic_providers.slug}`} />} >Listado</MenuItem>
                        </SubMenu>

                        <SubMenu icon={<img style={icons_style} src={`${url_images}/sidebar/Menu_Lateral/svg/fota_v2.svg`} />} label="Flota">
                            <MenuItem component={<Link to={`/${rutas.vehiculos.slug}`} />}>Mi flota</MenuItem>
                            <MenuItem component={<Link to={`/${rutas.actividad_flota.slug}`} />} >Monitoreo</MenuItem>
                            <MenuItem component={<Link to={`/${rutas.proveedores.slug}`} />}>Categorías</MenuItem>
                        </SubMenu>

                        <SubMenu icon={<img style={icons_style} src={`${url_images}/sidebar/Menu_Lateral/svg/Clientes_v2.svg`} />} label="Clientes">
                            <MenuItem component={<Link to={`/${rutas.clientes.slug}`} />} >Listado</MenuItem>
                            <MenuItem component={<Link to={`/${rutas.facturacion.slug}`} />} >Facturación</MenuItem>
                        </SubMenu>


                        <SubMenu icon={<img style={icons_style} src={`${url_images}/sidebar/Menu_Lateral/svg/ruta_v2.svg`} />} label="Rutas">
                            <MenuItem component={<Link to={`/${rutas.rutas.slug}`} />}>Mis rutas</MenuItem>
                            <MenuItem component={<Link to={`/${rutas.rutas_asignaciones.slug}`} />}>Invitaciones</MenuItem>
                        </SubMenu>

                        <MenuItem icon={<img style={icons_style} src={`${url_images}/sidebar/Menu_Lateral/svg/Integraciones_v2.svg`} />} component={<Link to={`/${rutas.integraciones.slug}`} />}>Integraciones</MenuItem>
                        
                        {/* <SubMenu icon={<img style={icons_style} src={`${url_images}/sidebar/Menu_Lateral/svg/Actividad_v2.svg`} />} label="Configuración">
                        <MenuItem component={<Link to={`/${rutas.avanzada.slug}`} />} >General</MenuItem>
                        <MenuItem component={<Link to={`/${rutas.usuarios.slug}`} />} >Usuarios</MenuItem>
                        <MenuItem component={<Link to={`/${rutas.excel_formats.slug}`} />} >Formatos excel</MenuItem>
                    </SubMenu> */}

                        <SubMenu icon={<img style={icons_style} src={`${url_images}/sidebar/Menu_Lateral/svg/Bodega_v2.svg`} />} label="Bodega">
                            <MenuItem component={<Link to={`/${rutas.bodega_bodegas.slug}`} /> } >Mis bodegas</MenuItem>
                            <MenuItem component={<Link to={`/${rutas.bodega_expediciones.slug}`} /> } >Expediciones</MenuItem>
                            <MenuItem component={<Link to={`/${rutas.etiquetas.slug}`} /> } >Etiquetas</MenuItem>
                        </SubMenu>

                    </Menu>

                    <Menu menuItemStyles={menuItemStylesSignOut}>
                    <MenuItem icon={<img style={icons_style} src={`${url_images}/sidebar/Menu_Lateral/svg/SignOut.svg`} />} component={<div onClick={() => dispatch(cerrarSesion())} />}>Cerrar sesión</MenuItem>
                    </Menu>
                </Sidebar>
                <div style={{ width: "100%", height: "100%", overflowY: "scroll" }}> 
                    {router}
                </div>
    </div>
  </div>
}

export default Structure