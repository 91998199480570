import { Table } from "react-bootstrap"
import SinDatos from "../general/sin_registros"
import { AiTwotoneDelete } from 'react-icons/ai'

const TablaProductos = (props) => {
    const productos = props.productos ? props.productos : []
    if(Array.isArray(productos) !== true) return <SinDatos />
    if(productos.length < 1) return <SinDatos />

    const eliminar = (pos) => {
        if(props.onDelete) props.onDelete(pos)
    }
    return <div className="table-responsive">
        <Table bordered hover variant="dark">
            <thead>
                <tr>
                    <th>ACCIÓN</th>
                    <th>DESCRIPCIÓN</th>
                    <th>CÓDIGO</th>
                    <th>ANCHO</th>
                    <th>ALTO</th>
                    <th>LARGO</th>
                    <th>PESO</th>
                    <th>CANTIDAD</th>
                    <th>PRECIO</th>
                </tr>
            </thead>
            <tbody>
                {
                    productos.map((producto,i) => {
                        return <tr key={`producto-${i}`}>
                            <th style={{ textAlign: "center" }}><AiTwotoneDelete onClick={() => eliminar(i)} className="hover" style={{ color:"red", fontSize: 30 }} /></th>
                            <th><input className="form-control" defaultValue={producto.description} /></th>
                            <th><input className="form-control" defaultValue={producto.codigo_item} /></th>
                            <th><input className="form-control" defaultValue={producto.ancho} /></th>
                            <th><input className="form-control" defaultValue={producto.alto} /></th>
                            <th><input className="form-control" defaultValue={producto.largo} /></th>
                            <th><input className="form-control" defaultValue={producto.peso} /></th>
                            <th><input className="form-control" defaultValue={producto.quantity} /></th>
                            <th><input className="form-control" defaultValue={producto.price} /></th>
                        </tr>
                    })
                }
            </tbody>
        </Table>
    </div>

}

export default TablaProductos