import Skeleton from "react-loading-skeleton"
import { urlapi } from "../../../lib/backend/data"
import BoxConductor from "../../conductores/box_conductor"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

const IdentificadorConductor = (props) => {
    const {
        id
    } = props
    const [ loading, setLoading ] = useState(true)
    const [ conductor, setConductor ] = useState(false)
    const session = useSelector(state => state.miusuario)

    const obtenerDatos = async () => {
        return fetch(`${urlapi}/conductores/proveedor/detail?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
            } else if(res._id){
                setConductor(res)
            }
            return setLoading(false)
        })
        .catch(error => setLoading(false))
    }

    useEffect(() => {
        obtenerDatos()
    }, [])

    const mostrarInformacion = () => {
        if(loading) return <Skeleton />
        if(!conductor) return false
        return <div>
            <BoxConductor typeView="simple" conductor={conductor} />
        </div>
    }

    return <div>
        {mostrarInformacion()}
    </div>
}

export default IdentificadorConductor