import { useState } from "react"
import { Modal } from "react-bootstrap"
import ListadoPersonalizado from "./listado_custom"

const ProcesarCanalModal = (props) => {
    const [ openModal, setOpenModal ] = useState(false)

    const handleClose = () => {
        setOpenModal(false)
    }

    return <div>
        <Modal show={openModal} size="lg" onHide={()=>handleClose()} centered >
            <Modal.Header closeButton>
            <Modal.Title>PROCESAR CANAL</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="mb-0">Selecciona un canal para emitir los estados y acciones correspondientes asociadas al mismo</p>
                <ListadoPersonalizado onClick={(id) => {
                    if(props.onClick) props.onClick(id)
                    handleClose()
                }} />
            </Modal.Body>
        </Modal>
        <h6 className="mb-0" onClick={() => setOpenModal(true)}>PROCESAR CANAL</h6>
    </div>
}

export default ProcesarCanalModal