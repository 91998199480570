import DetalleLoteTarifa from '../../../subComponents/pricing/tarifas/lotes/detalles'

const LoteDetalles = (props) => {
    
        return <div className="fluid">
          <h1 className="h2">Detalles del lote</h1>
                    <DetalleLoteTarifa />
            </div>
}

export default LoteDetalles