import { Card, Col, Row } from "react-bootstrap"
import { fechaATexto } from "../../lib/helpers/helpers"
import { getDireccion, obtenerGeoValor } from "../../lib/helpers/pedidos/pedidos"
import MostrarGeoDatos from "./box_geo_datos"

const BoxList = (props) => {
    const pedido = props.pedido
    const seleccionado = props.seleccionado ? props.seleccionado : false
    const showGeoData = props.showGeoData ? props.showGeoData : false
    let destinatario = ''
    let titulo_estado = ''
    let titulo_sub_estado = ''
    let conductor = ''
    let patente = ''
    let color_estado = '#bebebe'

    if(typeof pedido.orden === 'object'){
        if(typeof pedido.orden.billing === 'object'){
            destinatario = pedido.orden.billing.first_name
        }
    }

    const clickPedido = (id) => {
        if(props.onClickPedido) return props.onClickPedido(id)
        return false
    }

    if(pedido.estado_entrega){
        if(typeof pedido.estado_entrega === 'object'){
            if(pedido.estado_entrega.titulo) titulo_estado = pedido.estado_entrega.titulo
            if(pedido.estado_entrega.color) color_estado = pedido.estado_entrega.color
        }
    }

    if(pedido.sub_estado_entrega){
        if(typeof pedido.sub_estado_entrega === 'object'){
            if(pedido.sub_estado_entrega.titulo) titulo_sub_estado = pedido.sub_estado_entrega.titulo
        }
    }

    if(pedido.conductor){
        if(typeof pedido.conductor === 'object'){
            if(pedido.conductor.nombres) conductor = pedido.conductor.nombres
            if(pedido.conductor.patente) patente = pedido.conductor.patente
        }
    }

    const mostrarVehiculo = (conductor, patente) => {
        if(!conductor) return "Sin asignar"
        let string = conductor
        if(patente) string = `${string} ${patente}`
        return string
    }

    const MostrarGeoDatosComponent = () => {
        if(showGeoData !== true) return false
        return <MostrarGeoDatos pedido={pedido} />
    }

    let string_estado = `${titulo_estado} ${titulo_sub_estado}`
    if(!titulo_estado && !titulo_sub_estado) string_estado = "Sin datos"

    return <Card bg={ seleccionado === true ? 'primary' : 'white' } className={`hover p-3 ${seleccionado === true ? 'text-white' : 'text-dark'}`} onClick={() => clickPedido(pedido._id)}>
        <Row>
            <Col xs={7}>
                <p style={{ fontSize:9, fontWeight:'normal', borderRadius:2, backgroundColor: color_estado, padding: 6, color:'white' }} className="mb-2">{string_estado.toUpperCase()}</p>
            </Col>
            <Col xs={5} className="text-right"><h6 style={{ fontSize: 11, padding: '5px 0px' }} className="mb-0">{pedido.pedido}</h6></Col>
            <Col xs={6}><h6 style={{ fontSize:13 }} className="mb-0">{destinatario}</h6></Col>
            <Col xs={6} className="text-right"><h6 style={{ fontSize:13 }} className="mb-2">{patente}</h6></Col>
            <Col md={12}><h6 style={{ fontSize:16, fontWeight:"bold" }} className="mb-0">{getDireccion(pedido)}</h6></Col>
            <Col md={12}><h6 style={{ fontSize:13, fontWeight:'lighter' }} className="mb-0">{ fechaATexto(pedido.createdAt)}</h6></Col>
            <Col md={12}>
            <MostrarGeoDatosComponent pedido={pedido} />
            </Col>
        </Row>
    </Card>
}

export default BoxList