import React, { useState, useRef } from "react";
import { Button, Col, Row } from "react-bootstrap";
import QRCode from 'qrcode.react';
import { useReactToPrint } from 'react-to-print';
const Barcode = require('react-barcode');

const MostrarEtiquetas = React.forwardRef((props, ref) => {
  if(!props.etiquetas) return false
  if(props.etiquetas.length < 1) return false
  return <div ref={ref} className="" >
    {
      props.etiquetas.map((etiqueta,i) => {
        let cantidad_total = 0
        if(etiqueta.idrecurso){
            cantidad_total = props.etiquetas.filter(e => e.idrecurso === etiqueta.idrecurso).length
        }
        return <div className="etiquetabodega" key={`etiqueta-${etiqueta._id}`} style={{ width: '100%' }}>
            <div className="text-center pt-2 pb-2">
              <img src="/images/logo.png" style={{ width: '60%' }} />
              { etiqueta.id ? <h1><b>ID 2</b>: {etiqueta.id}</h1> : false }
              <div className="qr" style={{ margin: '0 auto' }}><QRCode value={etiqueta._id} renderAs="svg" /></div>
            </div>
            <div className="text-center">
              { etiqueta.value ? <h2><b>LABEL NUMBER</b>: {etiqueta.value}</h2> : false }
              { etiqueta.identificador ? <h2>REF: {etiqueta.identificador}</h2> : false }
              {etiqueta.destinatario ? <p className="mb-0" style={{ fontSize: 25, fontWeight: "bold" }}>{etiqueta.destinatario} { etiqueta.telefono ? <b>TELF. {etiqueta.telefono}</b> : false }</p> : false }
              {etiqueta.direccion ? <p className="mb-0" style={{ fontSize: 25, fontWeight: "bold" }}>{etiqueta.direccion}</p> : false }
              { etiqueta.numero ? <h2>BULTO {etiqueta.numero} de {cantidad_total}</h2> : false }
              <h3>Tracking ID</h3>
              <Barcode height={80} value={etiqueta._id} />
            </div>
          { i < props.etiquetas.length -1 ? <div className="page-break"></div> : false }
        </div>
      })
    }
  </div>
})

const EtiquetasStatic = (props) => {
  const etiquetas = props.etiquetas
  const componentRef = useRef()
  const pageStyle = `
  @page {
    size: 62mm;
  }
  @media print {
    .page-break {
      page-break-before: always;
    }
    h3 {
      font-size: 40px !important;
    }
    .qr svg {
      width: 50%;
      height: auto;
      display: block;
      margin: 0 auto;
    }
  }  
`;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle
  })

  const render = () => {

    return <div>
        <Button size="sm" style={{ marginBottom: 10, fontSize: 11 }} variant="success" onClick={() => handlePrint()}>IMPRIMIR</Button>
         <MostrarEtiquetas etiquetas={etiquetas} ref={componentRef} />
    </div>
  }

  return (
    <div>
      {render()}
    </div>
  );
}

export default EtiquetasStatic