import { useSelector } from "react-redux"
import { urlapi } from "../../../lib/backend/data"
import { procesarDatosGraficosEstadisticaConductorLastMile, procesarOTIFParaGraficos } from "../../../lib/helpers/conductores"
import { StringMes, formatDateAno } from "../../../lib/helpers/dates"
import GraficosMesLastMile from "../../graficos/graficos_last_mile"
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap"
import { toast } from "react-toastify"
import { useEffect, useState } from "react"
import { formatMes } from "../../../lib/helpers/opcionesfecha"
import SinDatos from "../../general/sin_registros"
import GraficoOtiff from "../../estadisticas/grafico_otif"

const GraficoVehiculo = (props) => {
    const {
        condicion_default,
        hideMonthSelection,
        propietario
    } = props
    const [ condicionBuquedaOrdenes, setCondicionBusquedaOrdenes ] = useState(false)
    const [ loadingOrdenes, setLoadingOrdenes ] = useState(true)
    const [ tipoVisualizacion, setTipoVisualizacion] = useState('asignadas')
    const [ datosGraficosHistoricoV2, setDatosGraficosHistoricosV2 ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ showModal, setShowModal ] = useState(false)
    const [ ano, setAno] = useState(formatDateAno(new Date()))
    const [ vehiculosSeleccionados, setVehiculosSeleccionados] = useState([])
    const [ otif, setOtif ] = useState(false)
    const [ ordenes, setOrdenes ] = useState([])
    const [ mes, setMes] = useState(formatMes(new Date()))
    const [ pedidos, setPedidos ] = useState([])
    const token = session.tokenSession

    useEffect(() => {
        obtenerOrdenes(vehiculosSeleccionados, mes)
    }, [])

    const obtenerOrdenes = async (drivers, month) => {
        setLoadingOrdenes(true)
        return fetch(`${urlapi}/estadisticas/ordenes-avanzada`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_default,
                propietario,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingOrdenes(false)

            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingOrdenes(false)

            } else if(Array.isArray(res) !== false){
                setPedidos(res)
                const graphs_v2 = procesarDatosGraficosEstadisticaConductorLastMile(res)
                const otif_mes = procesarOTIFParaGraficos(graphs_v2)
                setOtif(otif_mes)
                setDatosGraficosHistoricosV2(graphs_v2)
            }
            return setLoadingOrdenes(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingOrdenes(false)
        })
    }

    const refrescarDatos = () => {
        obtenerOrdenes(vehiculosSeleccionados, mes)
    }

    const handleChangeMes = (e) => {
        const { value } = e.target
        setMes(value)
        obtenerOrdenes(vehiculosSeleccionados, value)
    }

    const handleChangeAno = (e) => {
        const { value } = e.target
        return setAno(value)
    }

    const handleChangeTipo = (e) => {
        const { value } = e.target
        return setTipoVisualizacion(value)
    }

    const handleClose = () => {
        setShowModal(false)
    }

    const seleccionarDia = (data) => {

        const dia = data.dia < 10 ? `${0}${data.dia}` : data.dia

        const fecha = `${ano}-${mes}-${dia}`
        let ids_query = []
        if(tipoVisualizacion === "asignadas"){
            for(const info_dia of datosGraficosHistoricoV2.asignadas.dias ){
                const dia_iteracion = info_dia.dia < 10 ? `${0}${info_dia.dia}` : info_dia.dia
                if(dia.toString() !== dia_iteracion.toString()) continue
                const ids = info_dia.datos.map(d => d._id)
                ids_query = [...ids_query, ...ids]
            }
        } else if(tipoVisualizacion === "completadas"){
            for(const info_dia of datosGraficosHistoricoV2.completadas.dias ){
                const dia_iteracion = info_dia.dia < 10 ? `${0}${info_dia.dia}` : info_dia.dia
                if(dia.toString() !== dia_iteracion.toString()) continue
                const ids = info_dia.datos.map(d => d._id)
                ids_query = [...ids_query, ...ids]
            }
        } else if(tipoVisualizacion === "retrasadas"){
            for(const info_dia of datosGraficosHistoricoV2.rechazadas.dias ){
                const dia_iteracion = info_dia.dia < 10 ? `${0}${info_dia.dia}` : info_dia.dia
                if(dia.toString() !== dia_iteracion.toString()) continue
                const ids = info_dia.datos.map(d => d._id)
                ids_query = [...ids_query, ...ids]
            }
        }
        if(ids_query.length > 0){
            setCondicionBusquedaOrdenes({ _id: { $in: ids_query } })
            setShowModal(true)
        }
    }

    const mostrarGraficos = () => {
        if(!datosGraficosHistoricoV2) return <SinDatos />
        switch ( tipoVisualizacion ) {
            case 'asignadas':
                return datosGraficosHistoricoV2.asignadas ?  <GraficosMesLastMile onClick={data => seleccionarDia(data)} datos={datosGraficosHistoricoV2.asignadas} height={'200px'} /> : <SinDatos />
            case 'completadas':
                return datosGraficosHistoricoV2.completadas ?  <GraficosMesLastMile onClick={data => seleccionarDia(data)} datos={datosGraficosHistoricoV2.completadas} height={'200px'} color="green" /> : <SinDatos />
            case 'retrasadas':
                return datosGraficosHistoricoV2.rechazadas ?  <GraficosMesLastMile onClick={data => seleccionarDia(data)} datos={datosGraficosHistoricoV2.rechazadas} height={'200px'} color='#c50f0f' colors={['#c50f0f']} /> : <SinDatos />
            default:
                break;
        }
    }

    if(loadingOrdenes===true) return <Spinner animation='border' />

    let puntos = []

            if(pedidos){
                if(Array.isArray(pedidos) !== false){
                    for( const pedido of pedidos ){
                        if(pedido.location){
                            if(pedido.location.coordinates){
                                if(Array.isArray(pedido.location.coordinates) !== false){
                                    if(pedido.location.coordinates.length > 1){
                                        puntos.push({
                                            lat: pedido.location.coordinates[1],
                                            lng: pedido.location.coordinates[0],
                                            detalles: pedido
                                        })
                                    }
                                }
                            }
                        }
                    }
                }
            }

    return <div>
            <GraficoOtiff typeView="small" data={otif} />
    </div>
}

export default GraficoVehiculo