import { Col, Row } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"

const CargandoDetalleFull = () => {
    return <Row>
        <Col md={3}>
            <Skeleton height={30} className="mb-3" />
            <Skeleton height={30} className="mb-3" />
            <Skeleton height={30} className="mb-3" />
            <Skeleton height={30} className="mb-3" />
            <Skeleton height={30} className="mb-3" />
            <Skeleton height={30} className="mb-3" />
            <Skeleton height={30} className="mb-3" />
            <Skeleton height={30} className="mb-3" />
            <Skeleton height={30} className="mb-3" />
            <Skeleton height={30} className="mb-3" />
            <Skeleton height={30} className="mb-3" />
            <Skeleton height={30} className="mb-3" />
        </Col>
        <Col md={9}>
            <Skeleton height={80} className="mb-3" />
            <Skeleton height={380} className="mb-3" />
            <Skeleton height={80} className="mb-3" />
        </Col>
    </Row>
}

export default CargandoDetalleFull