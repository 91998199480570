const debug = false

const datos = {
    urlapi: debug === true ? 'http://localhost:5001/api/1.0' : 'https://lastmile.delpasystem.com/api/1.0',
    key_local_storage_user: 'lm_delpa_user',
    key_local_storage_config: 'lm_delpa_config',
    key_local_storage_permissions: 'lm_delpa_access',
    pagina: 20,
    version_app: 4.9,
    mapbox_token: "pk.eyJ1IjoiY2FybG9zZGVscGEiLCJhIjoiY2xreTBqeDQwMHphcjNrbzJzbW4weTMweiJ9._pOF3JQBp_uT78K0B1KlbA",
    maps_key: "AIzaSyA2UVu71Lhw-lWjuTloT-VGIylp_63lgRY",
    timezone: "America/Santiago",
    configuracion_visual: {
        grafico_vehiculo_proveedor: true,
        grafico_general: true,
        eventos_conductores: true,
    },
    interfaz:{
        tamano_letra_tabla: 13,
        tabla_cabecera:{
            background: "#e0e0e0", 
            border: "none"
        },
        tabla_cabecera_texto: {
            fontSize: 13,
            color:"#616161"
        },
    },
    configs3: {
        dirName: "lastmile/",
        bucketName: "urrapp",
        region: "us-east-1",
        accessKeyId: 'AKIAW3FR56LCTXHZCW5T',
        secretAccessKey: '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',
    },
    mercadolibre_config:{
        client_id: "8686228924463533",
        redirect_uri: "https://lastmile.delpasystem.com/integraciones/mercadolibre/login"
    }
}

module.exports = datos