import { useState } from "react"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import { BsLayoutThreeColumns } from "react-icons/bs"
import { Esquema } from "../../lib/esquemas/tablas"
import { useDispatch, useSelector } from "react-redux"
import { modificarEsquema } from '../../redux/actions/esquemaTablas'

const PersonalizarColumnas = (props) => {
    const {
        screen
    } = props

    const [ openModal, setOpenModal ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const esquema_tablas = useSelector(state => state.esquema_tablas)
    const dispatch = useDispatch()

    const handleClose = () => {
        setOpenModal(false)
    }

    const handleChange = (e, column) => {
        const instancia_esquema = JSON.parse( JSON.stringify(esquema_tablas) )
        const i = instancia_esquema[screen].findIndex(e => e.key === column.key)
        if(i > -1){
            instancia_esquema[screen][i].active = e.target.checked
        } else {
            instancia_esquema[screen].push({
                ...column,
                active: e.target.checked
            })
        }
        return dispatch(modificarEsquema(instancia_esquema, session.tokenSession))
    }

    const grupos = []

    for ( const item of Esquema.ORDENES ){
        const {
            group
        } = item
        const i = grupos.findIndex(g => g.titulo === group)
        if(i > -1){
            grupos[i].items.push(item)
        } else {
            grupos.push({
                titulo: group,
                items: [ item ]
            })
        }
    }

    return <div>
        <Button size="sm" className='w-100' variant='light' onClick={() => setOpenModal(true)} ><BsLayoutThreeColumns /> COLUMNAS</Button>
        <Modal show={openModal} size="lg" onHide={handleClose}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Personaliza las columnas que deseas visualizar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                {
                    grupos.map((grupo,ig) => {
                        return <Col xs={12} key={`gr-${ig}`} className="mb-3">
                            <h5 style={{ textTransform: "uppercase" }}>{grupo.titulo}</h5>
                            <Row>
                            {
                                grupo.items.map((column,i) => {
                                    let checked = false
                                    const pos =  esquema_tablas[screen].findIndex(e => e.key === column.key)
                                    if(pos > -1) checked = esquema_tablas[screen][pos].active === true ? true : false

                                    return <Col md={4} key={`column-${i}`} className="mb-3">
                                        <Form.Group className="mb-1" controlId={column.key}>
                                        <Form.Check type="switch" defaultChecked={checked} name={column.key} label={column.label} onChange={(e) => handleChange(e, column)} />
                                        </Form.Group>
                                    </Col>
                                })
                            }
                            </Row>
                            { grupos.length === (ig+1) ? false : <hr className="hr" /> }
                        </Col>
                    })
                }
                
            </Row>
        </Modal.Body>
        
      </Modal>
    </div>
}

export default PersonalizarColumnas