import { Esquema } from "../../lib/esquemas/tablas"

const ordenes = Esquema.ORDENES.map(ob => ({...ob, active: true }))

const data_default = {
    ordenes,
    tour_dismiss: false
}

function reducer( state = data_default, { type, payload } ){
    switch( type ){
        case 'modificarEsquema': {
            return payload
        }
        case 'reiniciarEsquema': {
            return data_default
        }
        default:
            return state
    }
}

export default reducer