export const centerMapCoordinates = (coords) => {
    if (coords.length === 1) {
      return coords[0];
    }
  
    let x = 0.0;
    let y = 0.0;
    let z = 0.0;
  
    for (let coord of coords) {
      let latitude = coord.lat * Math.PI / 180;
      let longitude = coord.lng * Math.PI / 180;
  
      x += Math.cos(latitude) * Math.cos(longitude);
      y += Math.cos(latitude) * Math.sin(longitude);
      z += Math.sin(latitude);
    }
  
    let total = coords.length;
  
    x = x / total;
    y = y / total;
    z = z / total;
  
    let centralLongitude = Math.atan2(y, x);
    let centralSquareRoot = Math.sqrt(x * x + y * y);
    let centralLatitude = Math.atan2(z, centralSquareRoot);
  
    return {
      lat: centralLatitude * 180 / Math.PI,
      lng: centralLongitude * 180 / Math.PI
    };
  }

  export const calcularCentroYZoom = (coordenadas) => {
    let latMin = coordenadas[0].lat;
    let latMax = coordenadas[0].lat;
    let lngMin = coordenadas[0].lng;
    let lngMax = coordenadas[0].lng;
  
    // Encontrar los valores mínimos y máximos de latitud y longitud
    coordenadas.forEach(coordenada => {
      latMin = Math.min(latMin, coordenada.lat);
      latMax = Math.max(latMax, coordenada.lat);
      lngMin = Math.min(lngMin, coordenada.lng);
      lngMax = Math.max(lngMax, coordenada.lng);
    });
  
    // Calcular el centro de las coordenadas
    const centroLat = (latMin + latMax) / 2;
    const centroLng = (lngMin + lngMax) / 2;
    const centro = { lat: centroLat, lng: centroLng };
  
    // Calcular el nivel de zoom necesario para mostrar todos los marcadores
    const GLOBE_WIDTH = 256; // Ancho del globo terráqueo en píxeles
    const ZOOM_MAX = 21; // Nivel de zoom máximo en Google Maps
    let zoom = ZOOM_MAX;
  
    for (let i = ZOOM_MAX; i >= 0; i--) {
      if ((lngMax - lngMin) * Math.pow(2, i) * GLOBE_WIDTH <= window.innerWidth) {
        zoom = i;
        break;
      }
    }
  
    return { centro, zoom };
  }