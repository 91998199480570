import { useEffect, useState } from "react"
import { Button, Col, Modal, Row } from "react-bootstrap"
import Select from "react-select"
import { toast } from 'react-toastify';
import data from '../../lib/backend/data'

const BuscadorProveedores = (props) => {
    const [ openModal, setOpenModal ] = useState(false)
    const [ proveedores, setProveedores ] = useState([])
    const [ loadingProveedores, setLoadingProveedores ] = useState(true)
    const [ seleccionProveedores, setSeleccionProveedores ] = useState([])
    const token = props.token ? props.token : false

    const obtenerProveedores = async (condicion)=>{
        setLoadingProveedores(true)
        return fetch(`${data.urlapi}/proveedores/list-full`,{
            method:'POST',
            body: JSON.stringify({
                condicion
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingProveedores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingProveedores(false)
            } else if(Array.isArray(res) !== false){
                let data = res.map(p => ({ value: p._id, label: p.titulo }))
                setProveedores(data)
            }
            return setLoadingProveedores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingProveedores(false)
        })
    }

    useEffect(()=>{
        obtenerProveedores({})
    },[])

    const abrirFiltro = () => {
        return setOpenModal(true)
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const handleChangeProveedor = (e) => {
        let opciones = []
        if(e){
            e.map(valor => {
                opciones.push(valor.value)
            })
        }
        return setSeleccionProveedores(opciones)
    }

    const actualizar = async () => {
        if(seleccionProveedores.length > 0){
            if(props.onActualizar) props.onActualizar(seleccionProveedores)
            setOpenModal(false)
        }
        return 
    }

    const modalFiltro = () => {
        const estados = []
        const sub_estados = []
        return <Modal show={openModal} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Selección de proveedores múltiple</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col xs={12} className="mb-2">
                    <label className='d-block form-control-label'>Seleccione uno o varios proveedores</label>
                    <Select
                                    isLoading={loadingProveedores} 
                                    loadingMessage={()=>'Cargando información'}
                                    placeholder="Selecciona una opción"
                                    noOptionsMessage={()=>'Sin opciones'}
                                    onChange={handleChangeProveedor}
                                    isMulti={true}
                                    options={proveedores} 
                                />
                </Col>
                <Col md={12}>
                    <Button size="sm" variant="success" onClick={()=>actualizar()}>ACTUALIZAR</Button>
                </Col>
            </Row>
        </Modal.Body>
        
      </Modal>
    }

    return <div>
        {modalFiltro()}
        <Button size="sm" variant="outline-primary" className='mb-3' onClick={()=>abrirFiltro()}>AGRUPAR POR PROVEEDOR</Button>
    </div>
}

export default BuscadorProveedores