import { Spinner } from "react-bootstrap"

const CargandoFlotante = (props) => {
    const visualizar = props.visualizar ? props.visualizar : false
    const procesando = props.procesando ? props.procesando : 0
    const procesado = props.procesado ? props.procesado : 0
    const mensaje = props.mensaje ? props.mensaje : ''
    if(!visualizar) return false
    return <div className='loading-universal'>
        <Spinner animation="border" />
        { mensaje ? <h5 className='mb-0'>{mensaje}</h5> : false }
        <h5 className='mb-0'>Espere un momento...</h5>
        <h6>{procesado} de {procesando} procesadas</h6>
    </div>
}

export default CargandoFlotante