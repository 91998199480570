import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { Button, Card, Col, Modal, OverlayTrigger, Row, Spinner, Tab, Tabs, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import data, { timezone, urlapi } from '../../lib/backend/data'
import { formatDateHoy } from '../../lib/helpers/dates'
import { fechaATexto, fechaATextoSimple } from '../../lib/helpers/helpers'
import { horas, obtenerRangos } from '../../lib/helpers/opcionesfecha'
import { rutas } from '../../lib/routes/routes'
import DetailFull from '../ordenes/detalle-full'
import { es } from 'date-fns/locale'
import { DateRangePicker } from 'react-date-range'
import SelectorRangoFechas from '../general/selector_rango_fechas'
import DetailEstadosCarga from '../estados_carga/detail'
import MapaEstados from '../mapas/mapa_estados'
import SinDatos from '../general/sin_registros'
import MapaEstadosCarga from '../../components/Mapas/mapa_estados'
import { BiMap } from 'react-icons/bi'

const ActividadConductores = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    const token = props.token ? props.token : false
    const [ vehiculos, setVehiculos ] = useState([])
    const [ loadingVehiculos, setLoadingVehiculos ] = useState(true)
    const [ showModal, setShowModal ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(false)
    const [ pedidoSeleccionado, setPedidoSeleccionado ] = useState(false)
    const [ idSeleccionado, setIdSeleccionado ] = useState(false)
    const [ fechaFiltro, setFechaFiltro ] = useState(formatDateHoy(new Date()))
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const ids = props.id_conductores ? props.id_conductores : []

    useEffect(() => {
        obtenerActividadPorVehiculo()
    }, [])

    const obtenerActividadPorVehiculo = async () => {
        const { desde, hasta } = obtenerRangos(selectionRange.startDate, selectionRange.endDate)
        if(Array.isArray(ids) !== true) return false
        // if(ids.length < 1) return false
        setLoadingVehiculos(true)
        return fetch(`${data.urlapi}/conductores/actividad`,{
            method:'POST',
            body: JSON.stringify({
                ids,
                fecha: fechaFiltro,
                condicion_fechas: { $gte: desde, $lte: hasta },
                condicion_rutas: { $or:[
                    { entrega_desde: { $gte: desde, $lte: hasta } },
                    { entrega_hasta: { $lte: hasta, $gte: desde } },
                    { entrega_hasta: { $gte: hasta }, entrega_desde: { $lte: desde } },
                ]
                }
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')

            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setVehiculos(res.map(e => {
                    e.seleccionado = true
                    return e
                }))
            }
            return setLoadingVehiculos(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingVehiculos(false)
        })
    }

    const seleccionarEstado = (e) => {
        setShowModal(true)
        obtenerPedido(e.idrecurso)
    }

    const handleClose = () => {
        setShowModal(false)
    }

    const obtenerPedido = async (id) => {
        setLoadingDetalle(true)
        setIdSeleccionado(id)
        return fetch(`${urlapi}/ordenes/details-full?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingDetalle(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingDetalle(false)
            } else if(res.orden){
                setPedidoSeleccionado(res)
            }
            return setLoadingDetalle(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingDetalle(false)
        })
    }

    const actualizarEstadoListado = (estado_entrega, sub_estado_entrega) => {

    }

    const cambiarRangoFecha = (item) => {
        return setSelectionRange(item.selection)
    }


    const handleChangeFecha = (e) => {
        const { value } = e.target
        return setFechaFiltro(value)
    }

    const desplegarMapa = (i) => {
        const valor_nuevo = vehiculos[i].selected ? false : true
        vehiculos[i].selected = valor_nuevo
        return setVehiculos([...[], ...vehiculos])
    }

    const mostrarResultados = () => {

        try {
            if(loadingVehiculos === true) return <div>
        <Spinner animation='border' />
        <h5>Cargando operación de vehículos</h5>
        </div>

    const estados_emitidos = vehiculos.reduce((prev,next) => {
        let conteo = 0
        if(next.estados){
            if(Array.isArray(next.estados) !== false){
                conteo = next.estados.length
            }
        }
        return prev + conteo
    }, 0)

    const estados_emitidos_finalizados = vehiculos.reduce((prev,next) => {
        let conteo = 0
        if(next.estados){
            if(Array.isArray(next.estados) !== false){
                conteo = next.estados.filter(e => e.finalizar === true).length
            }
        }
        return prev + conteo
    }, 0)

    const ordenes_actualizadas = []
    for( const veh of vehiculos){
        for( const estado of veh.estados ){
            if(ordenes_actualizadas.includes(estado.idrecurso)) continue
            ordenes_actualizadas.push(estado.idrecurso)
        }
    }

            return <div>

    <Card className='p-3 mb-3 text-white' bg="dark">
    <Row>
        <Col md={2} >
        
        <OverlayTrigger
            placement={'top'}
            overlay={
            <Tooltip>Vehículos que enviaron al menos una actualización de estado</Tooltip>
            }><h6 style={{ fontSize: 12 }} className='mb-0'>Vehículos <i class="fa-solid fa-circle-question text-help"></i></h6></OverlayTrigger>
        <h5 className='mb-0'>{vehiculos.length}</h5>
        </Col>
        <Col md={2} >
        <OverlayTrigger
            placement={'top'}
            overlay={
            <Tooltip>Actualizaciones de estado enviados por el app conductor o sistema web</Tooltip>
            }><h6 style={{ fontSize: 12 }} className='mb-0'>Estados emitidos <i class="fa-solid fa-circle-question text-help"></i></h6></OverlayTrigger>
        <h5 className='mb-0'>{estados_emitidos}</h5>
        </Col>
        <Col md={3} >
        <OverlayTrigger
            placement={'top'}
            overlay={
            <Tooltip>Órdenes que tuvieron algún tipo de gestión</Tooltip>
            }><h6 style={{ fontSize: 12 }} className='mb-0'>Órdenes actualizadas <i class="fa-solid fa-circle-question text-help"></i></h6></OverlayTrigger>
        
        <h5 className='mb-0'>{ordenes_actualizadas.length}</h5>
        </Col>
        <Col md={3} >
        <OverlayTrigger
            placement={'top'}
            overlay={
            <Tooltip>Órdenes finalizadas</Tooltip>
            }><h6 style={{ fontSize: 12 }} className='mb-0'>Órdenes finalizadas <i class="fa-solid fa-circle-question text-help"></i></h6></OverlayTrigger>
        <h5 className='mb-0'>{estados_emitidos_finalizados}</h5>
        </Col>

    </Row>

    </Card>


                {
                vehiculos.map((veh,iv) => {
                    let titulo_fecha = fechaATextoSimple(fechaFiltro)

                    let ordenes_individuales = []
                    for( const estado of veh.estados ){
                        if(ordenes_individuales.includes(estado.idrecurso)) continue
                        ordenes_individuales.push(estado.idrecurso)
                    }

                    return <Card className="p-2 mb-3" >
                        <Row>
                            <Col xs={3}>
                                <h5 className='mb-0'>{veh.nombres}</h5>
                                <h5 className='mb-0'>{veh.apellidos}</h5>
                                <label className='form-control-label mb-0'>Móvil</label>
                                <h5>{veh.phone}</h5>
                                <hr className='mb-2 mt-2'/>
                                <label className='form-control-label mb-0'><i className="fas fa-truck"></i> Vehículo</label>
                                <h6>{veh.modelo} · {veh.patente ? veh.patente.toUpperCase() : false}</h6>
                                {
                                    veh.rutas.map(ruta => {
                                        return <Link target="_blank" style={{ display: "block", fontSize: 13 }} to={`${rutas.rutas.slug}?id=${ruta._id}`}>Ruta {ruta.id} · {ruta.cantidad} Órdenes</Link>
                                    })
                                }
                            </Col>
                            <Col xs={9}>
                            <div style={{ backgroundColor: "#f3f3f3", padding: 10, borderRadius: 5 }}>
                                    <h6 className='mb-0' style={{ fontSize: 14 }}>Fecha de registros: {titulo_fecha}</h6>
                                    <p className='mb-3' style={{ fontSize: 10 }}><i className="fas fa-mobile"></i> A continuación se muestran los datos obtenidos de eventos enviados a través de app conductor o web. correspondientes a este vehículo</p>
                                    
                                
                            <div className="pr-3 pl-3 pb-3">
                            <Tabs defaultActiveKey="1" id="uncontrolled-tab-example" >
                            <Tab eventKey="1" title="Vista general" className='pt-4'>
                                    <h6 style={{ fontSize: 14 }} className='mb-3'>Total asignaciones: {veh.total_asignaciones}</h6>
                                    <Row>
                                        <Col>
                                        <Card className='p-3 mb-3 text-center'>
                                            <h6 style={{ fontSize: 14 }} className='mb-0'>Estados emitidos</h6>
                                            <h6 className='mb-0'>{veh.estados.length}</h6>
                                        </Card>
                                        </Col>
                                        <Col>
                                        <Card className='p-3 mb-3 text-center'>
                                            <h6 style={{ fontSize: 14 }} className='mb-0'>Órdenes actualizadas</h6>
                                            <h6 className='mb-0'>{ordenes_individuales.length}</h6>
                                        </Card>
                                        </Col>
                                        <Col>
                                        <Card className='p-3 mb-3 text-center'>
                                            <h6 style={{ fontSize: 14 }} className='mb-0'>Órdenes Finalizadas</h6>
                                            <h6 className='mb-0'>{veh.estados.filter(e => e.finalizar === true).length}</h6>
                                        </Card>
                                        </Col>
                                    </Row>
                                    {
                                    horas.map((hora,ih) => {
                                        const porcentaje = 100 / horas.length
                                        let titulo_estado = `Estados enviados entre las ${hora.text}`
                                        if((ih+1) < horas.length){
                                            titulo_estado = `${titulo_estado} y las ${horas[ih+1].text}`
                                        } else {
                                            titulo_estado = `${titulo_estado} y las 00:00 del dia siguiente`
                                        }
                                        const estados = veh.estados.filter(e => {
                                            const fecha = DateTime.fromISO(e.fecha).setZone(timezone)
                                            return hora.num === fecha.hour
                                        })

                                        return <div style={{ width: `${porcentaje}%`, display: 'inline-block' }}>
                                            <div style={{ marginBottom: 15 }}>
                                                {estados.map(e => {
                                                    return <div style={{ marginBottom: 0, lineHeight:1 }}>
                                                        <OverlayTrigger
                                                            placement={'top'}
                                                            overlay={
                                                            <Tooltip> {fechaATexto(e.fecha)}</Tooltip>
                                                            }
                                                            >
                                                            <i style={{ fontSize:11, color: e.estado_color }} onClick={() => seleccionarEstado(e)} className="fas fa-circle hover"></i>
                                                        </OverlayTrigger>
                                                    </div>
                                                })}
                                            </div>
                                            <OverlayTrigger
                                                            placement={'top'}
                                                            overlay={
                                                            <Tooltip> {estados.length} {titulo_estado}</Tooltip>
                                                            }
                                                            >
                                                            <p style={{ fontSize: "0.5em"}}>{hora.text}</p>
                                                        </OverlayTrigger>
                                            
                                        </div>
                                    })
                                }
                                
                                <Button variant="light" className="w-100 mt-2 mb-3" style={{ fontSize: 14 }} onClick={() => desplegarMapa(iv)}><BiMap size={20} />{veh.selected ? "CERRAR MAPA" : "MOSTRAR MAPA"}</Button>
                                {veh.selected === true ? veh.estados.length > 0 ? <MapaEstadosCarga estados={veh.estados} /> : <SinDatos/> : false }
                            </Tab>
                            <Tab eventKey="2" title="Cronología de estados" className='pt-4'>
                                <div style={{ maxHeight: 300, padding:10, overflowY: "scroll", background: "#eaeaea" }}>
                            { veh.estados.length > 0 ? veh.estados.map(estado => {
                                return <DetailEstadosCarga size="sm" estado={estado} hideMetaData={true} hideCreator={true} showLink={true} />
                            }) : <SinDatos/> }

                                </div>
                            </Tab>
                            </Tabs>
                            </div>
                            </div>
                            </Col>
                        </Row>
                    </Card>
                })
            }
            </div>

        } catch (error) {
            return <SinDatos />
        }

        
    }

    return <div>
        <Modal show={showModal} size="xl" onHide={()=>handleClose()} centered >
            <Modal.Header closeButton>
                <Modal.Title>Detalles del pedido</Modal.Title>
            </Modal.Header>
                                            <Modal.Body>
                                                {
                                                    loadingDetalle ? <div>
                                                        <Spinner animation='border' />
                                                        <h6 className='mb-0'>Cargando datos, espere un momento...</h6>
                                                    </div> : <DetailFull actualizarEstadoListado={(estado_entrega, sub_estado_entrega) => actualizarEstadoListado(estado_entrega, sub_estado_entrega)} token={token} pedido={pedidoSeleccionado} />
                                                }
                                            </Modal.Body>
                                        </Modal>

                                        <Row className='mb-3'>
                                            <Col md={6} xs={12}>
                                                <label className='form-control-label'>Selecciona un día de consulta</label>
                                               <input className='form-control' type="date" value={fechaFiltro} onChange={handleChangeFecha} />
                                            </Col>
                                            <Col md={4} xs={12}>
                                                <label className='form-control-label d-block'>Click para refrescar resultados</label>
                                                <Button variant="dark" onClick={() => obtenerActividadPorVehiculo()}>ACTUALIZAR RESULTADOS</Button>
                                            </Col>
                                        </Row>

{mostrarResultados()}

    </div>
}

export default ActividadConductores