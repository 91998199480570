import { useDispatch, useSelector } from "react-redux"
import { Navigate, Route, Routes } from "react-router-dom"
// import 'bootstrap/dist/css/bootstrap.min.css'
import './app.scss';
import Home from '../Home';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-loading-skeleton/dist/skeleton.css'
import Usuarios from '../Usuarios';
import UsuariosCrear from '../UsuariosCrear';
import UsuariosEditar from '../UsuariosEditar';
import Ordenes from '../Ordenes';
import EstadosCarga from '../EstadosCarga';
import Flujos from '../Flujos';
import Rutas from '../Rutas';
import RutasImportar from '../RutasImportar';
import RutasImportarAvanzado from '../RutasImportar/avanzado';
import Vehiculos from '../Vehiculos';
import VehiculosCrear from '../VehiculosCrear';
import VehiculosEditar from '../VehiculosEditar';
import Configuracion from '../Configuracion';
import Actividad from '../Actividad';
import Proveedores from '../Proveedores';
import Estadisticas from '../Estadisticas';
import CamposPersonalizados from '../CamposPersonalizados';
import RutasCrear from '../RutasCrear';
import RutasEditar from '../RutasEditar';
import OrdenesCrear from '../OrdenesCrear';
import MiCuenta from '../Cuenta';
import OlvideContrasena from '../OlvideContrasena';
import RutasAsignaciones from '../RutasAsignaciones';
import Geocercas from '../Geocercas';
import Planificador from '../Planificador';
import Etiquetas from '../Etiquetas';
import BodegaUsuarios from '../BodegaUsuarios';
import Tracking from '../Tracking';
import ExcelFormats from '../ExcelFormats';
import Integraciones from '../Integraciones';
import Expediciones from '../Bodega/Expediciones';
import Canales from '../Canales';
import PoliticasPrivacidad from '../Informacion/politicas_privacidad';
import 'react-circular-progressbar/dist/styles.css';
import Widget from '../Widget';
import Login from '../Login';
import { rutas } from "../../lib/routes/routes";
import DetallesPedido from "../../subComponents/ordenes/detalles";
import Bodegas from "../Bodegas";
import ActividadFlota from "../../subComponents/actividad/flota";
import ActividadEstadosCarga from "../../subComponents/actividad/estados_carga";
import RendimientoProveedores from "../../subComponents/actividad/proveedores/rendimiento";
import OperacionMisClientes from "../../subComponents/actividad/misclientes";
import ActividadAuditoria from "../../subComponents/actividad/auditoria";
import MercadoLibreConexion from "../../subComponents/integraciones/plugins/autorization_pages/mercadolibre";
import DetallesApiless from "../../subComponents/integraciones/apiless/detalles";
import ProveedoresTransporte from "../../subComponents/proveedores_transporte";
import ProveedorEnlazadoDetalle from "../../subComponents/proveedores_transporte/enlace/detalles";
import FuncionesCrear from "../../subComponents/integraciones/funciones/crear_funcion";
import Clientes from "../../subComponents/clientes";
import ClienteEnlazadoDetalle from "../../subComponents/clientes/enlace/detalles";
import CrearLoteTarifas from "../../subComponents/pricing/tarifas/lotes/crear";
import LoteDetalles from "../Pricing/lotes";
import Facturacion from "../../subComponents/facturacion";
import { Chart, LineController, LineElement, PointElement, BarElement, LinearScale, Tooltip, Title,CategoryScale, ArcElement, Legend } from 'chart.js';
import Header from "../Header";
import Structure from "../Structure";
import ActividadLanding from "../../subComponents/landing";
Chart.register(LineController, LineElement, PointElement, BarElement, Legend, LinearScale, ArcElement, Title, Tooltip, CategoryScale);

const RoutesApp = (props) => {

    const session = useSelector(state => state.miusuario)

    const outside_session = () => {
        return <Routes>
            <Route path="/login" element={<Login/>} />
            <Route path="/tracking/:id" element={<Tracking/>} />
            <Route path="/widget/:id" element={<Widget/>} />
            <Route path='*' element={<Navigate to="/login" />} />
            <Route  path="/politicas-privacidad" element={<PoliticasPrivacidad/>} />
        </Routes>
    }

    // USUARIO NO LOGEADO
    if(!session) return outside_session()
    if(!session.data) return outside_session()

    const routerComponent = (
        <main className="col-md-12 ms-sm-auto col-lg-12 leftauto" style={{ paddingTop: 15, height: "100%" }}>
            <Routes>
                    <Route  path="/" element={<Home />} />
                    <Route  path={`/${rutas.custom_fields.slug}`} element={<CamposPersonalizados />} />
                    <Route  path={`/${rutas.excel_formats.slug}`} element={<ExcelFormats />} />
                    <Route  path={`/${rutas.geocercas.slug}`} element={<Geocercas />} />
                    <Route  path={`/${rutas.canales.slug}`} element={<Canales />} />
                    <Route  path="/account" element={<MiCuenta />} />
                    <Route  path={`/${rutas.actividad.slug}`} element={<Actividad />} />
                    <Route  path={`/${rutas.actividad_flota.slug}`} element={<ActividadFlota />} />
                    <Route  path={`/${rutas.actividad_estados.slug}`} element={<ActividadEstadosCarga />} />
                    <Route  path={`/${rutas.actividad_auditoria.slug}`} element={<ActividadAuditoria />} />
                    <Route  path={`/${rutas.rendimiento_proveedores.slug}`} element={<RendimientoProveedores />} />
                    <Route  path={`/${rutas.mercadolibre_config.slug}`} element={<MercadoLibreConexion />} />
                    <Route  path={`/${rutas.apiless_detail.slug}/:id`} element={<DetallesApiless />} />
                    <Route  path={`/${rutas.facturacion.slug}`} element={<Facturacion />} />
                    <Route  path={`/${rutas.clientes.slug}`} element={<Clientes />} />
                    <Route  path={`/${rutas.operacion_en_curso.slug}`} element={<OperacionMisClientes />} />
                    <Route  path="/estadisticas" element={<Estadisticas />} />
                    <Route  path="/configuracion" element={<Configuracion />} />
                    <Route  path="/bodegas" element={<Bodegas/>} />
                    <Route  path="/proveedores" element={<Proveedores/>} />
                    <Route  path="/vehiculos" element={<Vehiculos/>} />
                    <Route  path="/vehiculos/nuevo" element={<VehiculosCrear/>} />
                    <Route  path="/vehiculos/edit/:id" element={<VehiculosEditar/>} />
                    <Route  path="/usuarios" element={<Usuarios/>} />
                    <Route  path="/estados-carga" element={<EstadosCarga/>} />
                    <Route  path="/flujos-carga" element={<Flujos/>} />
                    <Route  path="/etiquetas" element={<Etiquetas/>} />
                    <Route  path={`/${rutas.integraciones.slug}`} element={<Integraciones/>} />
                    <Route  path={`/${rutas.integraciones_funciones_crear.slug}`} element={<FuncionesCrear />} />
                    <Route  path={`/${rutas.bodega_usuarios.slug}`} element={<BodegaUsuarios/>} />
                    <Route  path="/ordenes/:id" element={<DetallesPedido />} />
                    <Route  path="/ordenes" element={<Ordenes/>} />
                    <Route  path="/ordenes/nuevo" element={<OrdenesCrear/>} />
                    <Route  path="/planificador" element={<Planificador/>} />
                    <Route  path={`/${rutas.bodega_expediciones.slug}`} element={<Expediciones/>} />
                    <Route  path={`/${rutas.logistic_providers.slug}`} element={<ProveedoresTransporte />} />
                    <Route  path={`/${rutas.logistic_providers_link.slug}/:id`} element={<ProveedorEnlazadoDetalle />} />
                    <Route  path={`/${rutas.clientes.slug}/:id`} element={<ClienteEnlazadoDetalle />} />
                    <Route  path="/rutas" element={<Rutas/>} />
                    <Route  path={`/${rutas.landing.actividad.slug}/:slug`} element={<ActividadLanding/>} />
                    <Route  path="/rutas-asignaciones" element={<RutasAsignaciones/>} />
                    <Route  path="/rutas/importar" element={<RutasImportar/>} />
                    <Route  path="/rutas/importar-avanzado" element={<RutasImportarAvanzado/>} />
                    <Route  path={`/${rutas.pricing_lote_nuevo.slug}`} element={<CrearLoteTarifas />} />
                    <Route  path={`/${rutas.pricing_lotes.slug}/:id`} element={<LoteDetalles />} />
                    <Route  path="/rutas/nuevo" element={<RutasCrear/>} />
                    <Route  path="/rutas/:id" element={<RutasEditar/>} />
                    <Route  path="/usuarios/nuevo" element={<UsuariosCrear/>} />
                    <Route  path="/usuarios/edit/:id" element={<UsuariosEditar/>} />
                    <Route  path="/politicas-privacidad" element={<PoliticasPrivacidad/>} />
                    <Route  path="/tracking/:id" element={<Tracking/>} />
                    <Route  path="/widget/:id" element={<Widget/>} />
                    <Route  path="/olvide-password" element={<OlvideContrasena/>} />
                    <Route path='*' element={<Navigate to="/" />} />
                </Routes>
            </main>
    )

    return <Structure router={routerComponent} />
    return <div className="fluid">
    <Header />
    <div className="container-fluid">
    <div className="row">
            
        </div>
        </div>
    </div>
}

export default RoutesApp